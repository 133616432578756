import React, { FC, useEffect, useState } from 'react'
import { Select } from 'antd'
import { GET } from '../../../core/request'

export const CollegeSelect: FC<{
  value?: string
  onChange?: (value: string) => void
}> = props => {
  const [data, setData] = useState<
    {
      id: number
      gid: number
      groupname: string
    }[]
  >([])
  const [loading, setLoading] = useState(true)

  async function fetch() {
    const res = await GET('statistics/v2/departments', {})
    setData(res.data?.data || [])
    setLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      placeholder={'全部院系'}
      loading={loading}
      style={{ width: 200 }}
      allowClear
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        return (option?.children as string).includes(input)
      }}
    >
      {data.map(it => (
        <Select.Option key={it.id} value={it.id}>
          {it.groupname}
        </Select.Option>
      ))}
    </Select>
  )
}
