import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
  Typography
} from 'antd'
import { EditModel } from 'components/EditModel'
import { SelectRequiredRule } from 'components/EditModel/utils'
import {
  APP_SCOPE,
  MarkingMode,
  MarkingRange,
  ModalSizeLarge,
  Subject
} from 'config'
import { useCurrentExamContent } from 'hooks/useExamData'
import _ from 'lodash'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { Paper, QuestionBindGroup } from 'typing'
import { FORMATTER_DATE, FORMATTER_TIME } from 'utils'
import { flattenGroupedQuestions } from 'utils/question'
import { GET } from '../../../core/request'
import { useMarkingSettingEditContext } from '../store'
import { QuestionBindingGroup } from './QuestionBindingGroup'
import { TeacherSelect } from './TeacherSelect'
import {
  MarkingSettingProvider,
  useMarkingSettingContent
} from './useMarkingSettingState'
import { useQuestionBindGroupContent } from './useQuestionBindGroup'

const { Text } = Typography
const { confirm } = Modal

const Dialog: FC = props => {
  const { currentExam } = useCurrentExamContent()
  const edit = useMarkingSettingEditContext()

  const { teacherList, paperQuestions } = useMarkingSettingContent()
  const { questionBindGroup } = useQuestionBindGroupContent()

  const [showQuestionBindGroup, toggleQuestionBindGroup] = useState(false)
  useEffect(() => {
    toggleQuestionBindGroup(edit.data.review_mode === MarkingMode.GROUP_FLOW)
  }, [edit.data])

  function validate(value) {
    const bindQuestionCount = _.size(
      _.flatMap(questionBindGroup, q => q.questions)
    )
    const paperQuestionsFlat = flattenGroupedQuestions(paperQuestions)
    const isAllQuestionBind = bindQuestionCount === paperQuestionsFlat.length

    if (showQuestionBindGroup && !isAllQuestionBind) {
      Modal.warn({
        title: `还有${
          paperQuestionsFlat.length - bindQuestionCount
        }题未加入到试题分组`
      })
      return false
    }

    const isAllGroupBindTeacher = _.every(questionBindGroup, g => {
      return !!g.teachers.length
    })
    if (showQuestionBindGroup && !isAllGroupBindTeacher) {
      Modal.warn({
        title: '还有分组未添加判卷教师'
      })
      return false
    }

    const examEndTime = moment(edit.data.end_time)
    const isExamEnded = moment().isAfter(examEndTime)
    const beginReviewMin = isExamEnded
      ? moment().add(1, 'hours')
      : moment(edit.data.end_time).add(1, 'hour')

    if (value.review_time && value.review_time.isBefore(beginReviewMin)) {
      if (isExamEnded) {
        Modal.warn({
          title: '判卷开始时间必须在当前时间之后1小时'
        })
      } else {
        Modal.warn({
          title: '判卷开始时间必须在考试结束之后1小时',
          content: (
            <div>
              考试结束时间 <br />
              {moment(edit.data.end_time).format(
                `${FORMATTER_DATE} ${FORMATTER_TIME}`
              )}
            </div>
          )
        })
      }
      return false
    }
    return true
  }

  function onSubmit(value) {
    const data = { ...value } as Paper.PaperMarkingSettingFormData
    data.teachers = teacherList
    data.quest_group = _.reduce<QuestionBindGroup, typeof data.quest_group>(
      questionBindGroup,
      (res, g, i) => {
        res.push({
          quests: _.map(g.questions, 'id'),
          teachers: _.map(g.teachers, 'uid')
        })
        return res
      },
      []
    )
    data.review_time = moment(data.review_time).format('YYYY-MM-DD HH:mm:00')
    edit.onSubmit(data)
  }

  function onReworkClick() {
    confirm({
      title: '注意',
      content:
        '该试卷已完成分配，可能存在批阅数据。重新分配将导致批阅数据的丢失',
      okText: '重新分配',
      okButtonProps: { danger: true },
      onOk: async () => {
        return GET(
          APP_SCOPE.isInFanYa
            ? `/fanya/paper/${edit.data.id}/reset`
            : `/city/paper/${edit.data.id}/reset`,
          {}
        ).then(() => {
          edit.onCancel()
        })
      }
    })
  }

  return (
    <EditModel
      edit={edit}
      title={'判卷设置'}
      modalProps={{ width: ModalSizeLarge + 100 }}
      formProps={{
        labelCol: { span: 3 },
        wrapperCol: { span: 24 - 3 }
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {edit.params && (
        <div>
          <div className='title' style={{ marginBottom: 8 }}>
            <Text strong>{edit.params.name}</Text>
          </div>
          <div className='desc'>
            <Tag color={'success'}>{currentExam && currentExam.name}</Tag>
            <Tag color={'success'}>{Subject[edit.params.subject]}</Tag>
            <Tag color={'success'}>{edit.params.score}分</Tag>
            <Tag color={'success'}>{edit.params.quest_count}题</Tag>
          </div>
          <Divider />
          <Alert
            message={
              <>
                配置须知：
                <div>
                  •
                  仅试卷为未分配状态，才可修改题组与判卷教师，若试卷已分配，则无法修改；
                </div>
                <div>
                  •
                  若在考试结束前完成判卷设置，系统将在考试结束后1小时内完成试卷分配；
                </div>
                <div>
                  •
                  若在考试结束后完成判卷设置，系统将在设置提交后1小时内完成试卷分配。
                </div>
              </>
            }
            style={{ marginBottom: 24 }}
          />
        </div>
      )}

      <Form.Item label={'判卷人员'}>
        <TeacherSelect />
      </Form.Item>
      <Form.Item
        label={'判卷模式'}
        name={'review_mode'}
        rules={[SelectRequiredRule]}
      >
        <Radio.Group
          name={'review_mode'}
          style={{ marginTop: 4 }}
          onChange={e =>
            toggleQuestionBindGroup(e.target.value === MarkingMode.GROUP_FLOW)
          }
        >
          <Radio value={MarkingMode.ALL}>整卷批阅</Radio>
          <Radio value={MarkingMode.GROUP_FLOW}>按题流水批阅</Radio>
        </Radio.Group>
      </Form.Item>
      {showQuestionBindGroup && (
        <Form.Item wrapperCol={{ offset: 3 }}>
          <QuestionBindingGroup readonly={false} />
        </Form.Item>
      )}
      <Form.Item
        label={'判卷范围'}
        name={'review_range'}
        rules={[SelectRequiredRule]}
      >
        <Select style={{ width: 300 }}>
          <Select.Option value={MarkingRange.ALL}>
            判卷人可批阅全部答卷
          </Select.Option>
          <Select.Option value={MarkingRange.OTHER_SCHOOL}>
            只批阅除自己所在学校外答卷
          </Select.Option>
          <Select.Option value={MarkingRange.SELF_SCHOOL}>
            判卷人只批阅本校答卷
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={'判卷开始'}
        name={'review_time'}
        rules={[SelectRequiredRule]}
      >
        <DatePicker
          showTime
          showToday={false}
          format={'YYYY-MM-DD HH:mm'}
          disabledDate={curr => curr.isBefore(edit.params.end_time, 'day')}
        />
      </Form.Item>
      <Form.Item
        label={'保密设置'}
        name={'hide_info'}
        valuePropName={'checked'}
      >
        <Checkbox>隐藏答卷人信息</Checkbox>
      </Form.Item>
      {edit.isEdit && (
        <Form.Item label={'分配状态'}>
          {(edit.data as Paper.PaperMarkingSettingFormData)
            .review_distribute_status === 2 ? (
            <Space>
              分配完成
              <Button type={'primary'} danger onClick={onReworkClick}>
                重新分配
              </Button>
            </Space>
          ) : (
            '分配中'
          )}
        </Form.Item>
      )}
    </EditModel>
  )
}

export const MarkingSettingDialog: FC = props => (
  <MarkingSettingProvider>
    <Dialog />
  </MarkingSettingProvider>
)
