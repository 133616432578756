import _ from 'lodash'
import React from 'react'

export function TableSelectAlert<T = any>(props: {
  data: T[]
  textKey?: string
  onCleanClick?: () => void
}) {
  const textKey = _.map(props.data, props.textKey || 'name').join('、')

  return (
    <div
      className='ant-alert ant-alert-info ant-alert-no-icon'
      style={{ marginBottom: 16 }}
    >
      <span className='ant-alert-message'>
        已选中{props.data.length}条记录
        {!!textKey.length && `: ${textKey}`}
      </span>
      {props.onCleanClick && (
        <button
          type='button'
          className='ant-alert-close-icon'
          tabIndex={0}
          onClick={props.onCleanClick}
        >
          <span className='ant-alert-close-text'>清空</span>
        </button>
      )}
    </div>
  )
}
