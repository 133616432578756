import { Card, Col, Divider, Modal, Row, Tooltip } from 'antd'
import { RichText } from 'components/Preview/RichText'
import { QuestionType } from 'config'
import _ from 'lodash'
import React, { FC, ReactNode, useState } from 'react'
import { Paper } from 'typing'
import { QuestionNesting } from 'typing.paper'
import { getPlanText } from 'utils'
import { isQuestionFlattenNesting } from 'utils/question'

export const QuestionGroupRender: FC<{
  g: Paper.QuestionGroup
  nesting?: boolean
  renderAction: (
    q: Paper.Question,
    child?: Paper.QuestionNestingChildQuestion
  ) => ReactNode
  renderGroupAction: () => ReactNode
}> = ({ g, nesting, renderAction, renderGroupAction }) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewValue, setPreviewValue] = useState<{
    content: string
    q_content?: string
  } | null>(null)

  return (
    <Card
      key={g.id}
      title={
        <Row>
          <Col flex={'auto'}>
            {g.displayName}、{g.typeNameAlias}
          </Col>
          {renderGroupAction()}
        </Row>
      }
      size={'small'}
      className={'group'}
      style={{ marginBottom: 8 }}
    >
      <div className={'questions'} style={{ marginLeft: 16 }}>
        {g.children.map(q => (
          <div key={q.id}>
            <Row className='question'>
              <Tooltip title='点击预览'>
                <Col
                  flex={'auto'}
                  className={'title'}
                  onClick={() => {
                    setPreviewValue({
                      content: q.content,
                      q_content: isQuestionFlattenNesting(q)
                        ? q.q_content
                        : undefined
                    })
                    setPreviewVisible(true)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {q.displayName}.
                  <div className='content'>
                    {_.truncate(
                      isQuestionFlattenNesting(q)
                        ? getPlanText(q.q_content) +
                            ' ' +
                            getPlanText(q.content)
                        : getPlanText(q.content),
                      { length: 100 }
                    )}
                  </div>
                </Col>
              </Tooltip>
              {renderAction(q)}
              <Divider style={{ margin: '8px 0' }} />
            </Row>

            {/* Nesting */}
            {nesting && (q as QuestionNesting).children && (
              <div className={`children`}>
                {_.map((q as QuestionNesting).children, (child, i) => (
                  <Row key={`${q.id}-${child.id}`} className='question'>
                    <Col flex={'auto'} className={'title'}>
                      {child.displayName}.
                      <div className='content'>
                        {getPlanText(child.content)}
                      </div>
                    </Col>
                    {renderAction(q, child)}
                    <Divider style={{ margin: '8px 0' }} />
                  </Row>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal
        title='题目预览'
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        {previewValue?.q_content && (
          <RichText className='content' html={previewValue.q_content} />
        )}
        <RichText className='content' html={previewValue?.content} />
      </Modal>
    </Card>
  )
}
