import { APP_SCOPE } from 'config'
import { GET } from 'core/request'
import _ from 'lodash'
import path from 'path'

const log = require('debug')

export function isIFrameAudioContent(val = '') {
  return /\/audioplay\.html[^"]+/g.test(val)
}

export async function transIframeAudio(src = '', hideFilename = false) {
  if (!isIFrameAudioContent(src)) return src

  const jobs: { url; id; http? }[] = []

  const $el = $(`<div>${src}</div>`)
  const $frames = $el.find('iframe')

  if (APP_SCOPE.isInFanYa) {
    $frames.each((i, frame) => {
      const src = $(frame).attr('src') as string
      if (!/\/audioplay\.html/.test(src)) {
        return
      }

      const objectId = src.split('objectid=')[1]
      if (!objectId) return

      const url = `https://mooc1.chaoxing.com/ueditorupload/read?objectId=${objectId}`
      let name = $(frame).attr('name') || ''

      if (hideFilename) {
        const ext = _.tail(name.split('.'))
        name = `作答附件.${ext}`
      }
      const link = $(`<div><a target="_blank" href="${url}">${name}</a></div>`)
      $(frame).replaceWith(link)
    })
    return $el.html()
  }

  $frames.each((i, frame) => {
    const url = $(frame).attr('src') as string
    if (!/\/module\/audioplay\.html/.test(url)) {
      return
    }
    const id = url.split('objectid=')[1]
    if (id) jobs.push({ id, url })
  })

  const host = 'https://mooc1-1.chaoxing.com/ananas/status/'

  for (const object of jobs) {
    const {
      data: { http }
    } = await GET(`/main/audio`, {
      data: { url: `${host}${object.id}` }
    })
    object.http = http
  }

  _.forEach(jobs, job => {
    $el
      .find(`iframe[src="${job.url}"]`)
      .replaceWith(`<audio controls src="/image_proxy?url=${job.http}" />`)
  })

  return $el.html()
}

/**
 * ueditorupload 附件
 * @see format /ueditorupload/read?objectId=e0af114046c38036d2d45dff78ff30c5&fileOriName=IMG_0898.JPG
 */
export function isAttachUEUpload(val = '') {
  return /href="(\/ueditorupload\/read)/g.test(val)
}

// Should check html includes attach before call
async function transAttachUEUpload($el, hideFilename = false): Promise<any> {
  const jobs: {
    url
    id
    http?
    fileName: string
    extName: string
    isImg: boolean
  }[] = []

  const $frames = $el.find('a')
  $frames.each((i, frame) => {
    const url = $(frame).attr('href') as string
    if (!/\/ueditorupload\/read/.test(url)) {
      return
    }
    const objPath = _.get(url.split('objectId='), '[1]', '').split('&')
    const id = _.get(objPath, '[0]')
    const fileName = _.get(objPath, '[1]', '').replace('fileOriName=', '')
    const sp = fileName.split('.')
    const extName = sp[sp.length - 1]
    const isImg = /jpe?g|bmp|png/i.test(extName)
    if (id) jobs.push({ id, url, fileName, extName, isImg })
  })

  const host = 'https://mooc1-1.chaoxing.com/ananas/status/'
  for (const object of jobs) {
    if (/docx?/i.test(object.extName)) {
      // Use doc preview
      object.http = path.join('https://mooc1.chaoxing.com', object.url)
    } else {
      const { data } = await GET(`/main/audio`, {
        data: { url: `${host}${object.id}` }
      })
      object.http = data.http || data.download
    }
  }

  log('transAttachUEUpload:')('jobs', jobs)

  _.forEach(jobs, job => {
    const a = $el.find(`a[href="${job.url}"]`)
    a.prev().remove()
    if (job.isImg) {
      a.replaceWith(
        `<img src="${job.http}" data-object-id="${job.id}" alt="" />`
      )
    } else {
      let name = job.fileName
      let link = `<a href="${job.http}" target="_blank" rel="noreferrer">${name}</a>`

      if (hideFilename) {
        const ext = _.tail(name.split('.'))
        name = `作答附件.${ext}`
        link = `<a href="${job.http}" download="${name}" target="_blank" rel="noreferrer">${name}</a>`
      }

      a.replaceWith(link)
    }
  })

  return $el
}

export function isAttachNew(val = '') {
  return /attachNew|attachUrl|attachZT|attach/.test(val)
}

// Should check need transform before call
function transAttachNew($el, hideFilename = false): any {
  $el.find('.attachNew').each((i, el) => {
    const span = $(el).children('span:first')
    let name = span.attr('name') || span.text() || '附件'
    const objectId = span.attr('data')

    const url = `https://mooc1.chaoxing.com/ueditorupload/read?objectId=${objectId}`
    let link = $(`<div><a target="_blank" href="${url}">${name}</a></div>`)

    if (hideFilename) {
      const ext = _.tail(name.split('.'))
      name = `作答附件.${ext}`
      link = $(
        `<div><a download="${name}" target="_blank" href="${url}">${name}</a></div>`
      )
    }

    $el.append(link)
  })

  // $el.find('.attachUrl span, .attachZT span').each((i, el) => {
  //   const url = encodeURIComponent($(el).attr('data') || '')
  //   $(el).parent().click(() => {
  //     top.window.open(url, '_blank')
  //   })
  // })

  return $el
}

export async function transAttach(src = '', hideFilename = false) {
  let $el = $(`<div>${src}</div>`)

  if (isAttachUEUpload(src)) {
    log('transAttachUEUpload:')('src', src)
    $el = await transAttachUEUpload($el, hideFilename)
  }

  if (isAttachNew(src)) {
    // log('transAttachNew:')('src', src)
    $el = transAttachNew($el, hideFilename)
  }

  return $el.html()
}

export function removeAttach(val: string = '') {
  const $el = $(`<div>${val}</div>`)
  $el.find('.workAttach, .attachNew').remove()
  return $el.html()
}

export function isAttachIframe(val = '') {
  return /attach-iframe/.test(val)
}

export function transAttachIframe(src = '', hideFilename = false) {
  const $html = $(`<div>${src}</div>`)
  $html.find('.attach-iframe').each((i, el) => {
    const $el = $(el)
    const objectId = $el.attr('objectid')
    const name = hideFilename ? `附件${i + 1}` : $el.attr('filename')
    const url = `https://mooc1.chaoxing.com/ueditorupload/read?objectId=${objectId}`
    $el.after(`<a href="${url}" target="_blank">${name}</a>`)
    $el.remove()
  })
  return $html.html()
}
