import './index.less'
import React, { FC, ReactNode } from 'react'
import { Space } from 'antd'

export const TableToolbar: FC<{ rightContent?: () => ReactNode }> = props => {
  return (
    <div className={'table-toolbar'}>
      <div className={'left'}>
        <Space>{props.children}</Space>
      </div>
      <div className={'right'}>
        {props.rightContent && props.rightContent()}
      </div>
    </div>
  )
}
