import { useLocalStorageState } from '@umijs/hooks'
import { Card, Checkbox, Divider, Modal, Spin } from 'antd'
import { QuestionItemContentRender } from 'components/Question/QuestionItemContentRender'
import { ModalSizeLarge, QuestionType } from 'config'
import {
  QuestionAnswerDataProvider,
  useQuestionAnswerDataContent
} from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { Paper } from 'typing'
import { StudentAnswer } from 'typing.marking'
import { isQuestionNesting, reversQuestionsToGroupTree } from 'utils/question'

type PreviewProps = {
  id: number
  studentId?: number
  showContentForce?: boolean

  questionFilter?: number[]
  studentAnswer?: StudentAnswer[]
  showScore?: boolean

  visible: boolean
  title: string
  onCancel: () => void
}

export const PreviewMarking: FC<Omit<
  PreviewProps,
  'visible' | 'title' | 'onCancel'
>> = props => {
  const {
    isInitialized,
    loading,
    setMarkingPaperId,
    paperQuestions,
    markingQuestions,
    setCurrentStudentId,
    setCurrentStudentAnswer,
    getAnswerByQuestion,
    getAnswerScoreByQuestion
  } = useQuestionAnswerDataContent()

  // Question
  const [markingQuestionVO, setMarkingQuestionVO] = useState<
    Paper.QuestionGroup[]
  >([])

  useEffect(() => {
    setMarkingPaperId(props.id)
  }, [])

  useEffect(() => {
    if (props.studentAnswer) {
      setCurrentStudentAnswer(props.studentAnswer)
    } else if (isInitialized && props.studentId) {
      setCurrentStudentId(props.studentId)
    }
  }, [isInitialized])

  useEffect(() => {
    let data = markingQuestions

    if (props.questionFilter) {
      data = _.filter(data, q => _.includes(props.questionFilter, q.id))
    }

    setMarkingQuestionVO(reversQuestionsToGroupTree(paperQuestions, data))
  }, [markingQuestions, paperQuestions])

  // UI
  const [showContent, toggleContentVisible] = useLocalStorageState(
    'preview-marking-show-question-content',
    true
  )
  const [showAnswerRight, toggleAnswerRight] = useLocalStorageState(
    'preview-marking-show-answer-right',
    false
  )

  function renderItem(question: Paper.Question) {
    const isNesting = isQuestionNesting(question)
    // TODO: remove
    // if (isNesting) {
    //   return (
    //     <div className={'children'}>
    //       {_.map((question as Paper.QuestionNesting).children, (q, i) => {
    //         return (
    //           <div key={i} id={`question_${question.id}_${q.id}`}>
    //             <div className={'title primary'}>
    //               {q.displayName} {QuestionType[q.type]}，{question.score}分
    //             </div>
    //             <QuestionItemContentRender
    //               question={q}
    //               parentQuestion={question}
    //               answerData={getAnswerByQuestion([
    //                 question.id,
    //                 (q.id as unknown) as string
    //               ])}
    //               showContent={
    //                 typeof props.showContentForce !== 'undefined'
    //                   ? props.showContentForce
    //                   : showContent
    //               }
    //               showAnswerRight={showAnswerRight}
    //               showAnswer={!!props.studentId}
    //               showScore={props.showScore}
    //               score={getAnswerScoreByQuestion([
    //                 question.id,
    //                 (q.id as unknown) as string
    //               ])}
    //               readonly
    //             />
    //             <Divider />
    //           </div>
    //         )
    //       })}
    //     </div>
    //   )
    // }
    return (
      <QuestionItemContentRender
        key={question.id}
        question={question}
        answerData={getAnswerByQuestion(question.id)}
        showContent={
          typeof props.showContentForce !== 'undefined'
            ? props.showContentForce
            : showContent
        }
        showAnswerRight={showAnswerRight}
        showAnswer={!!props.studentId}
        showScore={props.showScore}
        score={getAnswerScoreByQuestion(question.id)}
        readonly
      />
    )
  }

  if (!isInitialized || loading) {
    return <Spin />
  }
  return (
    <div>
      <div className='toolbar' style={{ marginBottom: 16, textAlign: 'right' }}>
        {typeof props.showContentForce === 'undefined' && (
          <Checkbox
            checked={showContent}
            onChange={e => toggleContentVisible(e.target.checked)}
          >
            显示题干
          </Checkbox>
        )}
        <Checkbox
          checked={showAnswerRight}
          onChange={e => toggleAnswerRight(e.target.checked)}
        >
          显示正确答案
        </Checkbox>
      </div>

      {markingQuestionVO &&
        markingQuestionVO.map((g, i) => (
          <div key={i} className={'question-marking-item'}>
            <div className={'header'}>
              <div className={'title'}>
                {g && (
                  <div className={'title__group'}>
                    {g.displayName}、{g.typeNameAlias || QuestionType[g.type]}
                  </div>
                )}
              </div>
            </div>

            <div className={'questions'}>
              <Card size={'small'} style={{ marginBottom: 24 }}>
                {g.children.map((q, i) => (
                  <>{renderItem(q)}</>
                ))}
              </Card>
            </div>
          </div>
        ))}
    </div>
  )
}

export const PreviewMarkingDialog: FC<PreviewProps> = props => {
  return (
    <Modal
      visible={props.visible}
      title={props.title}
      width={ModalSizeLarge}
      footer={false}
      onCancel={props.onCancel}
      destroyOnClose
    >
      <QuestionAnswerDataProvider>
        <PreviewMarking {...props} />
      </QuestionAnswerDataProvider>
    </Modal>
  )
}
