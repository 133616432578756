import { GradeMap } from 'config'
import React, { FC } from 'react'
import { useEditContext } from './index'
import { EditModel } from 'components'
import { Form, Input, DatePicker, Select } from 'antd'
import { RequiredRule } from 'components/EditModel/utils'

const { RangePicker } = DatePicker

export const Edit: FC = () => {
  const edit = useEditContext()

  return (
    <EditModel edit={edit}>
      <Form.Item label={'批次名称'} name={'name'} rules={[RequiredRule]}>
        <Input />
      </Form.Item>
      <Form.Item label={'考试年级'} name={'grade'} rules={[RequiredRule]}>
        <Select>
          {GradeMap.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={'考试时间'} name={'_dateRange'} rules={[RequiredRule]}>
        <RangePicker />
      </Form.Item>
    </EditModel>
  )
}
