import { GET } from 'core/request'
import { Exam, Paper } from 'typing'
import constate from 'constate'
import { useState } from 'react'

const [CurrentExamProvider, useCurrentExamContent] = constate(() => {
  const [examData, setExamData] = useState<Exam>()
  const [paperData, setPaperData] = useState<Paper.Paper>()

  async function initExam(id, pid?) {
    const { data: exam } = await GET(`/city/exam/${id}`, {})
    setExamData(exam)
    if (pid) {
      await initPaper(pid)
    }
  }

  async function initPaper(pid) {
    setPaperData(undefined)
    const { data: paper } = await GET(`/city/exam/paper/${pid}`, {})
    setPaperData(paper)
  }

  return {
    currentExam: examData,
    currentPaper: paperData,
    initCurrentExam: initExam,
    initCurrentPaper: initPaper
  }
})

CurrentExamProvider.displayName = 'CurrentExamProvider'

export { CurrentExamProvider, useCurrentExamContent }
