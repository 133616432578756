import { useMount } from '@umijs/hooks'
import { Button, Card, Col, Form, Input, Row, Tag } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Search, Table } from '../../components'
import { createList } from '../../core/service'
import { useQuery } from '../../hooks/useQuery'
import { CollegeSelect } from './components/CollegeSelect'
import { RateInput } from './components/RateInput'
import './style.less'

const { ListProvider, useListContext } = createList({
  url: () => '/statistics/gather/list/teacher_by_gather_exam',
  parseSearchData: data => {
    return {
      ...data,
      rate: undefined,
      lowerLimit: data.rate && data.rate[0],
      upperLimit: data.rate && data.rate[1]
    }
  }
})

export default function () {
  return (
    <ListProvider>
      <BatchExamsTeachers />
    </ListProvider>
  )
}

const BatchExamsTeachers = () => {
  const history = useHistory()
  const queryParams = useQuery<{ batch; examName; relationId; classId }>()
  const list = useListContext()

  function onTableChange(pagination, filters, sorter) {
    if (list.index.value !== pagination.current) {
      window.scrollTo(0, 0)
      list.onIndexChange(pagination.current)
      return
    }

    list.setPageSize(pagination.pageSize)
    list.onSearchSubmit({
      order_type: !!sorter.order
        ? sorter.order === 'ascend'
          ? '0'
          : '1'
        : null
    })
  }

  useMount(() => {
    list.setDefaultSearch({
      relationIds: queryParams.relationId,
      clazzIds: queryParams.classId
    })
    list.onSearchSubmit({})
  })

  return (
    <Card>
      <Row align={'middle'} className='teacher-report-header'>
        <Col flex={1}>
          <div className={'title'}>
            批次教师统计
            <Tag className={'tag'}>{queryParams.batch || '批次名称'}</Tag>
            <Tag className={'tag'}>{queryParams.examName || '考试名称'}</Tag>
          </div>
        </Col>
        <Col>
          <Button onClick={() => history.goBack()}>返回</Button>
        </Col>
      </Row>

      <Search list={list}>
        <Form.Item name={'group_id'}>
          <CollegeSelect />
        </Form.Item>
        <Form.Item name={'teacher'}>
          <Input placeholder={'输入教师姓名或账号'} />
        </Form.Item>
        <Form.Item name={'rate'}>
          <RateInput />
        </Form.Item>
      </Search>

      <Table
        list={list}
        onChange={onTableChange}
        columns={[
          { title: '教师姓名', dataIndex: 'display_name', fixed: 'left' },
          { title: '账号', dataIndex: 'teacher_uid' },
          { title: '院系', dataIndex: 'dept' },

          {
            title: '应批阅试卷份数',
            dataIndex: 'exam_paper_count',
            align: 'right'
          },
          {
            title: '已批阅阅试份数',
            dataIndex: 'completed_paper_count',
            align: 'right'
          },
          {
            title: '待批阅试卷份数',
            dataIndex: 'uncompleted_paper_count',
            align: 'right'
          },
          {
            title: '批阅试卷完成率',
            dataIndex: 'exam_paper_completed_count_rate',
            align: 'center',
            render: t => `${t}%`
          },

          { title: '应批阅题目份数', dataIndex: 'total', align: 'right' },
          { title: '已批阅题目份数', dataIndex: 'done', align: 'right' },
          {
            title: '待批阅题目份数',
            dataIndex: 'total',
            align: 'right',
            render: (t, r) => t - r.done
          },
          {
            title: '批阅完成率',
            dataIndex: 'rate',
            render: (_, record) =>
              `${Math.round((record.done / record.total) * 10000) / 100}%`,
            align: 'center',
            sorter: true
          },
          {
            title: '操作',
            key: 'actions',
            render: (t, r) => {
              const q = {
                teacher: r.display_name,
                batch: queryParams.batch,
                relationId: r.relationid,
                classId: r.classid
              }
              return (
                <Link
                  to={`/marking/${r.exam_paper_teacher_id}/history?paper_id=${r.paper_id}`}
                >
                  批阅历史
                </Link>
              )
            }
          }
        ]}
        pagination={{
          current: list.index.value,
          total: list.total,
          pageSize: list.size,
          showSizeChanger: true,
          showQuickJumper: true,
          hideOnSinglePage: !true,
          showTotal: total => `共${total}条记录`
        }}
        scroll={{
          x: 'max-content'
        }}
        indexColumn={{ fixed: 'left' }}
      />
    </Card>
  )
}
