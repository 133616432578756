import { useLocalStorageState } from '@umijs/hooks'
import { useMemo } from 'react'

export type TableColumnControlItem = {
  title: string
  dataIndex: string
  visible: boolean
}

export const useTableColumnControl = (
  key: string,
  defaultColumns: TableColumnControlItem[]
) => {
  const [columns, setColumns] = useLocalStorageState(key, defaultColumns)

  const visibleColumns = useMemo(() => {
    return columns?.filter(column => column.visible)
  }, [columns])

  return {
    columns,
    visibleColumns,
    setColumns,
    resetColumns: () => setColumns(defaultColumns)
  }
}
