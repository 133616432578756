import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Paper } from 'typing'
import { MarkingRecordList } from './MarkingRecords'
import { ReviewInput } from './ReviewInput'
import { ScoreInput } from './ScoreInput'
import { StepScoreForm } from './StepScoreForm'

export const ScoreFormItem: FC<{
  question: Paper.Question
  child?: Paper.QuestionNestingChildQuestion
}> = ({ question, child }) => {
  const {
    isCurrentUserHasAuditRole,
    isCurrentUserHasReviewRole,
    findMarkingDataForAudit,
    findQuestionStepSetting
  } = useQuestionAnswerDataContent()

  const {
    scoreForm,
    getScoreByQuestion,
    setQuestionScore,
    setNestingQuestionScore
  } = useMarkingForm()

  const maxScore = useMemo(() => {
    const q = child || question
    return q.score
  }, [child, question])

  const [score, setScore] = useState<number>()

  function _setScore(val) {
    setQuestionScore(question.id, val)
  }

  useEffect(() => {
    const scoreData = getScoreByQuestion(question.id)
    setScore(scoreData ? (scoreData.score as number) : undefined)
  }, [scoreForm, question, child, getScoreByQuestion])

  // Audit mode
  const markingRecords = useMemo(() => {
    if (isCurrentUserHasAuditRole || isCurrentUserHasReviewRole) {
      return findMarkingDataForAudit(question.id)
    }
  }, [
    isCurrentUserHasAuditRole,
    isCurrentUserHasReviewRole,
    findMarkingDataForAudit,
    child,
    question
  ])

  const stepScoreSetting = useMemo(() => {
    return findQuestionStepSetting(question.id)
  }, [child, question])

  //
  // Conditional rendering

  if (isCurrentUserHasReviewRole) {
    return (
      <>
        <ReviewInput question={question} child={child} />
        <MarkingRecordList items={markingRecords} />
      </>
    )
  }

  if (stepScoreSetting) {
    return (
      <StepScoreForm
        question={question}
        child={child}
        steps={stepScoreSetting}
      />
    )
  }

  return (
    <>
      <ScoreInput
        maxScore={maxScore}
        question={question}
        child={child}
        score={score}
        onScoreChange={_setScore}
      />
      <MarkingRecordList items={markingRecords} />
    </>
  )
}
