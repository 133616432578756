import { createList } from 'core/service'
import React, { FC, useEffect, useState } from 'react'
import { Table } from 'components'
import numeral from 'numeral'
import _ from 'lodash'
import { Report } from 'typing'
import { LinkOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table/interface'
import { useExportContent } from 'components/ReportExamPaperTables/TableTabsWrapper'

const { ListProvider, useListContext } = createList<
  Report.TotalScoreTableItemUnion,
  Report.ListQueryType
>({
  url: ({ entryType, scope }) => {
    return scope === 'school'
      ? `/city/exam/${
          entryType === 'exam' ? '' : 'paper/'
        }statistic/score/school`
      : `/city/exam/${
          entryType === 'exam' ? '' : 'paper/'
        }statistic/score/class`
  }
})

const TotalScoreTableContent: FC<Report.BasicTableProps> = ({
  scopeParams,
  stateParams,
  totalScoreSummary,
  onDetailClick
}) => {
  const list = useListContext()

  useEffect(() => {
    list.onSearchSubmit({
      entryType: scopeParams.reportEntryType,
      scope: scopeParams.scope,
      [scopeParams.reportEntryType === 'exam'
        ? 'exam_id'
        : 'exam_paper_id']: scopeParams.reportEntryId,
      fid: scopeParams.schoolId
    })
  }, [])

  // UI
  const [computedItems, setComputedItems] = useState<
    Report.TotalScoreTableItemUnion[]
  >()
  useEffect(() => {
    setComputedItems([
      {
        fid: -1,
        org_name: '全市汇总',
        class_name: '全校汇总',
        ...totalScoreSummary,
        avg_deviation: 0
      },
      ..._.map(list.items, item => {
        return {
          ...item,
          avg_deviation:
            Math.round((item.avg_score - totalScoreSummary.avg_score) * 10) / 10
        }
      })
    ])
  }, [list.items])

  const isStatisticRow = row => row.fid === -1 || row.statistic_clazz_id === -1

  const columns: ColumnsType<Report.TotalScoreTableItemUnion> = [
    {
      title: scopeParams.scope === 'school' ? '学校' : '班级',
      dataIndex: scopeParams.scope === 'school' ? 'org_name' : 'class_name',
      render: (t, r) => {
        return isStatisticRow(r) ? (
          t
        ) : (
          <a onClick={() => onDetailClick(r)}>
            {t} <LinkOutlined />
          </a>
        )
      }
    },
    { title: '参与学生', dataIndex: 'student_count', align: 'right' },
    {
      title: '平均分',
      dataIndex: 'avg_score',
      render: (t, r) => (
        <span
          className={
            isStatisticRow(r)
              ? ''
              : t < totalScoreSummary.avg_score
              ? 'danger'
              : 'success'
          }
        >
          {numeral(t).format('0.0')}
        </span>
      ),
      sorter: (a, b) => a.avg_score - b.avg_score,
      align: 'right'
    },
    {
      title: '均差',
      dataIndex: 'avg_deviation',
      render: (t, r) => (
        <span className={isStatisticRow(r) ? '' : t < 0 ? 'danger' : 'success'}>
          {isStatisticRow(r) ? '-' : t}
        </span>
      ),
      align: 'right'
    },
    {
      title: '优秀率',
      dataIndex: 'excellent_rate',
      render: t => numeral(t).format('0.0%'),
      sorter: (a, b) => a.excellent_rate - b.excellent_rate,
      align: 'right'
    },
    {
      title: '良好率',
      dataIndex: 'good_rate',
      render: t => numeral(t).format('0.0%'),
      sorter: (a, b) => a.good_rate - b.good_rate,
      align: 'right'
    },
    {
      title: '及格率',
      dataIndex: 'pass_rate',
      render: t => numeral(t).format('0.0%'),
      sorter: (a, b) => a.pass_rate - b.pass_rate,
      align: 'right'
    }
  ]

  // useExportContent
  const { setLoading, setStateParams, setData } = useExportContent()
  useEffect(() => {
    setStateParams(stateParams)
    setData(columns, computedItems)
  }, [computedItems])
  useEffect(() => {
    setLoading(list.loading)
  }, [list.loading])

  return (
    <>
      <Table
        list={list}
        items={computedItems}
        rowKey={(r: any) => r.fid || r.statistic_clazz_id}
        pagination={false}
        columns={columns}
      />
    </>
  )
}

export const TotalScoreTable: FC<Report.BasicTableProps> = props => {
  return (
    <ListProvider>
      <TotalScoreTableContent {...props} />
    </ListProvider>
  )
}
