import React, { FC, ReactNode } from 'react'
import { Button, Form } from 'antd'
import { DefaultLabelCol, DefaultWrapperCol } from 'components'
import { PlusOutlined } from '@ant-design/icons'

interface Field {
  name: number
  key: number
  fieldKey: number
}

interface FormGroupProps<T = any> {
  name: string | number | (string | number)[]
  children: (
    field: Field,
    state: {
      index: number
      length: number
      isFirst: boolean
      isLast: boolean
    },
    actions: {
      add: (value: T) => void
      remove: (index: number) => void
      move: (from: number, to: number) => void
      insertAt: (index: number, value: T) => void
    }
  ) => ReactNode
  defaultValue?: () => any
  showAdd?: boolean
}

export const FormList: FC<FormGroupProps> = props => {
  return (
    <Form.List name={props.name}>
      {(fields, { add, remove, move }) => (
        <div>
          {fields.map((field, index) => {
            return props.children(
              (field as any) as Field,
              {
                index,
                length: fields.length,
                isFirst: index === 0,
                isLast: index === fields.length - 1
              },
              {
                add,
                remove,
                move,
                insertAt: (index, value) => {
                  add(value)
                  move(fields.length, index)
                }
              }
            )
          })}
          {props.showAdd && (
            <Form.Item
              wrapperCol={{ offset: DefaultLabelCol, span: DefaultWrapperCol }}
            >
              <Button
                onClick={() => add(props.defaultValue && props.defaultValue())}
              >
                <PlusOutlined /> 新增
              </Button>
            </Form.Item>
          )}
        </div>
      )}
    </Form.List>
  )
}

FormList.defaultProps = {
  showAdd: true
}
