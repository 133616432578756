import { useToggle } from '@umijs/hooks'
import { Card, Checkbox, Col, Row, Space, Tag } from 'antd'
import { QuestionItemContentRender } from 'components/Question/QuestionItemContentRender'
import { QuestionType } from 'config'
import { CurrentQuestionContext } from 'hooks/CurrentQuestionContext'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Sticky, StickyContainer } from 'react-sticky'
import { Paper } from 'typing'
import { MarkingAuditStatus } from 'typing.marking'
import { getQuestionPath } from 'utils/question'
import { TagPopover } from '../components/TagPopover'
import { ScoreFormForT2Group } from '../score-form/ScoreFormForT2Group'
import { useMarkingForm } from '../useMarkingForm'
import './QuestionList.less'

const BG_COLOR = '#fafafa'

export const QuestionItemFormT2Group: FC<{ group: Paper.Question[] }> = ({
  group
}) => {
  const {
    isCurrentUserHasReviewRole,
    checkQuestionAuditStatus
  } = useQuestionAnswerDataContent()
  const {
    paperQuestions,
    getAnswerByQuestion,
    showContent: g_showContent,
    showAnswerRight: g_showAnswerRight,
    questionIndexHeight,
    currentStudent
  } = useMarkingForm()

  const { state: showContent, toggle: toggleContentVisible } = useToggle()
  const {
    state: showAnswerRight,
    toggle: toggleAnswerRightVisible
  } = useToggle()

  const [questionGroup, setQuestionGroup] = useState<Paper.QuestionGroup>()
  useEffect(() => {
    const path = getQuestionPath(paperQuestions, group[0].id)
    setQuestionGroup(path ? path[0] : ({} as any))
  }, [])

  useEffect(() => {
    toggleContentVisible(g_showContent)
    toggleAnswerRightVisible(g_showAnswerRight)
  }, [g_showContent, g_showAnswerRight])

  const readonly = useMemo(() => {
    return isCurrentUserHasReviewRole
  }, [isCurrentUserHasReviewRole])

  const isNeedAudit = useMemo(() => {
    return _.some(group, q => {
      return checkQuestionAuditStatus(q.id) === MarkingAuditStatus.Unjudged
    })
  }, [checkQuestionAuditStatus, group])

  return (
    <Row
      className={'question-marking-item'}
      gutter={12}
      style={{ marginBottom: 16, flexWrap: 'nowrap' }}
    >
      <Col flex={'auto'}>
        <Card
          id={`question_${group[0].id}`}
          size={'small'}
          style={{
            minHeight: 376,
            backgroundColor: BG_COLOR,
            borderRadius: 8
          }}
        >
          <div>
            <Row className='header'>
              <Col flex={'auto'}>
                <div className={'title'}>
                  {questionGroup && (
                    <div className={'title__group'}>
                      {questionGroup.displayName}、
                      {questionGroup.typeNameAlias ||
                        QuestionType[questionGroup.type]}
                    </div>
                  )}
                </div>
              </Col>
              <Col>
                <Space>
                  <Checkbox
                    checked={showContent}
                    onChange={() => toggleContentVisible()}
                    tabIndex={-1}
                  >
                    显示题干
                  </Checkbox>
                  <Checkbox
                    checked={showAnswerRight}
                    onChange={() => toggleAnswerRightVisible()}
                    tabIndex={-1}
                  >
                    显示正确答案
                  </Checkbox>
                  <TagPopover question={group[0]} />
                  {isNeedAudit && <Tag color='red'>待仲裁</Tag>}
                </Space>
              </Col>
            </Row>

            <Row gutter={16}>
              {group.map((question, index) => (
                <Col key={index} span={24 / 2} xxl={{ span: 24 / 3 }}>
                  <div>
                    <CurrentQuestionContext.Provider
                      value={{
                        question: question
                      }}
                    >
                      <div className={'title__question'}>
                        {question.displayName}.{QuestionType[question.type]}，
                        {question.score}分
                      </div>
                      <QuestionItemContentRender
                        question={question}
                        student={currentStudent}
                        answerData={getAnswerByQuestion(question.id)}
                        showContent={showContent}
                        showAnswerRight={showAnswerRight}
                        showAnswer={true}
                        readonly={readonly}
                      />
                    </CurrentQuestionContext.Provider>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Card>
      </Col>
      <Col
        flex={'0 0 256px'}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <StickyContainer style={{ flex: 1 }}>
          <Sticky topOffset={questionIndexHeight ? -questionIndexHeight : 0}>
            {({ style, isSticky }) => (
              <div style={style}>
                <Card
                  size={'small'}
                  className={'marking-form'}
                  style={{
                    backgroundColor: BG_COLOR,
                    marginTop: isSticky
                      ? questionIndexHeight
                        ? questionIndexHeight + 8
                        : 8
                      : 0,
                    borderRadius: 8
                  }}
                >
                  {questionGroup && (
                    <ScoreFormForT2Group
                      group={group}
                      questionGroup={questionGroup}
                    />
                  )}
                </Card>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </Col>
    </Row>
  )
}
