import { PageWrapper } from 'components/PageWrapper'
import { Button, Card, Col, Modal, Row, Space, Spin, Typography } from 'antd'
import React, { FC, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { CurrentExamProvider, useCurrentExamContent } from 'hooks/useExamData'
import { dateRangeFormat } from 'utils'
import { Subject } from 'config'
import {
  StatisticStateProvider,
  useStatisticStateContext
} from 'hooks/useStatisticState'
import { ClockCircleOutlined } from '@ant-design/icons'
import { useMount, useUpdateEffect } from '@umijs/hooks'
import { ReportStateGuard } from 'components/reports/ReportStateGuard'
import { ReportExamPaperTable } from 'components/ReportExamPaperTables'
import { EditProvider, useEditContext } from '../exam-report'
import { Edit } from '../exam-report/Edit'

const { Title, Text } = Typography

const Page: FC = () => {
  const query = useParams<{ id; pid }>()

  const edit = useEditContext()

  // useCurrentExamContent
  const { currentExam, currentPaper, initCurrentExam } = useCurrentExamContent()
  useEffect(() => {
    initCurrentExam(query.id, query.pid)
  }, [])

  //#region useStatisticStateContext
  const {
    loading,
    statisticConfig,
    checkStatisticState,
    requestStatisticCompute
  } = useStatisticStateContext()

  async function _checkStatisticState() {
    await checkStatisticState('exam', query.id)
  }

  function openSetting() {
    if (currentPaper) edit.onEdit({ id: query.id })
  }

  useMount(() => {
    _checkStatisticState()
  })

  useUpdateEffect(() => {
    _checkStatisticState()
  }, [edit.lastModify])

  useEffect(() => {
    // 检查 statisticConfig
    if (!loading && !statisticConfig && currentPaper) {
      openSetting()
    }
  }, [loading, currentPaper])
  //#endregion

  // UI
  function onRequestStatisticComputeClick() {
    Modal.confirm({
      title: '确定重新汇总成绩',
      onOk: () => requestStatisticCompute()
    })
  }

  return (
    <PageWrapper
      breadcrumb={['统考管理', '试卷管理', '成绩统计']}
      headerExtra={() => (
        <Link to={`/exams/${query.id}/papers`} replace>
          返回上级
        </Link>
      )}
      content={
        currentExam &&
        currentPaper && (
          <Row>
            <Col flex={'auto'}>
              <Title level={4} style={{ marginBottom: 4 }}>
                {currentExam.name} - {Subject[currentPaper.subject]}
              </Title>
              <Space size={'large'}>
                <Text type={'secondary'}>{currentPaper.name}</Text>
                <Text type={'secondary'}>
                  考试时间：
                  {dateRangeFormat({
                    value: [currentPaper.begin_time, currentPaper.end_time],
                    showTime: true
                  })}
                </Text>
                <Text type={'secondary'}>总分：{currentPaper.score}</Text>
              </Space>
            </Col>
            <Col flex={'none'}>
              <Space>
                <Button
                  icon={<ClockCircleOutlined />}
                  onClick={() => onRequestStatisticComputeClick()}
                >
                  重新汇总成绩
                </Button>
                <Button type={'primary'} onClick={() => openSetting()}>
                  成绩统计设置
                </Button>
              </Space>
            </Col>
          </Row>
        )
      }
    >
      <ReportStateGuard openSetting={() => openSetting()}>
        <PageContent />
      </ReportStateGuard>

      <Edit />
    </PageWrapper>
  )
}

const PageContent: FC = props => {
  const { currentPaper } = useCurrentExamContent()

  if (!currentPaper) {
    return <Spin />
  }
  return (
    <Card title={'成绩统计'}>
      <ReportExamPaperTable
        reportEntryType={'paper'}
        reportEntryId={currentPaper.id}
      />
    </Card>
  )
}

export default function () {
  return (
    <CurrentExamProvider>
      <StatisticStateProvider>
        <EditProvider>
          <Page />
        </EditProvider>
      </StatisticStateProvider>
    </CurrentExamProvider>
  )
}
