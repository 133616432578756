import { DefaultLabelCol, DefaultWrapperCol, FormList } from 'components'
import { RequiredRule } from 'components/EditModel/utils'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Col, Form, InputNumber, Row } from 'antd'
import React, { FC } from 'react'
import './index.less'
import _ from 'lodash'

export const FormItemSubjectScoreLevel: FC<{
  name?: number
  title: string
}> = props => (
  <div className={'subject-score-level-setting-item'}>
    <div className='title'>{props.title}</div>

    <Form.Item label={'分数等级设置'} style={{ marginBottom: 0 }}>
      <Row>
        <Col style={{ padding: 4 }}>及格分</Col>
        <Col>
          <Form.Item
            name={
              props.name !== undefined
                ? [props.name, 'pass_score']
                : 'pass_score'
            }
            rules={[RequiredRule]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col style={{ padding: 4 }}>；良好分</Col>
        <Col>
          <Form.Item
            name={
              props.name !== undefined
                ? [props.name, 'good_score']
                : 'good_score'
            }
            rules={[RequiredRule]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col style={{ padding: 4 }}>；优秀分</Col>
        <Col>
          <Form.Item
            name={
              props.name !== undefined
                ? [props.name, 'excellent_score']
                : 'excellent_score'
            }
            rules={[RequiredRule]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>

    <FormList
      name={
        props.name !== undefined
          ? [props.name, 'scoreRangeGroup']
          : 'scoreRangeGroup'
      }
      showAdd={false}
    >
      {(field, state, actions) => (
        <Form.Item
          label={state.isFirst && '统计分数区间'}
          key={state.index}
          wrapperCol={{
            span: DefaultWrapperCol,
            offset: state.isFirst ? 0 : DefaultLabelCol
          }}
          style={{ marginBottom: 0 }}
        >
          <Row>
            <Col>
              <Form.Item
                name={[field.name, 'begin']}
                fieldKey={[field.fieldKey, 'begin'] as any}
                rules={[RequiredRule]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col style={{ padding: 4 }}>分(含) 至</Col>
            {state.isLast ? (
              <Col style={{ padding: 4 }}>满分</Col>
            ) : (
              <Col>
                <Form.Item
                  name={[field.name, 'end']}
                  fieldKey={[field.fieldKey, 'end'] as any}
                  rules={[RequiredRule]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
            )}
            <Col style={{ padding: 4 }}>
              {!state.isLast && <span>分(不含)</span>}
              {!state.isLast && (
                <span
                  className={'primary'}
                  style={{ marginLeft: 8, fontSize: 16 }}
                >
                  <PlusCircleOutlined
                    onClick={() => {
                      actions.insertAt(state.index + 1, {})
                    }}
                  />
                </span>
              )}
              {!state.isFirst && !state.isLast && (
                <span
                  className='danger'
                  style={{ marginLeft: 8, fontSize: 16 }}
                >
                  <MinusCircleOutlined
                    onClick={() => {
                      actions.remove(state.index)
                    }}
                  />
                </span>
              )}
            </Col>
          </Row>
        </Form.Item>
      )}
    </FormList>
  </div>
)
