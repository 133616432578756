import { message } from 'antd'
import { defaultErrorHandler, GET } from 'core/request'

export async function requestAndSaveFile(url: string, data: any) {
  message.loading('正在导出...', 0)
  try {
    const res: any = await GET(url, {
      data,
      responseType: 'blob',
      timeout: 1000 * 60
    })
    const blob = new Blob([res.data])
    const fileName = res.headers['content-disposition'].split('filename=')[1]
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = decodeURIComponent(fileName)
    link.click()
    window.URL.revokeObjectURL(link.href)
  } catch (e) {
    defaultErrorHandler(e as any)
  } finally {
    message.destroy()
  }
}
