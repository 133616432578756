import { browserHistory, isRuntimeCXStudy } from 'config'
import $ from 'jquery'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import NTC from 'number-to-chinese-words'
import { CXStudyPushWindow } from './runtime-study'

export * from './attachment'
export * from './rich-text'

export const FORMATTER_DATE = 'YYYY.MM.DD'
export const FORMATTER_TIME = 'HH:mm'

export function dateRangeToParams(value: Moment[]) {
  return {
    begin_date: value[0] && value[0].format(FORMATTER_DATE),
    end_date: value[1] && value[1].format(FORMATTER_DATE)
  }
}

export function dateRangeFormat({
  value,
  dateFormatter = FORMATTER_DATE,
  timeFormatter = FORMATTER_TIME,
  showTime = false
}: {
  value: Moment[] | string[]
  dateFormatter?: string
  timeFormatter?: string
  showTime?: boolean
}) {
  if (!value[0] || !value[1]) {
    return ''
  }
  const v1 = moment(value[0])
  const v2 = moment(value[1])
  const start = v1.format(
    showTime ? `${dateFormatter} ${timeFormatter}` : dateFormatter
  )
  const end = (() => {
    const isSameDay = v1.isSame(v2, 'day')
    if (isSameDay) {
      return showTime ? v2.format(timeFormatter) : ''
    } else {
      return v2.format(
        showTime ? `${dateFormatter} ${timeFormatter}` : dateFormatter
      )
    }
  })()
  return end ? `${start} - ${end}` : start
}

export function numberToChinese(val: number) {
  return NTC.toWords(val)
}

export const getPlanText = (val: string) => {
  const wrapper = $(`<div>${val}</div>`)
  return wrapper.text()
}

export function adaptImageBaseUrlOfContent(content: string): string {
  // <img class=\"ans-formula-moudle\" src=\"/ananas/latex/p/753685\" data=\"%7B%22formula%22%3A%22%5C%5Cint_a%5Eb1dx%3D%22%2C%22id%22%3A753685%7D\">
  if (/"\/ananas\/latex/.test(content)) {
    return content.replace(/src="(\/ananas\/latex\/p\/\d+)"/gm, (_, s1) =>
      _.replace(s1, 'https://mooc1.chaoxing.com' + s1)
    )
  }
  return content
}

export function JSONParse<T = any, V = any>(val: V): T | V {
  try {
    return _.isString(val) ? JSON.parse(val) : val
  } catch (error) {
    return val
  }
}

export function routePushHelper(path: string, title: string) {
  if (isRuntimeCXStudy) {
    CXStudyPushWindow(
      `${location.protocol}//${location.host}${process.env['PUBLIC_URL']}${path}`,
      title,
      {}
    )
  } else {
    browserHistory.push(path)
  }
}
