import React, { FC, useState } from 'react'
import { Report } from 'typing'
import { Modal } from 'antd'
import { ModalSizeXLarge } from 'config'
import { TotalScoreTable } from 'components/ReportExamPaperTables/table/TotalScoreTable'
import { TableTabsWrapper } from 'components/ReportExamPaperTables/TableTabsWrapper'
import { DialogStudentDetail } from 'components/ReportExamPaperTables/dialog/DialogStudentDetail'
import { RangeScoreTable } from 'components/ReportExamPaperTables/table/RangeScoreTable'
import { QuestionScoreTable } from 'components/ReportExamPaperTables/table/QuestionScoreTable'

export const DialogClazzDetail: FC<Report.BasicTableDialogProps<
  Report.TotalScoreTableItem
>> = props => {
  const { scopeParams, stateParams, visible, onClose } = props
  return (
    <>
      <Modal
        visible={visible}
        title={
          scopeParams.reportEntryName
            ? `${stateParams.params?.org_name} (${scopeParams.reportEntryName})`
            : stateParams.params?.org_name
        }
        width={ModalSizeXLarge}
        onCancel={onClose}
        footer={false}
        destroyOnClose
      >
        <DialogClazzDetailTables {...props} />
      </Modal>
    </>
  )
}

export const DialogClazzDetailTables: FC<Omit<
  Report.BasicTableDialogProps<Report.TotalScoreTableItem>,
  'visible' | 'onClose'
>> = ({ scopeParams, stateParams }) => {
  const [studentDialogVisible, setStudentDialogVisible] = useState(false)
  const [studentDialogState, setStudentDialogState] = useState<
    Report.BasicTableDialogProps['stateParams']
  >()

  return (
    <>
      <TableTabsWrapper
        scopeParams={scopeParams}
        defaultTableIndex={stateParams.tabIndex}
      >
        {(tabIndex, summary) => {
          if (!stateParams.params) {
            return null
          }
          if (tabIndex === 0) {
            return (
              <TotalScoreTable
                scopeParams={scopeParams}
                stateParams={stateParams}
                totalScoreSummary={summary}
                onDetailClick={params => {
                  setStudentDialogState({
                    params,
                    tabIndex
                  })
                  setStudentDialogVisible(true)
                }}
              />
            )
          }
          if (tabIndex === 1) {
            return (
              <RangeScoreTable
                scopeParams={scopeParams}
                stateParams={stateParams}
                totalScoreSummary={summary}
                onDetailClick={params => {
                  setStudentDialogState({
                    params,
                    tabIndex
                  })
                  setStudentDialogVisible(true)
                }}
              />
            )
          }
          if (tabIndex === 2) {
            return (
              <QuestionScoreTable
                scopeParams={scopeParams}
                stateParams={stateParams}
                totalScoreSummary={summary}
                onDetailClick={params => {
                  setStudentDialogState({
                    params,
                    tabIndex
                  })
                  setStudentDialogVisible(true)
                }}
              />
            )
          }
        }}
      </TableTabsWrapper>

      {studentDialogState && (
        <DialogStudentDetail
          scopeParams={{
            ...scopeParams,
            scope: 'student',
            clazzId: studentDialogState.params.statistic_clazz_id
          }}
          stateParams={studentDialogState}
          visible={studentDialogVisible}
          onClose={() => setStudentDialogVisible(false)}
        />
      )}
    </>
  )
}
