import React, { FC, useEffect, useState } from 'react'
import { Paper } from 'typing'
import { PageWrapper } from 'components/PageWrapper'
import { CurrentExamProvider, useCurrentExamContent } from 'hooks/useExamData'
import { Link, useParams } from 'react-router-dom'
import { useMount } from '@umijs/hooks'
import { dateRangeFormat } from 'utils'
import {
  Card,
  Col,
  Progress,
  Row,
  Space,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons'
import { PaperReport } from './PaperReport'
import constate from 'constate'
import { GET } from 'core/request'
import { Grade, MarkingMode, Subject } from 'config'
import _ from 'lodash'
import numeral from 'numeral'

const { Title, Text } = Typography

const [ListProvider, useListContext] = constate(() => {
  const [statistic, setStatistic] = useState<{
    papers
    teachers
    donePercent
    todo
  }>()
  const [papers, setPapers] = useState<Paper.PaperWithStatistic[]>()
  const [loading, setLoading] = useState(true)

  async function fetch(id: string) {
    const { data } = await GET(`city/monitor/exam/${id}`, {})
    setLoading(false)
    setPapers(data.papers)
  }

  useEffect(() => {
    setStatistic({
      papers: _.sumBy(papers, p => p.student_count),
      teachers: _.sumBy(papers, p => p.teacher_count),
      donePercent:
        _.sumBy(papers, p => p.student_complete_count) /
        _.sumBy(papers, p => p.student_count),
      todo: _.sumBy(papers, p => p.student_not_complete_count)
    })
  }, [papers])

  return {
    statistic,
    papers,
    loading,
    fetch
  }
})
ListProvider.displayName = 'ListProvider'

const [EditProvider, useEditContext] = constate(() => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [params, setParams] = useState<{
    name
    score
    paper_student_count
    teacher_count
    review_time
  }>()
  const [teachers, setTeachers] = useState([])

  function onEdit(item: Paper.PaperWithStatistic) {
    async function fetch() {
      setLoading(true)
      const { data } = await GET(`city/monitor/paper/${item.id}`, {})
      setParams({
        ...data,
        teacher_count: data.teachers.length
      })
      setTeachers(data.teachers)
      setLoading(false)
    }

    setVisible(true)
    fetch()
  }

  return {
    visible,
    onCancel: () => setVisible(false),
    loading,
    onEdit,
    params,
    teachers
  }
})
EditProvider.displayName = 'EditProvider'

export { useEditContext }

export default function () {
  return (
    <CurrentExamProvider>
      <ListProvider>
        <EditProvider>
          <Page />
        </EditProvider>
      </ListProvider>
    </CurrentExamProvider>
  )
}

const Page: FC = props => {
  const query = useParams<{ id }>()
  const { currentExam, initCurrentExam } = useCurrentExamContent()

  const { fetch, loading, statistic, papers } = useListContext()
  const { onEdit } = useEditContext()

  useMount(() => {
    initCurrentExam(query.id)
    fetch(query.id)
  })

  return (
    <PageWrapper
      breadcrumb={['统考管理', '判卷进度']}
      headerExtra={() => (
        <Link to={'/exams'} replace>
          返回上级
        </Link>
      )}
      content={
        currentExam && (
          <div>
            <Title level={4} style={{ marginBottom: 4 }}>
              {currentExam.name}
            </Title>
            <Space size={'large'}>
              <Text type={'secondary'}>
                考试年级：{Grade[currentExam.grade]}
              </Text>
              <Text type={'secondary'}>
                考试时间：
                {dateRangeFormat({
                  value: [currentExam.begin_date, currentExam.end_date]
                })}
              </Text>
              <Text type={'secondary'}>试卷数：{currentExam.paper_count}</Text>
              <Text type={'secondary'}>
                科目：
                {currentExam.paper_subjects.map(s => Subject[s]).join('、')}
              </Text>
            </Space>
          </div>
        )
      }
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <Card size={'small'} style={{ marginBottom: 16 }}>
            <Row
              gutter={[0, 24]}
              style={{ paddingTop: 24, textAlign: 'center' }}
            >
              <Col span={6}>
                <Statistic
                  title={
                    <Tooltip title={'本次统考下所有试卷的答卷数总和'}>
                      <span style={{ cursor: 'pointer' }}>
                        答卷总数 <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  value={statistic && statistic.papers}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title={
                    <Tooltip title={'判卷员总人数'}>
                      <span style={{ cursor: 'pointer' }}>
                        判卷员 <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  value={statistic && statistic.teachers}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title={
                    <Tooltip
                      title={
                        '单份答卷试题已全部完成打分的答卷数量/答卷总数*100%'
                      }
                    >
                      <span style={{ cursor: 'pointer' }}>
                        已判卷 <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  value={
                    statistic && numeral(statistic.donePercent).format('0.00%')
                  }
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title={
                    <Tooltip title={'单份答卷试题未全部完成打分的答卷数量'}>
                      <span style={{ cursor: 'pointer' }}>
                        判卷未完成 <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  }
                  value={statistic && statistic.todo}
                />
              </Col>
            </Row>
          </Card>

          <Card title={'判卷动态'}>
            <Table<Paper.PaperWithStatistic>
              dataSource={papers}
              rowKey={'id'}
              columns={[
                {
                  title: '科目',
                  dataIndex: 'subject',
                  render: t => Subject[t],
                  width: 100
                },
                {
                  title: '试卷名',
                  dataIndex: 'name',
                  render: (t, r) => (
                    <a onClick={() => onEdit(r)}>
                      {t} <LinkOutlined />
                    </a>
                  )
                },
                {
                  title: '判卷模式',
                  dataIndex: 'review_mode',
                  render: t => {
                    return t === MarkingMode.ALL ? (
                      <Tag color={'warning'}>普通判卷</Tag>
                    ) : t === MarkingMode.GROUP_FLOW ? (
                      <Tag color={'processing'}>流水判卷</Tag>
                    ) : (
                      <Tag color={'#f55'}>未设置</Tag>
                    )
                  },
                  width: 90
                },
                {
                  title: '判卷员数',
                  dataIndex: 'teacher_count',
                  align: 'right',
                  width: 90
                },
                {
                  title: '已分配',
                  dataIndex: 'distribute_count',
                  render: t => {
                    return !!t ? '是' : '否'
                  },
                  width: 100,
                  align: 'center'
                },
                {
                  title: '完成进度',
                  render: (t, r) => (
                    <Progress
                      percent={
                        Math.floor(
                          (r.student_complete_count / r.student_count) *
                            100 *
                            100
                        ) / 100
                      }
                      strokeWidth={6}
                      style={{ width: 100 }}
                    />
                  ),
                  width: 140
                },
                {
                  title: '答卷总数',
                  dataIndex: 'student_count',
                  align: 'right',
                  width: 120
                },
                {
                  title: '完成判卷数',
                  dataIndex: 'student_complete_count',
                  render: (t, r) => (!!r.distribute_count ? t : '-'),
                  align: 'right',
                  width: 120
                },
                {
                  title: '未完成判卷数',
                  dataIndex: 'student_not_complete_count',
                  render: (t, r) => (!!r.distribute_count ? t : '-'),
                  align: 'right',
                  width: 120
                }
              ]}
              pagination={false}
            />
          </Card>
        </>
      )}

      <PaperReport />
    </PageWrapper>
  )
}
