import { Col, Row } from 'antd'
import _ from 'lodash'
import React, { FC, useEffect } from 'react'
import { Marking, Paper } from '../../typing'
import { isAnswerNesting, isAnswerNormals } from '../../utils/answer'
import { RichText } from '../Preview/RichText'
import {
  DrawerModalProps,
  RichTextWithDrawerModal
} from './RichTextWithDrawerModal'

const log = require('debug')

export const RightAnswerRender: FC<{
  answerData: Paper.AnswerContent[]
}> = ({ answerData }) => {
  useEffect(() => {
    log('renderRightAnswer:')(answerData)
  }, [])

  if (!answerData) return null

  if (typeof answerData === 'string') {
    return <RichText html={answerData} emptyText={'无答案'} />
  }

  return (
    <>
      {_.map(answerData, (a, i) => {
        const showIndex = answerData.length > 1
        if (a.name) {
          return (
            <Row key={i}>
              {showIndex && <Col style={{ marginRight: 8 }}>{a.name}.</Col>}
              <Col>
                <RichText html={a.content} emptyText={'无答案'} />
              </Col>
            </Row>
          )
        }
        return (
          <RichText
            key={i}
            html={a.content || a.answer || ''}
            emptyText={'无答案'}
          />
        )
      })}
    </>
  )
}

type StudentAnswerRenderProps = DrawerModalProps & {
  answerData:
    | string
    | Marking.AnswerNormal[]
    | Marking.AnswerNesting
    | undefined
}
export const StudentAnswerRender: FC<StudentAnswerRenderProps> = props => {
  const { answerData, ...drawerProps } = props

  useEffect(() => {
    // log('renderStudentAnswer:')('params', answerData)
  }, [])

  if (!answerData) {
    return null
  }

  if (typeof answerData === 'string') {
    return <RichTextWithDrawerModal html={answerData} {...drawerProps} />
  }

  // Question indexed answers
  if (isAnswerNormals(answerData)) {
    const showIndex = answerData.length > 1
    return (
      <>
        {_.map(answerData, (a, i) => {
          if (a.name) {
            return (
              <Row key={i}>
                {showIndex && <Col style={{ marginRight: 8 }}>{a.name}.</Col>}
                <Col flex={1}>
                  <RichTextWithDrawerModal
                    html={a.content}
                    {...drawerProps}
                    namedQuestionIndex={i}
                  />
                </Col>
              </Row>
            )
          }
          return (
            <RichTextWithDrawerModal
              key={i}
              html={a.content || a.answer}
              {...drawerProps}
            />
          )
        })}
      </>
    )
  }

  if (isAnswerNesting(answerData)) {
    return <RichTextWithDrawerModal html={answerData.answer} {...drawerProps} />
  }

  return <RichText html={''} />
}
