import React, { FC } from 'react'
import { Card, Tabs } from 'antd'
import TeacherIndex from './teacher-index'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import BatchIndex from './batch-index'

const Page: FC = () => {
  const history = useHistory()
  const defaultKey = _.get(window.history.state, 'state.tab', '1')
  return (
    <>
      <Card>
        <Tabs
          defaultActiveKey={defaultKey}
          onChange={k => {
            history.replace('/teacher-report', {
              tab: k
            })
          }}
        >
          <Tabs.TabPane tab={'汇总统计'} key={'1'}>
            <TeacherIndex />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'批次统计'} key={'2'}>
            <BatchIndex />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  )
}

export default Page
