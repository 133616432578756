import { Card } from 'antd'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import React, { FC, useLayoutEffect, useMemo, useRef } from 'react'
import { Paper } from 'typing'
import { groupQuestionByType2, isType2Question } from 'utils/question'
import { useMarkingForm } from '../useMarkingForm'
import { useScoreFormNavigation } from '../useScoreFormNavigation'
import { QuestionItem } from './QuestionItem'
import { QuestionItemFormT2Group } from './QuestionItemForT2Group'
import './QuestionList.less'

interface QuestionListConfig {}

const log = require('debug')('QuestionList')

export const QuestionList: FC<QuestionListConfig> = props => {
  const {
    isCurrentUserHasAuditRole,
    isGroupedTeacherMode
  } = useQuestionAnswerDataContent()

  const {
    paperQuestions,
    markingPaper,
    markingQuestionsVO,
    // PartialMarking
    isPartialMarkingMode
  } = useMarkingForm()

  const questions = useMemo<Paper.Question[]>(() => {
    return markingQuestionsVO
  }, [markingQuestionsVO])

  const type2GroupQuestions = useMemo(() => {
    if (isGroupedTeacherMode) return null
    if (!markingPaper?.question_type2_group_marking) return null

    // if questions not include type2 questions, return false
    const hasType2Questions = questions.filter(q => isType2Question(q.type))
    if (!hasType2Questions.length) return null

    let grouped = groupQuestionByType2(questions)

    // TODO pick all question of group questions, in audit mode
    // ignore audit question
    // ignore partial marking question

    if (isCurrentUserHasAuditRole) {
      grouped = grouped.map(item => {
        if (_.isArray(item)) {
          const group = paperQuestions.find(it => it.id === item[0]._groupId)
          if (!group) return item
          return group.children
        }
        if (isType2Question(item.type)) {
          const group = paperQuestions.find(it => it.id === item._groupId)
          if (!group) return item
          return group.children
        }
        return item
      })
    }

    if (!grouped.some(g => _.isArray(g))) return null

    return grouped
  }, [isCurrentUserHasAuditRole, markingPaper, paperQuestions, questions])

  // useEffect(() => {
  //   console.log('type2GroupQuestions', type2GroupQuestions)
  // }, [type2GroupQuestions])

  // ScoreForm navigate
  const containerRef = useRef<HTMLDivElement>(null)
  const { initQuestions, focusFirstQuestionForm } = useScoreFormNavigation()

  useLayoutEffect(() => {
    if (!containerRef) return
    if (!questions.length) return

    initQuestions(questions)

    // Get first question from questions
    const timer = setTimeout(() => {
      focusFirstQuestionForm()
    }, 100)
    return () => clearTimeout(timer)
  }, [questions])

  if (!questions.length) {
    return (
      <Card>
        <div style={{ textAlign: 'center' }}>
          {isCurrentUserHasAuditRole && isPartialMarkingMode ? (
            <>
              <div>根据【部分批阅】设置，当前试卷暂无需要仲裁的题目</div>
            </>
          ) : isCurrentUserHasAuditRole ? (
            '暂无需要仲裁的题目'
          ) : (
            '暂无题目'
          )}
        </div>
      </Card>
    )
  }

  if (type2GroupQuestions) {
    return (
      <div className='question-list' ref={containerRef}>
        {type2GroupQuestions.map((q, i) =>
          _.isArray(q) ? (
            <QuestionItemFormT2Group key={i} group={q} />
          ) : (
            <QuestionItem key={q.id} question={q} />
          )
        )}
      </div>
    )
  }

  return (
    <div className='question-list' ref={containerRef}>
      {questions.map(q => (
        <QuestionItem key={q.id} question={q} />
      ))}
    </div>
  )
}
