import { Col, InputNumber, Row, Typography } from 'antd'
import Button from 'antd/es/button'
import _ from 'lodash'
import React, { FC, useState } from 'react'
import { Paper } from 'typing'
import { useScoreFormNavigation } from '../useScoreFormNavigation'

const { Text } = Typography

export const ScoreInput: FC<{
  question: Paper.Question
  child?: Paper.QuestionNestingChildQuestion
  score?: number
  maxScore: number
  onScoreChange: (val: string | number | undefined) => void
  showQuickScore?: boolean
  extendRender?: () => React.ReactNode
}> = ({
  question,
  child,
  score,
  maxScore,
  onScoreChange,
  showQuickScore,
  extendRender
}) => {
  const [error, setError] = useState<string | undefined>()
  function onChange(value: number | string | undefined) {
    if (!_.isFinite(value)) {
      setError(undefined)
      onScoreChange(undefined)
      return
    }

    const val = Number(value)
    if (val > maxScore) {
      setError(`得分不能大于${maxScore}`)
      onScoreChange(undefined)
    } else {
      setError(undefined)
      onScoreChange(val)
    }
  }

  const { emitter } = useScoreFormNavigation()
  function onPressEnter() {
    emitter.emit('scoreForm:enterPress', {
      questionId: question.id
    })
  }

  return (
    <>
      <Row
        align={'middle'}
        justify={'center'}
        gutter={8}
        className={'score-input-form'}
      >
        <Col>得分</Col>
        <Col>
          <InputNumber
            value={score}
            onChange={onChange}
            onPressEnter={onPressEnter}
            min={0}
            size={'large'}
            style={{ textAlign: 'center' }}
          />
        </Col>
        <Col>
          <Text type={'secondary'}>≤{maxScore}</Text>
        </Col>
        {error && (
          <div className='error' style={{ marginTop: 4 }}>
            {error}
          </div>
        )}
      </Row>

      {(typeof showQuickScore === 'undefined' || showQuickScore) && (
        <div className={'score-input-action'}>
          <div className={'label'}>快速打分</div>
          <Row
            align={'middle'}
            justify={'center'}
            gutter={8}
            style={{ marginBottom: 8 }}
          >
            <Col span={12}>
              <Button
                type={'primary'}
                danger
                block
                tabIndex={-1}
                onClick={() => onChange(0)}
              >
                0分
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className={'btn-success'}
                block
                tabIndex={-1}
                onClick={() => onChange(maxScore)}
              >
                满分
              </Button>
            </Col>
          </Row>
          {maxScore <= 25 && (
            <Row gutter={[8, 8]}>
              {_.range(1, maxScore + 1).map(value => (
                <Col key={value} span={6}>
                  <Button
                    block
                    type={score === value ? 'primary' : 'default'}
                    onClick={() => onChange(value)}
                    tabIndex={-1}
                  >
                    {value}
                  </Button>
                </Col>
              ))}
            </Row>
          )}
        </div>
      )}

      {extendRender?.()}
    </>
  )
}
