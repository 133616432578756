import { QuestionType, QuestionTypeKey } from 'config'
import _ from 'lodash'
import { Paper } from 'typing'
import {
  QuestionIdentity,
  QuestionNesting,
  SteppedScoreSetting
} from 'typing.paper'
import { numberToChinese } from 'utils'

const log = require('debug')

export const isChoiceQuestion = (type: QuestionTypeKey) => {
  return !!~[0, 1, 3, 11, 12, 13, 14, 17, 20, 21, 24].indexOf(type)
}

export const isType2Question = (type: QuestionTypeKey) => {
  return !!~[2, 9, 10].indexOf(type)
}

export const isNestingQuestion = (type: QuestionTypeKey) => {
  return !!~[14, 15, 19, 21, 25].indexOf(type)
}

export const isFlattenNestingQuestion = (questionId: number | string) =>
  typeof questionId === 'string'

export const createPaperQuestion = (
  paper: Paper.PaperOriginal,
  includeChoiceQuestion = false,
  isType2Choice = false
): Paper.QuestionBox => {
  /*
   * QuestionBox
   * |-- QuestionGroup[] 题组
   *  |-- Question[] 题目
   *   |-- Question[] 子题
   */

  function toQuestionGroup(
    value: Paper.PaperOriginalQuestionGroup[]
  ): Paper.QuestionGroup[] {
    const groups = _.map(value, (g, i) => {
      const questions: Paper.Question[] = _.map(
        g.paperlibraryrelationquestion.data,
        q => ({
          ...toQuestion(q),
          _groupId: g.id
        })
      )
      log('toQuestionGroup:')('transToQuestion result', g.id, questions)

      const children = _.filter(questions, q => {
        if (isQuestionNesting(q) && !q.children.length) {
          return false
        }
        return choiceQuestionsFilterFunc(q)
      })

      const group: Paper.QuestionGroup = {
        ..._.omit(g, ['paperlibraryrelationquestion']),
        displayName: numberToChinese((g.__order ?? i) + 1),
        questionCount: g.questionnum,
        typeNameAlias: g.coursequestiontypename || QuestionType[g.type],
        children
      }
      log('toQuestionGroup:')('group', g.id, group)
      return group
    })

    return _.filter(groups, g => {
      return !!g.children.length
    })
  }

  function toQuestion(
    q: Paper.PaperOriginalQuestion,
    isNesting = false
  ): Paper.Question {
    const answerData = (() => {
      if (typeof q.answer === 'string' && q.answer) {
        try {
          return JSON.parse(q.answer as string)
        } catch (e) {
          console.error(e)
          return []
        }
      }
      return q.answer
    })()

    const displayName = isFlattenNestingQuestion(q.id)
      ? `${(q.q__order ?? 0) + 1} (${q.__order + 1})`
      : `${q.__order + 1}`

    if (isChoiceQuestion(q.type)) {
      // option
      return {
        ...q,
        displayName,
        cate: 'option',
        answer: answerData as Paper.AnswerOption[]
      }
    }
    // TODO: remove
    else if (isNestingQuestion(q.type)) {
      let _answerData = answerData as Paper.PaperOriginalAnswerData[]

      if (q.type === 25 && Array.isArray(answerData)) {
        _answerData = (answerData as Paper.Type25OriginalAnswerData)[1]
      }

      const nestingQuestions = _.map(_answerData, d => {
        return toQuestion(normalizeNestingData(d), true)
      })

      log('toQuestion:')('nestingQuestions', q.id, nestingQuestions)

      const children = _.filter(nestingQuestions, nq => {
        return choiceQuestionsFilterFunc(nq, true)
      }) as Paper.QuestionNesting['children']

      log('transToQuestion:')('nestingQuestions children', q.id, children)

      return {
        ..._.omit(q, 'answer'),
        displayName,
        cate: 'nesting',
        children
      }
    } else {
      // content
      return {
        ...q,
        displayName,
        cate: 'content',
        answer: answerData as Paper.AnswerContent[]
      }
    }
  }

  // TODO: remove
  function normalizeNestingData(
    data: Paper.PaperOriginalAnswerData
  ): Paper.PaperOriginalQuestion {
    return {
      ...data,
      id: data.id as any,
      content: data.data.content,
      score: data.data.score,
      answer: data.data.answer,
      __order: 0
    }
  }

  // TODO: remove
  // return true to include choice question
  function choiceQuestionsFilterFunc(q: Paper.Question, isNesting?: boolean) {
    if (includeChoiceQuestion) {
      return true
    }
    if (isChoiceQuestion(q.type)) {
      return false
    }
    if (isType2Question(q.type)) {
      if (isNesting) {
        return !isType2Choice
      }

      // Magic here
      // blankobj
      // false 主观 input
      // true  客观 choice isType2Choice
      //
      // ```
      // blankobj
      // =0 Input   主观    isType2Choice = false
      // =1 Choice  客观    isType2Choice = true
      //   blanksubject
      //   =1 主观
      //   =0 客观
      // ```
      // if (isType2Choice) {
      //   if (q.blanksubject === Type2BlankType.Input) {
      //     //   =1 主观
      //     return true
      //   } else {
      //     //   =0 客观
      //     return false
      //   }
      // }
      return true
    }
    return true
  }

  const group = toQuestionGroup(paper.paperlibraryrelation.data)

  log('toQuestionGroup:')('result', group)

  const box: Paper.QuestionBox = {
    ..._.omit(paper, 'paperlibraryrelation'),
    questionCount: paper.questionnum,
    group,
    isType2Choice
  }
  log('createPaperQuestion:')('box', box)
  return box
}

// Utils
export function isQuestionOption(
  question: Paper.Question
): question is Paper.QuestionOption {
  return question.cate === 'option'
}
export function isQuestionContent(
  question: Paper.Question
): question is Paper.QuestionContent {
  return question.cate === 'content'
}
export function isQuestionNesting(
  question: Paper.Question
): question is Paper.QuestionNesting {
  return question.cate === 'nesting'
}
export function isQuestionFlattenNesting(
  question: Paper.Question
): question is Paper.QuestionFlattenNesting {
  return isFlattenNestingQuestion(question.id)
}

export function resolveQuestionIdentity(question: QuestionIdentity) {
  return _.isArray(question)
    ? { parentQuestionId: question[0], questionId: question[1] }
    : { questionId: question }
}

export type QuestionPath = [
  Paper.QuestionGroup,
  Paper.Question,
  Paper.QuestionNestingChildQuestion?
]

/**
 * @param sourceData
 */
export function getQuestionPath(
  sourceData: Paper.QuestionGroup[],
  id: QuestionIdentity
): QuestionPath | null {
  let group: Paper.QuestionGroup | null = null
  let question: Paper.Question | null = null

  _.forEach(sourceData, g => {
    group = g
    const matched = _.find(g.children, c => {
      if (_.isArray(id)) {
        return c.id === id[0]
      }
      return c.id === id
    })
    if (matched) {
      question = matched
      return false
    }
  })
  if (!group || !question) {
    log.debug('getQuestionPath:error')(id, sourceData)
    return null
  }

  if (_.isArray(id)) {
    const nestingQuestion = question as Paper.QuestionNesting
    const nestedQuestion = _.find(nestingQuestion.children, c => {
      return c.id === id[1]
    })
    if (!nestedQuestion) {
      log.debug('getQuestionPath:error')(id, sourceData)
      return null
    }
    return [group, question, nestedQuestion]
  }
  return [group, question]
}

export function reversQuestionsToGroupTree(
  sourceData: Paper.QuestionGroup[],
  items: Paper.Question[]
): Paper.QuestionGroup[] {
  const questionPathGroup = getQuestionPathGroup(sourceData, items)

  const questionGroups = _.map(questionPathGroup, (paths, key) => {
    const g = _.clone(_.find(sourceData, g => g.id === +key))
    if (!g) {
      throw new Error(
        '[reversQuestionsToGroupTree] group no found by key:' + key
      )
    }
    g.children = _.map(paths, path => {
      return _.find(g.children, c => {
        return c.id === _.get(path, ['1', 'id'])
      }) as Paper.Question
    })
    g.children = _.sortBy(g.children, '_index')
    return g
  })
  log.debug('reversQuestionsToGroupTree:')('result', questionGroups)

  return _.orderBy(questionGroups, '_index')
}

export function flattenGroupedQuestions(
  groups: Paper.QuestionGroup[],
  nesting = false
) {
  return _.reduce<Paper.Question, Paper.Question[]>(
    _.flatMap(groups, g => g.children),
    (res, q) => {
      if (nesting && isQuestionNesting(q)) {
        // @ts-ignore
        res.push(...q.children)
      } else {
        res.push(q)
      }
      return res
    },
    []
  )
}

export type QuestionDisplayTag = Paper.Question & {
  groupIndex: number
  groupName: string
}

export function questionsToDisplayTags(
  groupData: Paper.QuestionGroup[],
  questions: Paper.Question[]
): QuestionDisplayTag[] {
  const res = questions.reduce<QuestionDisplayTag[]>((acc, q) => {
    const g = _.find(groupData, g => g.id === q._groupId)

    if (!g) {
      throw new Error('group not found by question id')
    }

    acc.push({
      groupIndex: g.__order,
      groupName: _.get(g, 'displayName') || '',
      ...q
    })
    return acc
  }, [])
  return _.orderBy(res, ['groupIndex', '__order', 'q__order'])
}

/**
 * Returns Record<GroupId, QuestionPath[]>
 */
function getQuestionPathGroup(
  sourceData: Paper.QuestionGroup[],
  items: Paper.Question[]
) {
  const questionPathList = _.map(items, item =>
    getQuestionPath(sourceData, item.id)
  )

  const questionPathGroup = _.groupBy(questionPathList, path => {
    return _.get(_.first(path), 'id')
  })
  log('getQuestionPathGroup:')('result', questionPathGroup)

  return questionPathGroup
}

export function createPaperQuestionForMarking(
  questionGroup: Paper.QuestionGroup[],
  questionIncludes: QuestionIdentity[]
): Paper.Question[] {
  return _.reduce<Paper.Question, Paper.Question[]>(
    flattenGroupedQuestions(questionGroup),
    (res, q) => {
      if (questionIncludes.length && !_.includes(questionIncludes, q.id)) {
        return res
      }
      if (isQuestionNesting(q)) {
        // TODO: remove
        q.children = q.children.filter(c => {
          if (isChoiceQuestion(c.type)) {
            return false
          }
          return true
        })
        if (q.children.length) {
          res.push(q)
        }
      } else {
        res.push(q)
      }

      return res
    },
    []
  )
}

export function groupQuestionByType2(questions: Paper.Question[]) {
  const res: Array<Paper.Question | Paper.Question[]> = []

  function isT2(q: Paper.Question) {
    return isType2Question(q.type)
  }

  // Find continuous type2 questions, and push to group
  // See https://codesandbox.io/p/sandbox/array-group-nshg9y?file=%2Fsrc%2Findex.ts
  let index = 0
  while (index < questions.length) {
    const q = questions[index]

    if (isT2(q)) {
      const group: Paper.Question[] = [q]

      let nextIndex = index + 1
      let next = questions[nextIndex]

      while (next && isT2(next) && next._groupId === q._groupId) {
        group.push(next)
        nextIndex++
        next = questions[nextIndex]
      }

      res.push(group.length > 1 ? group : group[0])

      index += nextIndex - index
    } else {
      res.push(q)
      index++
    }
  }

  return res
}

export function splitNumberIntoBucket(number: number, bucketSize: number[]) {
  const bucket: number[] = []
  let remain = number
  _.forEach(bucketSize, size => {
    if (remain > size) {
      bucket.push(size)
      remain -= size
    } else {
      bucket.push(remain)
      remain = 0
    }
  })
  return bucket
}

export function getQuestionDisplayName(path: QuestionPath) {
  return [path[0].displayName, '、', `${path[1].displayName}`].join('')
}

export function uniformQuestionCompare(
  a: QuestionIdentity,
  b: QuestionIdentity
) {
  // if (_.isArray(a) && _.isArray(b)) {
  //   return a[0] === b[0] && a[1] === b[1]
  // }
  // if (_.isNumber(a) && _.isNumber(b)) {
  //   return a === b
  // }
  return a === b
}

export function findAndParseStepScoreEmbedHTML(
  html: string,
  steps: SteppedScoreSetting['items'][number]['steps']
): Array<number | null> | null {
  const $el = $(`<div>${html}</div>`)
  const text = $el.text()

  const result = steps.map((s, i) => {
    // string pattern
    // ${title} ${description} 得分：${score}分
    const reg = getStepScoreEmbedHTMLReg(s)
    const match = text.match(reg)
    if (match) {
      return +match[1]
    }
    return null
  })
  console.log('findAndParseStepScoreEmbedHTML', html, result)
  return result
}

export function updateStepScoreEmbedHTML(
  html: string,
  steps: SteppedScoreSetting['items'][number]['steps'],
  value: Array<number | undefined>
): string {
  _.forEach(steps, (s, i) => {
    const reg = getStepScoreEmbedHTMLReg(s)
    const match = html.match(reg)
    if (_.isFinite(value[i])) {
      const score = value[i] as number
      if (match) {
        // Update score in html
        html = html.replace(reg, getStepScoreEmbedHTMLString(s, score))
      } else {
        // Append record to html
        html += `<div>${getStepScoreEmbedHTMLString(s, score)}</div>`
      }
    } else {
      // Remove record in html
      if (match) {
        html = html.replace(reg, '')
      }
    }
  })

  return html
}

function getStepScoreEmbedHTMLReg(
  step: SteppedScoreSetting['items'][number]['steps'][number]
) {
  return new RegExp(`${step.title}：${step.description}\\s+得分：(\\d+)分`)
}
function getStepScoreEmbedHTMLString(
  step: SteppedScoreSetting['items'][number]['steps'][number],
  score: number
) {
  return `${step.title}：${step.description}   得分：${score}分`
}

function toQuestionIds(questions: Paper.Question[]) {
  return _.map<Paper.Question, QuestionIdentity>(questions, q => q.id)
}

export function getNextQuestionId(
  questions: Paper.Question[],
  curr?: QuestionIdentity
) {
  // flatten markingQuestions to questionIds
  const questionIds = toQuestionIds(questions)

  // get indexof currentQuestionId
  if (!curr) return questionIds[0]
  const currentIndex = _.findIndex(questionIds, q => _.isEqual(q, curr))
  if (currentIndex === -1) return questionIds[0]

  // get next index
  const nextIndex = currentIndex + 1
  if (nextIndex >= questionIds.length) return null //finish
  return questionIds[nextIndex]
}
