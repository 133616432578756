import { Input } from 'antd'
import React, { FC, useState } from 'react'
import { Teacher } from 'typing.exam'
import { TeacherSelectDialogForFanya } from './DialogForFanya'

export const TeacherSelectorForFanya: FC<{
  value?: Teacher
  onChange?: (value: Teacher) => void
  disabled?: boolean
}> = props => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Input
        value={props.value?.display_name}
        readOnly
        onClick={() => {
          if (props.disabled) return
          setVisible(true)
        }}
        style={{ width: 200 }}
        placeholder={'请选择'}
        disabled={props.disabled}
      />
      <TeacherSelectDialogForFanya
        visible={visible}
        title={'选择教师'}
        multi={false}
        onConfirm={data => {
          props.onChange?.(data[0])
          setVisible(false)
        }}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}
