import {
  CaretRightOutlined,
  CheckCircleOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Button, Space } from 'antd'
import { StudentAnswerDrawerRender } from 'components/Answer/StudentAnswerDrawerRender'
import { RichText } from 'components/Preview/RichText'
import { IS_DEV } from 'config'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import React, { FC, memo } from 'react'
import { Marking, Paper } from 'typing'
import { isQuestionContent } from 'utils/question'
import {
  RightAnswerRender,
  StudentAnswerRender
} from '../Answer/StudentAnswerRender'
import { QuestionIdentity } from 'typing.paper'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'

type QuestionItemMarkingProps = {
  question: Paper.Question
  parentQuestion?: Paper.Question
  student?: { id: number }
  answerData?:
    | string
    | Marking.AnswerNormal[]
    | Marking.AnswerNesting
    | undefined
  showContent: boolean
  showAnswerRight: boolean
  showAnswer: boolean
  showScore?: boolean
  score?: number
  readonly?: boolean
  showScoreFormJumper?: boolean
}

function scrollScoreForm(questionId: QuestionIdentity) {
  const el = document.querySelector(`#score-form_${questionId}`)
  if (!el) return
  el.scrollIntoView(false)

  el.querySelector('input')?.focus()

  el.classList.add('highlight')
  setTimeout(() => {
    el.classList.remove('highlight')
  }, 1000)
}

export const QuestionItemContentRender: FC<QuestionItemMarkingProps> = memo(
  ({
    question,
    parentQuestion,
    student,
    answerData,
    showContent,
    showAnswerRight,
    showAnswer,
    readonly,
    showScore,
    score,
    showScoreFormJumper
  }) => {
    const { userImageMarkingMode } = useMarkingForm()

    return (
      <div className='question-item'>
        {showContent && (
          <RichText
            className='content'
            html={question.content}
            emptyText={'无题干'}
          />
        )}
        {showAnswer && (
          <div className='answer'>
            {IS_DEV && (
              <div>
                {parentQuestion && `${parentQuestion.id}-`}
                {question.id}
              </div>
            )}
            <div className='title'>
              <UserOutlined style={{ marginRight: 8 }} />
              学生答案:
              {showScoreFormJumper && parentQuestion && (
                <Space className='right'>
                  <Button
                    type='link'
                    size='small'
                    icon={<CaretRightOutlined />}
                    onClick={() => scrollScoreForm(question.id)}
                  >
                    打分
                  </Button>
                </Space>
              )}
            </div>
            {userImageMarkingMode ? (
              <StudentAnswerDrawerRender
                answerData={answerData}
                readonly={readonly}
              />
            ) : (
              <StudentAnswerRender
                questionId={question.id}
                question={question}
                studentId={student ? student.id : 0}
                answerData={answerData}
                readonly={readonly}
              />
            )}
          </div>
        )}
        {showAnswerRight && (
          <div className='answer-right'>
            <div className='title'>
              <CheckCircleOutlined style={{ marginRight: 8 }} />
              正确答案:
            </div>
            {isQuestionContent(question) && (
              <RightAnswerRender answerData={question.answer} />
            )}
          </div>
        )}
        {showScore && typeof score === 'number' && (
          <div
            style={{
              margin: '8px 0',
              textAlign: 'right'
            }}
          >
            <Button type='primary' danger>
              分数：{score}分
            </Button>
          </div>
        )}
      </div>
    )
  }
)
