import React, { FC, ReactNode, useEffect, useState } from 'react'
import { SummaryAlert } from 'components/ReportExamPaperTables/SummaryAlert'
import { TableToolbar } from 'components'
import { Button, Radio, Spin } from 'antd'
import {
  ExamPaperReportSummaryProvider,
  useExamPaperReportSummaryContent
} from 'hooks/useExamPaperReportSummary'
import { Report } from 'typing'
import { ExcelExporter } from 'utils/excel'
import { ColumnsType } from 'antd/es/table/interface'
import constate from 'constate'
import { useCurrentExamContent } from 'hooks/useExamData'
import { Subject } from 'config'
import moment from 'moment'
import _ from 'lodash'

interface TableTabsWrapperProps {
  scopeParams: Report.BasicScopeParams
  children: (
    tabIndex: number,
    summary: Report.ExamPaperSummaryData
  ) => ReactNode | null
  defaultTableIndex?: number
}

// ExportProvider
const [ExportProvider, useExportContent] = constate(() => {
  const [stateParams, setStateParams] = useState<Report.BasicStateParams>()
  const [columns, setColumns] = useState<ColumnsType>()
  const [items, setItems] = useState<any[]>()
  const [loading, setLoading] = useState(true)

  function setData(columns: ColumnsType<any>, items) {
    setColumns(columns)
    setItems(items)
  }
  return {
    stateParams,
    setStateParams,
    loading,
    setLoading,
    setData,
    columns,
    items
  }
})

ExportProvider.displayName = 'ExportProvider'
export { useExportContent }

export const TableTabsWrapperContent: FC<TableTabsWrapperProps> = ({
  scopeParams,
  children,
  defaultTableIndex
}) => {
  // useExamPaperReportSummaryContent
  const { initSummary, summary } = useExamPaperReportSummaryContent()
  useEffect(() => {
    initSummary(
      scopeParams.reportEntryType,
      scopeParams.reportEntryId,
      scopeParams.schoolId,
      scopeParams.clazzId
    )
  }, [])

  // UI
  const [tabIndex, setTableIndex] = useState<number>(defaultTableIndex || 0)

  // useExportContent
  const { loading, stateParams, items, columns } = useExportContent()
  const { currentExam, currentPaper } = useCurrentExamContent()
  function fileExport() {
    if (currentExam && columns && items) {
      const tabName = ['总分统计', '分数段统计', '试题统计'][tabIndex]
      const date = moment().format('YYYY_MMDD_HHmm')

      let fileName = currentExam.name
      if (currentPaper) {
        fileName += `-${Subject[currentPaper.subject]}`
      }
      if (stateParams) {
        fileName += `-${
          _.get(stateParams.params, 'org_name') ||
          _.get(stateParams.params, 'class_name')
        }`
      }
      fileName += `-${tabName}`
      fileName += ` ${date}`

      const exp = new ExcelExporter(fileName, tabName, columns, items)
      exp.exportFile()
    }
  }

  if (!summary) {
    return <Spin />
  }
  return (
    <div>
      {scopeParams.reportEntryType === 'paper' && (
        <SummaryAlert scopeParams={scopeParams} />
      )}

      <TableToolbar
        rightContent={() =>
          !loading ? (
            <Button type={'primary'} onClick={e => fileExport()}>
              结果导出
            </Button>
          ) : null
        }
      >
        <Radio.Group
          value={tabIndex}
          onChange={e => setTableIndex(e.target.value)}
          buttonStyle={'solid'}
        >
          {scopeParams.reportEntryType === 'exam' ? (
            <>
              {scopeParams.scope !== 'student' && (
                <>
                  <Radio.Button value={0}>总分统计</Radio.Button>
                  <Radio.Button value={1}>分数段统计</Radio.Button>
                </>
              )}
            </>
          ) : (
            <>
              <Radio.Button value={0}>总分统计</Radio.Button>
              {scopeParams.scope !== 'student' && (
                <Radio.Button value={1}>分数段统计</Radio.Button>
              )}
              <Radio.Button value={2}>试题统计</Radio.Button>
            </>
          )}
        </Radio.Group>
      </TableToolbar>

      {children(tabIndex, summary)}
    </div>
  )
}

export const TableTabsWrapper: FC<TableTabsWrapperProps> = props => {
  return (
    <ExportProvider>
      <ExamPaperReportSummaryProvider>
        <TableTabsWrapperContent {...props} />
      </ExamPaperReportSummaryProvider>
    </ExportProvider>
  )
}
