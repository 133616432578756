import { Modal } from 'antd'
import { ModalSizeLarge } from 'config'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import React, { FC, useEffect, useState } from 'react'

export const DrawerViewAllModal: FC<{
  questionId: number | string
  parentQuestionId?: number
  onClose: () => void
}> = props => {
  const { getCommentItemContent } = useQuestionAnswerDataContent()

  const [images, setImages] = useState<string>('')

  useEffect(() => {
    const content = getCommentItemContent(props.questionId)
    const $el = $(content)
    const $imgs = $el.find('img[data-marking-index]')

    if (!content || !$imgs.length) {
      return
    }

    $imgs.wrap('<p />')
    setImages($('<div />').append($imgs).html())
  }, [])

  return (
    <Modal
      title={'全部图片批注'}
      visible={true}
      footer={null}
      width={ModalSizeLarge}
      onCancel={props.onClose}
      className='content-images-bordered'
    >
      <div
        dangerouslySetInnerHTML={{
          __html: images || '暂无批注图片'
        }}
      />
    </Modal>
  )
}
