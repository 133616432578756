function prepareMarkingToolbar(html = '') {
  const $el = $(`<div>${html}</div>`)
  let $toolbar = $el.find('.answer-marking-toolbar')
  if ($toolbar.length) return html

  $toolbar = $(`<div class="answer-marking-toolbar"></div>`)
  $toolbar.appendTo($el)
  return $el.html()
}

export function appendCommentBtnToAnswer(html: string, isEdit: boolean) {
  const $el = $(`<div>${prepareMarkingToolbar(html)}</div>`)
  const $answerToolbar = $el.find('.answer-marking-toolbar')

  if (isEdit) {
    $(
      `<button class="ant-btn ant-btn-sm ant-btn-primary btn-comment">
      <span class="anticon">${commentIcon}</span><span>写批注</span>
     </button>`
    ).appendTo($answerToolbar)
  } else {
    $(
      `<button class="ant-btn ant-btn-sm ant-btn-primary btn-comment-view">
      <span class="anticon">${commentIcon}</span><span>查看批注</span>
     </button>`
    ).appendTo($answerToolbar)
  }
  return $el.html()
}

export type RichDrawerBtnAttrs = {
  // objectId
  id: string
  // answer image url
  src: string
  // image index
  index: number
  originId: string
}
export function appendDrawerBtnToAnswer(html: string, isEdit: boolean) {
  const $el = $(`<div>${prepareMarkingToolbar(html)}</div>`)
  const $imgList = $el.find('img').filter((i, img) => {
    // Filter out images in `attachNew`
    return !$(img).closest('.attachNew').length
  })
  const imageData: string[] = []

  if (isEdit) {
    $imgList.each((i, img) => {
      // 1. Wrap image
      const $img = $(img)
      const wrapper = $('<div class="image-wrapper"></div>')
      $img.wrap(wrapper)

      const src = $img.attr('src') || ''
      const id = getImageObjectId($img)
      imageData.push(id)

      // 2. Record image tracking id
      const trackId = $img.attr('originid')

      // 3. Append btns after image wrapper
      const editBtn = `<button class="ant-btn ant-btn-sm ant-btn-dangerous btn-image-drawer" data-id="${id}" data-origin-id="${
        trackId || ''
      }" data-src="${src}" data-index="${i}">
        <span class="anticon">${editIcon}</span><span>图片批注</span>
      </button>`
      const viewBtn = `<button class="ant-btn ant-btn-sm ant-btn-primary btn-image-drawer-view" data-id="${id}" data-src="${src}" data-index="${i}">
        <span class="anticon">${viewIcon}</span><span>图片批注</span>
      </button>`
      const btn = $(
        `<div style="margin-top: 8px; text-align:right;">
          ${isEdit ? editBtn : viewBtn}
        </div>`
      )
      btn.insertAfter($img)
    })
  }

  // 4. Append toolbar btns
  const isMarkingVisible = imageData.length

  if (isMarkingVisible) {
    const $answerToolbar = $el.find('.answer-marking-toolbar')

    if (isMarkingVisible) {
      const answerMarkingBtn = $(`
        <button class="ant-btn ant-btn-sm btn-image-drawer-view-all">
          <span class="anticon">${historyIcon}</span><span>查看图片批注</span>
        </button>
      `)
      answerMarkingBtn.appendTo($answerToolbar)
    }

    // $answerToolbar.appendTo($el)
  }

  return $el.html()
}

export function appendSimilarityBtnToAnswer(html = '') {
  const $el = $(`<div>${html}</div>`)
  const $answerToolbar = $el.find(`.answer-marking-toolbar`)
  $(
    `<button class="ant-btn ant-btn-sm ant-btn-default btn-similarity">
      <span class="anticon">${translationIcon}</span><span>相似度查询</span>
     </button>`
  ).appendTo($answerToolbar)
  return $el.html()
}

function getImageObjectId($img: JQuery): string {
  let id = $img.attr('data-object')
  if (id) {
    return id
  }

  const src = $img.attr('src') || ''
  id = (src.split('/').pop() || '').split('.').shift()
  if (!id) {
    console.error('Failed to get image object id.')
    return ''
  }
  return id
}

export function appendWordCountToAnswer(html = '') {
  const $el = $(`<div>${prepareMarkingToolbar(html)}</div>`)

  const workCount = answerWordCount(html)

  const $answerToolbar = $el.find(`.answer-marking-toolbar`)
  $(`<div class="text-word-count">字数统计：${workCount}</div>`).appendTo(
    $answerToolbar
  )

  return $el.html()
}

export function checkAnswerHasText(html = '') {
  return answerWordCount(html) > 0
}

// Remove link,button,medias from $el
function answerWordCount(html = '') {
  const $el = $(`<div>${html}</html>`)
  $el.find(['.answer-marking-toolbar', 'a'].join(',')).remove()
  // trim and remove empty lines
  return $el.text().trim().replace(/\s+/g, '').length
}

export type AnswerImage = {
  url: string
  objectId: string
  trackId: string
  // 普通题目中的[小题]序号
  namedQuestionIndex: number
  // 图片在答案中的序号
  imageIndex: number
  //
  commentImageUrl?: string
}

export function pickImageFromAnswer(
  html = '',
  namedQuestionIndex = 0
): AnswerImage[] {
  const $el = $(`<div>${html}</div>`)
  const $imgList = $el.find('img').filter((i, img) => {
    // Filter out images in `attachNew`
    return !$(img).closest('.attachNew').length
  })
  const imageData: AnswerImage[] = []
  $imgList.each((i, img) => {
    const $img = $(img)
    const src = $img.attr('src') || ''
    const objectId = getImageObjectId($img)
    if (src) {
      imageData.push({
        url: src,
        objectId,
        trackId: $img.attr('originid') || '',
        imageIndex: i,
        namedQuestionIndex
      })
    }
  })
  return imageData
}

export function getIndexOfCommentImage(
  image: Pick<AnswerImage, 'namedQuestionIndex' | 'imageIndex'>
) {
  return image.namedQuestionIndex * 1000 + image.imageIndex
}

const editIcon =
  '<svg viewBox="64 64 896 896" focusable="false" class="" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg>'
const viewIcon =
  '<svg viewBox="64 64 896 896" focusable="false" class="" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg>'
const historyIcon =
  '<svg viewBox="64 64 896 896" focusable="false" class="" data-icon="clock-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path></svg>'
const commentIcon =
  '<svg viewBox="64 64 896 896" focusable="false" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1096 0 48 48 0 10-96 0zm200 0a48 48 0 1096 0 48 48 0 10-96 0zm-400 0a48 48 0 1096 0 48 48 0 10-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 00-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 00-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 00112 714v152a46 46 0 0046 46h152.1A449.4 449.4 0 00510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 00142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg>'
const translationIcon =
  '<svg viewBox="64 64 896 896" focusable="false" data-icon="security-scan" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5 214 654.3V226.7l298-101.6 298 101.6v427.6zM402.9 528.8l-77.5 77.5a8.03 8.03 0 000 11.3l34 34c3.1 3.1 8.2 3.1 11.3 0l77.5-77.5c55.7 35.1 130.1 28.4 178.6-20.1 56.3-56.3 56.3-147.5 0-203.8-56.3-56.3-147.5-56.3-203.8 0-48.5 48.5-55.2 123-20.1 178.6zm65.4-133.3c31.3-31.3 82-31.3 113.2 0 31.3 31.3 31.3 82 0 113.2-31.3 31.3-82 31.3-113.2 0s-31.3-81.9 0-113.2z"></path></svg>'
