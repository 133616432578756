import { Modal } from 'antd'
import { ImageDrawerResult } from 'components/ImageDrawer/ImageDrawer'
import {
  APP_SCOPE,
  MarkingMode,
  browserHistory,
  isRuntimeCXStudy,
  redirectToFanYa
} from 'config'
import constate from 'constate'
import { AJAXError, GET, defaultErrorHandler } from 'core/request'
import _, { some } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Marking, Paper, Teacher } from 'typing'
import {
  MarkingAuditRecordVO,
  MarkingAuditStatus,
  MarkingRecords
} from 'typing.marking'
import { QuestionIdentity, SteppedScoreSetting } from 'typing.paper'
import { AnswerImage, getIndexOfCommentImage } from 'utils'
import { fetchStudentAnswer } from 'utils/answer'
import {
  createPaperQuestion,
  createPaperQuestionForMarking,
  isNestingQuestion,
  uniformQuestionCompare
} from 'utils/question'
import { CXStudyCloseWindow } from '../utils/runtime-study'

export type User = {
  display_name: string
  uid: number
}

const debug = require('debug')('useQuestionAnswerData:')
const error = require('debug')('useQuestionAnswerData:error:')

const hook = () => {
  function errorHandler() {
    Modal.destroyAll()
    Modal.error({
      title: '无法获取试卷数据',
      content: '请点击[重试]，尝试重新加载',
      okText: '重试',
      onOk: () => location.reload()
    })
  }

  const [isInitialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)

  const [markingPaperId, setMarkingPaperId] = useState<number>()
  const [markingPaper, setMarkingPaper] = useState<Marking.MarkingPaperDetail>()

  const isGroupedTeacherMode = useMemo(
    () =>
      markingPaper &&
      [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(
        markingPaper.review_mode
      ),
    [markingPaper]
  )
  const isAuditMode = useMemo(
    () => markingPaper?.review_mode === MarkingMode.AUDIT,
    [markingPaper]
  )

  const isImageMarkingMode = useMemo(() => {
    if (APP_SCOPE.isMobile) return false

    // if (markingPaper?.question_type2_group_marking) return false

    return markingPaper?.inline_drawing_marking_mode === 1
  }, [markingPaper])

  //#region user
  const [currentUser, setCurrentUser] = useState<User>()

  const isCurrentUserHasAuditRole = useMemo(() => {
    if (!currentUser || !markingPaper) return false

    if (isAuditMode) {
      if (markingPaper.judge?.uid === currentUser.uid) {
        return true
      }

      if (
        some(
          markingPaper.judge_teacher_by_question,
          it => it.teacher === currentUser.uid
        )
      ) {
        return true
      }

      // if (
      //   [332049656, 332049658, 332049655, 332049657, 332106718].includes(
      //     currentUser?.uid
      //   )
      // )
      //   return true
      return false
    }
    return false
  }, [isAuditMode, markingPaper, currentUser])

  const isCurrentUserHasReviewRole = useMemo(() => {
    return (
      markingPaper?.review_mode === MarkingMode.GROUP_FLOW &&
      !!markingPaper.inspector?.find(i => i.uid === currentUser?.uid)
    )
  }, [currentUser, markingPaper])

  async function fetchCurrentUser(exam_paper_id: number) {
    const { data } = await GET('fanya/exam/paper/teacher/info', {
      data: { exam_paper_id }
    })
    setCurrentUser(data)
  }

  async function fetchMarkingPaper(): Promise<Marking.MarkingPaperDetail | null> {
    try {
      const { data: _data } = await GET(
        `/${
          APP_SCOPE.isInFanYa ? 'fanya' : 'review'
        }/paper/${markingPaperId}/info`,
        {}
      )
      const data = _data as Marking.MarkingPaperDetail

      const reviewEndTime = data.review_end_time
      if (reviewEndTime && moment(reviewEndTime).isBefore(moment())) {
        Modal.destroyAll()
        Modal.error({
          title: '批阅时间已结束',
          onOk: () => {
            if (APP_SCOPE.isInFanYa) {
              redirectToFanYa()
            } else {
              browserHistory.goBack()
            }
          }
        })
        return null
      }

      if (typeof data.step_score_setting === 'string') {
        try {
          data.step_score_setting = JSON.parse(data.step_score_setting)
        } catch (e) {
          console.error('step_score_setting parse error', e)
          data.step_score_setting = undefined
        }
      }

      setMarkingPaper(data)

      return data
    } catch (e) {
      if ((e as AJAXError).handled) {
        return null
      }
      if (isRuntimeCXStudy) {
        CXStudyCloseWindow()
      } else {
        Modal.error({
          title: (e as Error).message,
          onOk: () => {
            if (APP_SCOPE.isInFanYa) {
              redirectToFanYa()
            } else {
              browserHistory.goBack()
            }
          }
        })
      }
    }
    return null
  }

  //#region 考题
  const [paperQuestions, setPaperQuestions] = useState<Paper.QuestionGroup[]>(
    []
  )
  const [markingQuestions, setMarkingQuestions] = useState<Paper.Question[]>([])

  async function fetchQuestion(id) {
    try {
      const { data } = await GET(`review/paper/${id}`, {})
      const po = _.get(data, 'data[0]') as Paper.PaperOriginal
      // Keep all questions
      const box = createPaperQuestion(po, true, false)
      setPaperQuestions(box.group)
      debug('paperQuestions', box.group)
    } catch (e) {
      console.error(e)
      errorHandler()
    }
  }

  function updateMarkingQuestions(options?: { questions: QuestionIdentity[] }) {
    if (markingPaper && paperQuestions.length) {
      debug(
        'updateMarkingQuestions params',
        paperQuestions,
        options?.questions ?? markingPaper.quests
      )
      const data = createPaperQuestionForMarking(
        paperQuestions,
        options?.questions ?? markingPaper.quests
      )
      setMarkingQuestions(data)
      debug('updateMarkingQuestions', data)
    }
  }

  useEffect(() => {
    updateMarkingQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markingPaper, paperQuestions])

  function findQuestion(questionId: QuestionIdentity): Paper.Question | null {
    if (!markingPaper) return null

    const questions = _.flatten(_.map(paperQuestions, q => q.children))
    const q = _.find(questions, q => q.id === questionId)

    if (!q) {
      error('findQuestion not found', questionId)
      return null
    }
    return q
  }
  //#endregion

  //#region 答案
  const [currentStudentId, setCurrentStudentId] = useState<number>()
  const [currentStudentAnswerOrigin, setCurrentStudentAnswerOrigin] = useState<
    Marking.StudentAnswerOrigin
  >()
  const [currentStudentAnswer, setCurrentStudentAnswer] = useState<
    Marking.StudentAnswer[]
  >([])

  const [currentStudentAnswerError, setCurrentStudentAnswerError] = useState<
    AJAXError
  >()

  async function fetchCurrentStudentAnswer() {
    if (!currentStudentId) {
      throw new Error('currentStudentId undefined')
    }

    try {
      setLoading(true)

      const [studentAnswerOrigin, studentAnswer] = await fetchStudentAnswer(
        currentStudentId
      )

      setCurrentStudentAnswerOrigin(studentAnswerOrigin)
      setCurrentStudentAnswer(studentAnswer)
    } catch (e) {
      console.error(e)
      setCurrentStudentAnswerOrigin(undefined)
      setCurrentStudentAnswer([])
      if (!(e as AJAXError).handled) {
        setCurrentStudentAnswerError(e as AJAXError)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentStudentId) {
      fetchCurrentStudentAnswer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudentId])

  function getAnswerByQuestion(questionId: QuestionIdentity) {
    if (!currentStudentAnswer.length) {
      return
    }
    // TODO: remove
    // if (_.isArray(questionId)) {
    //   const answer = _.find(currentStudentAnswer, { questionId: questionId[0] })
    //   if (!answer) {
    //     error('getAnswerByQuestion: answer no found by', questionId)
    //     return
    //   }
    //   const answers = _.find(answer.answer as Marking.AnswerNesting[], a => {
    //     return a.questionId === questionId[1]
    //   })
    //   if (!answers) {
    //     error('getAnswerByQuestion: answers no found by', questionId)
    //   }
    //   // fix: nesting answer
    //   // data: answer-20200118.json
    //   return (answers as any)?.answer || answers
    // }
    const answer = _.find(
      currentStudentAnswer,
      a => a.questionId === questionId
    )
    if (!answer) {
      error('getAnswerByQuestion: answer no found by', questionId)
      return
    }
    return answer.answer as Marking.AnswerNormal[]
  }

  // TODO remove duplicate code that same with getAnswerByQuestion
  function getAnswerScoreByQuestion(questionId: QuestionIdentity) {
    if (!currentStudentAnswer.length) {
      return
    }
    const answer = _.find(
      currentStudentAnswer,
      a => a.questionId === questionId
    )
    if (!answer) {
      error('getAnswerByQuestion: answer no found by', questionId)
      return
    }
    return answer.score
  }

  //#endregion

  //#region 批注
  const [commentForm, setCommentForm] = useState<Marking.CommentFormDataItem[]>(
    []
  )

  function findCommentItem(
    questionId: QuestionIdentity
  ): Marking.CommentFormDataItem | Marking.CommentFormDataItemNesting | null {
    let res = _.find(commentForm, c => c.questionId === questionId)
    if (!res) {
      console.warn(`CommentData no found: ${questionId}`)
    }
    return res ?? null
  }

  /**
   * Get teacher comment from commentFormData
   */
  function getCommentItemContent(questionId: QuestionIdentity): string {
    return findCommentItem(questionId)?.comment ?? ''
  }

  /**
   * Get comment image from commentFormData by index
   */
  function getCommentImage(
    questionId: QuestionIdentity,
    image: Pick<AnswerImage, 'namedQuestionIndex' | 'imageIndex'>
  ): string | null {
    const commentItem = findCommentItem(questionId)
    if (!commentItem) return null

    const comment = commentItem.comment
    const $el = $(`<div>${comment}</div>`)

    const index = getIndexOfCommentImage(image)
    let selector = `img[data-marking-index=${index}]`

    // Find img by uid for audit mode ONLY
    if (currentUser && isGroupedTeacherMode) {
      selector += `[data-marking-uid=${currentUser.uid}]`
    }

    debug('getCommentImage', 'question', questionId, 'selector', selector)

    const img = $el.find(selector)
    return img.attr('src') ?? null
  }

  function getCommentImageListForAudit(questionId: QuestionIdentity) {
    const commentItem = findCommentItem(questionId)
    if (!commentItem) return []

    const comment = commentItem.comment
    const $el = $(comment)

    const imgList = $el.find('img[data-marking-uid]')
    const teacherList: Partial<Teacher & { image: string }>[] = _.map(
      imgList,
      el => {
        const uid = $(el).attr('data-marking-uid')
        const uname = $(el).attr('data-marking-uname')
        return {
          uid: parseInt(uid!) || 0,
          display_name: uname,
          image: $(el).attr('src')
        }
      }
    )
    if (!currentUser) return teacherList
    return _.filter(teacherList, t => t.uid !== currentUser.uid)
  }

  function setCommentImage(data: {
    questionId: QuestionIdentity
    image: AnswerImage
    drawerResult: ImageDrawerResult
  }) {
    const {
      questionId,
      image,
      drawerResult: { file, canvasData }
    } = data

    const item = findCommentItem(questionId)
    if (!item) return

    debug('setCommentImage', data)

    const $el = $(`<div>${item.comment}</div>`)

    // Calc image index
    const index = getIndexOfCommentImage(image)

    let selector = `img[data-marking-index=${index}]`
    if (currentUser && isAuditMode) {
      // Find img by uid for audit mode ONLY
      selector += `[data-marking-uid=${currentUser.uid}]`
    }

    let img = $el.find(selector)

    if (img.length) {
      // Update image
      img.attr('src', file)
    } else {
      // Create image
      const uid = currentUser?.uid || ''
      const uname = currentUser?.display_name || ''
      const roleClass = isCurrentUserHasAuditRole
        ? 'data-marking-role="judge"'
        : ''
      $el.append(
        `<p><img originid="${
          image.trackId || ''
        }" src="${file}" data-marking-index="${index}" data-marking-uid="${uid}" data-marking-uname="${uname}" ${roleClass} /></p>`
      )
    }

    item.comment = $el.html()
    setCommentForm(commentForm.slice())
  }

  function removeCommentImage(data: {
    questionId: QuestionIdentity
    image: AnswerImage
  }) {
    const { questionId } = data

    const item = findCommentItem(questionId)
    if (!item) return

    const $el = $(`<div>${item.comment}</div>`)
    const index = getIndexOfCommentImage(data.image)
    let selector = `img[data-marking-index=${index}]`
    if (currentUser && isAuditMode) {
      selector += `[data-marking-uid=${currentUser.uid}]`
    }
    const img = $el.find(selector)
    img.remove()
    item.comment = $el.html()
    setCommentForm(commentForm.slice())
  }

  function setCommentContent(questionId: QuestionIdentity, content: string) {
    const item = findCommentItem(questionId)
    if (!item) return

    item.comment = content
    setCommentForm(commentForm.slice())
  }

  function initCommentForm() {
    const data: Marking.CommentFormDataItem[] = _.map(
      currentStudentAnswer,
      answer => {
        const res: Marking.CommentFormDataItem = {
          answerId: answer.answerId,
          questionId: answer.questionId,
          comment: answer.teacherComment || ''
        }
        // TODO: remove
        if (isNestingQuestion(answer.type)) {
          res.children = []
          try {
            const commentMap: Record<string, string> = (() => {
              try {
                if (_.isString(answer.teacherComment)) {
                  return JSON.parse(answer.teacherComment || '{}')
                }
                return answer.teacherComment ?? {}
              } catch (e) {
                console.error('initCommentForm', e, answer)
              }
            })()
            res.children = _.map(commentMap, (comment, questionId) => ({
              questionId: questionId,
              comment: comment || ''
            }))
          } catch (e) {
            console.error('initCommentForm', e, answer)
          }
        }
        return res
      }
    )
    debug('initCommentForm', data)
    setCommentForm(data)
  }

  useEffect(() => {
    initCommentForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudentAnswer])
  //#enderegion

  //#region judge
  const [markingDataForAudit, setMarkingDataForAudit] = useState<
    MarkingRecords
  >({})

  async function initMarkingDataForAudit() {
    try {
      const { data } = await GET(`fanya/exam/paper/${currentStudentId}/judge`, {
        data: {
          exam_paper_id: markingPaper!.exam_paper_id
        }
      })
      _.forEach(_.keys(data), k => {
        const v = data[k]
        data[k] = {
          ...v,
          judge_status: {
            0: MarkingAuditStatus.Judged,
            1: MarkingAuditStatus.Judged,
            2: MarkingAuditStatus.Unjudged,
            3: MarkingAuditStatus.Judged
          }[v.judge_status]
        }
      })
      console.log('initMarkingDataForAudit', data)
      setMarkingDataForAudit(data)
    } catch (e) {
      defaultErrorHandler(e as any)
    }
  }

  function findMarkingDataForAudit(
    questionId: QuestionIdentity
  ): MarkingAuditRecordVO[] {
    // TODO: remove
    if (_.isArray(questionId)) {
      const parent = markingDataForAudit?.[questionId[0]]
      if (!parent) {
        error('findMarkingDataForAudit: parent no found by', questionId)
        return []
      }
      const data = _.map(parent.scores, p => {
        const sub = _.find(
          p.sub_question,
          s => s.sub_question_id === questionId[1]
        )
        if (!sub) {
          error('findMarkingDataForAudit: sub no found by', questionId)
          return null
        }
        const vo: MarkingAuditRecordVO = {
          parentQuestionId: questionId[0],
          questionId: questionId[1],
          uid: p.teacher_uid,
          display_name: p.display_name,
          score: sub?.score ?? 0,
          judge_status: parent.judge_status
        }
        return vo
      })
      return data.filter(d => !!d) as MarkingAuditRecordVO[]
    }

    const data = markingDataForAudit?.[questionId]
    if (!data) {
      error('findMarkingDataForAudit: data no found by', questionId)
      return []
    }
    return _.map(data.scores, d => {
      return {
        questionId,
        uid: d.teacher_uid,
        display_name: d.display_name,
        score: d.score,
        judge_status: data.judge_status
      }
    })
  }

  function checkQuestionAuditStatus(
    questionId: QuestionIdentity
  ): MarkingAuditStatus | undefined {
    if (!isAuditMode) return

    // TODO: remove
    if (_.isArray(questionId)) {
      const parent = markingDataForAudit?.[questionId[0]]
      if (!parent) {
        error('checkQuestionAuditStatus: parent no found by', questionId)
        return
      }
      // Note: No nesting question status yet
      return parent.judge_status
    } else {
      const data = markingDataForAudit?.[questionId]
      if (!data) {
        error('checkQuestionAuditStatus: data no found by', questionId)
        return
      }
      return data.judge_status
    }
  }

  const findCurrentAuditedQuestionIds = useCallback(() => {
    return _.reduce(
      markingDataForAudit,
      (res, d, qid) => {
        if (d.judge_status === MarkingAuditStatus.Judged) {
          const isNumString = _.isString(qid) && /^\d+$/.test(qid)
          res.push(isNumString ? parseInt(qid) : qid)
        }
        return res
      },
      [] as QuestionIdentity[]
    )
  }, [markingDataForAudit])

  useEffect(() => {
    if (!currentStudentId) return

    if (isAuditMode) {
      initMarkingDataForAudit()
    } else if (isCurrentUserHasReviewRole) {
      initMarkingDataForAudit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markingPaper, currentStudentId, isAuditMode, isCurrentUserHasReviewRole])
  //#endregion

  //#region step score
  function findQuestionStepSetting(
    id: QuestionIdentity
  ): SteppedScoreSetting['items'][number]['steps'] | null {
    if (!markingPaper) return null

    const step_score_setting = markingPaper.step_score_setting as SteppedScoreSetting
    if (!step_score_setting) return null
    if (!step_score_setting.enable) return null

    const { items } = step_score_setting
    const item = _.find(items, it => {
      return uniformQuestionCompare(it.question_id, id)
    })
    return item?.steps ?? null
  }

  //#endregion

  // Init
  useEffect(() => {
    async function init() {
      if (_.isNumber(markingPaperId)) {
        const markingData = await fetchMarkingPaper()
        if (markingData) {
          await fetchQuestion(markingData.exam_paper_id)
          await fetchCurrentUser(markingData.exam_paper_id)
          setInitialized(true)
          debug('init by', markingPaperId)
        }
      }
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markingPaperId])

  return {
    isInitialized,
    loading,
    markingPaperId,
    setMarkingPaperId,
    markingPaper,
    isGroupedTeacherMode,
    isAuditMode,
    isImageMarkingMode,

    currentUser,
    isCurrentUserHasAuditRole,
    isCurrentUserHasReviewRole,

    paperQuestions,
    markingQuestions,
    updateMarkingQuestions,
    findQuestion,

    currentStudentId,
    setCurrentStudentId,
    currentStudentAnswerOrigin,
    currentStudentAnswerError,
    currentStudentAnswer,
    setCurrentStudentAnswer,
    getAnswerByQuestion,
    getAnswerScoreByQuestion,

    commentForm,
    setCommentForm,
    getCommentItemContent,
    setCommentContent,
    getCommentImage,
    setCommentImage,
    removeCommentImage,

    markingDataForAudit,
    setMarkingDataForAudit,
    findMarkingDataForAudit,
    getCommentImageListForAudit,
    checkQuestionAuditStatus,
    findCurrentAuditedQuestionIds,

    findQuestionStepSetting
  }
}

const [QuestionAnswerDataProvider, useQuestionAnswerDataContent] = constate(
  hook
)

QuestionAnswerDataProvider.displayName = 'QuestionAnswerDataProvider'

export { QuestionAnswerDataProvider, useQuestionAnswerDataContent }
