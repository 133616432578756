import { Form, Modal, Spin, message } from 'antd'
import { FormProps } from 'antd/es/form'
import { useForm } from 'antd/es/form/Form'
import { FormInstance } from 'antd/es/form/util'
import { ModalProps } from 'antd/es/modal'
import { FormLayoutPros } from 'components/EditModel/utils'
import { ModalSizeNormal } from 'config'
import { EditContext } from 'core/service/useEdit'
import React, { FC, useEffect } from 'react'

export const DefaultLabelCol = 4
export const DefaultWrapperCol = 24 - DefaultLabelCol - 2

export type EditProps = {
  title?: string
  edit: EditContext<any, any>
  form?: FormInstance
  modalProps?: ModalProps
  formProps?: FormProps
  validate?: (value) => boolean
  onSubmit?: (value) => void
}

const EditModel: FC<EditProps> = props => {
  const { edit, modalProps } = props
  const [form] = useForm(props.form)

  async function onModalOk() {
    try {
      const value = await form.validateFields()
      if (props.validate && !props.validate(value)) {
        return
      }
      props.onSubmit ? props.onSubmit(value) : edit.onSubmit(value)
    } catch (e) {
      message.warn('请检查表单是否未填写完整')
    }
  }

  return (
    <Modal
      title={props.title || (edit.isEdit ? '编辑' : '添加')}
      visible={edit.visible}
      maskClosable={false}
      width={ModalSizeNormal}
      okButtonProps={{ disabled: edit.loading, loading: edit.saving }}
      onOk={onModalOk}
      onCancel={edit.onCancel}
      {...modalProps}
    >
      <Spin spinning={edit.loading} delay={200}>
        <EditForm form={form} {...props} />
      </Spin>
    </Modal>
  )
}

export const EditForm: FC<EditProps & { form: FormInstance }> = props => {
  const formLayout: FormLayoutPros = {
    labelCol: { span: DefaultLabelCol },
    wrapperCol: { span: DefaultWrapperCol }
  }

  useEffect(() => {
    if (props.form && props.edit.visible) {
      props.form.resetFields()
      props.form.setFieldsValue(props.edit.data)
      console.log('setFieldsValue', props.edit.data)
    }
  }, [props.edit.data, props.edit.visible])

  return (
    <Form
      {...formLayout}
      form={props.form}
      className={'edit-form'}
      hideRequiredMark
      {...props.formProps}
    >
      {props.children}
    </Form>
  )
}

export { EditModel }
