import { defaultErrorHandler, GET } from 'core/request'
import constate from 'constate'
import { useState } from 'react'
import { Marking } from 'typing'
import { APP_SCOPE } from 'config'

const [CurrentMarkingPaperProvider, useCurrentMarkingPaperContent] = constate(
  () => {
    const [markingPaper, setMarkingPaper] = useState<
      Marking.MarkingPaperDetail
    >()

    async function initCurrentMarkingPaper(id) {
      try {
        const { data } = await GET(
          `/${APP_SCOPE.isInFanYa ? 'fanya' : 'review'}/paper/${id}/info`,
          {}
        )
        setMarkingPaper(data)
      } catch (e) {
        defaultErrorHandler(e)
      }
    }

    return {
      currentMarkingPaper: markingPaper,
      initCurrentMarkingPaper
    }
  }
)

CurrentMarkingPaperProvider.displayName = 'CurrentMarkingPaper'

export { CurrentMarkingPaperProvider, useCurrentMarkingPaperContent }
