import constate from 'constate'
import { GET, defaultErrorHandler } from 'core/request'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuestionAnswerDataContent } from './useQuestionAnswerData'
import { QuestionIdentity } from 'typing.paper'

export type MarkingTag = {
  id: number
  name: string
}

type AnswerTags = {
  // 当前
  recordLabel: MarkingTag[]
  // 可用
  courseLabel: MarkingTag[]
}

type AnswerId = string

const [MarkingTagsProvider, useMarkingTagsContent] = constate(() => {
  const {
    currentStudentId,
    currentStudentAnswer
  } = useQuestionAnswerDataContent()

  const answerIds = useMemo(() => {
    return currentStudentAnswer.map(item => item.answerId)
  }, [currentStudentAnswer])

  const [data, setData] = useState<Record<AnswerId, AnswerTags>>()

  const fetch = useCallback(
    async function () {
      if (!currentStudentId || !answerIds.length) return

      const { data } = await GET(`fanya/tag/list/${currentStudentId}`, {
        data: {
          answer_ids: answerIds.join(',')
        }
      })
      setData(data)
    },
    [currentStudentId, answerIds]
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  const findTagList = useCallback(
    (answerId: number) => {
      return data?.[answerId]?.courseLabel ?? []
    },
    [data]
  )

  const findCurrentTags = useCallback(
    (answerId: number) => {
      return data?.[answerId]?.recordLabel ?? []
    },
    [data]
  )

  const findAnswer = useCallback(
    (questionId: QuestionIdentity) => {
      return currentStudentAnswer.find(it => it.questionId === questionId)
    },
    [currentStudentAnswer]
  )

  const findTagListByQuestion = useCallback(
    (questionId: QuestionIdentity) => {
      const answer = findAnswer(questionId)
      if (!answer) return []
      return findTagList(answer.answerId)
    },
    [findTagList, findAnswer]
  )

  const findCurrentTagsByQuestion = useCallback(
    (questionId: QuestionIdentity) => {
      const answer = findAnswer(questionId)
      if (!answer) return []
      return findCurrentTags(answer.answerId)
    },
    [findCurrentTags, findAnswer]
  )

  const getAllTags = useCallback(() => {
    return _.chain(data)
      .values()
      .map(it => it.courseLabel)
      .flatten()
      .uniqBy(it => it.name)
      .value()
  }, [data])

  const updateQuestionTags = useCallback(
    async (questionId: QuestionIdentity, tags: MarkingTag[]) => {
      try {
        await GET(`fanya/tag/add/${currentStudentId}`, {
          data: {
            answer_id: findAnswer(questionId)?.answerId,
            label_ids: tags.map(it => it.id).join(','),
            labels: tags.map(it => it.name).join(',')
          }
        })
      } catch (error) {
        defaultErrorHandler(error as any)
        throw error
      }
    },
    [currentStudentId, findAnswer]
  )

  const newTag = useCallback(
    async (questionId: QuestionIdentity, tag: string) => {
      const answerId = findAnswer(questionId)?.answerId!
      try {
        await GET(`fanya/tag/add_new/${currentStudentId}`, {
          data: {
            answer_id: answerId,
            content: tag
          }
        })
        await fetch()
      } catch (error) {
        defaultErrorHandler(error as any)
        throw error
      }
    },
    [currentStudentId, fetch, findAnswer]
  )
  const editTag = useCallback(
    async (questionId: QuestionIdentity, tagId: number, tag: string) => {
      const answerId = findAnswer(questionId)?.answerId!
      try {
        await GET(`fanya/tag/update_new/${currentStudentId}`, {
          data: {
            answer_id: answerId,
            tag_id: tagId,
            content: tag
          }
        })
        fetch()
      } catch (error) {
        defaultErrorHandler(error as any)
        throw error
      }
    },
    [currentStudentId, fetch, findAnswer]
  )
  const removeTag = useCallback(
    async (questionId: QuestionIdentity, tagId: number) => {
      const answerId = findAnswer(questionId)?.answerId!
      try {
        await GET(`fanya/tag/delete/${currentStudentId}`, {
          data: {
            answer_id: answerId,
            tag_id: tagId
          }
        })
        fetch()
      } catch (error) {
        defaultErrorHandler(error as any)
        throw error
      }
    },
    [currentStudentId, fetch, findAnswer]
  )

  return {
    answerIds,
    data,

    fetch,
    findTagListByQuestion,
    findCurrentTagsByQuestion,
    updateQuestionTags,
    getAllTags,
    newTag,
    editTag,
    removeTag
  }
})

MarkingTagsProvider.displayName = 'MarkingTagsProvider'

export { MarkingTagsProvider, useMarkingTagsContent }
