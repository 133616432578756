import constate from 'constate'
import { useState } from 'react'
import { QuestionBindGroup } from 'typing'

const [RedistributeProvide, useRedistributeContext] = constate(() => {
  const [visible, setVisible] = useState(false)

  const [bindingGroup, setBindingGroup] = useState<QuestionBindGroup>()

  const [teacherGroupIndex, setTeacherGroupIndex] = useState<number>()

  function open(group: QuestionBindGroup, teacherGroupIndex?: number) {
    setVisible(true)
    setBindingGroup(group)
    setTeacherGroupIndex(teacherGroupIndex)
  }

  return {
    visible,
    setVisible,

    bindingGroup,
    teacherGroupIndex,

    open
  }
})

RedistributeProvide.displayName = 'RedistributeProvide'

export { RedistributeProvide, useRedistributeContext }
