import { createBrowserHistory } from 'history'
import _ from 'lodash'

export const browserHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL
})

export const IS_DEV = process.env.NODE_ENV === 'development'
export const IS_IN_FRAME = window.top !== window.self
export const IS_IN_FANYA = 1
export const isDevice_iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
export const isRuntimeCXStudy = /chaoxing/i.test(window.navigator.userAgent)

export const APP_NAME = '统考微应用'

export const ModalSizeNormal = 680
export const ModalSizeLarge = 800
export const ModalSizeXLarge = 1000

export const APP_SCOPE = {
  isMobile: false,
  isInFanYa: false,
  redirectUrl: ''
}

export function redirectToFanYa() {
  // if (APP_SCOPE.redirectUrl) {
  //   location.href = APP_SCOPE.redirectUrl
  //   return
  // }
  history.back()
}

export const Subject = {
  1: '语文',
  2: '数学',
  3: '英语',
  4: '物理',
  5: '化学',
  6: '历史',
  7: '地理',
  8: '生物',
  9: '政治',
  10: '思想品德',
  11: '文科综合',
  12: '理科综合',
  13: '社会实践',
  14: '体育',
  15: '音乐',
  16: '美术'
}
export const SubjectMap = _.map(Subject, (label, value) => ({
  label,
  value: +value
}))
export type SubjectKey = keyof typeof Subject

export const Grade = {
  1: '小学一年级',
  2: '小学二年级',
  3: '小学三年级',
  4: '小学四年级',
  5: '小学五年级',
  6: '小学六年级',
  7: '初一',
  8: '初二',
  9: '初三',
  10: '高一',
  11: '高二',
  12: '高三',
  13: '大一',
  14: '大二',
  15: '大三',
  16: '大四',
  17: '大五',
  18: '研一',
  19: '研二',
  20: '研三',
  21: '其他'
}
export const GradeMap = _.map(Grade, (label, value) => ({
  label,
  value: +value
}))
export type GradeKey = keyof typeof Grade

export const QuestionType = {
  0: '单选题',
  1: '多选题',
  2: '填空题',
  3: '判断题',
  4: '简答题',
  5: '名词解释',
  6: '论述题',
  7: '计算题',
  9: '分录题',
  10: '资料题',
  11: '连线题',
  12: '投票题',
  13: '排序题',
  14: '完型填空',
  15: '阅读理解',
  16: '综合题',
  17: '程序题',
  18: '口语题',
  19: '听力题',
  20: '测评题',
  21: '公用选项题',
  24: '选词填空',
  25: '选做题',
  26: '英文写作题',
  8: '其它'
}
export type QuestionTypeKey = keyof typeof QuestionType

export enum ExamScoreLevelType {
  EXAM_CONFIG,
  PAPER_CONFIG
}

export enum StatisticState {
  UN_COMPUTED,
  FETCHING,
  FETCH_ERROR,
  FETCH_SUCCESS,
  COMPUTING,
  COMPUTED
}

//
export enum MarkingMode {
  NONE = 0,
  GROUP_FLOW = 1,
  ALL = 2,
  AVERAGE = 3,
  AUDIT = 4
}
export enum MarkingRange {
  NONE,
  ALL,
  SELF_SCHOOL,
  OTHER_SCHOOL
}

export const KAOSHI_HOST = 'https://mooc1.chaoxing.com'
