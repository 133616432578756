import { Alert, Form, Input, Modal, Select, message } from 'antd'
import { IS_DEV } from 'config'
import { GET } from 'core/request'
import _ from 'lodash'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Teacher } from 'typing.exam'
import { useMarkingSettingEditContext } from '../../store'
import { useMarkingSettingContent } from '../useMarkingSettingState'
import { useQuestionBindGroupContent } from '../useQuestionBindGroup'

export type TeacherImportDialogReturn = {
  name: string
  tid?: string
  groupNo: number
  index: number
  line: string
  teacher?: Teacher
}[]

export const TeacherImportDialog: FC<{
  visible: boolean
  onConfirm: (data: TeacherImportDialogReturn) => void
  onCancel: () => void
}> = props => {
  const edit = useMarkingSettingEditContext()
  const { questionBindGroup } = useQuestionBindGroupContent()

  // School
  const { schoolList } = useMarkingSettingContent()
  const [school, setSchool] = useState<number>()
  useEffect(() => {
    if (schoolList.length) {
      setSchool(schoolList[0].fid)
    }
  }, [schoolList])

  const [data, setData] = useState<string>(
    IS_DEV
      ? `刘晓玲 1 
吕文辉  999  2
夏郡廷 3
黄清桥 4
测试教师 202306201 1
测试教师 20230620   1`
      : ''
  )
  // fa
  //  dd
  //  fa a
  //   dd a

  const [loading, setLoading] = useState(false)

  const [info, setInfo] = useState<string[]>()
  const [infoType, setInfoType] = useState<
    'format' | 'duplication' | 'tid-duplication' | 'fetch' | 'fetch-duplication'
  >('format')

  const onSubmit = useCallback(async () => {
    if (!school) {
      message.warn('请选择学校')
      return
    }
    if (!data) {
      message.warn('请填写完整')
      return
    }

    setInfo([])

    const error: string[] = []

    // Parse lines
    const lines = data
      .split('\n')
      .map(line => line.trim())
      .filter(line => line)

    const importList: TeacherImportDialogReturn = lines.map((line, index) => {
      const [name, ...ids] = line.split(/\s+/)
      const id = ids.length === 2 ? ids[1] : ids[0]
      const tid = ids.length === 2 ? ids[0] : undefined
      const groupNo = parseInt(id)
      if (!name || isNaN(groupNo)) {
        error.push(`第${index + 1}行: \`${line}\``)
      }
      return { name, groupNo, index, line, tid }
    })

    if (error.length) {
      setInfoType('format')
      setInfo(error)
      return
    }

    // Check name duplication that without tid , get duplicated names
    const duplicatedNamesWithoutTid = _.chain(importList)
      .groupBy('name')
      /**
       * Map<name, Row[]>
       */
      .pickBy(it => it.length > 1)
      /**
       * Omit group that every items has tid
       */
      .omitBy(it => it?.every(r => !!r.tid))
      .keys()
      .value()

    if (duplicatedNamesWithoutTid.length) {
      setInfoType('duplication')
      setInfo(
        duplicatedNamesWithoutTid.map(name => `姓名 \`${name}\` 在其他行重复`)
      )
      return
    }

    const duplicatedTids = _.chain(importList)
      .filter(it => !!it?.tid)
      .groupBy('tid')
      .pickBy(it => it?.length > 1)
      .keys()
      .value()
    if (duplicatedTids.length) {
      setInfoType('tid-duplication')
      setInfo(duplicatedTids.map(tid => `工号 \`${tid}\` 在其他行重复`))
      return
    }

    // Fetch teacher info
    setLoading(true)
    const { data: fetchedTeachers } = await GET('fanya/teachers/multi', {
      data: {
        fid: school,
        exam_paper_id: edit.data.id,
        teacher_names: importList.map(r => r.name).join(','),
        teacher_tids: importList.map(r => r.tid || '').join(','),
        indexs: ''
      }
    })
    setLoading(false)

    importList.forEach(importItem => {
      const matched = _.filter<Teacher>(fetchedTeachers, t =>
        importItem.tid
          ? t.name === importItem.tid
          : t.display_name === importItem.name
      )
      if (matched.length > 1) {
        error.push(`姓名 \`${importItem.name}\``)
      } else if (matched.length === 1) {
        importItem.teacher = matched[0]
        if (importItem.teacher) {
          importItem.teacher.fid = parseInt(importItem.teacher?.fid as any, 10)
          importItem.teacher.uid = parseInt(importItem.teacher?.uid as any, 10)
        }
      }
    })
    if (error.length) {
      setInfoType('fetch-duplication')
      setInfo(error)
      return
    }

    const unFetchedTeachers = importList.filter(r => !r.teacher)
    if (unFetchedTeachers.length) {
      setInfoType('fetch')
      setInfo(unFetchedTeachers.map(r => `第${r.index + 1}行：${r.name}`))
      return
    }

    // Check teachers exists in questionBindGroup by diff groupNo
    _.forEach(importList, it => {
      _.forEach(questionBindGroup, (bg, bgIndex) => {
        if (bgIndex === it.groupNo - 1) {
          return
        }
        const foundInGroup = _.find(bg.teachers, t => t.uid === it.teacher?.uid)
        if (foundInGroup) {
          error.push(
            `第${it.index + 1}行：${it.name} 已分配在第${bgIndex + 1}组`
          )
        }
      })
    })
    if (error.length) {
      setInfoType('duplication')
      setInfo(error)
      return
    }

    // All good

    setData('')
    props.onConfirm(importList)
  }, [school, data, questionBindGroup, props])

  return (
    <Modal
      visible={props.visible}
      title='导入判卷人员'
      onCancel={props.onCancel}
      onOk={onSubmit}
      okButtonProps={{ loading }}
      destroyOnClose
    >
      <Alert
        message={
          <>
            <p>
              <strong>一、说明:</strong>
            </p>
            <p>
              <div>1. 导入文本格式:</div>
              <strong>
                {'{'}姓名{'}'}
              </strong>{' '}
              [空格]{' '}
              <strong>
                {'{'}工号{'}'}
              </strong>{' '}
              [空格]{' '}
              <strong>
                {'{'}题目分组序号{'}'}
              </strong>
            </p>
            <p>2. 工号可选</p>
            <p>3. 用换行分割多条记录</p>
            <p>4. 一个判卷人员只能被分配给其中一个分组</p>
            <p>
              <strong>二、示例:</strong>
            </p>
            <pre>
              {`张三 10001 1 
李四 1
王五 2`}
            </pre>
          </>
        }
        style={{ marginBottom: 16 }}
      ></Alert>

      {!!info?.length && (
        <Alert
          message={
            <>
              <strong>
                {infoType === 'format' && '请检查格式:'}
                {infoType === 'duplication' && '请检查人员信息:'}
                {infoType === 'tid-duplication' && '请检查人员信息:'}
                {infoType === 'fetch' && '暂无以下人员信息:'}
                {infoType === 'fetch-duplication' &&
                  '以下人员存在多个同名账号，请手动添加:'}
              </strong>

              <div>
                {info.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>

              {infoType === 'duplication' && (
                <div style={{ marginTop: 8 }}>尝试添加工号信息，或手动添加</div>
              )}
            </>
          }
          type='error'
          style={{ marginBottom: 16 }}
        ></Alert>
      )}

      <Form.Item label={'学校'} labelCol={{ span: 4 }}>
        <Select<number>
          value={school}
          onChange={setSchool}
          style={{ width: 220 }}
        >
          {schoolList.map(s => (
            <Select.Option key={s.id} value={s.fid}>
              {s.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={'导入文本'} labelCol={{ span: 4 }}>
        <Input.TextArea
          rows={10}
          value={data}
          onChange={e => setData(e.target.value)}
        />
      </Form.Item>
    </Modal>
  )
}
