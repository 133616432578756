import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { GET } from '../../../core/request'

export const ExamBatchSelect: FC<{
  value?: string
  onChange?: (value: string) => void
}> = props => {
  const [data, setData] = useState<{ id: string; kspcflName: string }[]>([])
  const [loading, setLoading] = useState(true)

  async function fetch() {
    const res = await GET('statistics/v2/gather/list', {})
    setData(res.data?.data)
    setLoading(false)
  }
  useEffect(() => {
    fetch()
  }, [])
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      placeholder={'全部考试批次'}
      loading={loading}
      style={{ width: 150 }}
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        return (option?.children as string).includes(input)
      }}
    >
      {data.map(it => (
        <Select.Option key={it.id} value={it.id}>
          {it.kspcflName}
        </Select.Option>
      ))}
    </Select>
  )
}
