import { useLocalStorageState, useUpdateEffect } from '@umijs/hooks'
import constate from 'constate'
import { GET } from 'core/request'
import { useState } from 'react'

const log = require('debug')('useList')

export interface UseListOptions<T, S> {
  url: string | ((search: S) => string | null)
  getDefaultSearch?: () => Partial<S>
  parseSearchData?: (data: S) => any
  parseFetchedData?: (data, search: S) => { items: T[]; total: number }
  size?: number
  preventPaginationReload?: boolean
}

export interface ListContext<T = any, S = any> {
  items: T[]
  index: { value: number }
  size: number
  total: number
  loading: boolean
  search: Partial<S>
  defaultSearch: Partial<S>

  setDefaultSearch: (search: Partial<S>) => void
  fetch: (options?: { reset: boolean }) => Promise<any>
  onIndexChange: (current: number) => void
  setPageSize: (current: number) => void
  onSearchSubmit: (search: Partial<S>) => void
  onSearchReset: () => S
  indexMethod: (current: number) => number
}

const PAGE_SIZE = 20

export function createList<T = any, S = any>(
  options: Readonly<UseListOptions<T, S>>
) {
  function hook(): ListContext<T, S> {
    const [index, setIndex] = useState({ value: 1 })
    const [size, setSize] = useLocalStorageState(
      'page-size',
      options.size || PAGE_SIZE
    )
    const [total, setTotal] = useState(0)
    const [items, setItems] = useState<T[]>([])
    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState<S>(
      (options.getDefaultSearch ? options.getDefaultSearch() : {}) as S
    )

    const [defaultSearch, setDefaultSearch] = useState<Partial<S>>(
      () => (options.getDefaultSearch ? options.getDefaultSearch() : {}) as S
    )

    async function fetch() {
      const _search = { ...defaultSearch, ...search }

      const url =
        typeof options.url === 'function' ? options.url(_search) : options.url

      if (!url) {
        return
      }
      setLoading(true)
      setItems([])
      try {
        const searchData = options.parseSearchData
          ? options.parseSearchData(_search)
          : _search

        const { data } = await GET(url, {
          data: {
            pageIndex: Math.max(index.value - 1, 0),
            pageSize: size,
            ...searchData
          }
        })

        const { items, total } = parseFetchedData(data)
        log('fetched', items, total)
        setItems(items)
        setTotal(total)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    function parseFetchedData(data) {
      if (options.parseFetchedData) {
        return options.parseFetchedData(data, search)
      }

      const items = data.items || data
      const total = data.totalCount || items.length
      return {
        items,
        total
      }
    }

    useUpdateEffect(() => {
      if (!options.preventPaginationReload) {
        log('index change')
        fetch()
      }
    }, [index])

    useUpdateEffect(() => {
      setIndex({ value: 1 })
    }, [search])

    return {
      items,
      index,
      size,
      total,
      loading,
      search,
      defaultSearch,

      setDefaultSearch,
      fetch,
      onIndexChange: current => {
        setIndex({ value: current })
      },
      setPageSize: current => {
        setSize(current ?? PAGE_SIZE)
      },
      onSearchSubmit: search => {
        setSearch(prevState => ({ ...prevState, ...search }))
      },
      onSearchReset: () => {
        const val = (options.getDefaultSearch
          ? options.getDefaultSearch()
          : {}) as S
        setSearch(val)
        return val
      },
      indexMethod: current => current + 1 + (index.value - 1) * size
    }
  }

  const [ListProvider, useListContext] = constate(hook)
  ListProvider.displayName = 'ListProvider'
  return {
    ListProvider,
    useListContext
  }
}
