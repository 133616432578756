import { createList } from 'core/service'
import React, { FC, useEffect, useState } from 'react'
import { Table } from 'components'
import _ from 'lodash'
import { Report } from 'typing'
import { Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useExportContent } from 'components/ReportExamPaperTables/TableTabsWrapper'
import { useSingleSchoolModeContent } from 'components/ReportExamPaperTables'

const { ListProvider, useListContext } = createList<
  Report.TotalScoreTableItemStudent,
  Report.ListQueryType
>({
  url: ({ entryType, scope }) => {
    return `/city/exam/${
      entryType === 'exam' ? '' : 'paper/'
    }statistic/score/student`
  }
})

const TotalScoreTableStudentContent: FC<Report.BasicTableProps> = ({
  stateParams,
  scopeParams,
  totalScoreSummary
}) => {
  const { isSingleSchoolView } = useSingleSchoolModeContent()
  const list = useListContext()

  useEffect(() => {
    list.onSearchSubmit({
      entryType: scopeParams.reportEntryType,
      scope: scopeParams.scope,
      [scopeParams.reportEntryType === 'exam'
        ? 'exam_id'
        : 'exam_paper_id']: scopeParams.reportEntryId,
      fid: scopeParams.schoolId,
      statistic_clazz_id: scopeParams.clazzId
    })
  }, [])

  //#region UI
  const [computedItems, setComputedItems] = useState<
    Report.TotalScoreTableItemStudent[]
  >()
  useEffect(() => {
    const statistic = {
      id: -1,
      username: '班级汇总',
      score: Math.round(totalScoreSummary.avg_score * 10) / 10,
      avg_deviation: 0,
      avg_deviation_school: 0,
      avg_deviation_exam_unit: 0
    } as Report.TotalScoreTableItemStudent
    setComputedItems([
      statistic,
      ..._.map(list.items, item => {
        return {
          ...item,
          avg_deviation_exam_unit:
            Math.round((item.score - totalScoreSummary.exam_avg_score) * 10) /
            10,
          avg_deviation_school:
            Math.round((item.score - totalScoreSummary.school_avg_score) * 10) /
            10,
          avg_deviation:
            Math.round((item.score - totalScoreSummary.avg_score) * 10) / 10
        }
      })
    ])
  }, [list.items])

  const isStatisticRow = row => row.id === -1

  const LevelConfig = [
    { name: '优秀', type: 'success' },
    { name: '良好', type: 'processing' },
    { name: '及格', type: 'warning' },
    { name: '不及格', type: 'error' }
  ]
  function matchScoreLevel(
    level: Pick<
      Report.TotalScoreTableItemStudent,
      'excellent_score' | 'good_score' | 'pass_score'
    >,
    score: number
  ) {
    const ranges = [
      [0, level.pass_score],
      [level.pass_score, level.good_score],
      [level.good_score, level.excellent_score],
      [level.excellent_score, Infinity]
    ].reverse()
    const index = _.findIndex(ranges, range => {
      return _.inRange(score, range[0], range[1])
    })
    return LevelConfig[index]
  }

  const columns: ColumnsType<Report.TotalScoreTableItemStudent> = [
    { title: '学生姓名', dataIndex: 'username' },
    {
      title: '总分',
      dataIndex: 'score',
      render: (t, r) =>
        r.id === -1 ? <span className={'primary'}>{t}</span> : t,
      sorter: (a, b) => a.score - b.score,
      defaultSortOrder: 'descend',
      align: 'right'
    },
    {
      title: '班均差',
      dataIndex: 'avg_deviation',
      render: (t, r) => (
        <span className={isStatisticRow(r) ? '' : t < 0 ? 'danger' : 'success'}>
          {isStatisticRow(r) ? '-' : t}
        </span>
      ),
      align: 'right'
    },
    { title: '班排名', dataIndex: 'clazz_rank', align: 'right' },
    {
      title: '校均差',
      dataIndex: 'avg_deviation_school',
      render: (t, r) => <span>{isStatisticRow(r) ? '-' : t}</span>,
      align: 'right'
    },
    { title: '校排名', dataIndex: 'school_rank', align: 'right' },
    ...(() => {
      return isSingleSchoolView
        ? []
        : ([
            {
              title: '统考均差',
              dataIndex: 'avg_deviation_exam_unit',
              render: (t, r) => <span>{isStatisticRow(r) ? '-' : t}</span>,
              align: 'right'
            },
            { title: '统考排名', dataIndex: 'city_rank', align: 'right' }
          ] as ColumnsType<any>)
    })(),
    {
      title: '成绩等级',
      dataIndex: 'level',
      render: (t, r) => {
        if (isStatisticRow(r)) {
          return '-'
        }
        const level = matchScoreLevel(r, r.score)
        return <Tag color={level.type}>{level.name}</Tag>
      },
      align: 'right'
    }
  ]
  //#endregion

  // useExportContent
  const { setLoading, setStateParams, setData } = useExportContent()
  useEffect(() => {
    setStateParams(stateParams)
    setData(columns, computedItems)
  }, [computedItems])
  useEffect(() => {
    setLoading(list.loading)
  }, [list.loading])

  return (
    <>
      <Table
        list={list}
        items={computedItems}
        pagination={false}
        scroll={{ x: 'max-content', y: document.body.clientHeight * 0.6 - 50 }}
        columns={columns}
      />
    </>
  )
}

export const TotalScoreTableStudent: FC<Report.BasicTableProps> = props => {
  return (
    <ListProvider>
      <TotalScoreTableStudentContent {...props} />
    </ListProvider>
  )
}
