import { message } from 'antd'
import { GET } from 'core/request'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import React, { ReactNode, useCallback } from 'react'

export function AnswerSheetLoader(props: {
  children: (ctx: { open: () => void }) => ReactNode
}) {
  const { currentStudentId } = useQuestionAnswerDataContent()

  const fetch = useCallback(async () => {
    const { data } = await GET(`fanya/exam/paper/answer_card`, {
      data: {
        exam_paper_teacher_student_id: currentStudentId
      }
    })
    return data.ans_url
  }, [currentStudentId])

  async function open() {
    const hide = message.loading('加载中...')

    const url = await fetch()
    window.open(url)

    hide()
    message.success('已在新窗口打开答题卡')
  }

  return <>{props.children({ open })}</>
}
