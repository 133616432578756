// @ts-ignore
const fabric = window.fabric

var deleteImg = document.createElement('img')
deleteImg.src =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"

const RemoveControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  cursorStyle: 'pointer',
  mouseUpHandler: (e, { target }) => {
    const { canvas } = target
    canvas?.remove(target)
    canvas?.renderAll()
    return true
  },
  render: (ctx, left, top, styleOverride, fabricObject) => {
    const size = 20
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle!))
    ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size)
    ctx.restore()
  }
})

fabric.Textbox.prototype.controls.tr = RemoveControl
fabric.Path.prototype.controls.tr = RemoveControl

//
fabric.Object.prototype.transparentCorners = false
fabric.Object.prototype.cornerColor = 'blue'
fabric.Object.prototype.cornerStyle = 'circle'
fabric.Object.prototype.cornerSize = 12
// fabric.Object.prototype.minScaleLimit = 0.5

const originalToObject = fabric.Object.prototype.toObject
const customProps = ['id']
fabric.Object.prototype.toObject = function (props: any) {
  return originalToObject.call(this, customProps.concat(props))
}

export default fabric
