import { ModalSizeLarge, QuestionType } from 'config'
import { GET } from 'core/request'
import { Paper } from 'typing'
import { createPaperQuestion } from 'utils/question'
import { Checkbox, Modal, Spin } from 'antd'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { QuestionItemPreview } from '../Question/QuestionItemPreview'

interface PreviewProps {
  visible: boolean
  id: number
  onClose: () => void
}

export const PreviewDialog: FC<PreviewProps> = props => {
  const [paperQuestion, setPaperQuestion] = useState<Paper.QuestionBox | null>()
  const [loading, setLoading] = useState(false)

  const [answerRightVisible, setAnswerRightVisible] = useState(false)

  useEffect(() => {
    async function fetch(id) {
      setPaperQuestion(null)
      setLoading(true)
      const { data } = await GET(`city/paper/${id}`, {})
      const po = _.get(data, 'data[0]') as Paper.PaperOriginal
      if (po) {
        setPaperQuestion(createPaperQuestion(po, true))
      }
      setLoading(false)
    }
    if (props.id) {
      fetch(props.id)
    }
  }, [props.id])

  return (
    <Modal
      visible={props.visible}
      title={'查看试卷'}
      width={ModalSizeLarge}
      footer={false}
      onCancel={props.onClose}
    >
      {loading && <Spin />}

      {paperQuestion && (
        <div style={{ marginBottom: 16, textAlign: 'right' }}>
          <Checkbox
            checked={answerRightVisible}
            onChange={e => setAnswerRightVisible(e.target.checked)}
          >
            显示答案
          </Checkbox>
        </div>
      )}

      <div className={'question-preview-list'}>
        {paperQuestion &&
          paperQuestion.group.map((g, i) => (
            <div key={i} className={'group'}>
              <div className={'title'}>
                <span className={'index'}>{g.displayName}. </span>
                <span className={'name'}>{QuestionType[g.type]}</span>
                <span className={'desc'}>
                  (共{g.children.length}题, {g.score}分)
                </span>
              </div>
              <div className={'questions'}>
                {g.children.map((q, i) => (
                  <QuestionItemPreview
                    key={i}
                    q={q}
                    i={i}
                    answerRightVisible={answerRightVisible}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </Modal>
  )
}
