import {
  CloudDownloadOutlined,
  OrderedListOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { Button, Checkbox, Popover, Space, Typography, message } from 'antd'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import React, { FC, useState } from 'react'
import { useMarkingForm } from '../useMarkingForm'
import { PullMoreForm } from './PullMoreForm'

export const SettingPopover: FC = () => {
  const {
    isCurrentUserHasAuditRole,
    isCurrentUserHasReviewRole,
    isImageMarkingMode
  } = useQuestionAnswerDataContent()

  const {
    markingPaper,
    showContent,
    toggleContentVisible,

    showAnswerRight,
    toggleAnswerRightVisible,

    // markingStatusAutoDetect,
    // toggleMarkingStatusAutoDetect,
    userImageMarkingMode,
    toggleUserImageMarkingMode,

    markingSubmitConfirmIgnore,
    setMarkingSubmitConfirmIgnore,

    setPartialMarkingSettingVisible
  } = useMarkingForm()

  const [visible, setVisible] = useState(false)

  const [pullMoreVisible, setPullMoreVisible] = useState(false)

  async function onPullMorePaperDialogOpen() {
    setPullMoreVisible(true)
  }

  return (
    <>
      <Popover
        visible={visible}
        onVisibleChange={setVisible}
        content={
          <div style={{ padding: '8px' }}>
            <Typography.Text
              strong
              style={{ marginBottom: 8, display: 'block' }}
            >
              通用
            </Typography.Text>
            <Space direction='vertical' style={{ display: 'block' }}>
              <Checkbox
                checked={showContent}
                onChange={e => toggleContentVisible(e.target.checked)}
              >
                显示题干
              </Checkbox>
              <Checkbox
                checked={showAnswerRight}
                onChange={e => toggleAnswerRightVisible(e.target.checked)}
              >
                显示正确答案
              </Checkbox>

              {!isCurrentUserHasReviewRole && (
                <>
                  <Checkbox
                    checked={!markingSubmitConfirmIgnore}
                    onChange={e =>
                      setMarkingSubmitConfirmIgnore(!e.target.checked)
                    }
                  >
                    提交评分时，显示确认对话框
                  </Checkbox>
                  {/* <Checkbox
                  checked={openDrawerOnClickImage}
                  onChange={e => toggleOpenDrawerOnClickImage(e.target.checked)}
                >
                  点击图片时，打开批注工具
                </Checkbox> */}
                  {/* <Checkbox
                checked={markingStatusAutoDetect}
                onChange={e => toggleMarkingStatusAutoDetect(e.target.checked)}
              >
                跳过已有分数试卷
              </Checkbox> */}
                </>
              )}
            </Space>

            {isImageMarkingMode && (
              <>
                <Typography.Text
                  strong
                  style={{ marginTop: 16, marginBottom: 8, display: 'block' }}
                >
                  批注
                </Typography.Text>
                <Space direction='vertical' style={{ display: 'block' }}>
                  <Checkbox
                    checked={userImageMarkingMode}
                    onChange={e => {
                      toggleUserImageMarkingMode(e.target.checked)
                      message.loading('正在切换模式', 0)
                      setTimeout(() => {
                        location.reload()
                      }, 1000)
                    }}
                  >
                    图片快速批注模式
                  </Checkbox>
                </Space>
              </>
            )}

            {!isCurrentUserHasReviewRole && (
              <>
                <Typography.Text
                  strong
                  style={{ display: 'block', marginTop: 16, marginBottom: 8 }}
                >
                  高级
                </Typography.Text>
                <Space direction='vertical' style={{ display: 'block' }}>
                  <Button
                    icon={<OrderedListOutlined />}
                    onClick={() => {
                      setPartialMarkingSettingVisible(true)
                      setVisible(false)
                    }}
                  >
                    部分批阅设置
                  </Button>

                  {!!markingPaper?.allow_teacher_supplement &&
                    !isCurrentUserHasReviewRole &&
                    !isCurrentUserHasAuditRole && (
                      <>
                        <Button
                          icon={<CloudDownloadOutlined />}
                          onClick={onPullMorePaperDialogOpen}
                        >
                          拉取更多试卷
                        </Button>
                      </>
                    )}
                </Space>
              </>
            )}
          </div>
        }
        trigger={'click'}
        placement='bottomRight'
      >
        <Button size={'small'} icon={<SettingOutlined />}>
          设置
        </Button>
      </Popover>

      <PullMoreForm
        visible={pullMoreVisible}
        onClose={() => setPullMoreVisible(false)}
      />
    </>
  )
}
