import { Button, Card, Space, Table, Tag } from 'antd'
import { PageWrapper } from 'components/PageWrapper'
import { QuestionType } from 'config'
import { GET } from 'core/request'
import { useQuery } from 'hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export default function () {
  const history = useHistory()
  const { id, teacherId } = useParams<{ id; teacherId }>()

  const { paper_name } = useQuery<{
    paper_name: string
  }>()

  const [subject_uncompleted_count, setSubject_uncompleted_count] = useState(0)
  const [subject_total, setSubject_total] = useState(0)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any[]>([])

  async function fetch() {
    const { data } = await GET('fanya/paper/teacher/reviewed/detail', {
      data: {
        exam_paper_teacher_id: id,
        teacher_uid: teacherId
      }
    })
    setSubject_total(data.total)
    setSubject_uncompleted_count(data.total_todo)
    setData(data.list)
    setLoading(false)
  }
  useEffect(() => {
    fetch()
  }, [])

  return (
    <PageWrapper
      breadcrumb={['批阅统计']}
      headerRight={() => (
        <Button type='primary' onClick={() => history.goBack()}>
          返回
        </Button>
      )}
      content={
        <div>
          <div style={{ marginBottom: 8, fontSize: 15, color: '#333' }}>
            {paper_name}
          </div>
          <Space>
            <Tag color='green'>
              待批阅题目数/总题目数: {subject_uncompleted_count}/{subject_total}
            </Tag>
          </Space>
        </div>
      }
    >
      <Card>
        <Table
          loading={loading}
          dataSource={data}
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              render: (text, record, index) => index + 1,
              align: 'center',
              fixed: 'left'
            },
            {
              title: '题目',
              dataIndex: 'subject_index'
            },
            {
              title: '题型',
              dataIndex: 'subject_type',
              render(value) {
                return QuestionType[value]
              }
            },
            {
              title: '待批阅题目份数',
              dataIndex: 'todo',
              align: 'right'
            },
            {
              title: '已批阅题目份数',
              dataIndex: 'done',
              align: 'right'
            }
          ]}
          pagination={false}
          scroll={{
            x: 'max-content'
          }}
        ></Table>
      </Card>
    </PageWrapper>
  )
}
