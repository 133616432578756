import { createContext } from 'react'
import { Paper } from 'typing'

// TODO remove
export const CurrentQuestionContext = createContext<{
  question?: Paper.Question
  parentQuestion?: Paper.Question
}>({})

CurrentQuestionContext.displayName = 'CurrentQuestionContext'
