import Excel, { Column } from 'exceljs'
import {
  ColumnGroupType,
  ColumnsType,
  ColumnType
} from 'antd/es/table/interface'
import _ from 'lodash'
import $ from 'jquery'
import { renderToStaticMarkup } from 'react-dom/server'

function isColumnGroup(column): column is ColumnGroupType<any> {
  return column.children
}

const debug = require('debug')('ExcelExporter:')

export class ExcelExporter {
  workbook: Excel.Workbook
  sheet: Excel.Worksheet

  defaultFontStyle: Partial<Excel.Font> = {
    name: 'Microsoft YaHei',
    family: 2,
    size: 12
  }
  defaultAlign: Partial<Excel.Alignment> = {
    horizontal: 'left',
    vertical: 'middle'
  }
  defaultAlignRight: Partial<Excel.Alignment> = {
    horizontal: 'right',
    vertical: 'middle'
  }

  constructor(
    private fileName: string,
    private sheetName: string,
    private columns: ColumnsType<any>,
    private items: any[]
  ) {
    this.workbook = new Excel.Workbook()
    this.workbook.creator = 'chaoxing'
    this.workbook.created = new Date()

    this.sheet = this.workbook.addWorksheet(sheetName, {
      properties: {
        defaultRowHeight: 16
      }
    })

    this.setHeader()
    this.setRowData()

    debug(this)
  }

  setHeader() {
    const columns = this.columns.reduce<Partial<Column>[]>((res, col) => {
      const baseColumn: Partial<Column> = {
        width: typeof col.width === 'number' ? col.width / 8 : 20,
        font: this.defaultFontStyle,
        alignment:
          col.align === 'right' ? this.defaultAlignRight : this.defaultAlign
      }

      if (isColumnGroup(col)) {
        _.forEach(col.children, (child, index) => {
          res.push({
            header: [
              index ? '' : this.getReactElementText(col.title),
              this.getReactElementText(child.title)
            ],
            ...baseColumn
          })
        })
      } else {
        res.push({
          header: this.getReactElementText(col.title),
          ...baseColumn
        })
      }
      return res
    }, [])
    this.sheet.columns = columns
  }

  setRowData() {
    const rows = _.map(this.items, (item, index) => {
      return _.flatMap(this.columns, col => {
        if (isColumnGroup(col)) {
          return _.map(col.children, c =>
            this.mapColumnToRowItem(c, item, index)
          )
        }
        return this.mapColumnToRowItem(col, item, index)
      })
    })
    this.sheet.addRows(rows)
  }

  mapColumnToRowItem(column: ColumnType<any>, item, index) {
    let result
    if (column.dataIndex) {
      result = _.get(item, column.dataIndex)
    }
    if (column.render) {
      result = this.getReactElementText(column.render(result, item, index))
    }
    return result
  }

  getReactElementText(node) {
    if (_.isString(node) || _.isNumber(node)) {
      return node
    }
    try {
      return $(renderToStaticMarkup(node)).text()
    } catch (e) {
      return node
    }
  }

  async exportFile() {
    const data = await this.workbook.xlsx.writeBuffer()
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    const link = document.createElement('a')
    const url = (link.href = window.URL.createObjectURL(blob))
    link.download = `${this.fileName}.xlsx`
    link.click()
    window.URL.revokeObjectURL(url)
  }
}
