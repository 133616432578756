import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { Paper, QuestionBindGroup, Teacher } from 'typing'
import { QuestionIdentity } from 'typing.paper'
import { getQuestionPath } from 'utils/question'
import { MarkingMode } from '../../../config'
import { useMarkingSettingEditContext } from '../store'
import { useMarkingSettingContent } from './useMarkingSettingState'
import {
  getQuestionBindGroupDefaultValue,
  useQuestionBindGroupContent
} from './useQuestionBindGroup'

export const Initializer: FC = props => {
  const edit = useMarkingSettingEditContext()

  const {
    fetchSchool,
    fetchQuestion,

    setTeacher,
    teacherList,

    paperQuestions,
    setPaperQuestions,

    checkIsGroupedPaper
  } = useMarkingSettingContent()

  const {
    questionBindGroup,
    questionBindGroupSetter
  } = useQuestionBindGroupContent()

  const [isDependenciesInitialized, setDependenciesInitialized] = useState(
    false
  )
  const [initialized, setInitialized] = useState(false)

  async function init(data: Paper.PaperMarkingSettingFormData) {
    console.debug('init with edit data', data)
    // reset
    setDependenciesInitialized(false)
    setInitialized(false)
    edit.setLoading(true)
    setPaperQuestions([])
    questionBindGroupSetter.set(getQuestionBindGroupDefaultValue())

    // @ts-ignore
    setTeacher(_.map(edit.data.teachers, t => ({ tel: '', ...t })))
    await fetchSchool()

    await fetchQuestion(data.id)

    // TODO: setting sync
    await checkIsGroupedPaper()

    setDependenciesInitialized(true)
    edit.setLoading(false)
  }
  useEffect(() => {
    if (edit.data.id) {
      init(edit.data)
    }
  }, [edit.data])

  //#region initQuestionBindGroup
  function initQuestionBindGroup(
    mode: MarkingMode,
    quest_group: Paper.PaperMarkingSettingFormData['quest_group'],
    paperQuestions: Paper.QuestionGroup[],
    teacherList: Teacher[]
  ) {
    const bindGroup: QuestionBindGroup[] = _.map(
      quest_group,
      ({ quests, teachers }, i) => {
        if (!quests.length && !teachers.length) {
          return getQuestionBindGroupDefaultValue()[0]
        }
        const questions = _.reduce<QuestionIdentity, Paper.Question[]>(
          quests,
          (res, q) => {
            const path = getQuestionPath(paperQuestions, q)
            if (path) res.push(path[1])
            return res
          },
          []
        )

        let _teachers: Teacher[] = []

        // For mode 3,4
        let _groupedTeachers: Teacher[][] = []

        if ([MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(mode)) {
          const groupedTeacherIds = teachers as number[][]
          _groupedTeachers = _.map(groupedTeacherIds, teacherIds => {
            return _.reduce<number, Teacher[]>(
              teacherIds,
              (res, t) => {
                const matched = _.find(teacherList, { uid: t })
                if (matched) res.push(matched)
                return res
              },
              []
            )
          })
        } else {
          _teachers = _.reduce<number, Teacher[]>(
            teachers as number[],
            (res, t) => {
              const matched = _.find(teacherList, { uid: t })
              if (matched) res.push(matched)
              return res
            },
            []
          )
        }

        return {
          questions,
          teachers: _teachers,
          groupedTeachers: _groupedTeachers,
          _id: +i
        }
      }
    )
    console.log('[initQuestionBindGroup]', bindGroup)
    questionBindGroupSetter.set(
      bindGroup.length ? bindGroup : getQuestionBindGroupDefaultValue()
    )
  }

  useEffect(() => {
    if (initialized) return
    if (isDependenciesInitialized) {
      initQuestionBindGroup(
        edit.data.review_mode,
        edit.data.quest_group,
        paperQuestions,
        teacherList
      )
      setInitialized(true)
    }
  }, [isDependenciesInitialized])

  //#endregion

  function removeUnValidateTeacherFromBindGroup() {
    const isTeacherValidate = (t: Teacher) => {
      return !!_.find(teacherList, { uid: t.uid })
    }
    _.forEach(questionBindGroup, (group, index) => {
      const validate = _.every(group.teachers, t => {
        return isTeacherValidate(t)
      })
      if (!validate) {
        questionBindGroupSetter.updateAt(index, {
          ...group,
          teachers: _.filter(group.teachers, t => {
            return isTeacherValidate(t)
          })
        })
      }
    })
  }
  useEffect(() => {
    if (initialized) {
      removeUnValidateTeacherFromBindGroup()
    }
  }, [initialized, teacherList])

  return <div>{props.children}</div>
}
