import { createList } from 'core/service'
import React, { FC, useEffect } from 'react'
import { Table } from 'components'
import _ from 'lodash'
import { Report } from 'typing'
import { LinkOutlined } from '@ant-design/icons'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { useExportContent } from 'components/ReportExamPaperTables/TableTabsWrapper'

function createRanges(val: {
  [key: string]: number
}): Report.RangeScoreTableItemRange[] {
  const rangeObj = _.pickBy(val, (v, k) => !!~k.indexOf('range_'))
  return _.map(rangeObj, (count, rangeStr) => {
    const sp = rangeStr.split('_')
    const begin = _.parseInt(_.get(sp, '[1]'))
    const end = _.get(sp, '[2]') ? _.parseInt(_.get(sp, '[2]')) : null
    return {
      value: [begin, end],
      count
    }
  })
}

const { ListProvider, useListContext } = createList<
  Report.ScoreRangeTableItemUnion,
  Report.ListQueryType
>({
  url: ({ entryType, scope }) => {
    return scope === 'school'
      ? `/city/exam/${
          entryType === 'exam' ? '' : 'paper/'
        }statistic/range/school`
      : `/city/exam/${
          entryType === 'exam' ? '' : 'paper/'
        }statistic/range/class`
  },
  parseFetchedData: (data, search) => {
    const items = _.map<any, Report.ScoreRangeTableItemUnion>(data, item => {
      return {
        ...item,
        ranges: createRanges(item)
      }
    })

    const itemRangesValues = _.map(
      _.get(items[0], 'ranges'),
      item => item.value
    )
    const itemRangeCount = _.map(itemRangesValues, (rv, i) => {
      return _.sumBy(items, item => {
        return _.get(item.ranges, `[${i}].count`)
      })
    })
    const statisticRange = _.map(itemRangesValues, (rv, i) => {
      return {
        value: rv,
        count: itemRangeCount[i]
      }
    })

    const studentCount = _.sumBy(items, item => item.student_count)

    let statisticRow
    if (search.scope === 'school') {
      statisticRow = {
        fid: -1,
        org_name: '全市汇总',
        student_count: studentCount,
        ranges: statisticRange
      } as Report.RangeScoreTableItem
    } else {
      statisticRow = {
        statistic_clazz_id: -1,
        class_name: '全校汇总',
        student_count: studentCount,
        ranges: statisticRange
      } as Report.RangeScoreTableItemOfClazz
    }
    const itemsWithStatistic = [statisticRow, ...items]
    return { items: itemsWithStatistic, total: itemsWithStatistic.length }
  }
})

const RangeScoreTableContent: FC<Report.BasicTableProps> = ({
  stateParams,
  scopeParams,
  onDetailClick
}) => {
  const list = useListContext()

  useEffect(() => {
    list.onSearchSubmit({
      entryType: scopeParams.reportEntryType,
      scope: scopeParams.scope,
      [scopeParams.reportEntryType === 'exam'
        ? 'exam_id'
        : 'exam_paper_id']: scopeParams.reportEntryId,
      fid: scopeParams.schoolId
    })
  }, [])

  const isStatisticRow = row => row.fid === -1 || row.statistic_clazz_id === -1

  function getScoreLevelRanges(): Report.RangeScoreTableItemRange[] {
    return _.get(list.items[1], 'ranges')
  }

  function createRangeColumns(): ColumnType<any>[] {
    const ranges = getScoreLevelRanges()
    return _.map(ranges, (range, i) => {
      return {
        title: range.value[1]
          ? `${range.value[0]}~${range.value[1]}分`
          : `${range.value[0]}分以上`,
        dataIndex: ['ranges', `${i}`, 'count'],
        width: 100,
        align: 'right'
      }
    })
  }

  const columns: ColumnsType<Report.ScoreRangeTableItemUnion> = [
    {
      title: scopeParams.scope === 'school' ? '学校' : '班级',
      dataIndex: scopeParams.scope === 'school' ? 'org_name' : 'class_name',
      render: (t, r) => {
        return isStatisticRow(r) ? (
          t
        ) : (
          <a onClick={() => onDetailClick(r)}>
            {t} <LinkOutlined />
          </a>
        )
      },
      width: 220,
      fixed: 'left'
    },
    {
      title: '参与学生',
      dataIndex: 'student_count',
      width: 120,
      align: 'right'
    },
    ...createRangeColumns()
  ]

  // useExportContent
  const { setLoading, setStateParams, setData } = useExportContent()
  useEffect(() => {
    setStateParams(stateParams)
    setData(columns, list.items)
  }, [list.items])
  useEffect(() => {
    setLoading(list.loading)
  }, [list.loading])

  return (
    <>
      <Table
        list={list}
        rowKey={(r: any) => r.fid || r.statistic_clazz_id}
        scroll={{ x: 'max-content', y: document.body.clientHeight * 0.6 - 50 }}
        indexColumn={{
          fixed: 'left'
        }}
        pagination={false}
        columns={columns}
      />
    </>
  )
}

export const RangeScoreTable: FC<Report.BasicTableProps> = props => {
  return (
    <ListProvider>
      <RangeScoreTableContent {...props} />
    </ListProvider>
  )
}
