import { useMount } from '@umijs/hooks'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography
} from 'antd'
import { ColumnType, TablePaginationConfig } from 'antd/es/table'
import { SorterResult } from 'antd/es/table/interface'
import { Table, getPaginationProps } from 'components'
import { PageWrapper } from 'components/PageWrapper'
import { PreviewMarkingDialog } from 'components/Preview/PreviewMarkingDialog'
import { APP_SCOPE, browserHistory } from 'config'
import { GET } from 'core/request'
import { createList } from 'core/service'
import {
  CurrentMarkingPaperProvider,
  useCurrentMarkingPaperContent
} from 'hooks/useMarkingPaperData'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import qs from 'qs'
import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DateTimeInString, Paper } from 'typing'
import { FORMATTER_DATE, FORMATTER_TIME } from 'utils'
import { requestAndSaveFile } from 'utils/exporter'
import { createPaperQuestion } from 'utils/question'

export interface StudentMarkingHistory {
  id: number
  student_uid: number
  quests: {
    questionid: number
    score: number
  }[]
  is_complete: boolean
  complete_time: DateTimeInString
}

const { Title, Text } = Typography

const { ListProvider, useListContext } = createList<
  StudentMarkingHistory,
  { id: number; is_complete: boolean; order: 'desc' | 'asc' | null }
>({
  url: search =>
    `/${APP_SCOPE.isInFanYa ? 'fanya' : 'review'}/paper/${
      search.id
    }/students/history`,
  parseFetchedData(res) {
    const items = _.map(res.items, it => {
      const questObj = _.reduce(
        it.quests,
        (acc, q) => {
          // transform quest list to object { [questionId]: item }
          acc[q.questionid] = q
          return acc
        },
        {}
      )
      it.quests = questObj
      console.log(it)
      return it
    })
    return {
      items,
      total: res.totalCount
    }
  }
})

export default function () {
  return (
    <CurrentMarkingPaperProvider>
      <ListProvider>
        <Page />
      </ListProvider>
    </CurrentMarkingPaperProvider>
  )
}

const Page: FC = () => {
  const query = useParams<{ id }>()
  const history = useHistory()

  const {
    currentMarkingPaper,
    initCurrentMarkingPaper
  } = useCurrentMarkingPaperContent()

  const list = useListContext()

  const [tabIndex, setTableIndex] = useState('-1')
  function onTabChange(key) {
    setTableIndex(key)
    list.onSearchSubmit({
      is_complete: key === '-1' ? undefined : key
    })
  }

  function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    console.log(pagination, sorter)
    const s = sorter as SorterResult<StudentMarkingHistory>

    if (
      pagination.current !== list.index.value ||
      pagination.pageSize !== list.size
    ) {
      // page change
      list.setPageSize(pagination.pageSize!)
      setTimeout(() => {
        list.onIndexChange(pagination.current!)
      })
    } else {
      // sort change
      list.onSearchSubmit({
        order: !s.order ? null : s.order === 'ascend' ? 'asc' : 'desc'
      })
    }
  }

  const pagination = getPaginationProps(list)
  pagination.onChange = () => {
    window.scrollTo(0, 0)
  }

  //#region paperQuestions
  const [loading, setLoading] = useState(true)
  const [paperQuestions, setPaperQuestions] = useState<Paper.QuestionGroup[]>(
    []
  )
  async function fetchQuestion(id) {
    function errorHandler() {
      Modal.confirm({
        title: '无法获取试卷数据',
        content: '请点击[重试]，尝试重新加载',
        okText: '重试',
        onOk: () => fetchQuestion(id)
      })
    }
    setLoading(true)
    try {
      const { data } = await GET(`review/paper/${id}`, {})
      const po = _.get(data, 'data[0]') as Paper.PaperOriginal
      const box = createPaperQuestion(po, true)
      setPaperQuestions(box.group)
    } catch (e) {
      console.error(e)
      errorHandler()
    } finally {
      setLoading(false)
    }
  }

  function onSearchSubmit(data) {
    setTableIndex('-1')
    list.onSearchSubmit({
      ...data,
      is_complete: undefined
    })
  }
  //#endregion

  //#region columns
  const [reviewBtnVisible, setReviewBtnVisible] = useState(true)
  function createQuestionColumns(): ColumnType<StudentMarkingHistory>[] {
    const res = _.flatMap(paperQuestions, g => {
      return _.map(g.children, q => {
        return {
          title: `${g.displayName}、${q.displayName}`,
          dataIndex: ['quests', `${q.id}`, `score`],
          width: 90,
          align: 'right'
        } as ColumnType<StudentMarkingHistory>
      })
    })
    return res
  }
  //#endregion

  // Preview
  const [previewVisible, setPreviewVisible] = useState(false)
  const [paperMarkingId, setPaperMarkingId] = useState<number>(0)
  const [studentId, setStudentId] = useState<number>(0)
  async function onPreview(item: StudentMarkingHistory) {
    setPaperMarkingId(_.parseInt(query.id))
    setStudentId(item.id)
    setPreviewVisible(true)
  }

  useMount(() => {
    initCurrentMarkingPaper(query.id)
    const q = qs.parse(location.search, { ignoreQueryPrefix: true })
    fetchQuestion(q.new_fanya_paper_id)
  })

  useEffect(() => {
    const reviewEndTime = currentMarkingPaper?.review_end_time
    if (reviewEndTime && moment(reviewEndTime).isBefore(moment())) {
      setReviewBtnVisible(false)
    }
  }, [currentMarkingPaper])

  useEffect(() => {
    if (paperQuestions.length) {
      list.onSearchSubmit({ id: query.id, order: 'desc' })
    }
  }, [paperQuestions])

  const hideStudentInfo = !!currentMarkingPaper?.hide_info

  function onExportClick() {
    requestAndSaveFile(`fanya/paper/${query.id}/students/history/export`, {})
  }

  const renderContent = () => (
    <>
      {loading ? (
        <Spin />
      ) : (
        <Card>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <Form layout={'inline'} onFinish={onSearchSubmit}>
              {!hideStudentInfo ? (
                <Form.Item
                  name={'student_name'}
                  label={'学生姓名'}
                  style={{ width: '250px' }}
                >
                  <Input allowClear />
                </Form.Item>
              ) : (
                <Form.Item
                  name={'student_uid'}
                  label={'考生id'}
                  style={{ width: '250px' }}
                >
                  <Input allowClear />
                </Form.Item>
              )}
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                  搜索
                </Button>
              </Form.Item>
            </Form>
            <Space style={{ marginLeft: 'auto' }}>
              <Button type='primary' onClick={onExportClick}>
                导出批阅历史
              </Button>
              <Button onClick={browserHistory.goBack}>返回</Button>
            </Space>
          </div>

          <Tabs activeKey={tabIndex} onChange={onTabChange}>
            <Tabs.TabPane tab={'全部'} key={-1}></Tabs.TabPane>
            <Tabs.TabPane tab={'已批改'} key={1}></Tabs.TabPane>
            <Tabs.TabPane tab={'未批改'} key={0}></Tabs.TabPane>
          </Tabs>

          <Table<StudentMarkingHistory>
            list={list}
            pagination={pagination}
            scroll={{ x: 'max-content' }}
            indexColumn={{
              fixed: 'left'
            }}
            columns={[
              //@ts-ignore
              ...(() => {
                if (!hideStudentInfo) {
                  return [
                    {
                      title: '姓名',
                      dataIndex: 'student_user_name',
                      render: t => t || '-',
                      align: 'center',
                      width: 120,
                      fixed: 'left'
                    },
                    {
                      title: '学号',
                      dataIndex: 'student_login_name',
                      render: (t, r) => t || r.student_uid || '-',
                      align: 'center',
                      width: 120,
                      fixed: 'left'
                    }
                  ] as any
                }
                return [
                  {
                    title: '考生id',
                    dataIndex: 'student_uid',
                    width: 120,
                    fixed: 'left'
                  }
                ]
              })(),
              {
                title: '判卷时间',
                dataIndex: 'complete_time',
                render: (t, r) =>
                  r.is_complete
                    ? moment(t).format(`${FORMATTER_DATE} ${FORMATTER_TIME}`)
                    : '',
                width: 160,
                sorter: true,
                defaultSortOrder: 'descend'
              },
              ...createQuestionColumns(),
              {
                title: '操作',
                key: 'actions',
                render: (t, r) => (
                  <span>
                    <a onClick={() => onPreview(r)}>查看答卷</a>
                    {reviewBtnVisible && (
                      <>
                        <Divider type={'vertical'} />
                        <a
                          className={'danger'}
                          onClick={() => {
                            history.push(`/marking/${query.id}?spec=${r.id}`)
                          }}
                        >
                          回评
                        </a>
                      </>
                    )}
                  </span>
                ),
                width: 140,
                fixed: 'right'
              }
            ]}
            onChange={handleTableChange}
          />
        </Card>
      )}

      <PreviewMarkingDialog
        id={paperMarkingId}
        studentId={studentId}
        visible={previewVisible}
        title={'查看答卷'}
        onCancel={() => setPreviewVisible(false)}
      />
    </>
  )

  if (!currentMarkingPaper) {
    return <Spin />
  }

  if (APP_SCOPE.isInFanYa) {
    return renderContent()
  }

  return (
    <PageWrapper
      breadcrumb={['在线判卷', '批阅历史']}
      headerExtra={() => <a onClick={history.goBack}>返回上级</a>}
      content={
        currentMarkingPaper && (
          <div>
            <Title level={4} style={{ marginBottom: 8 }}>
              {currentMarkingPaper.name}
            </Title>
            <Text type={'secondary'}>
              <Row gutter={[8, 4]}>
                {currentMarkingPaper.exam_name && (
                  <Col span={6}>所属统考：{currentMarkingPaper.exam_name}</Col>
                )}
                <Col span={6}>
                  需批考题：{currentMarkingPaper.review_count}题
                </Col>
                <Col span={6}>
                  判卷完成：
                  {currentMarkingPaper.review_count ===
                  currentMarkingPaper.complete_review_count ? (
                    <Tag color={'success'}>100%</Tag>
                  ) : (
                    <Tag color={'error'}>
                      {numeral(
                        currentMarkingPaper.complete_review_count /
                          currentMarkingPaper.review_count
                      ).format('0.00%')}
                    </Tag>
                  )}
                </Col>
                <Col span={12}>
                  考试时间：
                  {moment(currentMarkingPaper.review_time).format(
                    `${FORMATTER_DATE} ${FORMATTER_TIME}`
                  )}
                </Col>
              </Row>
            </Text>
          </div>
        )
      }
    >
      {renderContent()}
    </PageWrapper>
  )
}
