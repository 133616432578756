import React, { FC, useMemo, useState } from 'react'
import { Input, Popover, Slider } from 'antd'

export const RateInput: FC<{
  value?: [number, number]
  onChange?: (val: [number, number]) => void
}> = props => {
  const [changed, setChanged] = useState(false)
  const [value, setValue] = useState<[number, number]>(props.value ?? [0, 100])

  const displayText = useMemo(() => {
    if (value[0] === value[1]) {
      return `${value[0]}%`
    }
    return `${value[0]}% - ${value[1]}%`
  }, [value])

  function onChange(val: [number, number]) {
    setValue(val)
    props.onChange?.(val)
  }

  return (
    <>
      <Popover
        title={'选择完成率区间'}
        content={
          <Slider
            range
            defaultValue={value}
            tipFormatter={v => `${v}%`}
            marks={{
              0: '0%',
              100: '100%'
            }}
            style={{ width: 200 }}
            onChange={val => {
              onChange(val as [number, number])
            }}
          />
        }
        trigger={'click'}
        placement={'bottom'}
        onVisibleChange={() => setChanged(true)}
        destroyTooltipOnHide
      >
        <Input
          placeholder={'选择批阅完成率'}
          value={changed ? displayText : ''}
          allowClear
          onChange={e => {
            if (!e.target.value) {
              onChange([0, 100])
              setChanged(false)
            }
          }}
        />
      </Popover>
    </>
  )
}
