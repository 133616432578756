import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import _ from 'lodash'
import { QuestionGroupRender } from 'pages/exam-papers/QuestionGroupRender'
import React, { FC, useEffect, useState } from 'react'
import { Paper } from 'typing'
import { QuestionIdentity } from 'typing.paper'
import {
  getQuestionDisplayName,
  getQuestionPath,
  uniformQuestionCompare
} from 'utils/question'
import { useMarkingSettingContent } from '../../exam-papers/MarkingSettingDialog/useMarkingSettingState'

type SingleValue = QuestionIdentity
type MultiValue = QuestionIdentity[]
type MaybeMultiValue = QuestionIdentity | QuestionIdentity[]

export const QuestionSelect: FC<{
  value?: MaybeMultiValue
  onChange?: (value?: MaybeMultiValue) => void
  disabledIds?: MultiValue
  multi?: boolean
  nesting?: boolean
}> = ({ value, onChange, disabledIds, multi, nesting }) => {
  const { paperQuestions } = useMarkingSettingContent()

  const isMulti = multi
  const isNestingMode = nesting

  const [questions, setQuestions] = useState<MultiValue>([])

  useEffect(() => {
    if (!value) return
    if (isMulti) {
      setQuestions(value as MultiValue)
    } else {
      setQuestions([value as SingleValue])
    }
  }, [isMulti, value])

  function getDisplayName(id: QuestionIdentity) {
    const path = getQuestionPath(paperQuestions, id)
    if (!path) return ''
    return getQuestionDisplayName(path)
  }

  const [visible, setVisible] = useState(false)

  function isDisabled(id: QuestionIdentity) {
    return disabledIds?.some(d => uniformQuestionCompare(d, id))
  }

  function isChecked(id: QuestionIdentity) {
    return questions.some(q => uniformQuestionCompare(q, id))
  }

  function onClick(q: QuestionIdentity) {
    const append = !isChecked(q)
    if (isMulti) {
      if (append) {
        onChange?.([...questions, q])
      } else {
        onChange?.(_.filter(questions, v => !uniformQuestionCompare(v, q)))
      }
    } else {
      onChange?.(q)
      setVisible(false)
    }
  }

  function renderAction({
    question,
    childQuestion,
    disabled
  }: {
    question: Paper.Question
    childQuestion?: Paper.QuestionNestingChildQuestion
    disabled?: boolean
  }) {
    const id: QuestionIdentity = question.id
    return (
      <>
        {disabled ? (
          <Button
            type={'primary'}
            size={'small'}
            shape={'circle'}
            icon={<PlusOutlined />}
            disabled={true}
          />
        ) : (
          <Button
            type={isChecked(id) ? 'primary' : 'default'}
            size={'small'}
            shape={'circle'}
            icon={isChecked(id) ? <CheckOutlined /> : <PlusOutlined />}
            onClick={() => onClick(id)}
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className='field'>
        <div style={{ marginBottom: 8 }}>
          <Button type='primary' onClick={() => setVisible(true)}>
            选择
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center'
          }}
        >
          {_.map(questions, (q, i) => (
            <div
              key={[q[0], q[1], String(i)].filter(Boolean).join('-')}
              data-key={[q[0], q[1], String(i)].filter(Boolean).join('-')}
              className='normal-tag'
            >
              {getDisplayName(q)}
            </div>
          ))}
        </div>
      </div>

      <Modal
        title='选择题目'
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <div className={'select-box'}>
          {paperQuestions.map((g, index) => (
            <QuestionGroupRender
              g={g}
              key={index}
              nesting={isNestingMode}
              renderAction={(q, child) => {
                // Hide parent question checkbox if is nesting mode
                if (
                  isNestingMode &&
                  !child &&
                  (q as Paper.QuestionNesting).children
                ) {
                  return null
                }

                return renderAction({
                  question: q,
                  disabled: isDisabled(q.id)
                })
              }}
              renderGroupAction={() => null}
            ></QuestionGroupRender>
          ))}
        </div>
      </Modal>
    </>
  )
}
