import { Col, InputNumber, Modal, Row, Spin, Typography } from 'antd'
import { ImageDrawer } from 'components/ImageDrawer/ImageDrawer'
import { useDrawerContent } from 'components/ImageDrawer/useDrawerContent'
import { ImageViewer } from 'components/ImageViewer'
import { POST, defaultErrorHandler } from 'core/request'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Paper } from 'typing'

const { Text } = Typography

export const LocalImageDrawerModal: FC<{
  question: Paper.Question
  parentQuestion?: Paper.Question
  url: string
  readonly?: boolean
  onConfirm: (fileUrl: string) => void
  onClose: () => void
  onReset: () => void
}> = props => {
  const questionId = props.question.id
  const parentQuestionId = props.parentQuestion?.id

  const { isCurrentUserHasAuditRole } = useQuestionAnswerDataContent()
  const {
    scoreForm,
    setQuestionScore,
    setNestingQuestionScore,
    getScoreByQuestion
  } = useMarkingForm()

  const [saving, setSaving] = useState(false)

  async function onConfirm(base64File: string) {
    setSaving(true)
    try {
      const { data } = await POST('review/image/upload', {
        data: { image_data: base64File }
      })
      props.onConfirm(data)
    } catch (e) {
      defaultErrorHandler(e as any)
    } finally {
      setSaving(false)
    }
  }

  const questionScore = useMemo(() => {
    return props.question.score
  }, [props.question])

  const currentScore = useMemo(() => {
    const scoreData = getScoreByQuestion(questionId)
    return scoreData ? (scoreData.score as number) : undefined
  }, [scoreForm])

  function setScore(val: number | null) {
    setQuestionScore(questionId as number, val)
  }

  const { getCommentImageListForAudit } = useQuestionAnswerDataContent()
  const teacherList = useMemo(() => {
    return getCommentImageListForAudit(questionId)
  }, [])

  const [imageViewerUrl, setImageViewerUrl] = useState<string | null>(null)
  function onTeacherRecordClick(item) {
    setImageViewerUrl(item.image)
  }

  const { resetToolMode } = useDrawerContent()

  useEffect(() => {
    resetToolMode()
  }, [])

  return (
    <Modal
      className={'image-drawer-dialog'}
      visible={true}
      onCancel={props.onClose}
      footer={null}
      title={
        <Row align='middle' gutter={16}>
          图片批注
          <Row
            align='middle'
            justify='center'
            gutter={8}
            style={{ flex: 1, marginRight: 40 }}
          >
            <Col>
              <Row align={'middle'} justify={'center'} gutter={12}>
                <Col style={{ fontSize: 14, fontWeight: 400 }}>得分</Col>
                <Col>
                  <InputNumber
                    value={currentScore}
                    onChange={(v: any) => setScore(v)}
                    max={questionScore}
                    min={0}
                  />
                </Col>
                <Col>
                  <Text type={'secondary'}>≤{questionScore}</Text>
                </Col>
              </Row>
            </Col>
            {isCurrentUserHasAuditRole && (
              <Col>
                <Row
                  align='middle'
                  gutter={8}
                  style={{ marginLeft: 40, fontSize: 13 }}
                >
                  <Col>查看原始批注：</Col>
                  {!teacherList.length && <Col>无</Col>}
                  {teacherList.map((item, index) => {
                    return (
                      <Col key={index}>
                        <a onClick={() => onTeacherRecordClick(item)}>
                          {item.display_name}
                        </a>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            )}
          </Row>
        </Row>
      }
    >
      <Spin spinning={saving} tip={'正在保存图片'}>
        <ImageDrawer
          key={props.url}
          url={`/image_proxy?url=${props.url.replace('http://', 'https://')}`}
          readonly={props.readonly}
          onCurrentScoreChange={setScore}
          onConfirm={onConfirm}
          onReset={props.onReset}
        />
      </Spin>

      {imageViewerUrl && (
        <ImageViewer
          url={imageViewerUrl}
          onClose={() => setImageViewerUrl(null)}
        />
      )}
    </Modal>
  )
}
