import { useMount } from '@umijs/hooks'
import { Affix, Space } from 'antd'
import classNames from 'classnames'
import { QuestionType } from 'config'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Paper } from 'typing'
import { reversQuestionsToGroupTree } from 'utils/question'
import '../index.less'

export const QuestionIndex: FC = props => {
  const {
    isCurrentUserHasAuditRole,
    findCurrentAuditedQuestionIds
  } = useQuestionAnswerDataContent()

  const {
    paperQuestions,
    markingQuestionsVO: markingQuestions,
    getScoreByQuestion,
    setQuestionIndexHeight,
    scrollToQuestion,

    auditQuestionFilter,
    setAuditQuestionFilter,

    isPartialMarkingMode,
    partialMarkingQuestions
  } = useMarkingForm()

  const [questions, setQuestions] = useState<Paper.QuestionGroup[]>([])

  useEffect(() => {
    // Computed questions
    const groupedQuestions = reversQuestionsToGroupTree(
      paperQuestions,
      markingQuestions
    )

    let result: Paper.QuestionGroup[] = groupedQuestions

    // // Audit mode
    // if (isCurrentUserHasAuditRole && auditQuestionFilter === 'UNAUDIT') {
    //   const auditQuestionIds = findCurrentAuditedQuestionIds()

    //   result = result
    //     .reduce((res, g) => {
    //       g.children = g.children.filter(q => !auditQuestionIds.includes(q.id))
    //       if (g.children.length) res.push(g)
    //       return res
    //     }, [] as Paper.QuestionGroup[])
    //     .filter(g => g.children.length)
    // }

    // // Partial marking mode
    // if (isPartialMarkingMode) {
    //   result = result
    //     .reduce((res, g) => {
    //       g.children = g.children.filter(q =>
    //         partialMarkingQuestions.includes(q.id)
    //       )
    //       if (g.children.length) res.push(g)
    //       return res
    //     }, [] as Paper.QuestionGroup[])
    //     .filter(g => g.children.length)
    // }

    setQuestions(result)
  }, [
    paperQuestions,
    markingQuestions,
    isCurrentUserHasAuditRole,
    findCurrentAuditedQuestionIds,
    auditQuestionFilter,
    isPartialMarkingMode,
    partialMarkingQuestions
  ])

  function isQuestionMarked(q: Paper.Question) {
    const score = getScoreByQuestion(q.id)
    return score && !!_.isNumber(score.score)
  }

  // Affix
  const [isAffix, setAffix] = useState(false)
  function onAffixChange(affixed) {
    setAffix(affixed)
  }

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  function calcHeight() {
    if (wrapperRef.current) {
      setQuestionIndexHeight(wrapperRef.current.getBoundingClientRect().height)
    }
  }

  useMount(() => {
    calcHeight()
  })
  useEffect(() => {
    calcHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, isAffix])

  // events
  function onQuestionClick(id) {
    scrollToQuestion(id)
  }

  return (
    <Affix onChange={onAffixChange}>
      <div
        ref={wrapperRef}
        className={`question-index-wrapper ${isAffix ? 'affixed' : ''}`}
      >
        <div style={{ whiteSpace: 'nowrap' }}>
          题目列表：
          {isCurrentUserHasAuditRole && (
            <Space className='filter' style={{ marginRight: 24 }}>
              <a
                className={classNames({
                  active: auditQuestionFilter === 'ALL'
                })}
                onClick={() => setAuditQuestionFilter('ALL')}
              >
                全部
              </a>
              /
              <a
                className={classNames({
                  active: auditQuestionFilter === 'UNAUDIT'
                })}
                onClick={() => setAuditQuestionFilter('UNAUDIT')}
              >
                待仲裁
              </a>
            </Space>
          )}
        </div>
        {questions.map((g, i) => (
          <div key={i} className={'question-index__group'}>
            <div className={'question-index__group-name'}>
              {g.displayName}、{g.typeNameAlias || QuestionType[g.type]}
            </div>
            <div className={'question-index__question-list'}>
              {g.children.map((q, j) => (
                <div
                  key={j}
                  className={classNames('question-index__item', {
                    touched: isQuestionMarked(q),
                    active: 0
                  })}
                  onClick={() => onQuestionClick(q.id)}
                >
                  {q.displayName}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Affix>
  )
}
