import { Popover } from 'antd'
import React, { FC, useState } from 'react'
import { FontSize, StrokeSize } from './Toolbar'

export const ColorToolbar: FC<{
  sizeMode: 'stroke' | 'font'
  color: string
  size: number
  onColorChange: (color: string) => void
  onSizeChange: (size: number) => void
  onClose: () => void
}> = props => {
  const colors = [
    '#FFFFFF',
    '#000000',
    '#ff0000',
    '#ffff00',
    '#00ff00',
    '#0000ff',
    '#8957a1',
    '#ff00ff'
  ]

  const [forceRender, setForceRender] = useState(0)

  return (
    <div className='color-toolbar'>
      <ul className='menu main'>
        {colors.map(color => (
          <li onClick={() => props.onColorChange(color)}>
            <div
              className={color === props.color ? 'active' : ''}
              style={{ background: color }}
            ></div>
          </li>
        ))}
      </ul>
      <div className='color-width'>
        <Popover
          key={forceRender}
          content={
            <div className='image-drawer-width-popover-content'>
              {(props.sizeMode === 'stroke' ? StrokeSize : FontSize).map(s => (
                <div
                  onClick={() => {
                    props.onSizeChange(s)
                    setForceRender(val => val + 1)
                  }}
                >
                  {s}
                </div>
              ))}
            </div>
          }
        >
          <div className='field'>
            <span>{props.size}</span>
            <img src={require('../../../assets/icon-arrow.png')} />
          </div>
        </Popover>
      </div>
      <div className='spLine'></div>
      <ul className='menu right'>
        <li className='primary' onClick={props.onClose}>
          <img src={require('../images/cancle.png')} />
        </li>
      </ul>
    </div>
  )
}
