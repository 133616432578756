import { getPaginationProps, RemoveConfirm, TableToolbar } from 'components'
import { PageWrapper } from 'components/PageWrapper'
import { Grade, Subject } from 'config'
import { createEdit, createList } from 'core/service'
import { Exam } from 'typing'
import { dateRangeToParams, FORMATTER_DATE } from 'utils'
import { useMount } from '@umijs/hooks'
import { Button, Card, Col, List, Row, Tag } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Edit } from './Edit'
import {
  FieldTimeOutlined,
  LineChartOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'

const { ListProvider, useListContext } = createList<Exam>({
  url: () => '/city/exam'
})

const { EditProvider, useEditContext } = createEdit<Exam>({
  submit: {
    url: '/city/exam',
    parser: data => {
      const { begin_date, end_date } = dateRangeToParams(data._dateRange)
      return { ..._.omit(data, 'dateRange'), begin_date, end_date } as Exam
    }
  },
  remove: {
    url: params => `/city/exam/${params.id}`
  }
})

export { useEditContext }

export default function () {
  return (
    <ListProvider>
      <EditProvider>
        <Page />
      </EditProvider>
    </ListProvider>
  )
}

const Page: FC = () => {
  const list = useListContext()
  const edit = useEditContext()
  edit.connect(list)

  const history = useHistory()

  useMount(() => {
    list.fetch()
  })

  return (
    <PageWrapper breadcrumb={['统考管理']}>
      <Card>
        <TableToolbar>
          <Button type={'primary'} onClick={() => edit.onAdd()}>
            添加考试批次
          </Button>
        </TableToolbar>

        <List<Exam>
          dataSource={list.items}
          loading={list.loading}
          itemLayout={'horizontal'}
          renderItem={item => (
            <List.Item>
              <div className='list-item__content'>
                <div className='list-item__title'>{item.name}</div>
                <div className='list-item__desc'>
                  <Row gutter={[24, 4]}>
                    <Col flex={'35%'}>批次id：{item.code}</Col>
                    <Col flex={'25%'}>考试年级：{Grade[item.grade]}</Col>
                    <Col flex={1}>
                      考试时间：{moment(item.begin_date).format(FORMATTER_DATE)}{' '}
                      至 {moment(item.end_date).format(FORMATTER_DATE)}
                    </Col>
                    <Col flex={'35%'}>试卷数：{item.paper_count}</Col>
                    <Col flex={1}>
                      考试科目：
                      {item.paper_subjects.length
                        ? item.paper_subjects.map(s => Subject[s]).join('、')
                        : '-'}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={'list-item__actions'}>
                <Button
                  type={'primary'}
                  icon={<UnorderedListOutlined />}
                  style={{ marginRight: 8 }}
                  onClick={() => history.push(`/exams/${item.id}/papers`)}
                >
                  试卷管理
                </Button>
                <Button
                  type={'primary'}
                  ghost
                  icon={<FieldTimeOutlined />}
                  style={{ marginRight: 8 }}
                  onClick={() =>
                    history.push(`/exams/${item.id}/marking-report`)
                  }
                >
                  判卷进度
                </Button>
                <Button
                  type={'primary'}
                  ghost
                  icon={<LineChartOutlined />}
                  onClick={() => history.push(`/exams/${item.id}/report`)}
                >
                  成绩统计
                </Button>
                <RemoveConfirm edit={edit} data={item}>
                  <Button type={'link'} danger>
                    删除
                  </Button>
                </RemoveConfirm>
              </div>
            </List.Item>
          )}
          pagination={getPaginationProps(list)}
        />
      </Card>

      <Edit />
    </PageWrapper>
  )
}
