import {
  CloseCircleOutlined,
  RotateLeftOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons'
import { useMount, useUnmount } from '@umijs/hooks'
import { Divider } from 'antd'
import React, { FC, useRef } from 'react'
import Viewer from 'viewerjs'
import './index.less'

export const ImageViewer: FC<{
  url: string
  onClose: () => void
}> = props => {
  const modalRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const viewer = useRef<Viewer | null>(null)

  useMount(() => {
    initViewer()
  })

  useUnmount(() => {
    if (viewer.current) {
      viewer.current.destroy()
    }
  })

  function initViewer() {
    if (!modalRef.current || !imageRef.current) {
      return
    }

    viewer.current = new Viewer(imageRef.current, {
      container: modalRef.current,
      button: false,
      navbar: false,
      title: false,
      toolbar: false,
      minZoomRatio: 0.1,
      maxZoomRatio: 5,
      hide() {
        props.onClose()
      }
    })
    viewer.current.show()
  }

  return (
    <div ref={modalRef} className={'image-viewer-modal'}>
      <img ref={imageRef} src={props.url} alt='' />
      <div className={'toolbar'}>
        <ZoomInOutlined
          onClick={() => viewer.current && viewer.current.zoom(0.5, true)}
        />
        <ZoomOutOutlined
          onClick={() => viewer.current && viewer.current.zoom(-0.5, true)}
        />
        <RotateLeftOutlined
          onClick={() => viewer.current && viewer.current.rotate(-90)}
        />
        <Divider type={'vertical'} />
        <CloseCircleOutlined onClick={() => props.onClose()} />
      </div>
    </div>
  )
}
