import { Button, Col, Modal, Popover, Row, Typography } from 'antd'
import React, { FC } from 'react'
import { Teacher } from 'typing.exam'

const { Text } = Typography

export const ReviewRejectButton: FC<{
  teachers?: Teacher[]
  onConfirm: (teacher: Teacher) => void
  disabled?: boolean
}> = props => {
  const onClickItem = (item: Teacher) => {
    Modal.confirm({
      title: `确认打回给 [${item.display_name}]`,
      onOk: () => {
        props.onConfirm(item)
      }
    })
  }

  const onRandomRejectClick = () => {
    Modal.confirm({
      title: `确认随机打回`,
      onOk: () => {
        const randomTeacher =
          props.teachers?.[Math.floor(Math.random() * props.teachers.length)]
        if (randomTeacher) {
          props.onConfirm(randomTeacher)
        }
      }
    })
  }

  const onRejectClick = () => {
    const item = props.teachers![0]
    Modal.confirm({
      title: `确认打回给 [${item.display_name}]`,
      onOk: () => {
        props.onConfirm(item)
      }
    })
  }

  const content = () => {
    return (
      props.teachers?.length && (
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text strong>选择打回批阅的教师:</Text>
          </div>
          <Row gutter={8} style={{ maxWidth: 300 }}>
            {props.teachers.map(it => (
              <Col span={12} style={{ marginBottom: 8 }}>
                <Button block size='small' onClick={() => onClickItem(it)}>
                  {it.display_name}
                </Button>
              </Col>
            ))}
          </Row>
          <div style={{ marginTop: 0 }}>
            <div style={{ marginBottom: 8 }}>
              <Text>或</Text>
            </div>
            <Button
              type='primary'
              block
              size='small'
              onClick={onRandomRejectClick}
            >
              随机打回
            </Button>
          </div>
        </div>
      )
    )
  }

  if (props.disabled) {
    return (
      <Button
        danger
        block
        style={{
          marginTop: 16
        }}
      >
        已打回
      </Button>
    )
  }

  if (!props.teachers?.length) return null

  if (props.teachers.length === 1) {
    return (
      <Button
        type='primary'
        danger
        block
        style={{
          marginTop: 16
        }}
        onClick={onRejectClick}
      >
        打回
      </Button>
    )
  }

  return (
    <Popover content={content} trigger={'click'}>
      <Button
        type='primary'
        danger
        block
        style={{
          marginTop: 16
        }}
      >
        打回
      </Button>
    </Popover>
  )
}
