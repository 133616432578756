import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import classnames from 'classnames'
import { CurrentQuestionContext } from 'hooks/CurrentQuestionContext'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { QuestionIdentity } from 'typing.paper'
import fabric from './fabric'
import MarkerImageHalfRight from './images/m-halfright.svg'
import MarkerImageRight from './images/m-right.svg'
import MarkerImageWrong from './images/m-wrong.png'
import './style.less'
import { Toolbar } from './toolbar/Toolbar'
import { useDrawerContent } from './useDrawerContent'
import {
  getScoreTextObjects,
  parseScoreTextObjects,
  SignableScoreText
} from './utils'

type ImageDrawerProps = {
  url: string
  readonly?: boolean
  inline?: boolean

  onCurrentScoreChange: (
    value: number | null,
    question?: QuestionIdentity
  ) => void
  onConfirm: (base64File: string) => void
  onReset: () => void
}

export type ImageDrawerResult = {
  file: string
  canvasData?: string
}

export type ImageDrawerAPI = {
  getImageData: () => ImageDrawerResult | null
  rotate: (dir: 'l' | 'r') => void
  undo: () => void
  redo: () => void
  clean: () => void
}

const log = require('debug')('ImageDrawer')

const MarkerImages = {
  'marker-right': MarkerImageRight,
  'marker-wrong': MarkerImageWrong,
  'marker-half-right': MarkerImageHalfRight
}

export const ImageDrawer = forwardRef<ImageDrawerAPI, ImageDrawerProps>(
  (props, ref) => {
    const { scoreForm, getScoreByQuestion } = useMarkingForm()

    const { question, parentQuestion } = useContext(CurrentQuestionContext)

    if (!question) {
      throw new Error('question is required')
    }

    const questionId = question.id
    const parentQuestionId = parentQuestion?.id

    const {
      toolMode,
      resetToolMode,

      color,
      fontSize,
      panSize,
      score,

      scoreSignMode,
      setScoreSignReadonly,

      settingScoreAutoComputeByScoreText,

      scoreMarkerPrefix,
      isScoreMarkerBracketWrapped,

      registerQuestionCanvas,
      unregisterQuestionCanvas,
      computedQuestionScoreByScoreTexts
    } = useDrawerContent()

    const currentScore = useMemo(() => {
      const scoreData = getScoreByQuestion(questionId)
      return scoreData ? (scoreData.score as number) : undefined
    }, [scoreForm])

    const changeCurrentScore = useCallback(
      (score: number | null) => {
        if (_.isNil(question.score)) {
          return
        }

        if (score === null) {
          props.onCurrentScoreChange(null, question.id)
          return
        }

        log('changeCurrentScore: newScore', score)
        props.onCurrentScoreChange(score, question.id)
      },
      [question, props, parentQuestion]
    )

    const updateScoreByScoreText = useCallback(() => {
      if (!settingScoreAutoComputeByScoreText) {
        return
      }
      computedQuestionScoreByScoreTexts(
        questionId,
        question.score,
        newScore => {
          changeCurrentScore(newScore)
        }
      )
    }, [
      changeCurrentScore,
      question.score,
      questionId,
      settingScoreAutoComputeByScoreText,
      computedQuestionScoreByScoreTexts
    ])

    //
    // Fabric
    // @reference: https://meyt.github.io/fabric-betterdocs/index.html
    //
    const containerRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const canvasInstanceRef = useRef<fabric.Canvas | null>(null)
    const [loading, setLoading] = useState(true)

    const bg = useRef<fabric.Image | null>(null)
    const bgAngle = useRef(0)

    const dirty = useRef(false)
    function onChange() {
      dirty.current = true
    }

    // Expose api
    useImperativeHandle(
      ref,
      () => {
        return {
          getImageData() {
            const canvas = canvasInstanceRef.current
            if (!canvas || !dirty.current) return null
            const file = canvas.toDataURL({
              format: 'jpeg'
            })
            const canvasData = JSON.stringify(canvas)
            // console.log(canvas.toJSON())
            return {
              file,
              canvasData
            }
          },
          rotate: rotateCanvas,
          undo: onUndo,
          redo: onRedo,
          clean: initCanvas
        }
      },
      []
    )

    function initCanvas() {
      disposeCanvas()

      // console.log('initCanvas')

      dirty.current = false

      const canvas = new fabric.Canvas(canvasRef.current, {
        preserveObjectStacking: true
      })
      canvasInstanceRef.current = canvas
      registerQuestionCanvas(questionId, canvas)

      canvas.freeDrawingBrush.color = color
      canvas.freeDrawingBrush.width = panSize
      canvas.backgroundColor = '#fff'

      // watch all
      ;[
        'object:added',
        'object:modified',
        'object:removed',
        'path:created'
      ].forEach(event => {
        canvas.on(event, onChange)
      })

      fabric.Image.fromURL(props.url, img => {
        bg.current = img
        renderBackground(img)

        cleanState()
        saveState()
      })
    }

    function disposeCanvas() {
      const canvas = canvasInstanceRef.current
      if (canvas) {
        unregisterQuestionCanvas(questionId, canvas)
        canvas.dispose()
      }
    }

    function renderBackground(img: fabric.Image) {
      const canvas = canvasInstanceRef.current
      if (!canvas) return

      const { width, height, scale } = getBackgroundSize(img)

      canvas.setWidth(width)
      canvas.setHeight(height)
      canvas.setBackgroundImage(
        img,
        () => {
          canvas.renderAll()
        },
        {
          scaleX: scale,
          scaleY: scale,
          originX: 'center',
          originY: 'center',
          ...canvas.getCenter()
        }
      )
      setLoading(false)
    }

    function getContainerSize() {
      const container = containerRef.current
      if (!container) return { width: 0, height: 0 }

      const width = container.clientWidth
      const height = container.clientHeight
      // console.log('container size', width, height)
      return { width, height }
    }

    function getBackgroundSize(img: fabric.Image, rotated = false) {
      let maxWidth = Math.min(window.innerWidth - 30, 1200)
      if (props.inline) {
        const { width: containerWidth } = getContainerSize()
        // width = Math.min(width, containerWidth)
        // height = (width / img.width!) * img.height!
        maxWidth = containerWidth
      }

      let width = img.width!
      let height = img.height!
      if (rotated) {
        ;[width, height] = [height, width]
      }

      const canvasWidth = Math.min(width, maxWidth)
      const canvasHeight = (canvasWidth / width) * height
      const scale = canvasWidth / width
      return { scale, width: canvasWidth, height: canvasHeight }
    }

    //
    // Actions
    //

    function rotateCanvas(dir: 'l' | 'r') {
      const canvas = canvasInstanceRef.current!

      bgAngle.current += dir === 'l' ? -90 : 90
      const isRotate = bgAngle.current % 180 !== 0
      const { width, height, scale } = getBackgroundSize(bg.current!, isRotate)

      // resize canvas
      canvas.setWidth(width)
      canvas.setHeight(height)

      // rotate
      const background = canvas.backgroundImage as fabric.Image
      background.rotate(bgAngle.current)
      background.set(canvas.getCenter())
      background.set({ scaleX: scale, scaleY: scale })

      canvas.renderAll()
    }

    const addTextBox = useCallback(
      (position: fabric.Point) => {
        const canvas = canvasInstanceRef.current!
        const textbox = new fabric.Textbox('输入文字', {
          left: position.x,
          top: position.y,
          fontSize,
          fill: color,
          // fontFamily: 'sans-serif',
          borderColor: 'blue',
          editingBorderColor: 'blue'
        })

        // @ts-ignore
        textbox.set('id', 'TEXT_' + nanoid())

        textbox.setControlsVisibility({
          tl: false,
          bl: false,
          ml: false,
          mr: false,
          mt: false,
          mb: false,
          mtr: false
        })

        canvas.add(textbox)
        canvas.setActiveObject(textbox)
        textbox.enterEditing()
        textbox.selectAll()

        resetToolMode()
      },
      [fontSize, color]
    )

    const addScoreText = useCallback(
      (position: fabric.Point) => {
        if (isNaN(score)) return

        const str = (() => {
          let str = ''
          if (scoreMarkerPrefix) str += scoreMarkerPrefix
          if (isScoreMarkerBracketWrapped) str += '('
          str += scoreSignMode === 'plus' ? '+' : '-'
          str += score
          if (isScoreMarkerBracketWrapped) str += ')'
          return str
        })()

        const text = new fabric.Text(str, {
          // @ts-ignore
          id: nanoid(),
          left: position.x,
          top: position.y,
          fontSize,
          fill: 'red',
          borderColor: 'blue'
        })

        // @ts-ignore
        text.set('id', 'SCORE_' + nanoid())

        text.setControlsVisibility({
          tl: false,
          bl: false,
          ml: false,
          mr: false,
          mt: false,
          mb: false,
          mtr: false
        })

        const canvas = canvasInstanceRef.current!
        canvas.add(text)

        setScoreSignReadonly(true)

        console.log(
          'settingScoreAutoComputeByScoreText',
          settingScoreAutoComputeByScoreText
        )
        if (settingScoreAutoComputeByScoreText) {
          // Mutation score
          // const mutation = scoreSignMode === 'minus' ? -score : score
          // changeCurrentScore(mutation)
          updateScoreByScoreText()
        }
      },
      [
        fontSize,
        score,
        scoreSignMode,
        setScoreSignReadonly,
        settingScoreAutoComputeByScoreText,
        scoreMarkerPrefix,
        isScoreMarkerBracketWrapped,
        updateScoreByScoreText
      ]
    )

    const addMarker = useCallback((img: string, position: fabric.Point) => {
      const canvas = canvasInstanceRef.current!
      fabric.Image.fromURL(img, img => {
        img.set({
          left: position.x,
          top: position.y,
          scaleX: 0.5,
          scaleY: 0.5
        })

        // @ts-ignore
        img.set('id', 'IMG_' + nanoid())

        canvas.add(img)
        canvas.setActiveObject(img)
      })
    }, [])

    const currentLineObject = useRef<fabric.Line>()
    const lineMode = useRef<'default' | 'firstDown'>('default')

    const addLine = useCallback(
      (position: fabric.Point) => {
        const canvas = canvasInstanceRef.current!
        const p = [position.x, position.y, position.x, position.y]
        const line = (currentLineObject.current = new fabric.Line(p, {
          stroke: color,
          strokeWidth: panSize,
          hasControls: false,
          hasBorders: false,
          selectable: false
        }))

        // @ts-ignore
        line.set('id', 'LINE_' + nanoid())

        line.setControlsVisibility({
          tl: false,
          bl: false,
          ml: false,
          mr: false,
          mt: false,
          mb: false,
          mtr: false
        })
        canvas.add(line)
        console.log('add line', line)
      },
      [color, panSize]
    )
    const addLineDone = useCallback((position: fabric.Point) => {
      const canvas = canvasInstanceRef.current!
      const line = currentLineObject.current!
      line.set({ selectable: true, hasControls: true, hasBorders: true })
      canvas.renderAll()

      currentLineObject.current = undefined
    }, [])

    //
    // Events
    //

    const onCanvasClick = useCallback(
      (e: fabric.IEvent) => {
        if (e.target) return

        if (toolMode === 'text') {
          addTextBox(e.pointer!)
        } else if (toolMode === 'line') {
          if (lineMode.current === 'default') {
            lineMode.current = 'firstDown'
            addLine(e.pointer!)
          } else if (lineMode.current === 'firstDown') {
            lineMode.current = 'default'
            addLineDone(e.pointer!)
          }
        } else if (toolMode === 'score') {
          addScoreText(e.pointer!)
        } else if (
          ['marker-right', 'marker-wrong', 'marker-half-right'].includes(
            toolMode
          )
        ) {
          addMarker(MarkerImages[toolMode], e.pointer!)
        }
      },
      [toolMode, addTextBox, addScoreText, addLine, addLineDone, addMarker]
    )

    const onMouseMove = useCallback(
      (e: fabric.IEvent) => {
        const canvas = canvasInstanceRef.current!
        if (toolMode === 'line' && lineMode.current === 'firstDown') {
          const pointer = e.pointer!
          const line = currentLineObject.current
          if (line) {
            line.set({ x2: pointer.x, y2: pointer.y })
            canvas.renderAll()
          }
        }
      },
      [toolMode]
    )

    const onObjectRemoved = useCallback(
      e => {
        if (preventEvents.current) {
          return
        }

        saveState()

        if (settingScoreAutoComputeByScoreText) {
          // const obj = e.target
          // console.log(obj)
          // if (obj.id && obj.id.startsWith('SCORE_') && obj.text) {
          //   const num = scoreTextToNumber(obj.text)
          //   changeCurrentScore(num * -1)
          // }
          updateScoreByScoreText()
        }

        setScoreSignReadonly(!!getCurrentScoreTextObjects().length)
      },
      [
        setScoreSignReadonly,
        settingScoreAutoComputeByScoreText,
        updateScoreByScoreText
      ]
    )

    useLayoutEffect(() => {
      setTimeout(() => initCanvas())
    }, [props.url])

    useEffect(() => {
      return () => disposeCanvas()
    }, [])

    useEffect(() => {
      const canvas = canvasInstanceRef.current
      if (!canvas) return
      canvas.on('mouse:down', onCanvasClick)
      canvas.on('mouse:move', onMouseMove)
      canvas.on('object:removed', onObjectRemoved)
      canvas.on('object:added', onObjectAdd)
      canvas.on('object:modified', onObjectModified)
      return () => {
        canvas.off('mouse:down', onCanvasClick)
        canvas.off('mouse:move', onMouseMove)
        canvas.off('object:removed', onObjectRemoved)
        canvas.off('object:added', onObjectAdd)
        canvas.off('object:modified', onObjectModified)
      }
    }, [canvasInstanceRef, onCanvasClick, onMouseMove, onObjectRemoved])

    // tool mode
    useEffect(() => {
      const canvas = canvasInstanceRef.current
      if (!canvas) return
      if (toolMode === 'default') {
        canvas.isDrawingMode = false
        onPathAdded()
      }
      if (toolMode === 'pan') {
        canvas.isDrawingMode = true
      }
    }, [toolMode, canvasInstanceRef])

    // color
    useEffect(() => {
      const canvas = canvasInstanceRef.current
      if (!canvas) return
      canvas.freeDrawingBrush.color = color
    }, [color, canvasInstanceRef])

    // pan size
    useEffect(() => {
      const canvas = canvasInstanceRef.current
      if (!canvas) return
      canvas.freeDrawingBrush.width = panSize
    }, [panSize, canvasInstanceRef])

    function onSave() {
      if (settingScoreAutoComputeByScoreText) {
        if (!validateScore()) {
          Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '分数批注的计算结果可能与当前得分不一致，请检查',
            onOk: doSave,
            okText: '继续保存'
          })
        } else {
          doSave()
        }
        return
      }

      doSave()
    }

    function doSave() {
      const canvas = canvasInstanceRef.current!
      const data = canvas.toDataURL({
        format: 'png'
      })
      props.onConfirm(data)

      // TODO: serialize canvas
      // const json = JSON.stringify(canvas)
      // console.log(json)
    }

    // 计算文本对象的分数，与当前得分比较
    function validateScore() {
      const scoreTexts = getScoreText()

      if (!scoreTexts.length) return true
      if (scoreTexts.some(text => isNaN(text.value.value))) return true
      if (_.isNil(currentScore)) return true

      const sumOfTexts = scoreTexts.reduce((sum, it) => sum + it.value.value, 0)

      const expectScore =
        scoreSignMode === 'plus' ? sumOfTexts : question!.score + sumOfTexts

      if (expectScore !== currentScore) {
        return false
      }

      return true
    }

    //
    // Undo
    //
    const preventEvents = useRef(false)
    const undoStack = useRef<string[]>([])
    const stackIndex = useRef(0)

    function onObjectAdd(e) {
      if (preventEvents.current) {
        return
      }

      console.log('onObjectAdd', e)
      saveState()
    }
    function onObjectModified(e) {
      if (preventEvents.current) {
        return
      }

      console.log('onObjectModified', e)
      saveState()
    }

    function cleanState() {
      undoStack.current = []
      stackIndex.current = 0
    }

    function saveState() {
      const canvas = canvasInstanceRef.current!

      // check stackIndex
      // remove all after stackIndex
      if (stackIndex.current < undoStack.current.length - 1) {
        undoStack.current.splice(stackIndex.current + 1)
      }
      undoStack.current.push(canvas.toJSON())
      stackIndex.current = undoStack.current.length - 1

      // console.log('saveState', stackIndex.current, undoStack.current)
    }

    const undo = useCallback(() => {
      const canvas = canvasInstanceRef.current!
      const index = stackIndex.current - 1
      const prev = undoStack.current[index]
      // console.log('undo', index, prev)

      if (prev) {
        preventEvents.current = true
        stackIndex.current = index

        canvas.clear()
        canvas.loadFromJSON(prev, () => {
          canvas.renderAll()
          preventEvents.current = false
        })

        setTimeout(updateScoreByScoreText, 100)
      }
    }, [updateScoreByScoreText])

    const redo = useCallback(() => {
      const canvas = canvasInstanceRef.current!
      const index = stackIndex.current + 1
      const next = undoStack.current[index]
      if (next) {
        preventEvents.current = true
        stackIndex.current = index

        canvas.clear()
        canvas.loadFromJSON(next, () => {
          canvas.renderAll()
          preventEvents.current = false
        })

        setTimeout(updateScoreByScoreText, 100)
      }
    }, [updateScoreByScoreText])

    function onUndo() {
      if (stackIndex.current <= 0) {
        Modal.confirm({
          title: '撤销全部批注',
          content: '将还原为学生的原始作答',
          onOk: () => {
            cleanState()
            props.onReset()

            if (question) {
              // props.onCurrentScoreChange(null, question.id)
              updateScoreByScoreText()
            }
          }
        })
        return
      }
      undo()
    }
    function onRedo() {
      redo()
    }

    //
    // Tools
    //

    function onPathAdded() {
      const canvas = canvasInstanceRef.current!
      const paths = canvas.getObjects().filter(obj => obj.type === 'path')
      paths.forEach(path => {
        // @ts-ignore
        if (!path.id) path.set('id', 'PATH_' + nanoid())
      })

      canvas.renderAll()
    }

    function getCurrentScoreTextObjects(): fabric.Text[] {
      return getScoreTextObjects(canvasInstanceRef.current!)
    }

    function getScoreText(): {
      id: string
      text: string
      value: SignableScoreText
    }[] {
      const texts = getCurrentScoreTextObjects()
      return parseScoreTextObjects(texts)
    }

    const showToolbar = useMemo(() => {
      if (props.inline || props.readonly) return false
      return !loading
    }, [loading, props.inline, props.readonly])

    return (
      <div
        className={classnames({
          'image-drawer': true,
          inline: props.inline
        })}
        ref={containerRef}
      >
        <div className='image-drawer-scroll'>
          <canvas className='image-container' ref={canvasRef}></canvas>
        </div>
        {!showToolbar ? null : (
          <>
            <Toolbar
              onRotate={rotateCanvas}
              onSave={onSave}
              onUndo={onUndo}
              onRedo={onRedo}
              maxScore={question.score}
            />
          </>
        )}
      </div>
    )
  }
)
