import constate from 'constate'
import { GET } from 'core/request'
import { useState } from 'react'
import { Report } from 'typing'

const hook = () => {
  const [summary, setSummary] = useState<Report.ExamPaperSummaryData>()

  async function initSummary(
    entryType: Report.EntryType,
    entryId: number,
    schoolId?: number,
    classId?: number
  ) {
    const { data } = await GET(
      entryType === 'exam'
        ? '/city/exam/statistic/summary'
        : '/city/exam/paper/statistic/summary',
      {
        data: {
          [entryType === 'exam' ? 'exam_id' : 'exam_paper_id']: entryId,
          fid: schoolId,
          statistic_class_id: classId
        }
      }
    )
    setSummary(data)
  }

  return {
    summary,
    initSummary
  }
}

const [
  ExamPaperReportSummaryProvider,
  useExamPaperReportSummaryContent
] = constate(hook)
ExamPaperReportSummaryProvider.displayName = 'ExamPaperReportSummaryProvider'

export { ExamPaperReportSummaryProvider, useExamPaperReportSummaryContent }
