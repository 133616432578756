import { Search, Table } from 'components'
import { ModalSizeLarge } from 'config'
import { createList } from 'core/service'
import { Teacher } from 'typing'
import { Form, Input, Modal, Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useMarkingSettingContent } from '../useMarkingSettingState'
import _ from 'lodash'
import { TableSelectAlert } from 'components/Table/TableSelectAlert'

interface TeacherSelectDialogProps {
  visible: boolean
  onConfirm: (data: Teacher[]) => void
  onCancel: () => void
}

interface SearchFormData {
  fid: number
  keyword: string
}

const {
  ListProvider: TeacherListProvider,
  useListContext: useTeacherListContext
} = createList<Teacher, SearchFormData>({
  url: () => '/fanya/teachers',
  parseFetchedData: data => {
    const items = _.map(data.items, item => {
      return {
        ...item,
        fid: _.parseInt(item.fid) || item.fid,
        uid: _.parseInt(item.uid) || item.uid
      }
    })
    const total = data.count
    return {
      items,
      total
    }
  }
})

const Dialog: FC<TeacherSelectDialogProps> = props => {
  const [selectedItems, setSelectedItems] = useState<Teacher[]>([])

  return (
    <Modal
      visible={props.visible}
      title={'添加判卷人员'}
      width={ModalSizeLarge}
      destroyOnClose
      onOk={() => props.onConfirm(selectedItems)}
      onCancel={props.onCancel}
    >
      <TeacherListProvider>
        <TableSelectAlert data={selectedItems} textKey={'display_name'} />
        <List setSelectedItems={setSelectedItems} />
      </TeacherListProvider>
    </Modal>
  )
}

const List: FC<{ setSelectedItems }> = ({ setSelectedItems }) => {
  const { schoolList, findSchool } = useMarkingSettingContent()
  const list = useTeacherListContext()
  const [form] = Form.useForm()

  function onSchoolChange(fid) {
    list.search.fid = fid
    list.fetch()
  }

  function onSelectRow(rows: Teacher[]) {
    const newRows = _.map(rows, r => ({
      ...r,
      school: findSchool(r.fid)
    }))
    setSelectedItems(newRows)
  }

  useEffect(() => {
    if (schoolList.length === 1) {
      const d = _.first(schoolList)!
      form.setFieldsValue({ fid: d.fid })
      onSchoolChange(d.fid)
    }
  }, [schoolList])

  return (
    <div>
      <Search list={list} form={form} showReset={false}>
        <Form.Item label={'学校'} name={'fid'}>
          <Select style={{ width: 180 }} onChange={onSchoolChange}>
            {schoolList.map(s => (
              <Select.Option key={s.id} value={s.fid}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'姓名'} name={'keyword'}>
          <Input allowClear />
        </Form.Item>
      </Search>

      <Table<Teacher>
        rowKey={'uid'}
        list={list}
        size={'small'}
        columns={[
          { title: '教师', dataIndex: 'display_name' },
          { title: '工号', dataIndex: 'name' },
          {
            title: '手机号',
            dataIndex: 'tel',
            render: t => (t === '0' ? '-' : t)
          }
        ]}
        onSelectRow={onSelectRow}
      />
    </div>
  )
}

export const TeacherSelectDialog: FC<TeacherSelectDialogProps> = props => (
  <TeacherListProvider>
    <Dialog {...props} />
  </TeacherListProvider>
)
