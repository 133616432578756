import {
  Button,
  Col,
  Divider,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message
} from 'antd'
import classnames from 'classnames'
import { PageWrapper } from 'components/PageWrapper'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { APP_SCOPE, redirectToFanYa } from '../../../config'
import { QuestionIndex } from '../components/QuestionIndex'
import { SettingPopover } from '../components/SettingPopover'
import { StudentSearch } from '../components/StudentSearch'
import { QuestionList } from '../question-list/QuestionList'

const { Text } = Typography

export const MarkingReviewForm: FC = props => {
  const query = useParams<{ id }>()
  const history = useHistory()

  const {
    currentUser,
    isCurrentUserHasReviewRole
  } = useQuestionAnswerDataContent()

  const {
    isInitialized,
    loading,
    getSpecMarkingId,
    getMarkingIndex,

    markingPaperId,
    setMarkingPaperId,
    markingPaper,
    currentStudentIndex,
    setCurrentStudentIndex,
    studentList,

    currentStudent,
    gotoPrevStudent,
    pageReload
  } = useMarkingForm()

  useEffect(() => {
    setMarkingPaperId(_.parseInt(query.id))
  }, [])

  // one based page
  const [page, setPage] = useState<number>()

  // one based page
  const [resumePage, setResumePage] = useLocalStorage(
    `review_mode_user${currentUser?.uid}_exam${markingPaperId}_page`,
    1
  )

  useEffect(() => {
    // Sync index to local page
    const index = getMarkingIndex()
    if (index !== null) {
      setResumePage(index + 1)
    }
  }, [])

  useEffect(() => {
    if (!isInitialized) return
    if (getMarkingIndex() !== null || getSpecMarkingId() !== null) return

    // Set default index
    if (isCurrentUserHasReviewRole) {
      setCurrentStudentIndex(0)
    }
  }, [isInitialized, isCurrentUserHasReviewRole])

  // Detect resume page
  useEffect(() => {
    if (getMarkingIndex() !== null || getSpecMarkingId() !== null) return

    if (resumePage > 1 && resumePage !== currentStudentIndex! + 1) {
      setTimeout(() => {
        message.info(
          <>
            <Space>
              上次批阅到第<span className={'primary'}>{resumePage}</span>份试卷,
              <a onClick={() => pageReload(resumePage - 1)}>点击前往</a>
            </Space>
          </>,
          15
        )
      }, 1000)
    }
  }, [])

  function onBackClick() {
    if (APP_SCOPE.isInFanYa) {
      redirectToFanYa()
    } else {
      getSpecMarkingId() ? history.goBack() : history.replace('/marking')
    }
  }

  function onSubmit() {
    if (isLastIndexOfStudentList()) {
      Modal.info({
        title: '提示',
        content: '已经是最后一份答卷'
      })
      return
    }
    onPagerNextClick()
  }

  function onGoto() {
    if (typeof page === 'number') pageReload(page - 1)
  }

  const isPagerPrevDisabled = useMemo(() => {
    return currentStudentIndex === 0
  }, [currentStudentIndex])

  const isPagerNextDisabled = useMemo(() => {
    if (isLastIndexOfStudentList()) return true
  }, [isLastIndexOfStudentList])

  function isLastIndexOfStudentList() {
    return currentStudentIndex! >= studentList.length - 1
  }

  function isCurrentStudentMarked() {
    const student = studentList[currentStudentIndex!]
    if (!student) return false
    return !!student.is_complete
  }

  function onPagerPrevClick() {
    if (currentStudentIndex! <= 0) {
      message.info('已经是第一份答卷')
      return
    }
    gotoPrevStudent()
  }
  function onPagerNextClick() {
    if (isLastIndexOfStudentList()) {
      message.info('已经是最后一份答卷')
      return
    }
    pageReload(Math.min(currentStudentIndex! + 1, studentList.length - 1))
  }

  return (
    <PageWrapper
      content={
        <div>
          <Row style={{ marginBottom: 16 }}>
            <Col flex={'auto'}>
              <Space>
                <Text strong style={{ fontSize: 16 }}>
                  {markingPaper && markingPaper.name}
                </Text>
                <Tag color='red'>督察模式</Tag>
              </Space>
            </Col>
            <Col>
              <Button
                type={'primary'}
                size={'small'}
                onClick={() => onBackClick()}
              >
                退出阅卷
              </Button>
            </Col>
          </Row>

          <Row justify='space-between'>
            <Col>
              <Space>
                <Text type={'secondary'} className='page-info'>
                  共 <span className={'primary'}>{studentList.length}</span>{' '}
                  份试卷， 当前批阅第{' '}
                  <span className={'primary'}>
                    {typeof currentStudentIndex === 'number' &&
                      currentStudentIndex + 1}
                  </span>{' '}
                  份试卷
                </Text>
                {isCurrentStudentMarked() ? (
                  <Tag color='success'>教师已批阅</Tag>
                ) : (
                  <Tag color='error'>教师未批阅</Tag>
                )}
              </Space>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Space className='pager'>
                <a
                  className={classnames({
                    'ant-btn': true,
                    'ant-btn-default': true,
                    'ant-btn-sm': true,
                    'pager-prev': true,
                    disabled: isPagerPrevDisabled
                  })}
                  onClick={onPagerPrevClick}
                >
                  上一份
                </a>
                <a
                  className={classnames({
                    'ant-btn': true,
                    'ant-btn-default': true,
                    'ant-btn-sm': true,
                    'pager-next': true,
                    disabled: isPagerNextDisabled
                  })}
                  onClick={onPagerNextClick}
                >
                  下一份
                </a>
                <Space style={{ marginLeft: 8 }}>
                  <InputNumber
                    value={page}
                    onChange={e => setPage(typeof e === 'number' ? e : 1)}
                    min={1}
                    max={studentList.length}
                    size='small'
                    style={{ width: 80 }}
                    placeholder='输入页码'
                  />
                  <Button size='small' onClick={onGoto}>
                    前往
                  </Button>
                </Space>
              </Space>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Space>
                <StudentSearch />
                <SettingPopover />
              </Space>
            </Col>
          </Row>

          <Divider style={{ margin: '12px 0' }} />

          {markingPaper && !markingPaper.hide_info && currentStudent && (
            <Space
              className={'student-info'}
              size={'large'}
              style={{ marginBottom: 5 }}
            >
              <span>学号: {currentStudent.student_login_name || '-'}</span>
              <span>姓名: {currentStudent.student_user_name || '-'}</span>
            </Space>
          )}

          <QuestionIndex />
        </div>
      }
    >
      {loading ? (
        <Spin />
      ) : (
        <div>
          <QuestionList />
          <div className={'footer'}>
            <Button
              type={'primary'}
              size={'large'}
              shape={'round'}
              onClick={onSubmit}
            >
              下一份
            </Button>
          </div>
        </div>
      )}
    </PageWrapper>
  )
}
