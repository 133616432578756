import React, { FC } from 'react'
import { Button, Col, List, Popover, Row } from 'antd'
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { TableColumnControlItem } from './useTableColumnControl'

export const TableColumnControl: FC<{
  columns: TableColumnControlItem[]
  onChange: (columns: TableColumnControlItem[]) => void
  onReset: () => void
}> = props => {
  const content = (
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <Row justify={'end'}>
        <Col>
          <Button size={'small'} type={'link'} onClick={props.onReset}>
            重置
          </Button>
        </Col>
      </Row>
      <List
        dataSource={props.columns}
        renderItem={item => (
          <List.Item
            key={item.dataIndex}
            actions={[
              <Button
                type={'text'}
                icon={item.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                size={'small'}
                onClick={() => onClick(item)}
              ></Button>
            ]}
            style={{ padding: '5px 0' }}
          >
            <span style={{ color: item.visible ? '#333' : '#999' }}>
              {item.title}
            </span>
          </List.Item>
        )}
        size={'small'}
      />
    </div>
  )

  function onClick(item: TableColumnControlItem) {
    props.onChange(
      props.columns?.map(it => ({
        ...it,
        visible: it.dataIndex === item.dataIndex ? !item.visible : it.visible
      }))
    )
  }

  return (
    <Popover content={content} placement={'bottomRight'} trigger={'click'}>
      <Button size='small' icon={<SettingOutlined />}>
        设置表格项
      </Button>
    </Popover>
  )
}
