import React, { FC } from 'react'
import { Modal, Progress, Space, Spin, Table, Typography } from 'antd'
import { useEditContext } from '.'
import { ModalSizeLarge } from 'config'
import moment from 'moment'
import { FORMATTER_DATE, FORMATTER_TIME } from 'utils'
import { Paper } from 'typing'

const { Text } = Typography

export const PaperReport: FC = props => {
  const edit = useEditContext()

  return (
    <Modal
      visible={edit.visible}
      title={edit.params ? edit.params.name : ''}
      width={ModalSizeLarge}
      onCancel={edit.onCancel}
      footer={false}
    >
      {edit.loading || !edit.params ? (
        <Spin />
      ) : (
        <div>
          <Space size={'large'} style={{ marginBottom: 16 }}>
            <Text type={'secondary'}>分值：{edit.params.score}</Text>
            <Text type={'secondary'}>
              判卷员数：{edit.params.teacher_count}
            </Text>
            <Text type={'secondary'}>
              答卷总数：{edit.params.paper_student_count}
            </Text>
            <Text type={'secondary'}>
              判卷开始时间：
              {moment(edit.params.review_time).format(
                `${FORMATTER_DATE} ${FORMATTER_TIME}`
              )}
            </Text>
          </Space>

          <Table<any>
            dataSource={edit.teachers}
            rowKey={'id'}
            pagination={false}
            columns={[
              {
                title: '序号',
                render: (t, r, i) => i + 1
              },
              { title: '判卷员', dataIndex: 'display_name' },
              {
                title: '完成进度',
                dataIndex: 'type',
                render: (t, r) => (
                  <Progress
                    percent={
                      Math.floor(
                        (r.student_complete_count / r.distribute_count) *
                          100 *
                          100
                      ) / 100
                    }
                    strokeWidth={6}
                    style={{ width: 140 }}
                  />
                )
              },
              {
                title: '答卷总数',
                dataIndex: 'distribute_count',
                align: 'right'
              },
              {
                title: '完成判卷数',
                dataIndex: 'student_complete_count',
                align: 'right'
              },
              {
                title: '未完成判卷数',
                dataIndex: 'student_not_complete_count',
                align: 'right'
              }
            ]}
          />
        </div>
      )}
    </Modal>
  )
}
