import constate from 'constate'
import _ from 'lodash'
import { Initializer } from 'pages/exam-papers/MarkingSettingDialog/Initializer'
import * as React from 'react'
import { FC, useState } from 'react'
import { useList } from 'react-use'
import { Paper, QuestionBindGroup, Teacher } from 'typing'

export const getQuestionBindGroupDefaultValue = (): QuestionBindGroup[] => [
  { questions: [], teachers: [], groupedTeachers: [], _id: Date.now() },
  { questions: [], teachers: [], groupedTeachers: [], _id: Date.now() }
]

const QuestionBindGroupHook = () => {
  const [questionBindGroup, questionBindGroupSetter] = useList<
    QuestionBindGroup
  >(getQuestionBindGroupDefaultValue())
  return {
    questionBindGroup,
    questionBindGroupSetter
  }
}
const [BindGroupProvider, useQuestionBindGroupContent] = constate(
  QuestionBindGroupHook
)

// BindQuestion
const BindQuestionHook = () => {
  const {
    questionBindGroup,
    questionBindGroupSetter
  } = useQuestionBindGroupContent()

  const [visible, setVisible] = useState(false)

  // 编辑入参
  const [editParams, setEditParams] = useState<{
    index: number
    questionBindGroup: QuestionBindGroup
  }>({} as any)

  // 编辑
  function onBindingQuestionEdit(groupIndex: number) {
    setEditParams({
      index: groupIndex,
      questionBindGroup: _.cloneDeep(questionBindGroup[groupIndex])
    })
    setVisible(true)
  }

  // 提交
  function onBindingQuestionEditSubmit(items: Paper.Question[]) {
    console.debug('onBindingQuestionEditSubmit', items)
    questionBindGroupSetter.updateAt(editParams.index, {
      ...questionBindGroup[editParams.index],
      questions: items
    })
  }

  return {
    visible,
    setVisible,
    editParams,
    onBindingQuestionEdit,
    onBindingQuestionEditSubmit
  }
}
const [BindQuestionProvider, useBindQuestionContent] = constate(
  BindQuestionHook
)

// BindTeacher
const BindTeacherHook = () => {
  const {
    questionBindGroup,
    questionBindGroupSetter
  } = useQuestionBindGroupContent()

  const [visible, setVisible] = useState(false)

  // 绑定入参
  const [editParams, setEditParams] = useState<{
    // 题组索引
    index: number
    // 题组內联的教师组索引
    innerTeacherGroupIndex?: number
    questionBindGroup: QuestionBindGroup
  }>({} as any)

  // 编辑
  function onBindingTeacherEdit(
    questionGroupIndex: number,
    teacherGroupIndex?: number
  ) {
    setEditParams({
      index: questionGroupIndex,
      innerTeacherGroupIndex: teacherGroupIndex,
      questionBindGroup: _.cloneDeep(questionBindGroup[questionGroupIndex])
    })
    setVisible(true)
  }

  // 提交
  function onBindingTeacherEditSubmit(items: Teacher[]) {
    if (typeof editParams.innerTeacherGroupIndex === 'number') {
      const questionGroup = questionBindGroup[editParams.index]

      // Find and reassign the teacher group
      questionGroup.groupedTeachers![editParams.innerTeacherGroupIndex] = items
      questionBindGroupSetter.updateAt(editParams.index, questionGroup)
      return
    }

    questionBindGroupSetter.updateAt(editParams.index, {
      ...questionBindGroup[editParams.index],
      teachers: items
    })
  }

  // 內联教师组操作
  function onAddInnerTeacherGroup(questionGroupIndex: number) {
    const teacherGroup =
      questionBindGroup[questionGroupIndex].groupedTeachers || []
    const newTeacherGroup = teacherGroup.concat([[]])
    questionBindGroupSetter.updateAt(questionGroupIndex, {
      ...questionBindGroup[questionGroupIndex],
      groupedTeachers: newTeacherGroup
    })
  }
  function onRemoveInnerTeacherGroup(
    questionGroupIndex: number,
    teacherGroupIndex: number
  ) {
    const teacherGroup = questionBindGroup[questionGroupIndex].groupedTeachers
    if (!teacherGroup) return

    _.pullAt(teacherGroup, teacherGroupIndex)

    questionBindGroupSetter.updateAt(questionGroupIndex, {
      ...questionBindGroup[questionGroupIndex],
      groupedTeachers: teacherGroup
    })
  }

  return {
    visible,
    setVisible,
    editParams,
    onBindingTeacherEdit,
    onBindingTeacherEditSubmit,
    //
    onAddInnerTeacherGroup,
    onRemoveInnerTeacherGroup
  }
}
const [BindTeacherProvider, useBindTeacherContent] = constate(BindTeacherHook)

const QuestionBindGroupProvider: FC = props => (
  <BindGroupProvider>
    <BindQuestionProvider>
      <BindTeacherProvider>
        <Initializer>{props.children}</Initializer>
      </BindTeacherProvider>
    </BindQuestionProvider>
  </BindGroupProvider>
)
export {
  QuestionBindGroupProvider,
  useQuestionBindGroupContent,
  useBindQuestionContent,
  useBindTeacherContent
}
