import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Empty, List, Modal, Row, Tag } from 'antd'
import { ModalSizeLarge } from 'config'
import _ from 'lodash'
import React, { FC, ReactNode, useEffect } from 'react'
import { useList } from 'react-use'
import { Teacher } from 'typing'
import { useMarkingSettingContent } from '../useMarkingSettingState'
import {
  useBindTeacherContent,
  useQuestionBindGroupContent
} from '../useQuestionBindGroup'

interface BindingTeacherDialogProps {
  onConfirm: (items: Teacher[]) => void
}

export const BindingTeacherDialog: FC<BindingTeacherDialogProps> = props => {
  const { questionBindGroup } = useQuestionBindGroupContent()
  const {
    visible,
    setVisible,
    editParams,
    onBindingTeacherEditSubmit
  } = useBindTeacherContent()

  const { teacherList } = useMarkingSettingContent()

  const [selectedItems, { set: setSelectedItems, push, removeAt }] = useList<
    Teacher
  >([])

  function uniquePush(item: Teacher) {
    if (!_.find(selectedItems, { uid: item.uid })) {
      push(item)
    }
  }

  useEffect(() => {
    if (visible && editParams) {
      setSelectedItems(
        // Find teacher from inner group or questionBindGroup
        typeof editParams.innerTeacherGroupIndex === 'number'
          ? editParams.questionBindGroup.groupedTeachers?.[
              editParams.innerTeacherGroupIndex
            ] ?? []
          : editParams.questionBindGroup.teachers
      )
    }
  }, [visible, editParams])

  function isSelected(item: Teacher) {
    const outerSelected: Teacher[] = _.flatMap(
      questionBindGroup,
      (g, questionGroupIndex) => {
        // For inner teacher group case
        if (typeof editParams.innerTeacherGroupIndex === 'number') {
          // Exclude teacher in current inner teacher group
          return _.flatten(
            _.filter(g.groupedTeachers, (t, teacherGroupIndex) => {
              if (questionGroupIndex !== editParams.index) {
                return true
              }
              return teacherGroupIndex !== editParams.innerTeacherGroupIndex
            })
          )
        } else {
          // Exclude teacher in current questionBindGroup
          if (questionGroupIndex === editParams.index) return []
          return g.teachers
        }
      }
    )
    return !!_.find(_.concat(outerSelected, selectedItems), {
      uid: item.uid
    })
  }

  function confirm() {
    onBindingTeacherEditSubmit(selectedItems)
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={'题组判卷人员'}
      width={ModalSizeLarge}
      destroyOnClose
      onOk={() => confirm()}
      onCancel={() => setVisible(false)}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Card
            size={'small'}
            title={'选择判卷人员'}
            style={{ minHeight: 450 }}
          >
            <div className={'select-box'}>
              <TeacherSelectedRender
                teacherItems={teacherList}
                renderTitle={t =>
                  isSelected(t) ? (
                    <>
                      {t.display_name}
                      <Tag color='green'>已分配</Tag>
                    </>
                  ) : (
                    t.display_name
                  )
                }
                renderAction={t => (
                  <Button
                    type={'primary'}
                    size={'small'}
                    shape={'circle'}
                    icon={<PlusOutlined />}
                    onClick={() => uniquePush(t)}
                  />
                )}
              />
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            size={'small'}
            title={'已选择判卷人员'}
            style={{ minHeight: 450 }}
          >
            <div className={'select-box'}>
              <TeacherSelectedRender
                teacherItems={selectedItems}
                renderAction={(t, i) => (
                  <Button
                    type={'primary'}
                    danger
                    size={'small'}
                    shape={'circle'}
                    icon={<MinusOutlined />}
                    onClick={() => removeAt(i)}
                  />
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}

const TeacherSelectedRender: FC<{
  teacherItems: Teacher[]
  renderAction: (t: Teacher, i: number) => ReactNode
  renderTitle?: (t: Teacher) => ReactNode
}> = ({ teacherItems, renderAction, renderTitle }) => {
  const { findSchool } = useMarkingSettingContent()

  if (!teacherItems.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  return (
    <>
      {teacherItems.map((t, i) => (
        <div key={t.uid} className={'select-box__item'}>
          <Row align={'middle'} style={{ flexWrap: 'nowrap' }}>
            <Col flex={'auto'}>
              <List.Item.Meta
                title={renderTitle ? renderTitle(t) : t.display_name}
                description={`${findSchool(t.fid).name} / ${t.name || t.tel}`}
              />
            </Col>
            {renderAction(t, i)}
          </Row>
          <Divider style={{ margin: '8px 0' }} />
        </div>
      ))}
    </>
  )
}
