import { Col, InputNumber, Row, Tag, Typography } from 'antd'
import Button from 'antd/es/button'
import { QuestionType } from 'config'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Paper } from 'typing'
import { splitNumberIntoBucket } from 'utils/question'
import { useScoreFormNavigation } from '../useScoreFormNavigation'

const { Text } = Typography

export const ScoreFormForT2Group: FC<{
  group: Paper.Question[]
  questionGroup: Paper.QuestionGroup
}> = ({ group, questionGroup }) => {
  return (
    <div id={`score-form_${group[0].id}`} className='score-form'>
      <div className={'header'}>
        <div className={'title'}>
          {questionGroup && (
            <div className={'title__group'}>
              {questionGroup.displayName}、
              {questionGroup.typeNameAlias || QuestionType[questionGroup.type]}
            </div>
          )}
        </div>
      </div>
      <ScoreFormItem group={group} />
    </div>
  )
}

const ScoreFormItem: FC<{
  group: Paper.Question[]
}> = ({ group }) => {
  const { scoreForm, getScoreByQuestion, setQuestionScore } = useMarkingForm()
  const {
    isCurrentUserHasAuditRole,
    findMarkingDataForAudit,
    markingDataForAudit
  } = useQuestionAnswerDataContent()

  const { emitter } = useScoreFormNavigation()

  const [score, setScore] = useState<number>()

  const scoreMax = useMemo(() => {
    return group.reduce((acc, cur) => acc + cur.score, 0)
  }, [group])

  function _setScore(val) {
    const chunk = splitNumberIntoBucket(
      val,
      group.map(q => q.score)
    )
    _.forEach(chunk, (v, i) => {
      setQuestionScore(group[i].id, v)
    })
  }

  // init group score
  useEffect(() => {
    const scores = group.map(question => {
      const scoreData = getScoreByQuestion(question.id)
      return scoreData?.score ?? undefined
    })

    if (_.every(scores, s => !_.isFinite(s))) {
      setScore(undefined)
    } else {
      const sum = _.reduce(
        scores,
        (acc, cur) => {
          if (!cur || !_.isFinite(cur)) return acc
          return acc + cur
        },
        0
      )
      setScore(sum)
    }
  }, [scoreForm, getScoreByQuestion, group])

  // audit recode for audit

  type GroupedMarkingRecord = {
    uid: number
    display_name: string
    score: number
  }

  const markingRecords = useMemo(() => {
    if (!isCurrentUserHasAuditRole) return null

    const records = group.map(question => {
      return findMarkingDataForAudit(question.id)
    })

    const grouped = _.reduce(
      records,
      (acc, groupItem) => {
        _.forEach(groupItem, it => {
          // find acc by uid use groupItem.uid
          const accItem = acc.find(accItem => accItem.uid === it.uid)
          if (accItem) {
            accItem.score += it.score
          } else {
            acc.push({
              uid: it.uid,
              display_name: it.display_name,
              score: it.score
            })
          }
        })

        return acc
      },
      [] as GroupedMarkingRecord[]
    )

    return grouped
  }, [group, isCurrentUserHasAuditRole, markingDataForAudit])

  const renderMarkingRecords = useCallback(() => {
    return (
      markingRecords && (
        <>
          <div className='label' style={{ marginTop: 16, marginBottom: 8 }}>
            <Text strong>评分记录</Text>
          </div>
          <div>
            {!markingRecords.length && <Text type='secondary'>暂未评分</Text>}
            {markingRecords.map((item, index) => (
              <div
                className='score-record'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 4
                }}
              >
                <div style={{ flex: 1 }}>
                  {index + 1}. {item.display_name}
                </div>
                <Tag color='red'>{item.score}分</Tag>
              </div>
            ))}
          </div>
        </>
      )
    )
  }, [markingRecords])

  const renderForm = useCallback(() => {
    function onPressEnter() {
      const question = _.last(group)
      if (!question) return

      emitter.emit('scoreForm:enterPress', {
        questionId: question.id
      })
    }

    const [error, setError] = useState<string | undefined>()

    function onChange(value: number | string | undefined) {
      if (!_.isFinite(value)) {
        setError(undefined)
        _setScore(undefined)
        return
      }

      const val = Number(value)
      if (val > scoreMax) {
        setError(`得分不能大于${scoreMax}`)
        _setScore(undefined)
      } else {
        setError(undefined)
        _setScore(val)
      }
    }

    return (
      <>
        <Row
          align={'middle'}
          justify={'center'}
          gutter={8}
          className={'score-input-form'}
        >
          <Col>得分</Col>
          <Col>
            <InputNumber
              value={score}
              onChange={onChange}
              onPressEnter={onPressEnter}
              min={0}
              size={'large'}
              style={{ textAlign: 'center' }}
            />
          </Col>
          <Col>
            <Text type={'secondary'}>≤{scoreMax}</Text>
          </Col>
          {error && (
            <div className='error' style={{ marginTop: 4 }}>
              {error}
            </div>
          )}
        </Row>

        <div className={'score-input-action'}>
          <div className={'label'}>快速打分</div>
          <Row
            align={'middle'}
            justify={'center'}
            gutter={8}
            style={{ marginBottom: 8 }}
          >
            <Col span={12}>
              <Button
                type={'primary'}
                danger
                block
                tabIndex={-1}
                onClick={() => onChange(0)}
              >
                0分
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className={'btn-success'}
                block
                tabIndex={-1}
                onClick={() => onChange(scoreMax)}
              >
                满分
              </Button>
            </Col>
          </Row>
          {scoreMax <= 20 && (
            <Row gutter={[8, 8]}>
              {Array.from({ length: scoreMax }).map((v, i) => (
                <Col key={i} span={6}>
                  <Button
                    block
                    type={score === i + 1 ? 'primary' : 'default'}
                    onClick={() => onChange(i + 1)}
                    tabIndex={-1}
                  >
                    {i + 1}
                  </Button>
                </Col>
              ))}
            </Row>
          )}
        </div>

        {renderMarkingRecords()}
      </>
    )
  }, [score, scoreMax, group, emitter, renderMarkingRecords])

  return renderForm()
}
