import { MarkingMode, QuestionTypeKey, SubjectKey } from 'config'
import { Moment } from 'moment'
import { DateTimeInString, Teacher } from 'typing'
import { SteppedScoreSetting } from 'typing.paper'

/**
 * 批阅试卷
 */
export type MarkingPaper = {
  id: number
  exam_id: number
  exam_name: string
  paper_id: number
  name: string
  review_time: DateTimeInString | Moment
  review_end_time: DateTimeInString | Moment
  review_count: number
  complete_review_count: number
  teacher_uid: number
  has_begin: boolean
}

/**
 * 批阅试卷详细
 */
export type MarkingPaperDetail = MarkingPaper & {
  exam_paper_id: number
  subject: SubjectKey
  quests: number[]
  teacher_uid: number
  teacher_name: string
  hide_info: boolean
  show_answer_card: boolean
  // consult
  class_id: number
  relation_id: number
  review_mode: MarkingMode
  judge?: Teacher
  score_plus_mode: DrawerScoreTextSignMode
  score_auto_count: DrawerScoreTextAutoComputeByMarking
  inspector?: Teacher[]
  question_type2_group_marking: boolean
  step_score_setting?: string | SteppedScoreSetting
  inline_drawing_marking_mode?: 0 | 1
  allow_teacher_supplement?: 0 | 1
  judge_teacher_by_question?: {
    question: number[]
    teacher: number
  }[]
}

export type StudentAnswerOrigin = {
  id: number
  quest_ids: number[]
  answers: {
    data: {
      id: number
      clazzid: number
      questionid: number
      type: QuestionTypeKey
      answer: string
      markStatus: 0 | 1
      score: number
      teacherComment: string
    }[]
    extraParams: {
      blankObj: 0 | 1
    }
  }
}

export type StudentAnswerItemOrigin = StudentAnswerOrigin['answers']['data'][0]

export type StudentAnswer = {
  answerId: number
  questionId: number
  type: QuestionTypeKey
  answer: string | AnswerNormal[] | AnswerNesting[]
  score: number
  origin: StudentAnswerItemOrigin
  teacherComment: string
}

export type AnswerNormal = {
  answer?: string
  content?: string
  name?: string
}

export type AnswerNesting = {
  questionId: string
  type: QuestionTypeKey
  answer: string
  score: number | null
}

export type ScoreFormDataItem = {
  answerId: number
  questionId: number | string
  score: number | null
  // children?: {
  //   questionId: string
  //   score: number | null
  // }[]
}

export interface DrawerData {
  questionId: number
  parentQuestionId?: number
  index: number
  objectId: string
  file: string
}

export interface CommentFormDataItem {
  answerId: number
  questionId: number
  comment: string
  children?: CommentFormDataItemNesting[]
}

export type CommentFormDataItemNesting = {
  questionId: number | string
  comment: string
}

// 批阅记录项
type MarkingRecordItem = {
  teacher_uid: number
  display_name: string
  score: number
  // 打回专用
  exam_t_s_id: number
  sub_question?: {
    sub_question_id: string
    score: number
  }[]
}

// 题目仲裁状态
export enum MarkingAuditStatus {
  Unjudged = 0, // 待仲裁
  Judged = 1 // 无需仲裁
}

// 题目打回状态
export type MarkingRejectStatus = boolean

// 试卷批阅记录
export type MarkingRecords = {
  [questionId: string]: {
    judge_status: MarkingAuditStatus

    repulsed: MarkingRejectStatus

    scores: MarkingRecordItem[]

    // 题组教师
    quest_group_teachers: Teacher[]
  }
}

export type MarkingAuditRecordVO = {
  parentQuestionId?: number
  questionId: number | string
  uid: number
  display_name: string
  score: number
  judge_status: MarkingAuditStatus
}

export enum DrawerScoreTextSignMode {
  ALL = 0,
  PLUS = 1,
  MINUS = 2
}

export enum DrawerScoreTextAutoComputeByMarking {
  YES = 1,
  NO = 0
}
