import { APP_SCOPE } from 'config'
import constate from 'constate'
import { GET } from 'core/request'
import { useEffect, useState } from 'react'

type OrgInfo = { id: number; fid: number; name: string; is_top_level: boolean }

const [LoginProvider, useLoginContent] = constate(() => {
  const path = window.location.pathname

  const [token] = useState(localStorage.getItem('token'))
  const [orgInfo, setOrgInfo] = useState<OrgInfo>()

  const [isCityExam, setIsCityExam] = useState(true)

  async function fetchOrgInfo() {
    const { data } = await GET('city/organization/current', {})
    setOrgInfo(data)
    setIsCityExam((data as OrgInfo).is_top_level)
  }

  useEffect(() => {
    const skipInitPath = ['/error']
    const isSkipInit = skipInitPath.some(item => path.endsWith(item))

    if (token && !isSkipInit && !APP_SCOPE.isInFanYa) {
      fetchOrgInfo()
    }
  }, [])

  return {
    token,
    orgInfo,
    isCityExam
  }
})

LoginProvider.displayName = 'LoginProvider'

export { LoginProvider, useLoginContent }
