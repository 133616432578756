import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Modal, Row, Typography, message } from 'antd'
import classNames from 'classnames'
import { QuestionType } from 'config'
import _ from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import { useList } from 'react-use'
import { Paper } from 'typing'
import { QuestionGroup, QuestionIdentity } from 'typing.paper'
import { getPlanText } from 'utils'
import { reversQuestionsToGroupTree } from 'utils/question'
import { useMarkingForm } from '../useMarkingForm'

export const PartialMarkingSetting: FC = () => {
  const {
    partialMarkingSettingVisible,
    setPartialMarkingSettingVisible,
    partialMarkingQuestions,
    partialMarkingQuestionsSetter,

    paperQuestions,
    markingQuestionsVOWithoutPartialMarkingFilter: markingQuestions,

    pageReload
  } = useMarkingForm()

  const questionGroups = useMemo(
    () => reversQuestionsToGroupTree(paperQuestions, markingQuestions),
    [paperQuestions, markingQuestions]
  )

  const originalQuestionIds = useMemo(
    () =>
      _.reduce<QuestionGroup, QuestionIdentity[]>(
        questionGroups,
        (res, g) => {
          res.push(...g.children.map(q => q.id))
          return res
        },
        []
      ),
    [questionGroups]
  )

  const [selected, selectedSetter] = useList<QuestionIdentity>([])

  useEffect(() => {
    if (!partialMarkingSettingVisible) return

    if (!partialMarkingQuestions.length) {
      // Initialize selected questions with all questions
      selectedSetter.set(originalQuestionIds)
    } else {
      selectedSetter.set(partialMarkingQuestions)
    }
  }, [
    partialMarkingSettingVisible,
    partialMarkingQuestions,
    originalQuestionIds,
    selectedSetter
  ])

  function onSelectAllClick() {
    if (selected.length >= originalQuestionIds.length) {
      selectedSetter.set([])
      return
    }
    selectedSetter.set(originalQuestionIds)
  }

  function onSelectGroup(g: QuestionGroup) {
    const ids = g.children.map(q => q.id)
    const values = _.uniq([...selected, ...ids])
    selectedSetter.set(values)
  }

  function onCheckChange(q: Paper.Question) {
    if (isQuestionSelected(q)) {
      const index = selected.findIndex(id => id === q.id)
      selectedSetter.removeAt(index)
    } else {
      selectedSetter.push(q.id)
    }
  }

  function isQuestionSelected(q: Paper.Question) {
    return selected.includes(q.id)
  }

  function onOk() {
    if (!selected.length) {
      message.warn('至少选择一个题目')
      return
    }

    const value = selected.length === originalQuestionIds.length ? [] : selected
    partialMarkingQuestionsSetter(value)
    setPartialMarkingSettingVisible(false)

    message.loading('加载中...')
    pageReload()
  }

  return (
    <Modal
      title='部分批阅设置'
      visible={partialMarkingSettingVisible}
      onOk={onOk}
      onCancel={() => setPartialMarkingSettingVisible(false)}
    >
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography.Text strong>选择批阅题目</Typography.Text>
        <Button type='link' size='small' onClick={onSelectAllClick}>
          {selected.length === originalQuestionIds.length ? '反选' : '全选'}
        </Button>
      </div>

      {questionGroups.map((g, i) => (
        <Card
          key={i}
          size='small'
          title={
            <Row>
              <Col flex={1}>
                {g.displayName}、{g.typeNameAlias || QuestionType[g.type]}
              </Col>
              <Col>
                <a
                  onClick={() => onSelectGroup(g)}
                  style={{ fontWeight: 'normal' }}
                >
                  选择
                </a>
              </Col>
            </Row>
          }
          style={{ marginBottom: 16 }}
        >
          {g.children.map((q, j) => (
            <div
              key={j}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: j === 0 ? 0 : 8,
                paddingTop: 8,
                borderTop: j === 0 ? 0 : '1px solid #f1f1f1'
              }}
            >
              <div
                key={j}
                className={classNames('', {
                  active: 0
                })}
                style={{ flex: 1 }}
              >
                {q.displayName}
                {'. '}
                {_.truncate(getPlanText(q.content), {
                  length: 50
                })}
              </div>
              <Button
                type={isQuestionSelected(q) ? 'primary' : 'default'}
                shape='circle'
                size='small'
                icon={
                  isQuestionSelected(q) ? <CheckOutlined /> : <PlusOutlined />
                }
                onClick={() => onCheckChange(q)}
              ></Button>
            </div>
          ))}
        </Card>
      ))}
    </Modal>
  )
}
