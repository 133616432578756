import React, { FC, useEffect, useState } from 'react'
import {
  ExamPaperReportSummaryProvider,
  useExamPaperReportSummaryContent
} from 'hooks/useExamPaperReportSummary'
import { TotalScoreTable } from 'components/ReportExamPaperTables/table/TotalScoreTable'
import { TableTabsWrapper } from 'components/ReportExamPaperTables/TableTabsWrapper'
import { Report } from 'typing'
import {
  DialogClazzDetailTables,
  DialogClazzDetail
} from 'components/ReportExamPaperTables/dialog/DialogClazzDetail'
import { RangeScoreTable } from 'components/ReportExamPaperTables/table/RangeScoreTable'
import { QuestionScoreTable } from 'components/ReportExamPaperTables/table/QuestionScoreTable'
import { useCurrentExamContent } from 'hooks/useExamData'
import { useLoginContent } from 'hooks/useLogin'
import { Spin } from 'antd'
import { GET } from 'core/request'
import _ from 'lodash'
import constate from 'constate'

interface ReportExamPaperTableProps {
  reportEntryType: Report.EntryType
  reportEntryId: number
  reportEntryName?: string
}

const [SingleSchoolModeProvider, useSingleSchoolModeContent] = constate(() => {
  const { isCityExam, orgInfo } = useLoginContent()
  const [isSingleSchoolView, setIsSingleSchoolView] = useState(false)
  const [currentSchoolViewFid, setCurrentSchoolViewFid] = useState()
  const [loading, setLoading] = useState(true)

  async function checkSingleSchool(
    reportEntryType: Report.EntryType,
    reportEntryId: number
  ) {
    if (isCityExam) {
      setLoading(false)
      return
    }
    const url = `/city/exam/${
      reportEntryType === 'exam' ? '' : 'paper/'
    }statistic/score/school`
    const { data } = await GET(url, {
      data: {
        [reportEntryType === 'exam'
          ? 'exam_id'
          : 'exam_paper_id']: reportEntryId
      }
    })
    setIsSingleSchoolView(data.length === 1)
    setCurrentSchoolViewFid(_.get(data, '[0].fid'))
    setLoading(false)
  }
  return {
    isCityExam,
    orgInfo,
    isSingleSchoolView,
    checkSingleSchool,
    loading,
    currentSchoolViewFid
  }
})

SingleSchoolModeProvider.displayName = 'SingleSchoolModeProvider'
export { useSingleSchoolModeContent }

const ReportExamPaperTableContent: FC<ReportExamPaperTableProps> = props => {
  const { reportEntryType, reportEntryId } = props

  const {
    isCityExam,
    orgInfo,
    isSingleSchoolView,
    checkSingleSchool,
    loading,
    currentSchoolViewFid
  } = useSingleSchoolModeContent()

  useEffect(() => {
    if (orgInfo) {
      checkSingleSchool(reportEntryType, reportEntryId)
    }
  }, [isCityExam, orgInfo])

  const [clazzDialogVisible, setClazzDialogVisible] = useState(false)
  const [clazzDialogState, setClazzDialogState] = useState<
    Report.BasicTableDialogProps['stateParams']
  >()

  function renderSchoolView() {
    if (!orgInfo) return null
    return (
      <>
        <DialogClazzDetailTables
          scopeParams={{
            ...props,
            scope: 'clazz',
            schoolId: currentSchoolViewFid
          }}
          stateParams={{ tabIndex: 0, params: orgInfo }}
        />
      </>
    )
  }

  function renderCityView() {
    return (
      <>
        <TableTabsWrapper
          scopeParams={{ reportEntryType, reportEntryId, scope: 'school' }}
        >
          {(tabIndex, summary) => {
            if (tabIndex === 0) {
              return (
                <TotalScoreTable
                  scopeParams={{
                    ...props,
                    scope: 'school'
                  }}
                  totalScoreSummary={summary}
                  onDetailClick={params => {
                    setClazzDialogState({
                      params,
                      tabIndex
                    })
                    setClazzDialogVisible(true)
                  }}
                />
              )
            }
            if (tabIndex === 1) {
              return (
                <RangeScoreTable
                  scopeParams={{
                    ...props,
                    scope: 'school'
                  }}
                  totalScoreSummary={summary}
                  onDetailClick={params => {
                    setClazzDialogState({
                      params,
                      tabIndex
                    })
                    setClazzDialogVisible(true)
                  }}
                />
              )
            }
            if (tabIndex === 2) {
              return (
                <QuestionScoreTable
                  scopeParams={{
                    ...props,
                    scope: 'school'
                  }}
                  totalScoreSummary={summary}
                  onDetailClick={params => {
                    setClazzDialogState({
                      params,
                      tabIndex
                    })
                    setClazzDialogVisible(true)
                  }}
                />
              )
            }
          }}
        </TableTabsWrapper>

        {clazzDialogState && clazzDialogState.params && (
          <DialogClazzDetail
            scopeParams={{
              ...props,
              scope: 'clazz',
              schoolId: clazzDialogState.params.fid
            }}
            stateParams={clazzDialogState}
            visible={clazzDialogVisible}
            onClose={() => setClazzDialogVisible(false)}
          />
        )}
      </>
    )
  }

  if (loading) {
    return <Spin />
  }
  return isSingleSchoolView ? renderSchoolView() : renderCityView()
}

export const ReportExamPaperTable: FC<ReportExamPaperTableProps> = props => {
  const { currentPaper, initCurrentPaper } = useCurrentExamContent()

  useEffect(() => {
    if (props.reportEntryType === 'paper' && !currentPaper) {
      initCurrentPaper(props.reportEntryId)
    }
  }, [props.reportEntryId])

  if (props.reportEntryType === 'paper' && !currentPaper) {
    return <Spin />
  }
  return (
    <SingleSchoolModeProvider>
      <ExamPaperReportSummaryProvider>
        <ReportExamPaperTableContent {...props} />
      </ExamPaperReportSummaryProvider>
    </SingleSchoolModeProvider>
  )
}
