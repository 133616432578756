import { LinkOutlined } from '@ant-design/icons'
import { useMount } from '@umijs/hooks'
import { Button, Card, Divider, Tag, Typography } from 'antd'
import { RemoveConfirm, Table, TableToolbar } from 'components'
import { PageWrapper } from 'components/PageWrapper'
import { PreviewDialog } from 'components/Preview/PreviewDialog'
import { MarkingMode, Subject } from 'config'
import { CurrentExamProvider, useCurrentExamContent } from 'hooks/useExamData'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Paper } from 'typing'
import { FORMATTER_DATE, FORMATTER_TIME, dateRangeFormat } from 'utils'
import { Edit } from './Edit'
import { MarkingSettingDialog } from './MarkingSettingDialog/Dialog'
import {
  EditProvider,
  ListProvider,
  MarkingSettingEditProvider,
  useEditContext,
  useListContext,
  useMarkingSettingEditContext
} from './store'

const { Title, Text } = Typography

export default function () {
  return (
    <CurrentExamProvider>
      <ListProvider>
        <EditProvider>
          <MarkingSettingEditProvider>
            <Page />
          </MarkingSettingEditProvider>
        </EditProvider>
      </ListProvider>
    </CurrentExamProvider>
  )
}

const Page: FC = () => {
  const query = useParams<{ id }>()

  const { currentExam, initCurrentExam } = useCurrentExamContent()
  const list = useListContext()
  const edit = useEditContext()
  edit.connect(list)

  const markingSettingEdit = useMarkingSettingEditContext()
  markingSettingEdit.connect(list)

  useMount(() => {
    initCurrentExam(query.id)
    list.onSearchSubmit({ exam_id: query.id })
  })

  // Preview
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewPaperId, setPreviewPaperId] = useState<number>(0)
  function onPreview(data: Paper.Paper) {
    setPreviewPaperId(data.paper_id)
    setPreviewVisible(true)
  }

  return (
    <PageWrapper
      breadcrumb={['统考管理', '试卷管理']}
      headerExtra={() => (
        <Link to={'/exams'} replace>
          返回上级
        </Link>
      )}
      content={
        currentExam && (
          <div>
            <Title level={4} style={{ marginBottom: 4 }}>
              {currentExam.name}
            </Title>
            <Text type={'secondary'}>
              考试时间：
              {dateRangeFormat({
                value: [currentExam.begin_date, currentExam.end_date]
              })}
            </Text>
          </div>
        )
      }
    >
      <Card>
        <TableToolbar>
          <Button type={'primary'} onClick={() => edit.onAdd()}>
            添加试卷
          </Button>
        </TableToolbar>

        <Table<Paper.Paper>
          list={list}
          scroll={{ x: 'max-content' }}
          indexColumn={{ fixed: 'left' }}
          columns={[
            {
              title: '考试科目',
              dataIndex: 'subject',
              render: t => Subject[t],
              width: 100,
              fixed: 'left'
            },
            {
              title: '试卷名称',
              dataIndex: 'name',
              render: (t, r) => (
                <a onClick={() => onPreview(r)}>
                  {t} <LinkOutlined />
                </a>
              ),
              width: 160,
              fixed: 'left'
            },
            {
              title: '试卷总分',
              dataIndex: 'score',
              width: 100,
              align: 'right'
            },
            {
              title: '考试时间',
              key: 'dateRange',
              render: (t, r) =>
                dateRangeFormat({
                  value: [r.begin_time, r.end_time],
                  showTime: true
                }),
              width: 140
            },
            {
              title: '判卷模式',
              dataIndex: 'review_mode',
              render: t => {
                return t === MarkingMode.ALL ? (
                  <Tag color={'warning'}>普通判卷</Tag>
                ) : t === MarkingMode.GROUP_FLOW ? (
                  <Tag color={'processing'}>流水判卷</Tag>
                ) : (
                  <Tag color={'#f55'}>未设置</Tag>
                )
              },
              width: 100
            },
            {
              title: '判卷开始',
              dataIndex: 'review_time',
              render: t =>
                t ? (
                  moment(t).format(`${FORMATTER_DATE} ${FORMATTER_TIME}`)
                ) : (
                  <Tag color={'#f55'}>未设置</Tag>
                ),
              width: 140
            },
            {
              title: '操作',
              key: 'actions',
              width: 220,
              render: (t, r) => (
                <span>
                  <a onClick={() => markingSettingEdit.onEdit(r)}>判卷设置</a>
                  <Divider type={'vertical'} />
                  <Link to={`/exams/${query.id}/papers/${r.id}/report`}>
                    成绩统计
                  </Link>
                  <Divider type={'vertical'} />
                  <RemoveConfirm edit={edit} data={r} />
                </span>
              ),
              fixed: 'right'
            }
          ]}
        />
      </Card>

      <Edit />
      <MarkingSettingDialog />

      <PreviewDialog
        id={previewPaperId}
        visible={previewVisible}
        onClose={() => setPreviewVisible(false)}
      />
    </PageWrapper>
  )
}
