import qs from 'qs'
import React from 'react'
import ErrorBg from '../assets/error.png'

export default () => {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })

  return (
    <div
      style={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img src={ErrorBg} alt='' />
      <div
        style={{
          marginTop: 16,
          fontSize: 16
        }}
      >
        {query.message || '服务繁忙, 请稍后再试'}
      </div>
    </div>
  )
}
