import { message } from 'antd'
import { POST, defaultErrorHandler } from 'core/request'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import React, { FC, useCallback, useMemo } from 'react'
import { Paper, Teacher } from 'typing'
import { StudentMarkStatus, useMarkingForm } from '../useMarkingForm'
import { ReviewRejectButton } from './ReviewRejectButton'

export const ReviewInput: FC<{
  question: Paper.Question
  child?: Paper.QuestionNestingChildQuestion
}> = ({ question }) => {
  const {
    markingPaper,
    markingDataForAudit,
    setMarkingDataForAudit
  } = useQuestionAnswerDataContent()

  const { currentStudent } = useMarkingForm()

  const teachersForReject = useMemo(() => {
    return markingDataForAudit?.[question.id]?.quest_group_teachers
  }, [markingDataForAudit, question])

  const statusForReject = useMemo(() => {
    return markingDataForAudit?.[question.id]?.repulsed ?? false
  }, [markingDataForAudit, question])

  const idForReject = useMemo(() => {
    return markingDataForAudit?.[question.id]?.scores[0]?.exam_t_s_id
  }, [markingDataForAudit, question])

  const setQuestionToRejected = useCallback(() => {
    const item = markingDataForAudit?.[question.id]
    if (!item) return

    item.repulsed = true

    setMarkingDataForAudit({
      ...markingDataForAudit,
      [question.id]: item
    })
  }, [markingDataForAudit, question.id])

  const onRejectConfirm = useCallback(
    async (teacher: Teacher) => {
      const hide = message.loading('正在处理', 0)
      try {
        await POST(
          `fanya/exam/paper/${markingPaper?.exam_paper_id}/${idForReject}/repulse`,
          {
            data: {
              student_teacher_question_id: question.id,
              teacher_uid: teacher.uid
            }
          }
        )
        setQuestionToRejected()
        message.success('打回成功')
      } catch (e) {
        hide()
        defaultErrorHandler(e as any)
      } finally {
        hide()
      }
    },
    [question, markingPaper, idForReject, setQuestionToRejected]
  )

  if (currentStudent?.is_complete === StudentMarkStatus.MARKED) {
    return (
      <ReviewRejectButton
        teachers={teachersForReject}
        onConfirm={onRejectConfirm}
        disabled={statusForReject}
      />
    )
  }
  return null
}
