import { Spin } from 'antd'
import { DrawerProvider } from 'components/ImageDrawer/useDrawerContent'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import {
  MarkingFormProvider,
  useMarkingForm
} from 'pages/marking-form/useMarkingForm'
import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MarkingForm } from './MarkingForm'
import { MarkingReviewForm } from './review/MarkingReviewForm'
import { ScoreFormNavigateProvider } from './useScoreFormNavigation'

const Page: FC = props => {
  const query = useParams<{ id }>()

  const { isCurrentUserHasReviewRole } = useQuestionAnswerDataContent()

  const { isInitialized, setMarkingPaperId } = useMarkingForm()

  useEffect(() => {
    setMarkingPaperId(_.parseInt(query.id))
  }, [])

  if (!isInitialized) {
    return <Spin />
  }

  return isCurrentUserHasReviewRole ? <MarkingReviewForm /> : <MarkingForm />
}

export default () => (
  <MarkingFormProvider>
    <ScoreFormNavigateProvider>
      <DrawerProvider>
        <Page />
      </DrawerProvider>
    </ScoreFormNavigateProvider>
  </MarkingFormProvider>
)
