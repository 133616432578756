import { PlusOutlined } from '@ant-design/icons'
import { Button, Space, Tag } from 'antd'
import _ from 'lodash'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useList } from 'react-use'
import { Teacher } from 'typing.exam'
import { TeacherSelectDialogForFanya } from './DialogForFanya'

export const MultiTeacherListSelectorForFanya: FC<{
  value?: Teacher[]
  onChange?: (value: Teacher[]) => void
  disabled?: boolean
}> = props => {
  const [visible, setVisible] = useState(false)

  const [list, setList] = useList<Teacher>(props.value || [])

  const onAdd = useCallback(
    (data: Teacher[]) => {
      const newList = _.uniqBy([...list, ...data], 'uid')
      setList.set(newList)
      setVisible(false)
    },
    [list, setList]
  )

  useEffect(() => {
    props.onChange?.(list)
  }, [list, props.onChange])

  return (
    <>
      <Space>
        {list.map((item, index) => (
          <Tag
            key={item.uid}
            closable={!props.disabled}
            onClose={() => {
              setList.removeAt(index)
            }}
          >
            {item.display_name}
          </Tag>
        ))}

        {!props.disabled && (
          <Button
            size='small'
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
          >
            新增
          </Button>
        )}
      </Space>

      <TeacherSelectDialogForFanya
        visible={visible}
        title={'选择教师'}
        multi={true}
        onConfirm={onAdd}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}
