import _ from 'lodash'
import { Marking } from 'typing'
import { JSONParse } from 'utils'
import { isNestingQuestion } from 'utils/question'
import { APP_SCOPE } from '../config'
import { GET } from '../core/request'
import { CommentFormDataItem } from '../typing.marking'

const log = require('debug')

export function isAnswerNormals(
  answer: Marking.AnswerNormal[] | Marking.AnswerNesting
): answer is Marking.AnswerNormal[] {
  return _.isArray(answer)
}
export function isAnswerNesting(
  answer: Marking.AnswerNormal[] | Marking.AnswerNesting
): answer is Marking.AnswerNesting {
  return !_.isArray(answer)
}

export function parseStudentAnswer(
  data: Marking.StudentAnswerOrigin
): Marking.StudentAnswer[] {
  return _.map<Marking.StudentAnswerItemOrigin, Marking.StudentAnswer>(
    data.answers.data,
    d => {
      return {
        origin: d,
        answerId: d.id,
        questionId: d.questionid,
        type: d.type,
        score: d.markStatus ? d.score : (null as any),
        answer: (() => {
          try {
            // Adapt to mock data in json format already
            const result = JSONParse(d.answer)
            if (isNestingQuestion(d.type)) {
              return _.flatMap(result, r => {
                return _.map<any, Marking.AnswerNesting>(r, (v, k) => {
                  const val = JSONParse(v)
                  return {
                    questionId: k,
                    type: val.type,
                    answer: JSONParse(val.answer),
                    score: val.score
                  }
                })
              })
            }
            // Fix 2021-1-5
            if (typeof result === 'number') {
              return String(result)
            }
            return result
          } catch (e) {
            log('parseStudentAnswer:')((e as Error).message, d.answer)
            return d.answer as string
          }
        })(),
        teacherComment: d.teacherComment
      }
    }
  )
}

export function getMarkingCommentDTO(
  commentFormData: CommentFormDataItem
): { teacherComment: string } {
  if (commentFormData.children) {
    const teacherCommentMap = _.reduce(
      commentFormData.children,
      (res, child) => {
        res[child.questionId] = child.comment
        return res
      },
      {}
    )
    log('getMarkingCommentDTO:')('teacherCommentMap', teacherCommentMap)
    return { teacherComment: JSON.stringify(teacherCommentMap) }
  } else {
    return {
      teacherComment: commentFormData.comment
    }
  }
}

export async function fetchStudentAnswer(
  studentId: number
): Promise<[Marking.StudentAnswerOrigin, Marking.StudentAnswer[], boolean]> {
  const _log = log('fetchStudentAnswer:')

  _log('params', studentId)

  const { data } = await GET(
    `${
      APP_SCOPE.isInFanYa ? 'fanya' : 'review'
    }/paper/students/${studentId}/answer`,
    {}
  )

  const studentAnswer = parseStudentAnswer(data)
  const isType2NeedMarkInNesting =
    _.get(
      data as Marking.StudentAnswerOrigin,
      'answers.extraParams.blankObj'
    ) === 0

  _log('currentStudentAnswerOrigin', data)
  _log('currentStudentAnswer', studentAnswer)
  _log('isType2NeedMarkInNesting', isType2NeedMarkInNesting)

  return [data, studentAnswer, isType2NeedMarkInNesting]
}
