import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  TimePicker,
  Typography
} from 'antd'
import { useForm } from 'antd/es/form/util'
import { EditModel } from 'components'
import { RequiredRule } from 'components/EditModel/utils'
import { SubjectMap } from 'config'
import { useCurrentExamContent } from 'hooks/useExamData'
import _ from 'lodash'
import { PaperSelectDialog } from 'pages/exam-papers/PaperSelectDialog'
import React, { FC, useEffect, useState } from 'react'
import { Paper } from 'typing'
import { useEditContext } from './store'

const { RangePicker: TimeRangePicker } = TimePicker
const { Text } = Typography

export const Edit: FC = () => {
  const { currentExam } = useCurrentExamContent()
  const edit = useEditContext()
  const [form] = useForm()

  const [selectDialogVisible, setSelectDialogVisible] = useState(false)

  function validate(value) {
    const hasPapers = value.papers && value.papers.length
    if (!hasPapers) {
      message.warn('请添加考试试卷')
    }
    return hasPapers
  }

  useEffect(() => {
    if (currentExam && edit.visible) {
      edit.data.exam_id = currentExam.id
    }
  }, [currentExam, edit.visible])

  return (
    <EditModel edit={edit} form={form} validate={validate}>
      <Form.Item label={'统考批次'}>
        {currentExam && currentExam.name}
      </Form.Item>
      <Form.Item label={'考试科目'} name={'subject'} rules={[RequiredRule]}>
        <Select style={{ width: 200 }}>
          {SubjectMap.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={'考试时间'} style={{ marginBottom: 0 }}>
        <Form.Item
          name={'_date'}
          style={{ display: 'inline-block' }}
          rules={[RequiredRule]}
        >
          <DatePicker />
        </Form.Item>{' '}
        <Form.Item
          name={'_timeRange'}
          style={{ display: 'inline-block' }}
          rules={[RequiredRule]}
        >
          <TimeRangePicker picker={'time'} format={'HH:mm'} />
        </Form.Item>
      </Form.Item>
      <Form.Item label={'考试试卷'}>
        <div style={{ marginBottom: 16 }}>
          <Button type={'primary'} onClick={() => setSelectDialogVisible(true)}>
            添加
          </Button>
        </div>
        <Form.List name={'papers'}>
          {(fields, { remove }) => (
            <div>
              {fields.map(field => (
                <Form.Item {...field} style={{ marginBottom: 8 }}>
                  <PaperItem onRemove={() => remove(field.name)} />
                </Form.Item>
              ))}
            </div>
          )}
        </Form.List>
      </Form.Item>

      <PaperSelectDialog
        visible={selectDialogVisible}
        onClose={() => setSelectDialogVisible(false)}
        onConfirm={data => {
          const val = form.getFieldValue('papers') || []
          form.setFieldsValue({
            papers: _.uniqBy([...val, data], 'paper_id')
          })
          setSelectDialogVisible(false)
        }}
      />
    </EditModel>
  )
}

const PaperItem: FC<{ value?: Paper.Paper; onRemove: () => void }> = props => {
  const data = props.value || ({} as Paper.Paper)
  return (
    <Card size={'small'}>
      <Row>
        <Col flex={'auto'}>
          <div style={{ marginBottom: 8 }}>
            <Text strong>{data.name}</Text>
          </div>
          <Text type={'secondary'}>试卷id: {data.paper_id}</Text>
        </Col>
        <Col>
          <CloseOutlined onClick={() => props.onRemove()} />
        </Col>
      </Row>
    </Card>
  )
}
