import { createList } from 'core/service'
import React, { FC, useEffect, useState } from 'react'
import { Table } from 'components'
import _ from 'lodash'
import { Paper, Report } from 'typing'
import { LinkOutlined } from '@ant-design/icons'
import { createPaperQuestion } from 'utils/question'
import { useCurrentExamContent } from 'hooks/useExamData'
import { GET } from 'core/request'
import { Modal, Spin, Typography } from 'antd'
import { ColumnGroupType, ColumnsType, ColumnType } from 'antd/es/table'
import { QuestionType } from 'config'
import numeral from 'numeral'
import { useExportContent } from 'components/ReportExamPaperTables/TableTabsWrapper'

const { Text } = Typography

const { ListProvider, useListContext } = createList<
  Report.QuestionScoreTableItem,
  { scope: Report.ScopeType; exam_paper_id; fid; statistic_clazz_id }
>({
  url: ({ scope }) => {
    return scope === 'school'
      ? '/city/exam/paper/statistic/quest/school'
      : scope === 'clazz'
      ? 'city/exam/paper/statistic/quest/class'
      : 'city/exam/paper/statistic/quest/student'
  },
  parseFetchedData: (data, search) => {
    const items = _.map<any, Report.QuestionScoreTableItem>(data, item => {
      return {
        ...item,
        fid: item.ref_id,
        statistic_clazz_id: item.ref_id,
        org_name: item.ref_name,
        class_name: item.ref_name
      }
    })
    return { items, total: items.length }
  }
})

const QuestionScoreTableContent: FC<
  Report.BasicTableProps & {
    paperQuestions: Paper.QuestionGroup[]
  }
> = ({ stateParams, scopeParams, paperQuestions, onDetailClick }) => {
  const list = useListContext()

  useEffect(() => {
    list.onSearchSubmit({
      scope: scopeParams.scope,
      exam_paper_id: scopeParams.reportEntryId,
      fid: scopeParams.schoolId,
      statistic_clazz_id: scopeParams.clazzId
    })
  }, [])

  const isStatisticRow = row => row.ref_id === 0

  //#region columns
  function getScoreFromItemQuestsByQuestion(
    index: number,
    questions: Paper.Question[]
  ): number {
    const item = list.items[index]
    const matchedQuestions = _.filter(item.quests, q => {
      return !!_.find(questions, target => target.id === q.questionid)
    })
    return _.sumBy(matchedQuestions, q => {
      return q.sum_score / q.student_count
    })
  }
  function getScoreFromItemQuests(index: number, questionId: any): number {
    const item = list.items[index]
    const q = _.find(item.quests, { questionid: questionId })
    return q ? q.sum_score / q.student_count : 0
  }

  function createQuestionColumns(): ColumnType<
    Report.QuestionScoreTableItem
  >[] {
    return _.reduce<
      Paper.QuestionGroup,
      ColumnGroupType<Report.QuestionScoreTableItem>[]
    >(
      paperQuestions,
      (res, g) => {
        const group: ColumnGroupType<Report.QuestionScoreTableItem> = {
          title: `${g.displayName}、${QuestionType[g.type]}`,
          children: [
            {
              title: (
                <span>
                  总分
                  <br />
                  <Text type={'secondary'}>({g.score}分)</Text>
                </span>
              ),
              render: (t, r, i) => (
                <span className={'primary'}>
                  {numeral(
                    getScoreFromItemQuestsByQuestion(i, g.children)
                  ).format('0.0')}
                </span>
              ),
              width: 90,
              align: 'center'
            },
            ..._.map<Paper.Question, ColumnType<Report.QuestionScoreTableItem>>(
              g.children,
              q => {
                return {
                  title: (
                    <span>
                      {q.displayName}
                      <br />
                      <Text type={'secondary'}>({q.score}分)</Text>
                    </span>
                  ),
                  render: (t, r, i) =>
                    numeral(getScoreFromItemQuests(i, q.id)).format('0.0'),
                  width: 80,
                  align: 'center'
                }
              }
            )
          ]
        }
        res.push(group)
        return res
      },
      []
    )
  }
  //#endregion

  const columns: ColumnsType<Report.QuestionScoreTableItem> = [
    {
      title:
        scopeParams.scope === 'school'
          ? '学校'
          : scopeParams.scope === 'clazz'
          ? '班级'
          : '学生姓名',
      dataIndex: 'ref_name',
      render: (t, r) => {
        return isStatisticRow(r) || scopeParams.scope === 'student' ? (
          t
        ) : (
          <a onClick={() => onDetailClick(r)}>
            {t} <LinkOutlined />
          </a>
        )
      },
      width: 220,
      fixed: 'left'
    },
    ...createQuestionColumns()
  ]

  // useExportContent
  const { setLoading, setStateParams, setData } = useExportContent()
  useEffect(() => {
    setStateParams(stateParams)
    setData(columns, list.items)
  }, [list.items])
  useEffect(() => {
    setLoading(list.loading)
  }, [list.loading])

  return (
    <>
      <Table
        list={list}
        rowKey={'ref_id'}
        scroll={{ x: 'max-content', y: document.body.clientHeight * 0.6 - 50 }}
        indexColumn={{ fixed: 'left' }}
        pagination={false}
        bordered
        columns={columns}
      />
    </>
  )
}

export const QuestionScoreTable: FC<Report.BasicTableProps> = props => {
  const { currentPaper } = useCurrentExamContent()

  const [loading, setLoading] = useState(true)
  const [paperQuestions, setPaperQuestions] = useState<Paper.QuestionGroup[]>(
    []
  )

  async function init(id) {
    const { data } = await GET(`city/paper/${id}`, {})
    const po = _.get(data, 'data[0]') as Paper.PaperOriginal
    if (!po) {
      console.error('无法获取试卷数据')
      Modal.error({
        title: '无法获取试卷数据，请稍后再试',
        onOk: () => init(id)
      })
      return
    }
    const box = createPaperQuestion(po, true)
    setPaperQuestions(box.group)
    setLoading(false)
  }

  useEffect(() => {
    currentPaper && init(currentPaper.paper_id)
  }, [currentPaper])

  if (loading) {
    return <Spin />
  }

  return (
    <ListProvider>
      <QuestionScoreTableContent {...props} paperQuestions={paperQuestions} />
    </ListProvider>
  )
}
