import { useMount } from '@umijs/hooks'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tag
} from 'antd'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Search, Table } from '../../components'
import { createList } from '../../core/service'
import { useQuery } from '../../hooks/useQuery'
import { dateRangeFormat } from '../../utils'
import { TableColumnControl } from './components/TableColumnControl'
import {
  TableColumnControlItem,
  useTableColumnControl
} from './components/useTableColumnControl'
import './style.less'

const { ListProvider, useListContext } = createList({
  url: () => '/statistics/gather/byteacher/batch/examlist',
  parseSearchData: data => ({
    ...data,
    dateRange: null,
    startTime:
      data.dateRange && moment(data.dateRange[0]).format('YYYY-MM-DD 00:00:00'),
    endTime:
      data.dateRange && moment(data.dateRange[1]).format('YYYY-MM-DD 23:59:59')
  })
})

export default function () {
  return (
    <ListProvider>
      <Page />
    </ListProvider>
  )
}

const Page = () => {
  const history = useHistory()
  const query = useParams<{ tid; bid }>()
  const queryParams = useQuery<{ teacher; batch; relationId; classId }>()

  //#region list
  const list = useListContext()

  const defaultColumns = [
    { title: '考试名称', dataIndex: 'kspcmc', fixed: 'left', width: 220 },
    { title: '试卷名称', dataIndex: 'examname', width: 220 },
    {
      title: '考试码',
      dataIndex: 'ksdm',
      width: 100,
      render: v => <Tag>{v}</Tag>
    },
    { title: '考试场次名称', dataIndex: 'ksccmc', width: 150 },
    {
      title: '考试时间',
      dataIndex: 'startTime',
      render: (s, r) => (
        <span style={{ fontSize: 13 }}>
          {dateRangeFormat({
            value: [s, r.endTime],
            showTime: true
          })}
        </span>
      ),
      width: 280
    },
    { title: '课程名称', dataIndex: 'kcmc', width: 150 },
    { title: '课程编号', dataIndex: 'kcid', width: 150 },
    { title: '教务教学班名称', dataIndex: 'jxbsmc', width: 150 },
    { title: '教务教学班id', dataIndex: 'jxbsid', width: 150 },
    { title: '考试形式', dataIndex: 'sfxs', width: 100 },
    { title: '考生数', dataIndex: 'count_student', align: 'right', width: 80 },

    {
      title: '应批阅试卷份数',
      dataIndex: 'exam_paper_count',
      align: 'right',
      width: 140
    },
    {
      title: '已批阅阅试份数',
      dataIndex: 'completed_paper_count',
      align: 'right',
      width: 140
    },
    {
      title: '待批阅试卷份数',
      dataIndex: 'uncompleted_paper_count',
      align: 'right',
      width: 140
    },
    {
      title: '批阅试卷完成率',
      dataIndex: 'exam_paper_completed_count_rate',
      align: 'center',
      render: t => `${t}%`,
      width: 140
    },

    { title: '应批阅题目份数', dataIndex: 'total', align: 'right', width: 140 },
    { title: '已批阅题目份数', dataIndex: 'done', align: 'right', width: 140 },
    {
      title: '待批阅题目份数',
      dataIndex: 'total',
      align: 'right',
      render: (t, r) => t - r.done || '',
      width: 140
    },
    {
      title: '批阅完成率',
      dataIndex: 'rate',
      render: (_, record) =>
        `${Math.round((record.done / record.total || 0) * 10000) / 100}%`,
      align: 'center',
      width: 140,
      sorter: true
    },
    {
      title: '操作',
      key: 'actions',
      width: 120,
      render: (t, r) => (
        <Link
          to={`/marking/${r.exam_paper_teacher_id}/history?paper_id=${r.paper_id}`}
        >
          批阅历史
        </Link>
      )
    }
  ]

  function onTableChange(pagination, filters, sorter) {
    if (list.index.value !== pagination.current) {
      window.scrollTo(0, 0)
      list.onIndexChange(pagination.current)
      return
    }

    list.setPageSize(pagination.pageSize)
    list.onSearchSubmit({
      order_type: !!sorter.order
        ? sorter.order === 'ascend'
          ? '0'
          : '1'
        : null
    })
  }

  useMount(() => {
    list.setDefaultSearch({
      teacher: query.tid,
      relationIds: queryParams.relationId,
      clazzIds: queryParams.classId
    })
    list.onSearchSubmit({})
  })
  //#endregion

  //#region columns
  const columnsControlItems = defaultColumns
    .filter(it => it.key !== 'actions')
    .map<TableColumnControlItem>(it => ({
      title: it.title,
      dataIndex: it.dataIndex!,
      visible: !['jxbsid', 'jxbsmc'].includes(it.dataIndex!)
    }))

  const {
    columns,
    visibleColumns,
    setColumns,
    resetColumns
  } = useTableColumnControl('teacher-batch-exams--columns', columnsControlItems)

  const tableColumns = useMemo(() => {
    return defaultColumns.filter(it => {
      if (!it.dataIndex) return true
      const vc = visibleColumns.find(vc => it.dataIndex === vc.dataIndex)
      return vc?.visible
    })
  }, [visibleColumns])
  //#endregion

  return (
    <Card>
      <Row align={'middle'} className='teacher-report-header'>
        <Col flex={1}>
          <div className={'title'}>
            教师考试批阅统计
            <Tag className={'tag'}>{queryParams.teacher}</Tag>
            <Tag className={'tag'}>{queryParams.batch || '批次名称'}</Tag>
          </div>
        </Col>
        <Col>
          <Button onClick={() => history.goBack()}>返回</Button>
        </Col>
      </Row>
      <Search list={list}>
        <Form.Item name={'sfxs'}>
          <Select placeholder={'考试形式'} style={{ width: 120 }}>
            <Select.Option value={1}>线上考试</Select.Option>
            <Select.Option value={0}>线下考试</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name={'ksccmc'}>
          <Input allowClear placeholder={'输入考试场次'} />
        </Form.Item>
        <Form.Item name={'examName'}>
          <Input allowClear placeholder={'输入考试名称/考试码'} />
        </Form.Item>
        <Form.Item name={'kcmc'}>
          <Input allowClear placeholder={'输入课程名称/编号'} />
        </Form.Item>
        <Form.Item name={'dateRange'}>
          <DatePicker.RangePicker />
        </Form.Item>
      </Search>

      <Row justify={'end'} style={{ marginBottom: 8 }}>
        <Col>
          <TableColumnControl
            columns={columns}
            onChange={setColumns}
            onReset={resetColumns}
          />
        </Col>
      </Row>

      <Table
        size={'small'}
        list={list}
        onChange={onTableChange}
        scroll={{ x: 'max-content' }}
        indexColumn={{ fixed: 'left' }}
        columns={tableColumns as any}
        pagination={{
          current: list.index.value,
          total: list.total,
          pageSize: list.size,
          showSizeChanger: true,
          showQuickJumper: true,
          hideOnSinglePage: !true,
          showTotal: total => `共${total}条记录`
        }}
      />
    </Card>
  )
}
