import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Popover, Space } from 'antd'
import { APP_SCOPE } from 'config'
import _ from 'lodash'
import React, { FC, useCallback, useState } from 'react'
import '../index.less'
import { useMarkingSettingContent } from '../useMarkingSettingState'
import {
  getQuestionBindGroupDefaultValue,
  useQuestionBindGroupContent
} from '../useQuestionBindGroup'
import { TeacherSelectDialog } from './Dialog'
import { TeacherSelectDialogForFanya } from './DialogForFanya'
import { TeacherImportDialog, TeacherImportDialogReturn } from './Import'

export const TeacherSelect: FC = props => {
  const {
    teacherList,
    addTeacher,
    removeTeacherAt,
    findSchool,
    formFieldReadonly: readonly
  } = useMarkingSettingContent()

  const [teacherSelectDialogVisible, setTeacherSelectDialogVisible] = useState(
    false
  )

  function onRemove(i: number) {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: '确认删除',
      content: '同时会从题组中删除该判卷人员',
      okType: 'danger',
      onOk: () => removeTeacherAt(i)
    })
  }

  //#region teacher import
  const {
    questionBindGroup,
    questionBindGroupSetter
  } = useQuestionBindGroupContent()

  const [importVisible, setImportVisible] = useState(false)

  const parseImportData = useCallback(
    (data: TeacherImportDialogReturn) => {
      const groupSize = _.maxBy(data, 'groupNo')?.groupNo || 1

      // Fill group list
      const add = Array.from({
        length: groupSize - questionBindGroup.length
      }).map(() => getQuestionBindGroupDefaultValue()[0])
      //@ts-ignore
      const draftGroups = [...questionBindGroup, ...add]

      // Assign teacher to group by groupNo
      _.forEach(data, it => {
        const index = it.groupNo - 1
        if (it.teacher) {
          draftGroups[index] = {
            ...draftGroups[index],
            teachers: _.uniqBy(
              [...draftGroups[index].teachers, it.teacher],
              'uid'
            )
          }
        }
      })

      // Update group
      questionBindGroupSetter.set(draftGroups)

      // Also add teacher to selected-teacher list
      addTeacher(_.map(data, it => it.teacher!))
    },
    [questionBindGroup]
  )
  //#endregion

  return (
    <div>
      {!readonly && (
        <Space style={{ marginBottom: 8 }}>
          <Button
            type={'primary'}
            onClick={() => setTeacherSelectDialogVisible(true)}
          >
            选择判卷人员
          </Button>
          <Button type={'link'} onClick={() => setImportVisible(true)}>
            从文本导入
          </Button>
        </Space>
      )}

      {!!teacherList.length && (
        <div className={'teacher-select-control-wrapper'}>
          <div className='title'>已添加判卷人员{teacherList.length}名</div>
          <div className='list'>
            {teacherList.map((t, i) => (
              <Popover
                key={i}
                content={
                  <div>
                    {findSchool(t.fid).name && (
                      <div>学校：{findSchool(t.fid).name}</div>
                    )}
                    <div>工号：{t.name}</div>
                    <div>手机号：{t.tel || '-'}</div>
                  </div>
                }
              >
                <div className={'normal-tag'}>
                  <span>{t.display_name}</span>
                  {!readonly && (
                    <span className={'remove'} onClick={() => onRemove(i)}>
                      <CloseOutlined />
                    </span>
                  )}
                </div>
              </Popover>
            ))}
          </div>
        </div>
      )}

      {APP_SCOPE.isInFanYa ? (
        <TeacherSelectDialogForFanya
          visible={teacherSelectDialogVisible}
          onConfirm={data => {
            setTeacherSelectDialogVisible(false)
            addTeacher(data)
          }}
          onCancel={() => setTeacherSelectDialogVisible(false)}
        />
      ) : (
        <TeacherSelectDialog
          visible={teacherSelectDialogVisible}
          onConfirm={data => {
            setTeacherSelectDialogVisible(false)
            addTeacher(data)
          }}
          onCancel={() => setTeacherSelectDialogVisible(false)}
        />
      )}

      <TeacherImportDialog
        visible={importVisible}
        onCancel={() => setImportVisible(false)}
        onConfirm={data => {
          parseImportData(data)
          setImportVisible(false)
        }}
      />
    </div>
  )
}
