import React, { FC } from 'react'
import { message, Popconfirm, Typography } from 'antd'
import { EditContext } from 'core/service/useEdit'

const { Text } = Typography

export const RemoveConfirm: FC<{
  edit: EditContext<any, any>
  data: any
}> = props => {
  async function onConfirm() {
    const hide = message.loading('正在删除...')
    try {
      await props.edit.onRemove(props.data)
    } catch (e) {
    } finally {
      hide()
    }
  }
  return (
    <Popconfirm title={'确认删除'} onConfirm={onConfirm}>
      {props.children ? (
        (props.children as any)
      ) : (
        <Text type={'danger'} style={{ cursor: 'pointer' }}>
          删除
        </Text>
      )}
    </Popconfirm>
  )
}
