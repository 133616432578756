import constate from 'constate'
import EventEmitter from 'eventemitter3'
import _ from 'lodash'
import { useRef } from 'react'
import { Paper } from 'typing'
import { QuestionIdentity, QuestionNesting } from 'typing.paper'
import { isQuestionNesting } from 'utils/question'

const hooks = () => {
  const emitter = new EventEmitter<{
    'scoreForm:enterPress': (e: { questionId: QuestionIdentity }) => void
  }>()

  emitter.on('scoreForm:enterPress', e => {
    console.log('scoreForm:enterPress', e)
    focusNextQuestionForm(e.questionId)
  })

  const questions = useRef<Paper.Question[]>([])

  function initQuestions(_questions: Paper.Question[]) {
    questions.current = _questions
  }

  function focusFirstQuestionForm() {
    const first = questions.current[0]
    if (!first) return
    focusQuestionForm(first.id, {
      preventScroll: true
    })
  }

  function focusNextQuestionForm(current: QuestionIdentity) {
    const nextQuestionId = findNextQuestionId(current)
    if (!nextQuestionId) {
      // trigger submit
      $('.footer > button').trigger('click')
      return
    }
    focusQuestionForm(nextQuestionId)
  }

  function findNextQuestionId(
    current: QuestionIdentity
  ): QuestionIdentity | null {
    function _find(questionId: number): QuestionIdentity | null {
      const question = questions.current.findIndex(q => q.id === questionId)
      if (question === -1) {
        return null
      }
      const nextQuestion = questions.current[question + 1]
      if (!nextQuestion) {
        return null
      }
      return nextQuestion.id
    }

    return _find(current as number)
  }

  function focusQuestionForm(
    questionId: QuestionIdentity,
    options?: { preventScroll?: boolean }
  ) {
    console.log('focusQuestionForm', questionId)
    const $formInput = $(
      _.isArray(questionId)
        ? `#score-form_${questionId[0]}_${questionId[1]} .ant-input-number-input`
        : `#score-form_${questionId} .ant-input-number-input`
    ).first()
    $formInput.trigger('focus').trigger('select')

    if (options?.preventScroll !== true) {
      const $question = $(
        _.isArray(questionId)
          ? `#question_${questionId[0]}_${questionId[1]}`
          : `#question_${questionId}`
      )
      setTimeout(() => scrollQuestion($question), 100)
    }
  }

  function scrollQuestion(question) {
    if (!question.length) return
    // Scroll to question into view, with smooth animation, and top is 10% of viewport, use JQuery
    const top = question.offset()!.top - $(window).height()! * 0.1
    $('html, body').animate({ scrollTop: top }, 500)
  }

  return {
    initQuestions,
    focusFirstQuestionForm,
    focusQuestionForm,
    emitter
  }
}

const [ScoreFormNavigationProvider, useScoreFormNavigation] = constate(hooks)

ScoreFormNavigationProvider.displayName = 'ScoreFormNavigationProvider'
export {
  ScoreFormNavigationProvider as ScoreFormNavigateProvider,
  useScoreFormNavigation
}
