import _ from 'lodash'

export type SignableScoreText = {
  sign: '+' | '-'
  value: number
}

export function applyScoreText(texts: SignableScoreText[], baseScore: number) {
  let value = baseScore * 100
  _.forEach(texts, it => {
    value += it.value * 100
  })

  value = _.round(value / 100, 2)

  return value
}

export function parseScoreTextObjects(texts: fabric.Text[]) {
  return _.map(texts, obj => {
    const id = (obj as any).id
    const text = (obj as any).text
    const value = parseScoreTextString(text)
    return { id, text, value }
  })
}

export function getScoreTextObjects(canvas: fabric.Canvas): fabric.Text[] {
  return canvas
    .getObjects()
    .filter(
      obj => obj.type === 'text' && (obj as any).id?.startsWith('SCORE')
    ) as fabric.Text[]
}

/**
 * Pick number form text
 * patten `{prefix}({sign}{val})` or `({sign}{val})` or `{sign}{val}`
 * parse sign+val to float
 */
function parseScoreTextString(text: string): SignableScoreText {
  const reg = /([+-])?(\d+(?:\.\d+)?)/
  const match = text.match(reg)
  if (!match) return { sign: '-', value: 0 }
  return { sign: match[1] as '+' | '-', value: Number(match[1] + match[2]) }
}
