import { useMount } from '@umijs/hooks'
import { Button, Card, Col, Form, Row, Tag } from 'antd'
import qs from 'qs'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Search, Table } from '../../components'
import { createList } from '../../core/service'
import { useQuery } from '../../hooks/useQuery'
import { BatchTypeSelect } from './components/BatchTypeSelect'
import { ExamBatchSelect } from './components/ExamBatchSelect'
import { TermSelect } from './components/TermSelect'
import './style.less'

const { ListProvider, useListContext } = createList({
  url: () => '/statistics/gather/byteacher/batch'
})

export default function () {
  return (
    <ListProvider>
      <Page />
    </ListProvider>
  )
}

const Page = () => {
  const history = useHistory()
  const query = useParams<{ tid }>()
  const queryParams = useQuery<{ teacher }>()
  const list = useListContext()

  function onTableChange(pagination, filters, sorter) {
    if (list.index.value !== pagination.current) {
      window.scrollTo(0, 0)
      list.onIndexChange(pagination.current)
      return
    }

    list.setPageSize(pagination.pageSize)
    list.onSearchSubmit({
      order_type: !!sorter.order
        ? sorter.order === 'ascend'
          ? '0'
          : '1'
        : null
    })
  }

  useMount(() => {
    list.setDefaultSearch({
      teacher: query.tid
    })
    list.onSearchSubmit({})
  })

  return (
    <Card>
      <Row align={'middle'} className='teacher-report-header'>
        <Col flex={1}>
          <div className={'title'}>
            教师批次批阅统计
            <Tag className={'tag'}>{queryParams.teacher}</Tag>
          </div>
        </Col>
        <Col>
          <Button onClick={() => history.goBack()}>返回</Button>
        </Col>
      </Row>
      <Search list={list}>
        <Form.Item name={'term_id'}>
          <TermSelect />
        </Form.Item>
        <Form.Item name={'gather_id'}>
          <ExamBatchSelect />
        </Form.Item>
        <Form.Item name={'gather_type'}>
          <BatchTypeSelect />
        </Form.Item>
      </Search>

      <Table
        list={list}
        onChange={onTableChange}
        columns={[
          { title: '学期', dataIndex: 'xqmc', fixed: 'left' },
          { title: '考试批次', dataIndex: 'kspcmc', fixed: 'left' },
          { title: '考试批次类型', dataIndex: 'kspclx' },
          { title: '考试数', dataIndex: 'total', align: 'right' },

          {
            title: '应批阅试卷份数',
            dataIndex: 'exam_paper_count',
            align: 'right'
          },
          {
            title: '已批阅阅试份数',
            dataIndex: 'completed_paper_count',
            align: 'right'
          },
          {
            title: '待批阅试卷份数',
            dataIndex: 'uncompleted_paper_count',
            align: 'right'
          },
          {
            title: '批阅试卷完成率',
            dataIndex: 'exam_paper_completed_count_rate',
            align: 'center',
            render: t => `${t}%`
          },

          { title: '应批阅题目份数', dataIndex: 'total', align: 'right' },
          { title: '已批阅题目份数', dataIndex: 'done', align: 'right' },
          {
            title: '待批阅题目份数',
            dataIndex: 'total',
            align: 'right',
            render: (t, r) => t - r.done
          },
          {
            title: '批阅完成率',
            dataIndex: 'rate',
            render: (_, record) =>
              `${Math.round((record.done / record.total) * 10000) / 100}%`,
            align: 'center',
            sorter: true
          },
          {
            title: '操作',
            key: 'actions',
            render: (t, r) => {
              const q = {
                teacher: queryParams.teacher,
                batch: r.kspcmc || '考试批次名称',
                relationId: r.relationid,
                classId: r.classid
              }
              return (
                <Link
                  to={`/teacher-report/teachers/${query.tid}/batches/${
                    r.kspcid
                  }?${qs.stringify(q)}`}
                >
                  查看详情
                </Link>
              )
            }
          }
        ]}
        pagination={{
          current: list.index.value,
          total: list.total,
          pageSize: list.size,
          showSizeChanger: true,
          showQuickJumper: true,
          hideOnSinglePage: !true,
          showTotal: total => `共${total}条记录`
        }}
        scroll={{
          x: 'max-content'
        }}
        indexColumn={{ fixed: 'left' }}
      />
    </Card>
  )
}
