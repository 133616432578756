import { Divider } from 'antd'
import { QuestionType } from 'config'
import _ from 'lodash'
import React, { FC } from 'react'
import { Paper } from 'typing'
import { isQuestionNesting } from 'utils/question'
import { ScoreFormItem } from './ScoreFormItem'

export const ScoreForm: FC<{
  question: Paper.Question
  questionGroup: Paper.QuestionGroup
}> = ({ question, questionGroup }) => {
  return (
    <div id={`score-form_${question.id}`} className='score-form'>
      <div className={'header'}>
        <div className={'title'}>
          {questionGroup && (
            <div className={'title__group'}>
              {questionGroup.displayName}、
              {questionGroup.typeNameAlias || QuestionType[questionGroup.type]}
            </div>
          )}
          <div className={'title__question'}>
            {question.displayName}.{QuestionType[question.type]},{' '}
            {question.score}分
          </div>
        </div>
      </div>

      {isQuestionNesting(question) ? (
        <div className={'children'}>
          {_.map(question.children, (c, i) => {
            return (
              <div
                key={i}
                id={`score-form_${question.id}_${c.id}`}
                className='nesting-score-form'
              >
                <div className={'title primary'}>
                  {c.displayName} {QuestionType[c.type]}，{c.score}分
                </div>

                {/* Nesting form item */}
                <ScoreFormItem question={question} child={c} />
                {i < question.children.length - 1 && <Divider />}
              </div>
            )
          })}
        </div>
      ) : (
        <ScoreFormItem question={question} />
      )}
    </div>
  )
}
