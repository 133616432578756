import {
  ExclamationCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tooltip
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { MarkingMode } from 'config'
import _ from 'lodash'
import { useMarkingSettingEditContext } from 'pages/exam-papers/store'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Paper, QuestionBindGroup, Teacher } from 'typing'
import { QuestionDisplayTag, questionsToDisplayTags } from 'utils/question'
import { PaperMarkingSettingDistributeStatus } from '../../../../typing.paper'
import { RedistributeDialog } from '../RedistributeDialog'
import { TeacherSelectDialogForFanya } from '../TeacherSelect/DialogForFanya'
import { useMarkingSettingContent } from '../useMarkingSettingState'
import {
  useBindQuestionContent,
  useBindTeacherContent,
  useQuestionBindGroupContent
} from '../useQuestionBindGroup'
import { useRedistributeContext } from '../useRedistribute'
import { BindingQuestionDialog } from './BindingQuestionDialog'
import { BindingTeacherDialog } from './BindingTeacherDialog'

type QuestionBindGroupVO = QuestionBindGroup & {
  displayTags: QuestionDisplayTag[]
}

export const QuestionBindingGroup: FC<{
  markingMode?: MarkingMode
  readonly: boolean
}> = props => {
  const edit = useMarkingSettingEditContext()

  const { readonly } = props

  const {
    distributeStatus,
    paperQuestions,
    questionCount,
    findSchool,
    replaceTeacher,
    teacherList
  } = useMarkingSettingContent()

  const {
    questionBindGroup,
    questionBindGroupSetter
  } = useQuestionBindGroupContent()

  const {
    onBindingQuestionEdit,
    onBindingQuestionEditSubmit
  } = useBindQuestionContent()

  const {
    onBindingTeacherEdit,
    onBindingTeacherEditSubmit,
    //
    onAddInnerTeacherGroup,
    onRemoveInnerTeacherGroup
  } = useBindTeacherContent()

  const { open: openRedistributeDialog } = useRedistributeContext()

  // questionBindGroupVO
  const [questionBindGroupVO, setQuestionBindGroupVO] = useState<
    QuestionBindGroupVO[]
  >([])

  function transToQuestionDisplayTag(questions: Paper.Question[]) {
    return questionsToDisplayTags(paperQuestions, questions)
  }

  useEffect(() => {
    setQuestionBindGroupVO(
      _.map(questionBindGroup, bg => {
        return {
          ...bg,
          displayTags: transToQuestionDisplayTag(bg.questions)
        } as QuestionBindGroupVO
      })
    )
  }, [questionBindGroup])

  useEffect(() => {
    console.debug('questionBindGroupVO', questionBindGroupVO)
  }, [questionBindGroupVO])

  // Replace teacher
  const teacherToReplacement = useRef<{
    teacher: Teacher
    groupIndex: number
  }>()
  const [teacherSelectDialogVisible, setTeacherSelectDialogVisible] = useState(
    false
  )

  function openTeacherSelect(t: Teacher, groupIndex: number) {
    teacherToReplacement.current = {
      teacher: t,
      groupIndex
    }
    setTeacherSelectDialogVisible(true)
  }
  function onTeacherSelectConfirm(t: Teacher[]) {
    const from = teacherToReplacement.current?.teacher!
    const to = t[0]
    if (!to) return

    console.log('questionBindGroupVO', questionBindGroupVO)
    const questionBindGroup =
      questionBindGroupVO[teacherToReplacement.current!.groupIndex]

    Modal.confirm({
      content: `确认将判卷人员 [${from.display_name}] 替换为 [${to.display_name}]`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await replaceTeacher(
          from,
          to,
          questionBindGroup.questions.map(q => q.id)
        )
        setTeacherSelectDialogVisible(false)
      }
    })
  }

  function createTableColumns() {
    function renderTeacherList(list: Teacher[], groupIndex: number) {
      return (
        <div className={'normal-tag-list'}>
          {list.map((t, i) => (
            <Popover
              key={i}
              content={
                <div>
                  {findSchool(t.fid).name && (
                    <div>学校：{findSchool(t.fid).name}</div>
                  )}
                  <div>工号：{t.name}</div>
                  <div>手机号：{t.tel || '-'}</div>
                  {distributeStatus ===
                    PaperMarkingSettingDistributeStatus.DONE && (
                    <Button
                      type={'link'}
                      style={{ padding: 0 }}
                      onClick={() => openTeacherSelect(t, groupIndex)}
                    >
                      替换
                    </Button>
                  )}
                </div>
              }
            >
              <div key={t.uid} className={'normal-tag'}>
                {t.display_name}
              </div>
            </Popover>
          ))}
          {!readonly && (
            <div
              className={'normal-tag normal-tag--add'}
              onClick={() => onBindingTeacherEdit(groupIndex)}
            >
              <PlusOutlined />
            </div>
          )}
        </div>
      )
    }
    function renderGroupedTeacherList(
      groupedTeachers: Teacher[][],
      questionGroupIndex: number,
      questionBindGroupVO: QuestionBindGroupVO
    ) {
      return (
        <div className={'normal-tag-list'}>
          {groupedTeachers.map((group, teacherGroupIndex) => (
            <div className='teacher-group'>
              <Card
                className='title'
                size='small'
                title={`小组${teacherGroupIndex + 1}`}
                extra={
                  !readonly && (
                    <a
                      onClick={() =>
                        onRemoveInnerTeacherGroup(
                          questionGroupIndex,
                          teacherGroupIndex
                        )
                      }
                    >
                      删除
                    </a>
                  )
                }
                type='inner'
                style={{ marginBottom: 8 }}
              >
                <div className='teachers'>
                  {group.map(t => (
                    <Popover
                      key={teacherGroupIndex}
                      content={
                        <div>
                          <div>工号：{t.name}</div>
                          <div>手机号：{t.tel || '-'}</div>
                        </div>
                      }
                    >
                      <div key={t.uid} className={'normal-tag'}>
                        {t.display_name}
                      </div>
                    </Popover>
                  ))}
                  {!readonly && (
                    <div
                      className={'normal-tag normal-tag--add'}
                      onClick={() =>
                        onBindingTeacherEdit(
                          questionGroupIndex,
                          teacherGroupIndex
                        )
                      }
                    >
                      <PlusOutlined />
                    </div>
                  )}
                </div>

                <div style={{ textAlign: 'right' }}>
                  {distributeStatus ===
                    PaperMarkingSettingDistributeStatus.DONE &&
                    edit.data.review_mode === MarkingMode.AUDIT && (
                      <a
                        onClick={() =>
                          openRedistributeDialog(
                            questionBindGroupVO,
                            teacherGroupIndex
                          )
                        }
                      >
                        重新分配{' '}
                        <Tooltip title={'针对未批阅试卷, 重新分配教师'}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </a>
                    )}
                </div>
              </Card>
            </div>
          ))}
          {!readonly && (
            <Button
              type='primary'
              size='small'
              style={{ margin: '8px 0 16px' }}
              onClick={() => onAddInnerTeacherGroup(questionGroupIndex)}
            >
              添加小组
            </Button>
          )}
        </div>
      )
    }

    const columns: ColumnType<QuestionBindGroupVO>[] = [
      {
        title: '序号',
        key: 'index',
        align: 'center',
        width: '10%',
        render: (t, r, i) => i + 1
      },
      {
        title: '题号',
        dataIndex: 'displayTags',
        render: (displayTags: QuestionDisplayTag[], r, i) => (
          <div className={'normal-tag-list'}>
            {displayTags.map((q, i) => (
              <div key={i} className={'normal-tag'} data-question={q.id}>
                <span>{q.groupName}、</span>
                <span>{q.displayName}</span>
              </div>
            ))}
            {!readonly && (
              <div
                className={'normal-tag normal-tag--add'}
                onClick={() => onBindingQuestionEdit(i)}
              >
                <PlusOutlined />
              </div>
            )}
          </div>
        )
      }
    ]
    if (
      typeof props.markingMode === 'number' &&
      [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(props.markingMode)
    ) {
      columns.push({
        title: '人员分组',
        dataIndex: 'groupedTeachers',
        render: (list: Teacher[][], t, index) =>
          renderGroupedTeacherList(list, index, t)
      })
    } else {
      columns.push({
        title: '人员分组',
        dataIndex: 'teachers',
        render: (list: Teacher[], t, index) => renderTeacherList(list, index)
      })
    }
    columns.push({
      title: '操作',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (t, r, i) => (
        <>
          {!readonly && (
            <Popconfirm
              title={'确认删除'}
              onConfirm={() => questionBindGroupSetter.removeAt(i)}
            >
              <a className={'danger'}>删除</a>
            </Popconfirm>
          )}
          {distributeStatus === PaperMarkingSettingDistributeStatus.DONE &&
            edit.data.review_mode === MarkingMode.GROUP_FLOW && (
              <a onClick={() => openRedistributeDialog(r)}>
                重新分配{' '}
                <Tooltip title={'针对未批阅试卷, 重新分配教师'}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </a>
            )}
        </>
      )
    })
    return columns
  }

  const teachersThatCrossMultiGroup = useMemo(() => {
    const result = _.reduce<Teacher, Teacher[]>(
      teacherList,
      (acc, t) => {
        // find group include teacher
        const group = questionBindGroupVO.filter(q => {
          return q.teachers.find(teacher => teacher.uid === t.uid)
        })
        if (group.length > 1) {
          acc.push(t)
        }
        return acc
      },
      []
    )
    return result
  }, [questionBindGroupVO, teacherList])

  return (
    <div className={'question-group-bind-control-wrapper'}>
      {paperQuestions && (
        <div style={{ marginBottom: 8 }}>共{questionCount}题，分组如下：</div>
      )}

      {!!teachersThatCrossMultiGroup.length && (
        <Alert
          message={`注意: ${teachersThatCrossMultiGroup
            .map(t => `[${t.display_name}]`)
            .join('、')} 被分配到了多个题组`}
          type={'warning'}
          showIcon
          style={{ marginBottom: 18 }}
        />
      )}

      <Table<QuestionBindGroupVO>
        dataSource={questionBindGroupVO}
        rowKey={'_id'}
        size={'small'}
        bordered
        pagination={false}
        rowClassName={'row-disable-hover'}
        tableLayout={'fixed'}
        columns={createTableColumns()}
      />

      {/* 教师分组 */}
      {!readonly && (
        <Space style={{ marginTop: 16 }}>
          <Button
            type={'primary'}
            onClick={() => {
              if (
                props.markingMode &&
                [MarkingMode.AVERAGE, MarkingMode.AUDIT].includes(
                  props.markingMode
                )
              ) {
                questionBindGroupSetter.push({
                  questions: [],
                  teachers: [],
                  groupedTeachers: [[]],
                  _id: Date.now()
                })
              } else
                questionBindGroupSetter.push({
                  questions: [],
                  teachers: [],
                  _id: Date.now()
                })
            }}
          >
            添加题组
          </Button>
        </Space>
      )}

      <BindingQuestionDialog onConfirm={onBindingQuestionEditSubmit} />
      <BindingTeacherDialog onConfirm={onBindingTeacherEditSubmit} />

      <TeacherSelectDialogForFanya
        multi={false}
        visible={teacherSelectDialogVisible}
        onConfirm={data => onTeacherSelectConfirm(data)}
        onCancel={() => setTeacherSelectDialogVisible(false)}
      />

      <RedistributeDialog />
    </div>
  )
}
