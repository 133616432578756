import React, { FC } from 'react'
import { Alert, Space } from 'antd'
import { useExamPaperReportSummaryContent } from 'hooks/useExamPaperReportSummary'
import numeral from 'numeral'
import { Report } from 'typing'

export const SummaryAlert: FC<{
  scopeParams: Report.BasicScopeParams
}> = ({ scopeParams }) => {
  const { summary } = useExamPaperReportSummaryContent()

  if (!summary) return null
  return (
    <Alert
      type={'info'}
      showIcon
      message={
        <Space size={'large'}>
          {scopeParams.scope === 'school' && (
            <span>参与学校：{summary.org_count}</span>
          )}
          {scopeParams.scope === 'clazz' && (
            <span>参与班级：{summary.class_count}</span>
          )}
          <span>参与学生：{numeral(summary.student_count).format('0,0')}</span>
          <span>试卷总分：{summary.paper_score}</span>
          <span>试题总数：{summary.paper_quest_count}</span>
        </Space>
      }
      style={{ marginBottom: 24 }}
    />
  )
}
