import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tag
} from 'antd'
import React, { FC, useMemo, useState } from 'react'
import { QuestionIdentity, SteppedScoreSetting } from 'typing.paper'
import { numberToChinese } from 'utils'
import { getQuestionDisplayName, getQuestionPath } from 'utils/question'
import { useMarkingSettingContent } from '../../../exam-papers/MarkingSettingDialog/useMarkingSettingState'
import { QuestionSelect } from '../QuestionSelect'

type T = SteppedScoreSetting['items'][number]

export const StepScoreFormItem: FC<{ readonly: boolean }> = props => {
  const {
    paperQuestions,
    stepScoreSettingEnable,
    setStepScoreSettingEnable,
    stepScoreSettingItems,
    setStepScoreSettingItems,
    getDefaultStepScoreSettingItem
  } = useMarkingSettingContent()

  function remove(index: number) {
    setStepScoreSettingItems(prev => {
      const next = [...prev]
      next.splice(index, 1)
      return next
    })
  }

  // Edit
  const [editVisible, setEditVisible] = useState(false)
  const [editIndex, setEditIndex] = useState(-1)
  const isEdit = useMemo(() => editIndex > -1, [editIndex])
  const [form] = Form.useForm()

  const disabledIds = useMemo(() => {
    return stepScoreSettingItems
      .filter((v, i) => i !== editIndex)
      .map(v => v.question_id)
  }, [editIndex, stepScoreSettingItems])

  const [currentQuestionId, setCurrentQuestionId] = useState<QuestionIdentity>()
  const questionScore = useMemo(() => {
    if (!currentQuestionId) return null

    const path = getQuestionPath(paperQuestions, currentQuestionId)
    if (!path?.length) return null

    const question = path[path.length - 1]

    return question?.score
  }, [currentQuestionId, paperQuestions])

  function add() {
    form.resetFields()
    form.setFieldsValue(getDefaultStepScoreSettingItem())

    setEditIndex(-1)
    setEditVisible(true)
  }

  function edit(row: T, index: number) {
    setCurrentQuestionId(row.question_id)
    form.setFieldsValue(row)

    setEditIndex(index)
    setEditVisible(true)
  }

  async function submit() {
    const value = await form.validateFields()

    if (!validate(value as any)) {
      return
    }

    if (isEdit) {
      const newRow = {
        ...stepScoreSettingItems[editIndex],
        ...value
      }
      setStepScoreSettingItems(prev => {
        const next = [...prev]
        next.splice(editIndex, 1, newRow)
        return next
      })
    } else {
      setStepScoreSettingItems([
        ...stepScoreSettingItems,
        {
          ...getDefaultStepScoreSettingItem(),
          ...value
        }
      ])
    }

    setEditVisible(false)
  }

  function validate(value: T) {
    if (!value.steps?.length) {
      Modal.error({
        title: '请添加打分步骤'
      })
      return false
    }

    const totalScore = value.steps.reduce((prev, cur) => prev + cur.score, 0)
    if (totalScore !== questionScore) {
      Modal.error({
        title: '打分步骤分值总和与题目分值不一致',
        content: (
          <div>
            <div>步骤分值总和: {totalScore}分</div>
            <div>题目分值: {questionScore}分</div>
          </div>
        )
      })
      return false
    }

    return true
  }

  function getStepItem() {
    const stepLength = form.getFieldValue('steps')?.length || 0
    return {
      title: `第${numberToChinese(stepLength + 1)}步`
    }
  }

  function getQuestionName(questionId: QuestionIdentity) {
    const path = getQuestionPath(paperQuestions, questionId)
    if (!path) return ''
    return getQuestionDisplayName(path)
  }

  return (
    <Form.Item label='按步打分设置'>
      <Card size='small'>
        <Form.Item label='启用' labelAlign='left' style={{ marginBottom: 0 }}>
          <Switch
            checked={stepScoreSettingEnable}
            onChange={v => setStepScoreSettingEnable(v)}
            disabled={props.readonly}
          ></Switch>
        </Form.Item>

        {stepScoreSettingEnable && (
          <div style={{ marginTop: 16 }}>
            <Button type='primary' style={{ marginBottom: 8 }} onClick={add}>
              新增
            </Button>
            <Table
              rowKey={(v, i) => String(i)}
              dataSource={stepScoreSettingItems}
              columns={[
                {
                  title: '序号',
                  key: 'index',
                  render: (v, r, i) => i + 1
                },
                {
                  title: '题号',
                  dataIndex: 'question_id',
                  render: v => (
                    <div className='normal-tag'>{getQuestionName(v)}</div>
                  )
                },
                {
                  title: '打分步骤',
                  dataIndex: 'steps',
                  render: v => (
                    <Space>
                      <span>共{v.length}步,</span>
                      {v.map((step, i) => (
                        <Tag key={i}>
                          {step.title}: {step.score}分
                        </Tag>
                      ))}
                    </Space>
                  )
                },
                {
                  title: '操作',
                  key: 'action',
                  render: (v, r, i) => (
                    <Space key={i}>
                      <a onClick={() => edit(r, i)}>编辑</a>
                      <Popconfirm title='确认删除' onConfirm={() => remove(i)}>
                        <a>删除</a>
                      </Popconfirm>
                    </Space>
                  )
                }
              ]}
              pagination={false}
            ></Table>
          </div>
        )}
      </Card>

      <Modal
        visible={editVisible}
        title='按步打分设置'
        onOk={submit}
        onCancel={() => setEditVisible(false)}
        destroyOnClose
      >
        <Form form={form}>
          <Form.Item
            label='题号'
            name='question_id'
            labelCol={{ flex: '0 0 5em' }}
            rules={[{ required: true, message: '必填' }]}
          >
            <QuestionSelect
              nesting
              disabledIds={disabledIds}
              onChange={q => setCurrentQuestionId(q as any)}
            />
          </Form.Item>
          <Form.List name='steps'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...rest }, index) => (
                  <Row key={index} style={{ marginBottom: 16 }}>
                    <Col
                      flex='0 0 5em'
                      style={{
                        color: 'rgba(0,0,0,0.85)',
                        textAlign: 'right',
                        paddingRight: 10
                      }}
                    >
                      第{index + 1}步:
                    </Col>
                    <Col flex={1}>
                      <Card size='small'>
                        <Form.Item
                          label='步骤名称'
                          labelCol={{ span: 5 }}
                          {...rest}
                          name={[name, 'title']}
                          rules={[{ required: true, message: '必填' }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label='分值'
                          labelCol={{ span: 5 }}
                          {...rest}
                          name={[name, 'score']}
                          rules={[
                            { required: true, message: '必填' },
                            { type: 'number', min: 0, max: 100 }
                          ]}
                        >
                          <InputNumber />
                        </Form.Item>
                        <Form.Item
                          label='说明'
                          labelCol={{ span: 5 }}
                          {...rest}
                          name={[name, 'description']}
                        >
                          <Input />
                        </Form.Item>
                        {index > 1 && (
                          <div style={{ textAlign: 'right' }}>
                            <a onClick={() => remove(index)}>删除</a>
                          </div>
                        )}
                      </Card>
                    </Col>
                  </Row>
                ))}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => add(getStepItem())}
                  >
                    添加步骤
                  </Button>
                </div>
              </>
            )}
          </Form.List>
        </Form>

        {questionScore && (
          <div style={{ marginTop: '20px' }}>
            <Alert type='warning' message={<>该题分值: {questionScore}分</>} />
          </div>
        )}
      </Modal>
    </Form.Item>
  )
}
