import { FC, useState, useEffect } from 'react'
import { POST } from 'core/request'
import { Modal } from 'antd'
import React from 'react'

export const SimilarityModal: FC<{
  data: { studentAnswer: string; rightAnswer: string; score: number }
  onClose: () => void
}> = props => {
  function toPlain(html = '') {
    return $(`<div>${html}</div>`).text()
  }

  const [rate, setRate] = useState('-')
  const [rScore, setRScore] = useState('-')

  async function fetch() {
    try {
      const { data } = await POST('fanya/exam/paper/answer/similarity', {
        data: {
          answer: toPlain(props.data.studentAnswer),
          content: toPlain(props.data.rightAnswer)
        }
      })
      if (typeof data !== 'undefined') {
        setRate(`${data * 100}%`)
        setRScore(`${Math.round(data * props.data.score * 10) / 10}`)
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <Modal
      title={'相似度查询'}
      visible={true}
      onCancel={props.onClose}
      footer={null}
    >
      <div className='success' style={{ textAlign: 'center', fontSize: 24 }}>
        <span style={{ marginRight: 32 }}>相似度: {rate}</span>
        <span>建议得分: {rScore}</span>
      </div>
      <div style={{ paddingBottom: 4 }}>学生答案:</div>
      <div
        dangerouslySetInnerHTML={{ __html: toPlain(props.data.studentAnswer) }}
        style={{ border: '1px solid #eee', padding: 8, marginBottom: 16 }}
      ></div>
      <div style={{ paddingBottom: 4 }}>正确答案:</div>
      <div
        dangerouslySetInnerHTML={{ __html: props.data.rightAnswer }}
        style={{ border: '1px solid #eee', padding: 8, marginBottom: 0 }}
      ></div>
    </Modal>
  )
}
