import React, { FC } from 'react'
import { Paper } from 'typing'
import { Card, Col, Row, Typography } from 'antd'
import {
  isQuestionContent,
  isQuestionNesting,
  isQuestionOption
} from 'utils/question'
import classNames from 'classnames'
import { RichText } from 'components/Preview/RichText'

const { Text } = Typography

export const QuestionItemPreview: FC<{
  q: Paper.Question
  i: number
  answerRightVisible: boolean
}> = ({ q, i, answerRightVisible }) => (
  <Card key={i} size={'small'} className={'question'}>
    <div className={'content'}>
      <Row align={'middle'}>
        <Col className={'index'}>{q.displayName}. </Col>
        <Col flex={'auto'}>
          <RichText className={'ct'} html={q.content} emptyText={'无题干'} />
        </Col>
        <Col>
          <Text type={'secondary'}>{q.score}分</Text>
        </Col>
      </Row>
    </div>
    <div className={'answers-right'}>
      {isQuestionOption(q) &&
        q.answer.map((a, i) => (
          <Row
            key={i}
            className={classNames('answer', {
              success: answerRightVisible && a.isanswer
            })}
          >
            <Col style={{ marginRight: 8 }}>{a.name}.</Col>
            <Col>
              <RichText html={a.content} emptyText={'无答案'} />
            </Col>
          </Row>
        ))}
      {isQuestionContent(q) &&
        answerRightVisible &&
        q.answer.map((a, i) => (
          <div key={i} className={'answer'}>
            <RichText html={a.content || a.answer} emptyText={'无答案'} />
          </div>
        ))}
      {isQuestionNesting(q) &&
        q.children.map((c, i) => (
          <QuestionItemPreview
            key={i}
            q={c}
            i={i}
            answerRightVisible={answerRightVisible}
          />
        ))}
    </div>
  </Card>
)
