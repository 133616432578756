import React, { FC } from 'react'
import { Button, Card, Empty, Spin } from 'antd'
import { StatisticState } from 'config'
import { useStatisticStateContext } from 'hooks/useStatisticState'

export const ReportStateGuard: FC<{
  openSetting: () => void
}> = props => {
  const {
    loading,
    statisticConfig,
    statisticState
  } = useStatisticStateContext()

  if (loading) {
    return <Spin />
  }

  if (!statisticConfig) {
    return (
      <Card>
        <Empty
          description={
            <span>
              请完成 <a onClick={() => props.openSetting()}>成绩统计设置</a>
            </span>
          }
        />
      </Card>
    )
  }

  if (!statisticState) {
    return <Spin />
  }

  if (statisticState) {
    if (statisticState.statistic_status === StatisticState.UN_COMPUTED) {
      return (
        <Card>
          <Empty />
        </Card>
      )
    } else if (statisticState.statistic_status === StatisticState.COMPUTED) {
      return <>{props.children}</>
    } else {
      return (
        <Card>
          <Empty description={'正在汇总成绩，请稍等...'}>
            <Button
              type={'primary'}
              disabled={loading}
              onClick={() => location.reload()}
            >
              刷新
            </Button>
          </Empty>
        </Card>
      )
    }
  }

  return null
}
