import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Form, Select, Spin } from 'antd'
import { GET } from 'core/request'
import { Paper } from 'typing'
import _ from 'lodash'

export const ReportPaperSelectWrapper: FC<{
  examId
  children: (paper: Paper.Paper) => ReactNode
}> = props => {
  const [papers, setPapers] = useState<Paper.Paper[]>([])
  const [currentPaper, setCurrentPaper] = useState<Paper.Paper>()
  const [loading, setLoading] = useState(true)

  async function fetch() {
    const { data } = await GET('/city/exam/paper', {
      data: { exam_id: props.examId }
    })
    const papers = data.items as Paper.Paper[]
    setPapers(papers)
    setCurrentPaper(papers[0])
    setLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  if (!currentPaper) {
    return <Spin />
  }

  return (
    <>
      <Form layout={'inline'} style={{ marginBottom: 24 }}>
        <Form.Item label={'试卷'}>
          <Select
            loading={loading}
            value={currentPaper.id}
            onChange={id => {
              setCurrentPaper(_.find(papers, { id }))
            }}
            style={{ width: 450 }}
          >
            {papers.map(p => (
              <Select.Option key={p.id} value={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {!!currentPaper && props.children(currentPaper)}
    </>
  )
}
