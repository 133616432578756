import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useLocalStorageState, useUpdateEffect } from '@umijs/hooks'
import { Modal, message } from 'antd'
import AMModal from 'antd-mobile/es/modal'
import { ImageDrawerAPI } from 'components/ImageDrawer/ImageDrawer'
import computeScrollIntoView from 'compute-scroll-into-view'
import {
  APP_SCOPE,
  browserHistory,
  isRuntimeCXStudy,
  redirectToFanYa
} from 'config'
import constate from 'constate'
import { GET, POST, defaultErrorHandler } from 'core/request'
import { MarkingTagsProvider } from 'hooks/useMarkingTags'
import {
  QuestionAnswerDataProvider,
  User,
  useQuestionAnswerDataContent
} from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import qs from 'qs'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { useList, useSessionStorage } from 'react-use'
import { Marking, Paper } from 'typing'
import { MarkingAuditStatus } from 'typing.marking'
import { QuestionIdentity } from 'typing.paper'
import { AnswerImage } from 'utils'
import { fetchStudentAnswer, getMarkingCommentDTO } from '../../utils/answer'
import {
  CXStudyCloseWindow,
  CXStudySetRefreshState
} from '../../utils/runtime-study'

export enum StudentMarkStatus {
  UNMARKED = 0,
  MARKED = 1,
  PARTIAL_MARKED = 4
}

const log = require('debug')('useMarkingForm:')

// const PAGE_RELOAD_TIMES = 10
//
type MarkingQuery<V = string> = {
  source?: 'kaoshi'
  backurl?: string
  index?: V
  spec?: V
  skipped_students?: string
  marking_group_index?: V
}

const hooks = () => {
  const history = useHistory()
  const queryData = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }) as MarkingQuery

  function getMarkingIndexFromQuery() {
    return queryData.index ? _.parseInt(queryData.index) : null
  }

  function getSpecMarkingIdFromQuery() {
    return queryData.spec ? _.parseInt(queryData.spec) : null
  }

  function getSkipStudentIdsFromQuery() {
    if (!queryData.skipped_students) return null
    return _.chain(queryData)
      .get('skipped_students', '')
      .split(',')
      .filter(s => s.length > 0)
      .map(_.parseInt)
      .filter(_.isNumber)
      .value()
  }

  function getTeacherMarkingQuestionGroupIndexFromQuery() {
    return queryData.marking_group_index
      ? _.parseInt(queryData.marking_group_index)
      : 0
  }

  const {
    isInitialized,
    loading,
    markingPaperId,
    setMarkingPaperId,
    markingPaper,
    currentUser,

    paperQuestions,
    updateMarkingQuestions,
    markingQuestions,
    findQuestion,

    setCurrentStudentId,
    currentStudentAnswerOrigin,
    currentStudentAnswerError,
    currentStudentAnswer,
    getAnswerByQuestion,

    commentForm,
    setCommentImage,

    isCurrentUserHasAuditRole,
    checkQuestionAuditStatus,
    findCurrentAuditedQuestionIds,

    isImageMarkingMode
  } = useQuestionAnswerDataContent()

  const [saving, setSaving] = useState(false)

  //#region config
  const [showContent, toggleContentVisible] = useLocalStorageState(
    'marking-question-content-visible',
    false
  )
  const [showAnswerRight, toggleAnswerRightVisible] = useLocalStorageState(
    'marking-answer-right-visible',
    false
  )
  // Skip marked paper by questions score detecting
  const [markingStatusAutoDetect, toggleMarkingStatusAutoDetect] = useState(
    false
  )

  const [
    markingSubmitConfirmIgnore,
    setMarkingSubmitConfirmIgnore
  ] = useLocalStorageState('markingSubmitConfirmIgnore', false)

  const [
    openDrawerOnClickImage,
    toggleOpenDrawerOnClickImage
  ] = useLocalStorageState('marking-open-drawer-on-click-image', true)

  const [userImageMarkingMode, toggleUserImageMarkingMode] = useSessionStorage<
    boolean | undefined
  >('user-image-marking-mode')

  useEffect(() => {
    if (!isInitialized) return

    if (isImageMarkingMode) {
      // userImageMarkingMode has higher priority
      if (typeof userImageMarkingMode === 'undefined') {
        toggleUserImageMarkingMode(isImageMarkingMode)
      }
    } else {
      // reset userImageMarkingMode
      toggleUserImageMarkingMode(false)
    }
  }, [isInitialized, isImageMarkingMode])

  const [auditQuestionFilter, setAuditQuestionFilter] = useState<
    'ALL' | 'UNAUDIT' | 'AUDITED'
  >('UNAUDIT')
  //#endregion

  //#region PartialMarking
  const [
    partialMarkingSettingVisible,
    setPartialMarkingSettingVisible
  ] = useState(false)

  const [partialMarkingQuestions, partialMarkingQuestionsSetter] = useState<
    QuestionIdentity[]
  >([])

  const [
    partialMarkingQuestionsInitialed,
    setPartialMarkingQuestionsInitialed
  ] = useState(false)

  const getPartialMarkingStorageKey = (
    markingPaperId: number,
    currentUser: User,
    teacherMarkingQuestionGroupIndex = 0
  ) =>
    `partial-marking-questions-${markingPaperId}-${currentUser.uid}-${teacherMarkingQuestionGroupIndex}`

  // Init partialMarkingQuestions setting
  useEffect(() => {
    if (partialMarkingQuestionsInitialed) return
    if (!markingPaperId || !currentUser) return

    const valueOrigin = localStorage.getItem(
      getPartialMarkingStorageKey(markingPaperId, currentUser)
    )
    if (valueOrigin) {
      log('getPartialMarkingStorageKey', valueOrigin)
      partialMarkingQuestionsSetter(JSON.parse(valueOrigin))
    }
    setPartialMarkingQuestionsInitialed(true)
  }, [
    markingPaperId,
    currentUser,
    partialMarkingQuestions,
    partialMarkingQuestionsInitialed
  ])

  // Storage
  useEffect(() => {
    if (!partialMarkingQuestionsInitialed) return
    if (!markingPaperId || !currentUser) return

    localStorage.setItem(
      getPartialMarkingStorageKey(markingPaperId, currentUser),
      JSON.stringify(partialMarkingQuestions)
    )
  }, [
    partialMarkingQuestionsInitialed,
    partialMarkingQuestions,
    currentUser,
    markingPaperId
  ])

  const isPartialMarkingMode = useMemo(
    () => partialMarkingQuestions.length > 0,
    [partialMarkingQuestions]
  )

  //#endregion

  //#region marking question group
  const [
    isTeacherMarkingQuestionGroupInitialed,
    setTeacherMarkingQuestionGroupInitialed
  ] = useState(false)

  const [
    teacherMarkingQuestionGroup,
    setTeacherMarkingQuestionGroup
  ] = useState<QuestionIdentity[][]>()

  const [teacherCurrentMarkingQuestionGroupIndex] = useState<number>(
    getTeacherMarkingQuestionGroupIndexFromQuery() || 0
  )

  const teacherCurrentMarkingQuestionGroup = useMemo(() => {
    if (!teacherMarkingQuestionGroup || !teacherMarkingQuestionGroup.length) {
      return null
    }
    return teacherMarkingQuestionGroup[teacherCurrentMarkingQuestionGroupIndex]
  }, [teacherCurrentMarkingQuestionGroupIndex, teacherMarkingQuestionGroup])

  const teacherHasMultipleMarkingQuestionGroup = useMemo(
    () => teacherMarkingQuestionGroup && teacherMarkingQuestionGroup.length > 1,
    [teacherMarkingQuestionGroup]
  )

  async function fetchMarkingQuestionGroup() {
    try {
      const { data } = await GET('fanya/exam/paper/teacher/quest_group', {
        data: { exam_paper_id: markingPaper?.exam_paper_id }
      })
      // log('fetchMarkingQuestionGroup', data)
      setTeacherMarkingQuestionGroup(data)
      setTeacherMarkingQuestionGroupInitialed(true)
    } catch (e) {
      defaultErrorHandler(e as any)
    }
  }

  // Update  markingPaperDetail.quest
  useEffect(() => {
    if (!isInitialized) return
    if (!teacherCurrentMarkingQuestionGroup) return

    updateMarkingQuestions({
      questions: teacherCurrentMarkingQuestionGroup
    })
  }, [teacherCurrentMarkingQuestionGroup, isInitialized])
  //#endregion

  //#region Student
  type Student = {
    id: number
    is_complete: StudentMarkStatus
    complete_questions?: QuestionIdentity[]
    student_login_name: string
    student_user_name: string
  }
  const [
    studentList,
    { set: setStudentList, updateAt: updateStudentListAt }
  ] = useList<Student>([])
  const [currentStudentIndex, setCurrentStudentIndex] = useState<number>()
  const [currentStudent, setCurrentStudent] = useState<Student>()
  const [isAllMarkedOnInitial, setIsAllMarkedOnInitial] = useState(false)

  const skippedStudentIds = useRef(getSkipStudentIdsFromQuery() || [])
  function pushSkippedStudentIds(n: number) {
    skippedStudentIds.current.push(n)
  }

  async function fetchStudents(
    params: {
      includeQuestions?: QuestionIdentity[]
      teacherQuestionGroup?: QuestionIdentity[]
    } = {}
  ) {
    try {
      const { data } = await GET(
        `/${
          APP_SCOPE.isInFanYa ? 'fanya' : 'review'
        }/paper/${markingPaperId}/students`,
        {
          data: {
            // 仲裁模式下，支持部分批阅
            question_ids: params.includeQuestions
              ? _.join(params.includeQuestions, ',')
              : undefined,
            quest_group: params.teacherQuestionGroup
              ? _.join(params.teacherQuestionGroup, ',')
              : undefined
          }
        }
      )

      const students = _.sortBy(data, ['id']).map(it => ({
        ...it,
        is_complete: it.is_complete ?? 0
      }))

      setStudentList(students)

      setIsAllMarkedOnInitial(
        _.every(students, it => it.is_complete === StudentMarkStatus.MARKED)
      )

      if (!students.length) {
        Modal.warning({
          title: '无法获取到学生答卷',
          content: '请在考试结束后进入',
          onOk: () => {
            redirectToFanYa()
          }
        })
      }
    } catch (e) {
      Modal.error({
        title: (e as any).message,
        onOk: () => {
          if (APP_SCOPE.isInFanYa) {
            redirectToFanYa()
          } else {
            browserHistory.goBack()
          }
        }
      })
    }
  }

  const getInitialStudentIndex = useCallback(
    (data: Student[]) => {
      const specMarkingId = getSpecMarkingIdFromQuery()
      const specIndex = getMarkingIndexFromQuery()

      // 回评
      if (specMarkingId !== null) {
        return _.findIndex<Student>(data, s => s.id === specMarkingId)
      }

      // 强制刷新
      if (specIndex !== null) {
        return specIndex
      }

      // 部分批阅
      if (isPartialMarkingMode) {
        // Find first unmarked student that match partialMarkingQuestions
        return _.findIndex<Student>(data, s => {
          if (s.is_complete === StudentMarkStatus.MARKED) {
            return false
          }
          return !checkStudentHasMarkedByPartialQuestions(s)
        })
      }

      return _.findIndex<Student>(data, s =>
        [StudentMarkStatus.UNMARKED, StudentMarkStatus.PARTIAL_MARKED].includes(
          s.is_complete
        )
      )
    },
    [isPartialMarkingMode]
  )

  // Initial currentStudentIndex
  useEffect(() => {
    if (!studentList.length) return
    if (currentStudentIndex !== undefined) return
    if (!partialMarkingQuestionsInitialed) return

    const beginFrom = getInitialStudentIndex(studentList)
    setCurrentStudentIndex(beginFrom === -1 ? 0 : beginFrom)
  }, [
    studentList,
    currentStudentIndex,
    getInitialStudentIndex,
    partialMarkingQuestionsInitialed
  ])

  function setStudentAsMarked(index: number) {
    if (isPartialMarkingMode) {
      updateStudentListAt(index, {
        ...studentList[index],
        is_complete: 4,
        complete_questions: partialMarkingQuestions
      })
    } else {
      updateStudentListAt(index, {
        ...studentList[index],
        is_complete: 1
      })
    }
  }

  /**
   * Return next index of unmarked student
   *
   * If all marked, return currentStudentIndex + 1
   * If has unmarked, return first unmarked index
   * Return null if marking is finished
   *
   * If is partial marking, check next student:
   *  - if all of question in partialMarkingQuestions is marked, skip this student
   *  - if not, return this student
   */
  function getNextUnmarkedStudentIndex(): number | null {
    if (!studentList.length || typeof currentStudentIndex === 'undefined') {
      return 0
    }

    if (isAllMarkedOnInitial) {
      if (currentStudentIndex < studentList.length - 1) {
        return currentStudentIndex + 1
      }
      return null
    }

    const nextIndex = _.findIndex(studentList, (s, i) => {
      // Manually skipped
      if (skippedStudentIds.current.includes(s.id)) {
        return false
      }

      // ignore current student
      if (i === currentStudentIndex) {
        return false
      }

      if (isPartialMarkingMode) {
        return !checkStudentHasMarkedByPartialQuestions(s)
      }

      return s.is_complete !== StudentMarkStatus.MARKED
    })

    return nextIndex === -1 ? null : nextIndex
  }

  /**
   * 根据部分批阅设置的题目，判断学生是否已批阅
   *
   * Compare student complete_questions with partialMarkingQuestions
   *  - if all of partialMarkingQuestions is in complete_questions, return false
   *  - if not, return true
   */
  function checkStudentHasMarkedByPartialQuestions(student: Student): boolean {
    if (student.is_complete === StudentMarkStatus.MARKED) {
      return true
    }
    if (student.is_complete === StudentMarkStatus.UNMARKED) {
      return false
    }
    if (!student.complete_questions) {
      return false
    }

    const isPartialMarkingQuestionsAllMarked = _.every(
      partialMarkingQuestions,
      q => student.complete_questions?.includes(q)
    )

    console.log(
      'checkStudentHasMarkedByPartialQuestions',
      `student: ${student.id}, isAllMarked: ${isPartialMarkingQuestionsAllMarked}, complete_questions: ${student.complete_questions}, partialMarkingQuestions: ${partialMarkingQuestions}`
    )

    return isPartialMarkingQuestionsAllMarked
  }

  const isCurrentStudentMarked = useMemo(() => {
    if (!currentStudent) {
      return false
    }
    if (currentStudent.is_complete === StudentMarkStatus.MARKED) {
      return true
    }
    if (isPartialMarkingMode) {
      return checkStudentHasMarkedByPartialQuestions(currentStudent)
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudent, isPartialMarkingMode])

  // Pick unmarked student
  const unmarkedStudentCount = useMemo(() => {
    if (isPartialMarkingMode) {
      return _.filter(studentList, s => {
        const marked = checkStudentHasMarkedByPartialQuestions(s)
        return !marked
      }).length
    }

    return _.filter(studentList, student =>
      [StudentMarkStatus.UNMARKED, StudentMarkStatus.PARTIAL_MARKED].includes(
        student.is_complete
      )
    ).length
  }, [isPartialMarkingMode, studentList])

  /**
   * For user navigation
   */
  function getMarkedStudentIndex(direction: 'prev' | 'next'): number | null {
    const currentIndex = currentStudentIndex || 0

    function isMarked(student: Student) {
      if (isPartialMarkingMode) {
        return checkStudentHasMarkedByPartialQuestions(student)
      }
      return student.is_complete === StudentMarkStatus.MARKED
    }

    let index: number = -1
    if (direction === 'prev') {
      index = _.findLastIndex(studentList, isMarked, currentIndex - 1)
    } else {
      index = _.findIndex(studentList, isMarked, currentIndex + 1)
    }
    return index === -1 ? null : index
  }

  // Pager prev, ignore mark status
  function getPrevStudentIndex(): number {
    return Math.max(0, currentStudentIndex! - 1)
  }
  function hasPrevStudent(): boolean {
    if (currentStudentIndex === 0) return false
    return true
  }
  function gotoPrevStudent() {
    pageReload(getPrevStudentIndex())
  }

  // Pager next, marked only
  function getNextMarkedStudentIndex(): number | null {
    return getMarkedStudentIndex('next')
  }
  function hasNextStudent(): boolean {
    const next = getNextMarkedStudentIndex()
    if (next === null) return false
    return true
  }
  function gotoNextStudent() {
    const next = getNextMarkedStudentIndex()
    if (next === null) return
    pageReload(next)
  }

  // Initial teacher question group
  // Depend on QuestionAnswerData
  useEffect(() => {
    if (!markingPaperId) return
    if (!isInitialized) return

    fetchMarkingQuestionGroup()
  }, [isInitialized, markingPaperId])

  // Initial student list
  // Depend on QuestionAnswerData
  // Depend on PartialMarkingMode
  // Depend on markingQuestionGroup
  // TODO move into fetchStudents
  useEffect(() => {
    if (!isInitialized) return
    if (!markingPaperId) return
    if (!isTeacherMarkingQuestionGroupInitialed) return

    // 仲裁模式下，支持部分批阅
    if (isCurrentUserHasAuditRole) {
      // Wait for partialMarking init
      if (!partialMarkingQuestionsInitialed) return
      fetchStudents({
        includeQuestions: isPartialMarkingMode ? partialMarkingQuestions : []
      })
      return
    }

    // Wait for markingQuestionGroup init
    fetchStudents({
      teacherQuestionGroup: teacherCurrentMarkingQuestionGroup ?? undefined
    })
  }, [
    markingPaperId,
    isInitialized,
    isCurrentUserHasAuditRole,
    partialMarkingQuestionsInitialed,
    isPartialMarkingMode,
    partialMarkingQuestions,
    isTeacherMarkingQuestionGroupInitialed,
    teacherCurrentMarkingQuestionGroup
  ])

  useEffect(() => {
    if (studentList.length && typeof currentStudentIndex === 'number') {
      const s = studentList[currentStudentIndex]
      if (!s) return
      setCurrentStudent(s)
      setCurrentStudentId(s.id)
    }
  }, [currentStudentIndex, studentList, setCurrentStudentId])
  //#endregion

  //#region scoreForm
  const [scoreForm, scoreFormSetter] = useList<Marking.ScoreFormDataItem>([])

  function covertAnswerToScoreForm(
    answer: Marking.StudentAnswer[],
    question?: QuestionIdentity[]
  ) {
    const result: Marking.ScoreFormDataItem[] = []

    _.forEach(answer, answer => {
      if (question && !_.includes(question, answer.questionId)) {
        return
      }

      const res: Marking.ScoreFormDataItem = {
        questionId: answer.questionId,
        answerId: answer.answerId,
        score: answer.origin.markStatus ? answer.score : null
      }

      // TODO: remove nesting logic
      // if (isNestingQuestion(answer.type)) {
      //   const nestingItems = (answer.answer as unknown) as Marking.AnswerNesting[]
      //   res.children = _.map(nestingItems, c => {
      //     let score: number | null = null

      //     // Read score from answer
      //     if (isChoiceQuestion(c.type)) {
      //       score = c.score
      //     }
      //     if (isType2Question(c.type)) {
      //       if (!isType2NeedMarkInNesting) {
      //         score = c.score
      //       }
      //     }

      //     if (!!answer.origin.markStatus) {
      //       score = c.score
      //     }

      //     return {
      //       questionId: c.questionId,
      //       score: c.score
      //     }
      //   })
      // }
      result.push(res)
    })
    return result
  }

  function initScoreForm(
    answer: Marking.StudentAnswer[],
    questions?: QuestionIdentity[]
  ) {
    const items = covertAnswerToScoreForm(answer, questions)
    scoreFormSetter.set(items)
    log('initScoreForm', items)
  }

  const getScoreByQuestion = useCallback(
    (questionId: QuestionIdentity) => {
      // TODO: remove
      // if (_.isArray(questionId)) {
      //   const matched = _.find(scoreForm, s => s.questionId === questionId[0])
      //   return (
      //     matched &&
      //     _.find(matched.children, c => {
      //       return c.questionId === questionId[1]
      //     })
      //   )
      // }
      return _.find(scoreForm, { questionId })
    },
    [scoreForm]
  )

  function setQuestionScore(id: QuestionIdentity, score: number | null) {
    const index = _.findIndex(scoreForm, d => d.questionId === id)
    if (!~index) {
      console.error('[setQuestionScore] no found index with', id)
      scoreFormSetter.push({
        answerId: currentStudentAnswerOrigin
          ? currentStudentAnswerOrigin.id
          : 0,
        questionId: id,
        score
      })
      return
    }
    scoreFormSetter.updateAt(index, {
      ...scoreForm[index],
      score
    })
    log('setQuestionScore', id, score)
  }

  function setNestingQuestionScore(
    questionId: number,
    nestingQuestionId: string,
    score: number | null
  ) {
    throw new Error('Function deprecated')
  }

  function validateScoreForm() {
    const errorList = _.reduce<
      Marking.ScoreFormDataItem,
      Marking.ScoreFormDataItem[]
    >(
      scoreForm,
      (res, item) => {
        // Audit mode can ignore judged question
        const ignoreJudgedQuestion =
          isCurrentUserHasAuditRole &&
          checkQuestionAuditStatus(item.questionId) ===
            MarkingAuditStatus.Judged

        if (ignoreJudgedQuestion) return res

        // Ignore question that NOT in partialMarkingQuestions
        if (isPartialMarkingMode) {
          const isPartialMarkingQuestion = _.includes(
            partialMarkingQuestions,
            item.questionId
          )
          if (!isPartialMarkingQuestion) return res
        }

        // Return un-scored question
        if (typeof item.score !== 'number') {
          res.push(item)
        }
        return res
      },
      []
    )
    return {
      error: !!errorList.length,
      errorList
    }
  }

  // submit
  const [markedCount, setMarkedCount] = useState(0)

  async function submitScoreForm() {
    if (typeof currentStudentIndex !== 'number') {
      return
    }

    const student = studentList[currentStudentIndex]
    if (!student) {
      Modal.warning({
        title: '无法获取到学生答卷'
      })
      return
    }

    if (userImageMarkingMode) {
      await saveDrawersImageData()
    }
    await saveScoreFormData(scoreForm, student)
    await postScoreFormSubmit()
  }

  async function saveScoreFormData(
    scoreFormDataItems: Marking.ScoreFormDataItem[],
    student: Student
  ) {
    const data = {
      question_scores: _.map(scoreFormDataItems, score => {
        const commentFormDataItem = _.find(
          commentForm,
          c => c.questionId === score.questionId
        )

        const commentDto = commentFormDataItem
          ? getMarkingCommentDTO(commentFormDataItem)
          : {}

        const question = findQuestion(score.questionId)

        return {
          quest_answer_id: score.answerId,
          questionid: score.questionId,
          score: (() => {
            return score.score
          })(),
          full_score: question?.score,
          ...commentDto
        }
      }),
      compound_quest_sub_scores: _.reduce(
        scoreFormDataItems,
        (res, score) => {
          return res
        },
        {}
      ),
      student_id: student.id
    }

    log('postScoreForm', data)
    try {
      setSaving(true)
      await POST(
        `${APP_SCOPE.isInFanYa ? 'fanya' : 'review'}/paper/students/${
          student.id
        }/answer/review`,
        {
          data
        }
      )
    } catch (e) {
      defaultErrorHandler(e as any)
      throw e
    } finally {
      setSaving(false)
    }
  }

  async function postScoreFormSubmit({ skip } = { skip: false }) {
    if (getSpecMarkingIdFromQuery()) {
      Modal.success({
        title: '完成试卷回评',
        onOk: () => {
          if (APP_SCOPE.isInFanYa) {
            redirectToFanYa()
          } else if (isRuntimeCXStudy) {
            CXStudySetRefreshState()
            CXStudyCloseWindow()
          } else {
            history.goBack()
          }
        }
      })
      return
    }

    if (skip) {
      pushSkippedStudentIds(currentStudent?.id!)
    }

    // For single page marking mode
    setStudentAsMarked(currentStudentIndex!)

    // For page reload
    const _markedCount = markedCount + 1
    setMarkedCount(_markedCount)

    const nextIndex = getNextUnmarkedStudentIndex()

    if (nextIndex === null) {
      onFinish()
      return
    }

    if (!isRuntimeCXStudy) {
      message.loading(
        skip ? '正在开启下一份试卷' : '提交成功，正在开启下一份试卷'
      )
    }

    // For marking score sync from kaoshi
    if (markingStatusAutoDetect) {
      await checkAndSyncRemoteMarkedStatus()
    }

    pageReload(nextIndex)

    // console.log('reload: markedCount', _markedCount)
    // if (_markedCount > PAGE_FORCE_RELOAD_TIMES) {
    //   pageForceReload()
    //   return
    // }
    //
    // if (markingStatusAutoDetect) {
    //   const next = await checkAndSyncRemoteMarkedStatus()
    //   setCurrentStudentIndex(next)
    // } else {
    //   setCurrentStudentIndex(getNextStudentIndex())
    // }
    //
    // window.scrollTo(0, 0)
  }

  async function skipCurrentStudent() {
    await postScoreFormSubmit({ skip: true })
  }

  function onFinish() {
    const onOk = () => {
      if (isRuntimeCXStudy) {
        CXStudySetRefreshState()
        CXStudyCloseWindow()
      } else if (APP_SCOPE.isInFanYa) {
        redirectToFanYa()
      } else {
        history.replace('/marking')
      }
    }
    if (isRuntimeCXStudy) {
      AMModal.alert('完成试卷批阅', null, [{ text: '确定', onPress: onOk }])
    } else {
      if (isPartialMarkingMode) {
        Modal.success({
          title: '完成部分批阅',
          content: '如需批阅试卷的其他题目，请前往【部分批阅设置】操作'
        })
      } else {
        Modal.success({
          title: '完成试卷批阅',
          onOk
        })
      }
    }
  }

  function pageReload(nextIndex?: number, _markingQuestionGroupIndex?: number) {
    const pubPath = APP_SCOPE.isMobile
      ? 'fanya-exam-marking-mobile'
      : 'fanya-exam-marking'

    const url = `${location.origin}/${pubPath}/marking/${markingPaperId}`

    const groupIndex = (() => {
      if (_markingQuestionGroupIndex !== undefined) {
        return _markingQuestionGroupIndex
      }
      if (teacherHasMultipleMarkingQuestionGroup) {
        return teacherCurrentMarkingQuestionGroupIndex
      }
    })()

    // To clean up index and skipped_students
    const isGroupMarkingAndIndexChanged =
      teacherHasMultipleMarkingQuestionGroup &&
      typeof getTeacherMarkingQuestionGroupIndexFromQuery() === 'number' &&
      typeof groupIndex === 'number' &&
      getTeacherMarkingQuestionGroupIndexFromQuery() !== groupIndex

    let query: MarkingQuery<number> = {
      index: isGroupMarkingAndIndexChanged ? undefined : nextIndex,
      skipped_students: isGroupMarkingAndIndexChanged
        ? undefined
        : skippedStudentIds.current.join(',') || undefined,
      marking_group_index: groupIndex
    }

    // Restore enter params
    if (APP_SCOPE.isInFanYa) {
      query = { ...query, source: 'kaoshi', backurl: APP_SCOPE.redirectUrl }
    }

    // If all marked
    // If skip current student
    // Specify index of next student
    // if (isAllMarkedOnInitial || skip) {
    // query = { ...query, index: nextIndex }
    // }

    setTimeout(() => {
      window.scrollTo(0, 0)
      const _url = `${url}?${qs.stringify(query)}`
      console.log('reload', _url)
      location.replace(_url)
    }, 1000)
  }

  useUpdateEffect(() => {
    if (currentStudentAnswerError) {
      scoreFormSetter.set([])
      Modal.destroyAll()
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: currentStudentAnswerError.message || '无法获取到答卷内容',
        content: '请点击[重试]尝试重新加载，或者跳过该试卷',
        okText: '重试',
        cancelText: '跳过批改',
        cancelButtonProps: {
          danger: true
        },
        onOk: () => location.reload(),
        onCancel: () => postScoreFormSubmit({ skip: true })
      })
    }
  }, [currentStudentAnswerError])

  useEffect(() => {
    if (!loading) message.destroy()
  }, [loading])
  //#endregion

  //#region markingStatusAutoDetect
  function findNextUnMarkedStudents() {
    if (!studentList.length) return []
    const res: Student[] = []
    for (let i = currentStudentIndex! + 1; i < studentList.length; i++) {
      const s = studentList[i]
      // stop at tail of unmarked queue
      if (res.length && s.is_complete) break
      res.push(s)
    }
    return res
  }

  /**
   * Checking student answer's remote marking status,
   * and sync marked answer score to local server.
   *
   * Checking loop
   * 1. if student-answer has marked in remote, update answer status, then check next
   * 2. if student-answer has not marked in remote, break and return student index
   *
   * @return {number} next unmarked student index or 0
   */
  async function checkAndSyncRemoteMarkedStatus() {
    const students = findNextUnMarkedStudents()

    let nextIndex = 0

    try {
      await students.reduce(async (prevTask, student) => {
        // chain this to prev promise
        await prevTask

        log('checkAndSyncRemoteMarkedStatus student', student.id)
        const studentIndex = studentList.indexOf(student)

        const [_, studentAnswer] = await fetchStudentAnswer(student.id)
        const scoreForm = covertAnswerToScoreForm(studentAnswer)
        const isRemoteMarked = scoreForm.every(
          item => typeof item.score === 'number' && item.score > 0
        )

        // Record the index of truly unmarked student
        if (!isRemoteMarked) {
          nextIndex = studentIndex
          // Break promise chain
          throw `student ${student.id} answers unmarked`
        }

        // Sync scoreForm to local server
        if (isRemoteMarked) {
          await saveScoreFormData(scoreForm, student)
          setStudentAsMarked(studentIndex)
        }
      }, Promise.resolve())
    } catch (e) {
      log('checkAndSyncRemoteMarkedStatus', e)
    }

    return nextIndex
  }
  //#endregion

  //#region scroll
  const [questionIndexHeight, setQuestionIndexHeight] = useState<number>()

  function scrollToQuestion(id: QuestionIdentity) {
    try {
      const selector = `#question_${id}`
      const el = document.querySelector(selector) as HTMLDivElement
      if (el) {
        const action = computeScrollIntoView(el, {
          block: 'start',
          inline: 'start'
        })
        action.forEach(({ el, top }) => {
          window.scrollTo({
            top: top - (questionIndexHeight || 0) - 16,
            behavior: 'smooth'
          })
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  //#endregion

  // For partial marking setting ONLY
  const markingQuestionsVOWithoutPartialMarkingFilter = useMemo(() => {
    let result: Paper.Question[] = markingQuestions

    if (isCurrentUserHasAuditRole) {
      if (auditQuestionFilter === 'ALL') {
        return result
      }

      // Pick unAudited questions
      const auditedQuestionIds = findCurrentAuditedQuestionIds()
      log('auditedQuestionIds', auditedQuestionIds)

      result = markingQuestions.filter(q => !auditedQuestionIds.includes(q.id))
    }

    if (!isCurrentUserHasAuditRole && teacherCurrentMarkingQuestionGroup) {
      log(
        'teacherCurrentMarkingQuestionGroup',
        teacherCurrentMarkingQuestionGroup
      )
      result = markingQuestions.filter(q =>
        teacherCurrentMarkingQuestionGroup.includes(q.id)
      )
    }

    return result
  }, [
    auditQuestionFilter,
    findCurrentAuditedQuestionIds,
    isCurrentUserHasAuditRole,
    markingQuestions,
    teacherCurrentMarkingQuestionGroup
  ])

  const markingQuestionsVO = useMemo(() => {
    let result: Paper.Question[] = markingQuestionsVOWithoutPartialMarkingFilter

    if (isPartialMarkingMode) {
      result = result.filter(q => partialMarkingQuestions.includes(q.id))
    }

    return result
  }, [
    isPartialMarkingMode,
    markingQuestionsVOWithoutPartialMarkingFilter,
    partialMarkingQuestions
  ])

  //#region Drawer
  type DrawerData = {
    key?: string
    questionIdentity: QuestionIdentity
    image: AnswerImage
    uploadedUrl?: string
    canvasData?: string

    getImage: ImageDrawerAPI['getImageData']
  }

  // TODO clean up
  const drawerContext = useRef<DrawerData[]>([])

  function registerDrawerContext(data: DrawerData) {
    const key = `${JSON.stringify(data.questionIdentity)}-${
      data.image.namedQuestionIndex
    }-${data.image.imageIndex}`
    // check if exists
    const match = _.find(drawerContext.current, d => d.key === key)
    if (match) {
      match.getImage = data.getImage
    } else {
      drawerContext.current.push({
        ...data,
        key
      })
    }
  }

  async function saveDrawersImageData() {
    log('saveDrawersImageData', drawerContext.current)

    const jobs = drawerContext.current.map(async data => {
      const d = data.getImage()
      if (!d) {
        return Promise.resolve()
      }
      const url = await uploadImage(d.file)
      data.uploadedUrl = url
      data.canvasData = d.canvasData
    })

    await Promise.all(jobs)

    drawerContext.current
      .filter(it => it.uploadedUrl)
      .forEach(it => {
        setCommentImage({
          questionId: it.questionIdentity,
          image: it.image,
          drawerResult: { file: it.uploadedUrl!, canvasData: it.canvasData! }
        })
      })
  }

  async function uploadImage(base64File: string) {
    const { data } = await POST('review/image/upload', {
      data: { image_data: base64File }
    })
    return data as string
  }

  //#endregion
  //

  useEffect(() => {
    initScoreForm(
      currentStudentAnswer,
      markingQuestionsVOWithoutPartialMarkingFilter?.map(q => q.id)
    )
  }, [currentStudentAnswer, markingQuestionsVOWithoutPartialMarkingFilter])

  return {
    isInitialized,
    loading,
    getMarkingIndex: getMarkingIndexFromQuery,

    markingPaperId,
    setMarkingPaperId,
    markingPaper,
    paperQuestions,
    markingQuestions,
    markingQuestionsVO,
    markingQuestionsVOWithoutPartialMarkingFilter,
    getAnswerByQuestion,

    saving,
    getSpecMarkingId: getSpecMarkingIdFromQuery,
    currentStudentIndex,
    setCurrentStudentIndex,
    currentStudent,
    isAllMarkedOnInitial,
    isCurrentStudentMarked,
    unmarkedStudentCount,

    // config
    showContent,
    toggleContentVisible,
    showAnswerRight,
    toggleAnswerRightVisible,
    markingSubmitConfirmIgnore,
    setMarkingSubmitConfirmIgnore,
    markingStatusAutoDetect,
    toggleMarkingStatusAutoDetect,
    openDrawerOnClickImage,
    toggleOpenDrawerOnClickImage,
    userImageMarkingMode,
    toggleUserImageMarkingMode,

    // markingQuestionGroup
    teacherMarkingQuestionGroup,
    teacherCurrentMarkingQuestionGroup,
    teacherCurrentMarkingQuestionGroupIndex,
    teacherHasMultipleMarkingQuestionGroup,

    studentList,

    // scoreForm
    scoreForm,
    getScoreByQuestion,
    setQuestionScore,
    setNestingQuestionScore,
    validateScoreForm,
    submitScoreForm,
    skipCurrentStudent,

    //
    questionIndexHeight,
    setQuestionIndexHeight,
    scrollToQuestion,

    // pager
    hasPrevStudent,
    hasNextStudent,
    gotoPrevStudent,
    gotoNextStudent,
    pageReload,

    auditQuestionFilter,
    setAuditQuestionFilter,

    partialMarkingSettingVisible,
    setPartialMarkingSettingVisible,
    partialMarkingQuestions,
    partialMarkingQuestionsSetter,
    isPartialMarkingMode,

    // drawer
    registerDrawerContext
  }
}

const [Provider, useMarkingForm] = constate(hooks)

const MarkingFormProvider: FC = props => (
  <QuestionAnswerDataProvider>
    <MarkingTagsProvider>
      <Provider>{props.children}</Provider>
    </MarkingTagsProvider>
  </QuestionAnswerDataProvider>
)
MarkingFormProvider.displayName = 'MarkingFormProvider'

export { MarkingFormProvider, useMarkingForm }
