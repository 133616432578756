import { useMount } from '@umijs/hooks'
import { Form, Input } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Search, Table } from '../../components'
import { IS_DEV } from '../../config'
import { createList } from '../../core/service'
import { CollegeSelect } from './components/CollegeSelect'
import { RateInput } from './components/RateInput'

const { ListProvider, useListContext } = createList<any, any>({
  url: () => '/statistics/gather/byteacher/list',
  getDefaultSearch: () => (IS_DEV ? {} : {}),
  parseSearchData: data => {
    return {
      ...data,
      rate: undefined,
      lowerLimit: data.rate && data.rate[0],
      upperLimit: data.rate && data.rate[1]
    }
  }
})

export default function () {
  return (
    <ListProvider>
      <Page />
    </ListProvider>
  )
}

const Page = () => {
  const list = useListContext()

  function onTableChange(pagination, filters, sorter) {
    if (list.index.value !== pagination.current) {
      window.scrollTo(0, 0)
      list.onIndexChange(pagination.current)
      return
    }

    list.setPageSize(pagination.pageSize)
    list.onSearchSubmit({
      order_type: !!sorter.order
        ? sorter.order === 'ascend'
          ? '0'
          : '1'
        : null
    })
  }

  useMount(() => {
    list.fetch()
  })

  return (
    <>
      <Search list={list}>
        <Form.Item name={'group_id'}>
          <CollegeSelect />
        </Form.Item>
        <Form.Item name={'teacher'}>
          <Input allowClear placeholder='输入教师姓名或账号' />
        </Form.Item>
        <Form.Item name={'rate'}>
          <RateInput />
        </Form.Item>
      </Search>

      <Table
        list={list}
        onChange={onTableChange}
        columns={[
          { title: '教师姓名', dataIndex: 'display_name', fixed: 'left' },
          { title: '教师账号', dataIndex: 'uid' },
          { title: '院系', dataIndex: 'dept', render: v => v || '-' },

          {
            title: '应批阅试卷份数',
            dataIndex: 'exam_paper_count',
            align: 'right'
          },
          {
            title: '已批阅试卷份数',
            dataIndex: 'completed_paper_count',
            align: 'right'
          },
          {
            title: '待批阅试卷份数',
            dataIndex: 'uncompleted_paper_count',
            align: 'right'
          },
          {
            title: '批阅试卷完成率',
            dataIndex: 'exam_paper_completed_count_rate',
            align: 'center',
            render: t => `${t}%`
          },

          { title: '应批阅题目份数', dataIndex: 'total', align: 'right' },
          { title: '已批阅题目份数', dataIndex: 'done', align: 'right' },
          {
            title: '待批阅题目份数',
            dataIndex: 'total',
            align: 'right',
            render: (t, r) => t - r.done
          },
          {
            title: '批阅完成率',
            dataIndex: 'rate',
            render: (_, record) =>
              `${Math.round((record.done / record.total) * 10000) / 100}%`,
            align: 'center',
            sorter: true
          },
          {
            title: '操作',
            key: 'actions',
            render: (t, r) => (
              <Link
                to={`/teacher-report/teachers/${r.teacher_uid}/batches?teacher=${r.display_name}`}
              >
                查看详情
              </Link>
            )
          }
        ]}
        pagination={{
          current: list.index.value,
          total: list.total,
          pageSize: list.size,
          showSizeChanger: true,
          showQuickJumper: true,
          hideOnSinglePage: !true,
          showTotal: total => `共${total}条记录`
        }}
        scroll={{
          x: 'max-content'
        }}
        indexColumn={{ fixed: 'left' }}
      />
    </>
  )
}
