import EventEmitter from 'eventemitter3'
import { isRuntimeCXStudy } from '../config'

// @ts-ignore
const CXJSBridge = window.jsBridge || {
  bind: () => {},
  postNotification: () => {}
}

export const RuntimeEventBus = new EventEmitter()
RuntimeEventBus.on('refresh', () => {
  console.log('page refresh')
})
RuntimeEventBus.on('resume', data => {
  console.log('page resume', data)
})

// waitCXStudyReady
export const bootCXStudy = async () => {
  if (!isRuntimeCXStudy) {
    return
  }
  console.debug('bootCXStudy')
  await Promise.all([
    waitCXStudyReady(),
    new Promise(resolve => {
      setTimeout(resolve, 100)
    })
  ])
  // listen to resume event from child-page
  CXJSBridge.bind('CLIENT_REFRESH_EVENT', () => {
    RuntimeEventBus.emit('refresh')
  })
  CXJSBridge.bind('CLIENT_WEB_EXTRAINFO', data => {
    // if (data && data.action === 'refresh') {
    //   RuntimeEventBus.emit('refresh')
    //   return
    // }
    RuntimeEventBus.emit('resume', data)
  })
}

// events
let isCXStudyReady = false
let waitCXStudyReadyResolver: any = null
const waitCXStudyReadyPromise = new Promise(resolve => {
  waitCXStudyReadyResolver = resolve
  if (isCXStudyReady) {
    resolve(void 0)
  }
})
export const waitCXStudyReady = () => waitCXStudyReadyPromise
// @ts-ignore
window._jsBridgeReady = () => {
  console.log('_jsBridgeReady')
  isCXStudyReady = true
  waitCXStudyReadyResolver()
}

// data
export const CXStudySetWindowResumeData = (data = {}) => {
  CXJSBridge.postNotification('CLIENT_WEB_EXTRAINFO', data)
}

export const CXStudySetRefreshState = () => {
  // if (isDevice_iOS) {
  //   CXJSBridge.postNotification('CLIENT_WEB_EXTRAINFO', { action: 'refresh' })
  //   return
  // }
  CXJSBridge.postNotification('CLIENT_REFRESH_STATUS', { status: '1' })
}

// ui
/**
 * @param options.toolbarType 0:隐藏导航, 2:默认
 * @param options.loadType 0:本页 1:新窗口
 */
export const CXStudyPushWindow = (
  url,
  title,
  options: { loadType?: '0' | '1'; toolbarType?: '0' | '2' } = {}
) => {
  console.debug('pushWindow', url)
  CXJSBridge.postNotification('CLIENT_OPEN_URL', {
    title,
    webUrl: url,
    ...{
      loadType: '1',
      toolbarType: '2'
    },
    ...options
  })
}

export const CXStudyCloseWindow = (level = 1) => {
  CXJSBridge.postNotification('CLIENT_EXIT_LEVEL', { level })
}

export const CXStudyHideToolbar = () => {
  CXJSBridge.postNotification('CLIENT_TOOLBAR_TYPE', { toolbarType: '0' })
}

export const CXStudyPreviewImage = (images: string[], index: number = 0) => {
  CXJSBridge.postNotification('CLIENT_PREVIEW_IMAGES', {
    showIndex: index,
    imageUrls: images.map(img => ({ imageUrl: img }))
  })
}

export const CXStudyOrientation = (orientation: '1' | '4') => {
  CXJSBridge.postNotification('CLIENT_FORCE_ORIENTATION', {
    Orientation: orientation
  })
}
