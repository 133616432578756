import { Button, Card, Col, Form, Row, Select, Space, Table, Tag } from 'antd'
import { PageWrapper } from 'components/PageWrapper'
import { GET } from 'core/request'
import { useQuery } from 'hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { requestAndSaveFile } from 'utils/exporter'

export default function () {
  const history = useHistory()

  const params = useParams<{ id }>()
  const { paper_name } = useQuery<{ paper_name: string }>()

  const id = params.id

  const [orgs, setOrgs] = useState<any[]>([])
  const [teachers, setTeachers] = useState<any[]>([])
  const [summary, setSummary] = useState<any>()

  const [form, setForm] = useState<any>({})
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  async function fetch() {
    setLoading(true)
    const { data } = await GET('fanya/paper/teacher/reviewed/progress', {
      data: {
        exam_paper_teacher_id: id,
        ...form
      }
    })
    setData(data.items)
    setLoading(false)
  }

  async function fetchOrgs() {
    const { data } = await GET('fanya/paper/teacher/departments', {
      data: {
        exam_paper_teacher_id: id
      }
    })
    setOrgs(
      data?.data.map(t => ({
        ...t,
        label: t.groupname,
        value: t.id
      }))
    )
  }

  async function fetchTeachers() {
    const { data } = await GET('fanya/paper/teacher/reviewed/teachers', {
      data: {
        exam_paper_teacher_id: id
      }
    })
    setTeachers(
      data?.map(t => ({
        ...t,
        label: t.display_name,
        value: t.uid
      }))
    )
  }

  async function fetchSummary() {
    const { data } = await GET('fanya/paper/teacher/reviewed/summary', {
      data: {
        exam_paper_teacher_id: id
      }
    })
    setSummary(data)
  }

  function onSearch(value) {
    setForm(value)
  }

  useEffect(() => {
    fetch()
  }, [form])

  useEffect(() => {
    fetchSummary()
    fetchOrgs()
    fetchTeachers()
  }, [])

  function onExport() {
    requestAndSaveFile('fanya/paper/teacher/reviewed/export', {
      exam_paper_teacher_id: id
    })
  }

  return (
    <PageWrapper
      breadcrumb={['批阅统计']}
      headerRight={() => (
        <Button type='primary' onClick={() => history.goBack()}>
          返回
        </Button>
      )}
      content={
        <div>
          <div style={{ marginBottom: 8, fontSize: 15, color: '#333' }}>
            {paper_name}
          </div>
          <Space>
            <Tag color='green'>
              待批阅试卷份数/总份数: {summary?.paper_completed_count}/
              {summary?.paper_total}
            </Tag>
          </Space>
        </div>
      }
    >
      <Card>
        <Row>
          <Col flex={1}>
            <Form
              layout='inline'
              style={{ marginBottom: 16 }}
              autoComplete='off'
              onFinish={onSearch}
            >
              <Form.Item label='院系' name='group_id'>
                <Select
                  placeholder='输入院系名称'
                  options={orgs}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    const val = input.trim().toLowerCase()
                    if (option?.groupname?.toLowerCase().includes(val)) {
                      return true
                    }
                    return false
                  }}
                  style={{ width: 180 }}
                  allowClear
                />
              </Form.Item>
              <Form.Item label='教师' name='query_teacher_uid'>
                <Select
                  placeholder='输入教师姓名或账号'
                  options={teachers}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    const val = input.trim().toLowerCase()
                    if (
                      option?.display_name?.toLowerCase().includes(val) ||
                      option?.name?.toLowerCase().includes(val)
                    ) {
                      return true
                    }
                    return false
                  }}
                  style={{ width: 180 }}
                  allowClear
                />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Button onClick={onExport}>导出Excel</Button>
          </Col>
        </Row>

        <Table
          loading={loading}
          dataSource={data}
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              render: (text, record, index) => index + 1,
              align: 'center',
              fixed: 'left'
            },
            {
              title: '教师',
              dataIndex: 'teacher_name',
              fixed: 'left'
            },
            // {
            //   title: '教师UID',
            //   dataIndex: 'teacher_uid'
            // },
            {
              title: '院系',
              dataIndex: 'department'
            },
            {
              title: '应批阅试卷份数',
              dataIndex: 'paper_total',
              align: 'right'
            },
            {
              title: '已批阅试卷份数',
              dataIndex: 'paper_completed_count',
              align: 'right'
            },
            {
              title: '待批阅试卷份数',
              dataIndex: 'paper_uncompleted_count',
              align: 'right'
            },
            {
              title: '批阅试卷完成率',
              dataIndex: 'paper_complete_rate',
              render: text => text + '%',
              align: 'right'
            },
            {
              title: '应批阅题目份数',
              dataIndex: 'subject_total',
              align: 'right'
            },
            {
              title: '已批阅题目份数',
              dataIndex: 'subject_completed_count',
              align: 'right'
            },
            {
              title: '待批阅题目份数',
              dataIndex: 'subject_uncompleted_count',
              align: 'right'
            },
            {
              title: '批阅完成率',
              dataIndex: 'subject_complete_rate',
              render: text => text + '%',
              align: 'right'
            },
            {
              title: '操作',
              dataIndex: 'action',
              fixed: 'right',
              render: (text, record) => (
                <a
                  onClick={() => {
                    history.push(
                      `/exam-grouped-papers/${id}/statistics/teachers/${
                        record.teacher_uid
                      }?paper_name=${'xx'}&teacher_name=${record.teacher_name}`
                    )
                  }}
                >
                  查看详情
                </a>
              )
            }
          ]}
          pagination={false}
          scroll={{
            x: 'max-content'
          }}
        ></Table>
      </Card>
    </PageWrapper>
  )
}
