import { Alert, InputNumber, Modal, Table, message } from 'antd'
import { GET, POST, defaultErrorHandler } from 'core/request'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useMarkingForm } from '../useMarkingForm'

export const PullMoreForm = (props: {
  visible: boolean
  onClose: () => void
}) => {
  const { markingPaper, teacherCurrentMarkingQuestionGroup } = useMarkingForm()

  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    if (!teacherCurrentMarkingQuestionGroup) return

    setLoading(true)
    const { data } = await GET(`fanya/exam/paper/others_review_progress`, {
      data: {
        exam_paper_id: markingPaper?.exam_paper_id,
        quest_group: teacherCurrentMarkingQuestionGroup.join(',')
      }
    })
    let _list = data?.teacher || []
    // _list = [
    //   {
    //     teacher_uid: 1,
    //     display_name: '张三',
    //     un_complete_count: 2
    //   },
    //   {
    //     teacher_uid: 2,
    //     display_name: '李四',
    //     un_complete_count: 3
    //   }
    // ]
    setList(_list)
    setLoading(false)
    setFormData(_.map(_list, () => undefined))
  }, [markingPaper, teacherCurrentMarkingQuestionGroup])

  useEffect(() => {
    if (props.visible) {
      fetch()
    } else {
      setFormData([])
    }
  }, [fetch, props.visible])

  const [formData, setFormData] = useState<Array<number | undefined>>([])
  function getValue(index: number) {
    return formData[index] ?? undefined
  }
  function setValue(index: number, value?: number | string) {
    const _formData = [...formData]
    _formData[index] = typeof value === 'string' ? undefined : value
    setFormData(_formData)
  }

  const [submitting, setSubmitting] = useState(false)
  async function submit() {
    message.loading('正在拉取', 0)
    setSubmitting(true)

    try {
      const { data } = await POST(
        `fanya/exam/paper/${markingPaper?.exam_paper_id}/teacher/supplement`,
        {
          data: {
            quest_group: teacherCurrentMarkingQuestionGroup
              ? teacherCurrentMarkingQuestionGroup
              : undefined,
            teachers: _.reduce(
              list,
              (acc, item, index) => {
                acc[item.teacher_uid] = formData[index] ?? 0
                return acc
              },
              {}
            )
          }
        }
      )
      message.destroy()

      const { student_increment } = data

      if (_.isNumber(student_increment) && student_increment > 0) {
        Modal.info({
          title: '拉取成功',
          content: (
            <div>
              <p>成功拉取了{student_increment}份试卷</p>
            </div>
          ),
          okText: '刷新页面',
          onOk() {
            location.reload()
          }
        })
      } else {
        Modal.warn({
          title: '未拉取到试卷'
        })
      }
    } catch (e) {
      message.destroy()
      defaultErrorHandler(e as any)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal
      visible={props.visible}
      width={600}
      title='拉取更多试卷'
      onCancel={props.onClose}
      onOk={submit}
      okButtonProps={{
        disabled: submitting || loading || !list.length
      }}
    >
      {!loading && !list.length ? (
        <Alert
          message='其他教师暂无未批阅的试卷'
          type='warning'
          showIcon
          style={{ marginBottom: 16 }}
        />
      ) : (
        <Alert
          message='将其他教师未批阅的试卷，拉取到你当前的题组下'
          type='info'
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}

      <Table
        dataSource={list}
        rowKey='teacher_uid'
        columns={[
          {
            title: '序号',
            render: (text, record, index) => index + 1
          },
          {
            title: '教师',
            dataIndex: 'display_name'
          },
          {
            title: '待批阅试卷数',
            dataIndex: 'un_complete_count',
            align: 'right'
          },
          {
            width: 20
          },
          {
            title: '需拉取的试卷数',
            render(_, record, index) {
              return (
                <>
                  <InputNumber
                    placeholder='请输入'
                    min={0}
                    max={record.un_complete_count}
                    value={getValue(index)}
                    onChange={value => setValue(index, value)}
                  />
                  <span style={{ marginLeft: 6 }}>
                    ≤{record.un_complete_count}
                  </span>
                </>
              )
            }
          }
        ]}
      ></Table>
    </Modal>
  )
}
