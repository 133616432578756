import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Empty, Modal, Row, message } from 'antd'
import { ModalSizeLarge } from 'config'
import _ from 'lodash'
import { QuestionGroupRender } from 'pages/exam-papers/QuestionGroupRender'
import React, { FC, useEffect, useState } from 'react'
import { useList } from 'react-use'
import { Paper } from 'typing'
import { reversQuestionsToGroupTree } from 'utils/question'
import { useMarkingSettingContent } from '../useMarkingSettingState'
import {
  useBindQuestionContent,
  useQuestionBindGroupContent
} from '../useQuestionBindGroup'

interface BindingQuestionDialogProps {
  onConfirm: (items: Paper.Question[]) => void
}

export const BindingQuestionDialog: FC<BindingQuestionDialogProps> = props => {
  const { paperQuestions } = useMarkingSettingContent()
  const { visible, setVisible, editParams } = useBindQuestionContent()

  // selectedItems
  const [selectedItems, { set, push: pushSelectedItems }] = useList<
    Paper.Question
  >([])

  useEffect(() => {
    if (visible && editParams) {
      set(editParams.questionBindGroup.questions)
    }
  }, [visible, editParams])

  useEffect(() => {
    if (visible && !paperQuestions.length) {
      message.warn('该试卷暂无可分配的题目')
    }
  }, [visible])

  function removeSelectedItem(...item: Paper.Question[]) {
    set(
      _.filter(selectedItems, s => {
        return !_.find(item, { id: s.id })
        // if (_.isArray(item)) {
        // } else {
        //   return s.id !== item.id
        // }
      })
    )
  }

  // selectedItems VO
  const [
    questionGroupTreeOfSelectedItems,
    setQuestionGroupTreeOfSelectedItems
  ] = useState<Paper.QuestionGroup[]>([])

  useEffect(() => {
    const val = reversQuestionsToGroupTree(paperQuestions, selectedItems)
    if (val) {
      setQuestionGroupTreeOfSelectedItems(val)
    }
  }, [selectedItems])

  //
  function onConfirm() {
    props.onConfirm(_.sortBy(selectedItems, '_index'))
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={'题组修改'}
      width={ModalSizeLarge}
      destroyOnClose
      onOk={onConfirm}
      onCancel={() => setVisible(false)}
    >
      <SelectBox
        {...{
          questionGroupTreeOfSelectedItems,
          selectedItems,
          pushSelectedItems,
          removeSelectedItem
        }}
      />
    </Modal>
  )
}

const SelectBox: FC<{
  questionGroupTreeOfSelectedItems
  selectedItems
  pushSelectedItems: (...items: Paper.Question[]) => void
  removeSelectedItem: (...items: Paper.Question[]) => void
}> = ({
  questionGroupTreeOfSelectedItems,
  selectedItems,
  pushSelectedItems,
  removeSelectedItem
}) => {
  const { paperQuestions } = useMarkingSettingContent()
  const { questionBindGroup } = useQuestionBindGroupContent()
  const { editParams } = useBindQuestionContent()

  function isSelected(q: Paper.Question) {
    const bindGroupExcludeSelf = _.filter(
      questionBindGroup,
      (_, i) => i !== editParams.index
    )
    const items = _.flatMap(bindGroupExcludeSelf, g => g.questions)
    // @ts-ignore
    return !!_.find([...items, ...selectedItems], { id: q.id })
  }

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Card size={'small'} title={'选择题目'}>
          <div className={'select-box'}>
            {paperQuestions &&
              paperQuestions.map(g => (
                <QuestionGroupRender
                  key={g.id}
                  g={g}
                  renderAction={q => (
                    <Button
                      type={'primary'}
                      size={'small'}
                      shape={'circle'}
                      icon={<PlusOutlined />}
                      disabled={isSelected(q)}
                      onClick={() => pushSelectedItems(q)}
                    />
                  )}
                  renderGroupAction={() => (
                    <a
                      onClick={() => {
                        pushSelectedItems(
                          ..._.filter(g.children, q => !isSelected(q))
                        )
                      }}
                    >
                      全选
                    </a>
                  )}
                />
              ))}
          </div>
        </Card>
      </Col>
      <Col span={12}>
        <Card size={'small'} title={'已选题目'}>
          <div className={'select-box'}>
            {!questionGroupTreeOfSelectedItems.length && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {questionGroupTreeOfSelectedItems.map((g, i) => (
              <QuestionGroupRender
                key={i}
                g={g}
                renderAction={q => (
                  <Button
                    type={'primary'}
                    danger
                    size={'small'}
                    shape={'circle'}
                    icon={<MinusOutlined />}
                    onClick={() => removeSelectedItem(q)}
                  />
                )}
                renderGroupAction={() => (
                  <a
                    onClick={() => {
                      removeSelectedItem(...g.children)
                    }}
                  >
                    全不选
                  </a>
                )}
              />
            ))}
          </div>
        </Card>
      </Col>
    </Row>
  )
}
