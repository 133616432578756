import { useMount } from '@umijs/hooks'
import { Form } from 'antd'
import qs from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'
import { Search, Table } from '../../components'
import { createList } from '../../core/service'
import { BatchTypeSelect } from './components/BatchTypeSelect'
import { ExamBatchSelect } from './components/ExamBatchSelect'
import { TermSelect } from './components/TermSelect'

const { ListProvider, useListContext } = createList<any, any>({
  url: () => '/statistics/gather/list'
})

export default function () {
  return (
    <ListProvider>
      <Page />
    </ListProvider>
  )
}

const Page = () => {
  const list = useListContext()

  function onTableChange(pagination, filters, sorter) {
    if (list.index.value !== pagination.current) {
      window.scrollTo(0, 0)
      list.onIndexChange(pagination.current)
      return
    }

    list.setPageSize(pagination.pageSize)
    list.onSearchSubmit({})
  }

  useMount(() => {
    list.fetch()
  })

  return (
    <>
      <Search list={list}>
        <Form.Item name={'term_id'}>
          <TermSelect />
        </Form.Item>
        <Form.Item name={'gather_id'}>
          <ExamBatchSelect />
        </Form.Item>
        <Form.Item name={'gather_type'}>
          <BatchTypeSelect />
        </Form.Item>
      </Search>

      <Table
        list={list}
        onChange={onTableChange}
        columns={[
          { title: '学期', dataIndex: 'xqmc' },
          { title: '考试批次', dataIndex: 'kspcmc' },
          { title: '考试批次类型', dataIndex: 'kspclx' },
          { title: '考试数', dataIndex: 'exam_count', align: 'right' },
          { width: 50 },
          {
            title: '操作',
            key: 'actions',
            render: (t, r) => {
              const q = {
                batch: r.kspcmc,
                relationId: r.relationid,
                classId: r.classid
              }
              return (
                <Link
                  to={`/teacher-report/batches/${
                    r.kspcid
                  }/detail?${qs.stringify(q)}`}
                >
                  查看详情
                </Link>
              )
            }
          }
        ]}
        pagination={{
          current: list.index.value,
          total: list.total,
          pageSize: list.size,
          showSizeChanger: true,
          showQuickJumper: true,
          hideOnSinglePage: !true,
          showTotal: total => `共${total}条记录`
        }}
      />
    </>
  )
}
