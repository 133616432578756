import {
  Table as ATable,
  Button,
  Card,
  Form,
  Input,
  Modal,
  Space,
  Tag
} from 'antd'
import { Search, Table } from 'components'
import { PageWrapper } from 'components/PageWrapper'
import { PreviewMarkingDialog } from 'components/Preview/PreviewMarkingDialog'
import { GET } from 'core/request'
import { createList } from 'core/service'
import { useQuery } from 'hooks/useQuery'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { StudentAnswer } from 'typing.marking'
import { parseStudentAnswer } from 'utils/answer'

type StudentListItem = {
  exam_paper_teacher_id: number
  student_uid: number
}

const { ListProvider, useListContext } = createList({
  url: 'fanya/monitor/students'
})

export type Query = Partial<{
  pid: string
  questionId: number
  teacherId: number
  teacherName: string
  questionNames: string[]
  exam_name: string
}>

const StudentListInner: FC = () => {
  const {
    pid,
    questionId,
    teacherId,
    teacherName,
    questionNames,
    exam_name
  } = useQuery<Query>()
  const history = useHistory()

  const list = useListContext()

  useEffect(() => {
    if (pid && questionId && teacherId) {
      list.onSearchSubmit({
        exam_paper_id: pid,
        question_id: questionId,
        teacher_uid: teacherId
      })
    }
  }, [pid, questionId, teacherId])

  const [historyScoreListVisible, setHistoryScoreListVisible] = useState(false)
  const [historyScoreListLoading, setHistoryScoreListLoading] = useState(false)
  const [historyScoreList, setHistoryScoreList] = useState([])
  const [currentItem, setCurrentItem] = useState<StudentListItem>()

  async function getHistoryScoreList(item: StudentListItem) {
    setHistoryScoreListLoading(true)
    const { data } = await GET('fanya/monitor/students/score', {
      data: {
        exam_paper_id: pid,
        question_id: questionId,
        teacher_uid: teacherId,
        student_uid: item.student_uid
      }
    })
    setHistoryScoreList(data)
    setHistoryScoreListLoading(false)
  }
  async function openHistoryScoreList(item: StudentListItem) {
    setHistoryScoreListVisible(true)
    setCurrentItem(item)
    await getHistoryScoreList(item)
  }

  // Preview
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewData, setPreviewData] = useState<{
    id: number
    studentId: number
    questionFilter?: number[]
    studentAnswer: StudentAnswer[]
  }>()
  async function openPreview(item: any, index: number) {
    const { data } = await GET('fanya/monitor/students/relation', {
      data: {
        exam_paper_id: pid,
        teacher_uid: teacherId,
        student_uid: currentItem?.student_uid
      }
    })
    const { data: answerData } = await GET(
      `fanya/monitor/students/${data.teacher_student_relation_id}/answer`,
      {
        data: {
          question_id: questionId,
          index
        }
      }
    )
    const studentAnswer = parseStudentAnswer(answerData)
    // studentAnswer[0].score = 12
    // studentAnswer[0].teacherComment = '11aa'

    setPreviewData({
      id: currentItem!.exam_paper_teacher_id,
      studentId: data.teacher_student_relation_id,
      questionFilter: [Number(questionId!)],
      studentAnswer
    })
    setPreviewVisible(true)
  }

  return (
    <PageWrapper
      breadcrumb={['阅卷质量监控', '给分明细']}
      headerRight={() => (
        <Button type='primary' onClick={() => history.goBack()}>
          返回
        </Button>
      )}
      content={
        <Space>
          <span>
            <span style={{ color: '#333', fontWeight: 'bold' }}>
              试卷名称：
            </span>
            <Tag>{exam_name}</Tag>
          </span>
          <span>
            <span style={{ color: '#333', fontWeight: 'bold' }}>题目：</span>

            <Tag>{questionNames}</Tag>
          </span>
          <span>
            <span style={{ color: '#333', fontWeight: 'bold' }}>教师：</span>
            <Tag>{teacherName}</Tag>
          </span>
        </Space>
      }
    >
      <Card>
        <Search list={list} showReset={false}>
          <Form.Item label='学生' name='stu_name'>
            <Input allowClear placeholder='输入姓名或学号' />
          </Form.Item>
        </Search>
        <Table
          list={list}
          columns={[
            {
              title: '学生姓名',
              dataIndex: 'student_user_name'
            },
            {
              title: '学号',
              dataIndex: 'student_login_name'
            },
            {
              title: '最新得分',
              dataIndex: 'score',
              align: 'right'
            },
            {
              width: 30
            },
            {
              title: '操作',
              render: (text, record) => (
                <Space>
                  <a onClick={() => openHistoryScoreList(record)}>历史得分</a>
                </Space>
              )
            }
          ]}
          pagination={false}
          size='small'
        ></Table>
      </Card>

      <Modal
        title='历史得分'
        visible={historyScoreListVisible}
        onCancel={() => setHistoryScoreListVisible(false)}
        footer={null}
      >
        <ATable
          loading={historyScoreListLoading}
          columns={[
            {
              title: '序号',
              render: (text, record, index) => index + 1
            },
            {
              title: '判卷时间',
              dataIndex: 'reviewTime',
              render: text => moment(text).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              title: '得分',
              dataIndex: 'score',
              align: 'right'
            },
            {
              title: '操作',
              render: (text, record, index) => (
                <Space>
                  <a onClick={() => openPreview(record, index)}>查看详情</a>
                </Space>
              )
            }
          ]}
          dataSource={historyScoreList}
          pagination={false}
        />
      </Modal>

      {previewData && (
        <PreviewMarkingDialog
          {...previewData}
          showScore={true}
          visible={previewVisible}
          title={'查看答卷'}
          onCancel={() => setPreviewVisible(false)}
        />
      )}
    </PageWrapper>
  )
}

export default function () {
  return (
    <ListProvider>
      <StudentListInner />
    </ListProvider>
  )
}
