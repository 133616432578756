import { Modal, Spin } from 'antd'
import { APP_SCOPE } from 'config'
import loadjs from 'loadjs'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useQuestionAnswerDataContent } from '../../../hooks/useQuestionAnswerData'
import { QuestionIdentity } from 'typing.paper'

export type CommentDialogProps = {
  questionId: QuestionIdentity
  allowEdit: boolean
  onClose: () => void
}

export const CommentDialog: FC<CommentDialogProps> = props => {
  const [loading, setLoading] = useState(true)
  const {
    getCommentItemContent,
    setCommentContent
  } = useQuestionAnswerDataContent()
  const [comment] = useState(getCommentItemContent(props.questionId) || '')

  const editorRef = useRef<any>()
  const editor = useRef<any>()

  async function loadEditor() {
    await loadjs(
      [
        '/fanya-exam-marking/ueditor.config.js',
        'https://mooc1.chaoxing.com/js/editor20150812/ueditor.all.min.js'
      ],
      {
        returnPromise: true
      }
    )
    // await loadjs('/fanya-exam-marking/ueditor.lang.zh-cn.js', {
    //   returnPromise: true
    // })

    // @ts-ignore
    const ue = (editor.current = window.UE.getEditor(editorRef.current, {
      lang: 'zh-cn',
      autoHeight: false,
      toolbars: [
        [
          'bold',
          'italic',
          'underline',
          '|',
          'paragraph',
          'fontfamily',
          'fontsize'
        ]
      ],
      focus: true,
      initialFrameHeight: document.body.clientHeight * 0.5,
      enableAutoSave: false,
      pasteplain: true,
      enableContextMenu: false,
      elementPathEnabled: false,
      wordCount: false,
      autoFloatEnabled: false,
      fontfamily: [
        { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
        { label: '', name: 'heiti', val: '黑体, SimHei' },
        { label: '', name: 'songti', val: '宋体,SimSun' },
        { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' }
      ],
      readonly: !props.allowEdit
    }))

    return new Promise(resolve => {
      ue.ready(resolve)
    })
  }

  async function init() {
    await loadEditor()
    editor.current.setContent(comment)
    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  function onSubmit() {
    if (!props.allowEdit) {
      props.onClose()
      return
    }
    const content = editor.current.getContent()
    setCommentContent(props.questionId, content)
    props.onClose()
  }

  return (
    <Modal
      title={'批注'}
      visible={true}
      width={APP_SCOPE.isMobile ? '86%' : 900}
      okText={props.allowEdit ? '保存' : '关闭'}
      onOk={onSubmit}
      onCancel={props.onClose}
    >
      <Spin spinning={loading}>
        <div className='editor' ref={editorRef}></div>
      </Spin>
    </Modal>
  )
}
