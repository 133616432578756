import { InputNumber } from 'antd'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { Paper } from 'typing'
import { SteppedScoreSetting } from 'typing.paper'
import {
  findAndParseStepScoreEmbedHTML,
  updateStepScoreEmbedHTML
} from 'utils/question'
import { useMarkingForm } from '../useMarkingForm'
import { useScoreFormNavigation } from '../useScoreFormNavigation'

export const StepScoreForm: FC<{
  question: Paper.Question
  child?: Paper.QuestionNestingChildQuestion
  steps: SteppedScoreSetting['items'][number]['steps']
}> = memo(({ question, child, steps }) => {
  const {
    commentForm,
    getCommentItemContent,
    setCommentContent
  } = useQuestionAnswerDataContent()

  const { setQuestionScore, setNestingQuestionScore } = useMarkingForm()

  const [values, setValues] = useState<Array<number | undefined>>([])

  // Init score from question comment
  useEffect(() => {
    const _values = Array.from({ length: steps.length }) as any

    const comment = getCommentItemContent(question.id)

    const stepEmbedValue = findAndParseStepScoreEmbedHTML(comment, steps)

    if (!stepEmbedValue) {
      setValues(_values)

      // Reset question in ScoreForm
      setQuestionScore(question.id, null)

      return
    }

    _.forEach(steps, (step, i) => {
      const v = stepEmbedValue[i]
      if (_.isFinite(v)) {
        _values[i] = v
      }
    })

    setValues(_values)
  }, [child, question.id, steps, commentForm])

  function onChange(value: number | string | undefined, index: number) {
    console.log('onChange', question.id)
    const currValue = values[index]
    if (currValue === value) return

    const newValues = [...values]
    newValues[index] = _.isFinite(value) ? (value as number) : undefined
    setValues(newValues)

    // Update question in ScoreForm
    if (_.every(newValues, v => _.isFinite(v))) {
      const score = _.reduce(newValues, (sum, v) => sum + (v || 0), 0)
      setQuestionScore(question.id, score)
    } else {
      setQuestionScore(question.id, null)
    }

    // Update comment when score changed
    const comment = getCommentItemContent(question.id)

    const newComment = updateStepScoreEmbedHTML(comment, steps, newValues)

    setCommentContent(question.id, newComment)
  }

  // useEffect(() => {
  //   console.log(values)
  // }, [values])
  //

  const questionId = useMemo(
    () => (child ? `${child.id}-${question.id}` : question.id),
    [child, question.id]
  )

  const { emitter } = useScoreFormNavigation()

  function onPressEnter(index: number) {
    const hasNext = index < steps.length - 1
    if (hasNext) {
      const input = $(`#step-score-form__input__${questionId}_${index + 1}`)
      if (input) {
        input.trigger('focus').trigger('select')
      }
    } else {
      emitter.emit('scoreForm:enterPress', {
        questionId: question.id
      })
    }
  }

  return (
    <div className='step-score-form'>
      {_.map(steps, (step, i) => (
        <div key={i} className='item'>
          <div className='bd'>
            <div>{step.title}</div>
            <InputNumber
              id={`step-score-form__input__${questionId}_${i}`}
              value={values[i]}
              min={0}
              max={step.score}
              onChange={v => onChange(v, i)}
              onPressEnter={() => onPressEnter(i)}
            />
            <div>&le;{step.score}</div>
          </div>
          <div className='desc'>步骤说明: {step.description}</div>
        </div>
      ))}

      <div className='item score'>
        <span>得分</span>
        <div className='num'>
          {_.reduce(
            values,
            (sum, v) => {
              return sum + (v || 0)
            },
            0
          )}
        </div>
        <span>分</span>
      </div>
    </div>
  )
})
