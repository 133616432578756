import { APP_SCOPE } from 'config'
import React, { FC, useEffect, useState } from 'react'
import {
  RichDrawerBtnAttrs,
  appendCommentBtnToAnswer,
  appendDrawerBtnToAnswer,
  appendSimilarityBtnToAnswer,
  appendWordCountToAnswer,
  checkAnswerHasText
} from 'utils'
import { RichText, RichTextProps } from './RichText'

export interface RichTextWithDrawerBtnProps extends RichTextProps {
  // 图片批注
  onDrawerBtnClick?: (e: RichDrawerBtnAttrs) => void
  // 查看批注
  onDrawerViewBtnClick?: (e: RichDrawerBtnAttrs) => void
  // 查看全部批注
  onDrawerViewBtnAllClick?: () => void
  // 历史批注 已弃用
  // onDrawerHistoryBtnClick?: (objectIds: string) => void
  // 富文本批注
  onCommentBtnClick?: () => void
  // 查看富文本批注
  onCommentViewBtnClick?: () => void
  // 相似度查询
  onSimilarityBtnClick?: () => void
}

export const RichTextWithDrawerBtn: FC<RichTextWithDrawerBtnProps> = props => {
  const {
    onDrawerBtnClick,
    onDrawerViewBtnClick,
    onDrawerViewBtnAllClick,
    onCommentBtnClick,
    onCommentViewBtnClick,
    onSimilarityBtnClick,
    ...rtProps
  } = props

  const [html, setHtml] = useState('')
  useEffect(() => {
    let val = props.html || ''

    if (APP_SCOPE.isMobile) {
      if (onCommentBtnClick || onCommentViewBtnClick) {
        val = appendCommentBtnToAnswer(val, !!onCommentBtnClick)
      }

      setHtml(val)
      return
    }

    // TODO moving comment and similarity btn out of rich-text
    if (onCommentBtnClick || onCommentViewBtnClick) {
      val = appendCommentBtnToAnswer(val, !!onCommentBtnClick)
    }

    if (onSimilarityBtnClick && checkAnswerHasText(val)) {
      val = appendWordCountToAnswer(appendSimilarityBtnToAnswer(val))
    }

    if (onDrawerBtnClick || onDrawerViewBtnClick) {
      val = appendDrawerBtnToAnswer(val, !!onDrawerBtnClick)
    }

    setHtml(val)
  }, [props.html])

  function onClickHandler(e: JQuery) {
    const el = e.closest('button')
    const imageData = el.data() as RichDrawerBtnAttrs // { id; src; index; originId }

    if (el.is('.btn-image-drawer')) {
      onDrawerBtnClick && onDrawerBtnClick(imageData)
    }
    if (el.is('.btn-image-drawer-view')) {
      onDrawerViewBtnClick && onDrawerViewBtnClick(imageData)
    }
    if (el.is('.btn-image-drawer-view-all')) {
      onDrawerViewBtnAllClick && onDrawerViewBtnAllClick()
    }
    if (el.is('.btn-comment')) {
      onCommentBtnClick && onCommentBtnClick()
    }
    if (el.is('.btn-comment-view')) {
      onCommentViewBtnClick && onCommentViewBtnClick()
    }
    if (el.is('.btn-similarity')) {
      onSimilarityBtnClick && onSimilarityBtnClick()
    }
  }

  return <RichText {...rtProps} html={html} onClickHandler={onClickHandler} />
}
