import { useLocation } from 'react-router-dom'
import React from 'react'
import qs from 'qs'

export function useQuery<T = any>() {
  const { search } = useLocation()
  return React.useMemo<T>(() => qs.parse(search, { ignoreQueryPrefix: true }), [
    search
  ])
}
