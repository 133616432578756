import _ from 'lodash'
import { SubjectScoreLevel, SubjectScoreLevelOrigin } from 'typing'

export const transToSubjectScoreLevel = (
  scoreLevel: SubjectScoreLevelOrigin,
  score: number
): SubjectScoreLevel => {
  const computedDefaultLevelScore = _.isEmpty(scoreLevel) && score >= 10

  function getScoreAt(pos: number) {
    return Math.floor(score * pos)
  }

  function getScoreRangeGroupDefault() {
    if (computedDefaultLevelScore) {
      const steps = [0, 0.4, 0.6, 0.7, 0.8, 0.9, 1]
      const result: SubjectScoreLevel['scoreRangeGroup'] = []
      for (let i = 0; i < steps.length - 1; i++) {
        result.push({
          begin: getScoreAt(steps[i]),
          end: steps[i + 1] === 1 ? 'END' : getScoreAt(steps[i + 1])
        })
      }
      return result
    }
    return [
      { begin: 0, end: null },
      { begin: null, end: null },
      { begin: null, end: null },
      { begin: null, end: 'END' }
    ] as SubjectScoreLevel['scoreRangeGroup']
  }

  const scoreRangeGroup: SubjectScoreLevel['scoreRangeGroup'] =
    scoreLevel.score_range && scoreLevel.score_range.length
      ? _.map(scoreLevel.score_range, r => {
          return r.length === 2
            ? {
                begin: r[0],
                end: r[1]
              }
            : {
                begin: r[0],
                end: 'END'
              }
        })
      : getScoreRangeGroupDefault()

  if (computedDefaultLevelScore) {
    return {
      ...scoreLevel,
      pass_score: getScoreAt(0.6),
      good_score: getScoreAt(0.8),
      excellent_score: getScoreAt(0.9),
      scoreRangeGroup
    }
  }

  return {
    ...scoreLevel,
    scoreRangeGroup
  }
}

export const transToSubjectScoreLevelOrigin = (
  data: SubjectScoreLevel
): SubjectScoreLevelOrigin => {
  return {
    ..._.omit(data, 'scoreRangeGroup'),
    score_range: _.reduce(
      data.scoreRangeGroup,
      (res, g) => {
        // @ts-ignore
        res.push(g.end === 'END' ? [g.begin] : [g.begin, g.end])
        return res
      },
      [] as SubjectScoreLevelOrigin['score_range']
    )
  }
}

export function genScoreButtonItem(score: number, count = 10) {
  const limit = count

  const acc = (() => {
    if (score >= 100) return 10

    for (let acc = 1; acc <= 10; acc++) {
      const count = Math.floor(score / acc)
      if (count <= limit) {
        return acc
      }
    }
    return 1
  })()

  return Array.from({ length: Math.floor(score / acc) })
    .map((v, i) => (i + 1) * acc)
    .filter(v => v <= score)
}
