import { message, Modal } from 'antd'
import constate from 'constate'
import { defaultErrorHandler, GET, POST } from 'core/request'
import { useQuery } from 'hooks/useQuery'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useList } from 'react-use'
import { Paper, School, Teacher } from 'typing'
import {
  PaperMarkingSettingDistributeStatus,
  PaperMarkingSettingFormData,
  QuestionIdentity,
  SteppedScoreSetting
} from 'typing.paper'
import { createPaperQuestion } from 'utils/question'
import { useMarkingSettingEditContext } from '../store'
import { QuestionBindGroupProvider } from './useQuestionBindGroup'
import { RedistributeProvide } from './useRedistribute'
import { IS_DEV } from 'config'

const markingSettingHook = () => {
  const queryString = useQuery<{ readonly: any; preview: any }>()

  const edit = useMarkingSettingEditContext()

  //#region Status
  const [distributeStatus, setDistributeStatus] = useState<
    PaperMarkingSettingDistributeStatus
  >(PaperMarkingSettingDistributeStatus.PENDING)

  const [formFieldReadonly, setFormFieldReadonly] = useState(false)

  useEffect(() => {
    const formData = edit.data as PaperMarkingSettingFormData

    const hasDistributeState =
      typeof formData.review_distribute_status === 'number'

    if (hasDistributeState) {
      setDistributeStatus(formData.review_distribute_status)

      setFormFieldReadonly(
        formData.review_distribute_status >
          PaperMarkingSettingDistributeStatus.PENDING || queryString.preview
      )
      if (IS_DEV) {
        setFormFieldReadonly(false)
      }
    }
  }, [edit.data])
  //#endregion

  //#region 学校
  const [schoolList, setSchoolList] = useState<School[]>([])

  // UNSAFE
  function findSchool(fid: number): School {
    return _.find(schoolList, { fid }) || ({} as School)
  }
  async function fetchSchool() {
    const { data } = await GET('/city/sub/orgs', {})
    setSchoolList(data)
  }
  //#endregion

  //#region 教师
  const [teacherList, { set: setTeacher, removeAt: removeTeacherAt }] = useList<
    Teacher
  >([])

  function addTeacher(items: Teacher[]) {
    setTeacher(
      _.uniqBy(
        [
          // @ts-ignore
          ...teacherList,
          ...items
        ],
        'uid'
      )
    )
  }

  async function replaceTeacher(
    from: Teacher,
    to: Teacher,
    questionIdsOfGroup: QuestionIdentity[]
  ) {
    const formData = edit.data as PaperMarkingSettingFormData
    await POST('fanya/exam/paper/teacher/change', {
      data: {
        exam_paper_id: formData.id,
        from_teacher: from.uid,
        to_teacher: to.uid,
        to_teacher_info: to,
        quest_ids: questionIdsOfGroup
      }
    })
    message.success('替换成功')
    edit.onEdit(edit.params)
  }
  //#endregion

  //#region 考题
  const [paperQuestionBox, setPaperQuestionBox] = useState<Paper.QuestionBox>()
  const [paperQuestions, setPaperQuestions] = useState<Paper.QuestionGroup[]>(
    []
  )
  async function fetchQuestion(id) {
    console.log('fetchQuestion', id)

    const { data } = await GET(`city/paper/${id}`, {})
    const po = _.get(data, 'data[0]') as Paper.PaperOriginal
    if (!po) {
      console.error('无法获取试卷数据')
      Modal.error({
        title: '无法获取试卷数据，请稍后再试',
        onOk: () => {
          edit.onCancel()
        }
      })
      return
    }
    const box = createPaperQuestion(po)
    setPaperQuestionBox(box)
    setPaperQuestions(box.group)
  }

  const [questionCount, setQuestionCount] = useState(0)
  function getQuestionCount() {
    return _.reduce(
      paperQuestions,
      (sum, g) => {
        _.forEach(g.children, q => {
          sum += 1
        })
        return sum
      },
      0
    )
  }
  useEffect(() => {
    setQuestionCount(getQuestionCount())
  }, [paperQuestions])
  //#endregion

  //#region 试卷组
  const [isGroupedPaper, setIsGroupedPaper] = useState(false)
  const [papersGroupId, setPapersGroupId] = useState<number>()
  const [papersInGroup, setPapersInGroup] = useState<
    Array<{
      exam_paper_id: number
      name: string
      has_setting: boolean
    }>
  >([])
  async function checkIsGroupedPaper() {
    try {
      const { data } = await GET('fanya/group/paper/is_group', {
        data: {
          exam_paper_id: edit.data.id
        }
      })
      const isGrouped = data?.is_group_exam === 1
      setIsGroupedPaper(isGrouped)
      setPapersGroupId(data?.exam_group_id)
      if (isGrouped) {
        const { data: papers } = await GET('fanya/group/paper/list', {
          data: {
            exam_paper_id: edit.data.id,
            group_id: data?.exam_group_id
          }
        })
        setPapersInGroup(papers)
      }
    } catch (e) {
      defaultErrorHandler(e as any)
    }
  }

  //#region step-score
  const [stepScoreSettingEnable, setStepScoreSettingEnable] = useState(false)
  const [stepScoreSettingItems, setStepScoreSettingItems] = useState<
    SteppedScoreSetting['items']
  >([])

  // Init
  useEffect(() => {
    if (!edit.data) return
    const stepScoreSetting = (() => {
      const val = edit.data?.step_score_setting
      if (!val) return null
      if (typeof val === 'string') {
        try {
          return JSON.parse(val) as SteppedScoreSetting
        } catch (e) {
          console.error(e)
          return null
        }
      }
      return val
    })()
    if (stepScoreSetting) {
      setStepScoreSettingEnable(stepScoreSetting.enable)
      setStepScoreSettingItems(stepScoreSetting.items)
    }
  }, [edit])

  function getDefaultStepScoreSettingItem(): SteppedScoreSetting['items'][number] {
    const item: SteppedScoreSetting['items'][number] = {
      enable: true,
      question_id: null as any,
      steps: [
        {
          title: '第一步'
        } as any,
        {
          title: '第二步'
        } as any
      ]
    }
    return item
  }
  //#endregion

  return {
    distributeStatus,
    formFieldReadonly,

    // school
    schoolList,
    fetchSchool,
    findSchool,

    //
    paperQuestionBox,
    paperQuestions,
    fetchQuestion,
    setPaperQuestions,
    questionCount,

    // teacherSelect
    teacherList,
    setTeacher,
    addTeacher,
    removeTeacherAt,
    replaceTeacher,

    // 试卷组设置同步
    isGroupedPaper,
    papersGroupId,
    papersInGroup,
    checkIsGroupedPaper,

    // Step score
    stepScoreSettingEnable,
    setStepScoreSettingEnable,
    stepScoreSettingItems,
    setStepScoreSettingItems,
    getDefaultStepScoreSettingItem
  }
}

const [Provider, useContent] = constate(markingSettingHook)
Provider.displayName = 'MarkingSettingProvider'

const MarkingSettingProvider: FC = props => (
  <Provider>
    <QuestionBindGroupProvider>
      <RedistributeProvide>{props.children}</RedistributeProvide>
    </QuestionBindGroupProvider>
  </Provider>
)

export { MarkingSettingProvider, useContent as useMarkingSettingContent }
