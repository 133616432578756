import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select, Tag } from 'antd'
import { Table, getPaginationProps } from 'components'
import { ModalSizeNormal } from 'config'
import { createList } from 'core/service'
import { useMarkingTagsContent } from 'hooks/useMarkingTags'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import moment from 'moment'
import { StudentMarkingHistory } from 'pages/marking-history'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FORMATTER_DATE, FORMATTER_TIME } from 'utils'
import { useMarkingForm } from '../useMarkingForm'

const { ListProvider, useListContext } = createList<
  StudentMarkingHistory,
  { markingPaperId?: number; keyword?: string; name?: string }
>({
  url: query => `/fanya/tag/search/${query.markingPaperId}`
})

const StudentSearchInner: FC = () => {
  // QuestionAnswerData
  const { markingPaper, markingPaperId } = useQuestionAnswerDataContent()

  const { currentStudent, studentList, pageReload } = useMarkingForm()

  const hideStudentInfo = useMemo(() => {
    return markingPaper?.hide_info
  }, [markingPaper])

  // Tags
  const { getAllTags, fetch } = useMarkingTagsContent()
  const tagOptions = useMemo(() => {
    return getAllTags().map(tag => ({ label: tag.name, value: tag.name }))
  }, [getAllTags])

  // List
  const list = useListContext()

  useEffect(() => {
    list.setDefaultSearch({ markingPaperId })
  }, [markingPaperId])

  // Local
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (visible) {
      fetch()
    }
  }, [visible, fetch])

  function onSearchSubmit(data) {
    list.onSearchSubmit({ ...data })
  }

  const onNavToStudent = useCallback(
    (item: StudentMarkingHistory) => {
      const index = studentList.findIndex(s => s.id === item.id)
      if (index !== -1) {
        pageReload(index)
      }
    },
    [studentList, pageReload]
  )

  return (
    <>
      <Button
        size='small'
        icon={<SearchOutlined />}
        onClick={() => setVisible(true)}
      >
        试卷搜索
      </Button>

      <Modal
        title='试卷搜索'
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={ModalSizeNormal}
      >
        <Form
          layout={'inline'}
          onFinish={onSearchSubmit}
          style={{
            marginBottom: 20
          }}
        >
          {!hideStudentInfo && (
            <Form.Item name={'name'} label={'学生姓名'}>
              <Input allowClear style={{ width: 140 }} />
            </Form.Item>
          )}
          <Form.Item name={'keyword'} label={'标签'}>
            <Select
              options={tagOptions}
              allowClear
              style={{ width: 180 }}
            ></Select>
          </Form.Item>
          <Form.Item>
            <Button type={'primary'} htmlType={'submit'}>
              搜索
            </Button>
          </Form.Item>
        </Form>

        <Table<StudentMarkingHistory>
          list={list}
          pagination={getPaginationProps(list)}
          columns={[
            ...(() => {
              if (!hideStudentInfo) {
                return [
                  {
                    title: '姓名',
                    dataIndex: 'student_user_name',
                    render: t => t || '-',
                    align: 'center',
                    width: 120,
                    fixed: 'left'
                  },
                  {
                    title: '学号',
                    dataIndex: 'student_login_name',
                    render: (t, r) => t || r.student_uid || '-',
                    align: 'center',
                    width: 120,
                    fixed: 'left'
                  }
                ] as any
              }
              return [
                {
                  title: '考生id',
                  dataIndex: 'student_uid',
                  width: 120,
                  fixed: 'left'
                }
              ]
            })(),
            {
              title: '判卷时间',
              dataIndex: 'complete_time',
              render: (t, r) =>
                r.is_complete
                  ? moment(t).format(`${FORMATTER_DATE} ${FORMATTER_TIME}`)
                  : '',
              width: 160
            },
            {
              title: '操作',
              key: 'actions',
              width: 80,
              render: (t, r) => {
                if (currentStudent?.id === r.id) {
                  return <Tag color='success'>当前批阅</Tag>
                }
                return <a onClick={() => onNavToStudent(r)}>批阅</a>
              }
            }
          ]}
        />
      </Modal>
    </>
  )
}

export const StudentSearch = () => (
  <ListProvider>
    <StudentSearchInner />
  </ListProvider>
)
