import { Tag, Typography } from 'antd'
import React, { FC } from 'react'
import { MarkingAuditRecordVO } from 'typing.marking'

const { Text } = Typography

export const MarkingRecordList: FC<{ items?: MarkingAuditRecordVO[] }> = ({
  items
}) => {
  if (!items) return null

  return (
    <>
      <div className='label' style={{ marginTop: 16, marginBottom: 8 }}>
        <Text strong>评分记录</Text>
      </div>
      <div>
        {!items.length && <Text type='secondary'>暂未评分</Text>}
        {items.map((item, index) => (
          <div
            key={index}
            className='score-record'
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4
            }}
          >
            <div style={{ flex: 1 }}>
              {index + 1}. {item.display_name}
            </div>
            <Tag color='red'>{item.score}分</Tag>
          </div>
        ))}
      </div>
    </>
  )
}
