import { Modal } from 'antd'
import { CurrentQuestionContext } from 'hooks/CurrentQuestionContext'
import { useMarkingForm } from 'pages/marking-form/useMarkingForm'
import React, { FC, useState } from 'react'
import { Paper } from 'typing'
import { RichDrawerBtnAttrs } from 'utils'
import { useQuestionAnswerDataContent } from '../../hooks/useQuestionAnswerData'
import { RichTextWithDrawerBtn } from '../Preview/RichTextWithDrawerBtn'
import { CommentDialog, CommentDialogProps } from './modals/CommentDialog'
import { DrawerViewAllModal } from './modals/DrawerViewAllModal'
import { LocalImageDrawerModal } from './modals/LocalImageDrawerModel'
import { SimilarityModal } from './modals/SimilarityModal'
import { QuestionIdentity } from 'typing.paper'

const log = require('debug')('RichTextWithDrawerModal')

export interface DrawerModalProps {
  question: Paper.Question
  questionId: QuestionIdentity
  namedQuestionIndex?: number
  studentId: number
  readonly?: boolean
  showHistory?: boolean
}

export const RichTextWithDrawerModal: FC<
  DrawerModalProps & {
    html?: string
  }
> = props => {
  // log('props', props)
  const { openDrawerOnClickImage } = useMarkingForm()

  // const imageBaseIndex = useMemo(
  //   () => (props.namedQuestionIndex ? props.namedQuestionIndex * 1000 : 0),
  //   [props.namedQuestionIndex]
  // )

  //#region Local Drawer
  const { getCommentImage, setCommentImage } = useQuestionAnswerDataContent()

  const [drawerParams, setDrawerParams] = useState<{
    drawerBtnAttrs: RichDrawerBtnAttrs
    src: string
    originalSrc: string
    readonly: boolean
  }>()

  async function onDrawerClick(ba: RichDrawerBtnAttrs) {
    log('onDrawerClick', ba)

    const commentImg = getCommentImage(props.questionId, {
      imageIndex: ba.index,
      namedQuestionIndex: props.namedQuestionIndex || 0
    })
    setDrawerParams({
      drawerBtnAttrs: ba,
      src: commentImg || ba.src,
      originalSrc: ba.src,
      readonly: false
    })
  }

  async function onDrawerViewClick(ba: RichDrawerBtnAttrs) {
    const commentImg = getCommentImage(props.questionId, {
      imageIndex: ba.index,
      namedQuestionIndex: props.namedQuestionIndex || 0
    })
    setDrawerParams({
      drawerBtnAttrs: ba,
      src: commentImg || ba.src,
      originalSrc: ba.src,
      readonly: true
    })
  }

  function onLocalDrawerConfirm(fileUrl: string) {
    if (drawerParams) {
      setCommentImage({
        questionId: props.questionId,
        image: {
          url: '',
          objectId: '',
          namedQuestionIndex: props.namedQuestionIndex || 0,
          imageIndex: drawerParams.drawerBtnAttrs.index,
          trackId: drawerParams.drawerBtnAttrs.originId || ''
        },
        drawerResult: { file: fileUrl }
      })
    }
    setDrawerParams(undefined)
  }

  async function onLocalDrawerReset() {
    if (drawerParams) {
      setDrawerParams({
        ...drawerParams,
        src: drawerParams.originalSrc
      })
    }
  }
  //#endregion

  //#region drawerViewAll
  const [drawerViewAllModalVisible, setDrawerViewAllModalVisible] = useState(
    false
  )

  function onDrawerViewAllClick() {
    setDrawerViewAllModalVisible(true)
  }
  //#endregion

  //#region comments
  const [commentDialogData, setCommentDialogData] = useState<
    CommentDialogProps
  >()

  function onCommentBtnClick() {
    setCommentDialogData({
      questionId: props.questionId,
      allowEdit: true,
      onClose: () => setCommentDialogData(undefined)
    })
  }

  function onCommentViewBtnClick() {
    setCommentDialogData({
      questionId: props.questionId,
      allowEdit: false,
      onClose: () => setCommentDialogData(undefined)
    })
  }
  //#endregion

  const [similarityData, setSimilarityData] = useState<{
    studentAnswer: string
    rightAnswer: string
    score: number
  }>()
  function onSimilarityBtnClick() {
    setSimilarityData({
      studentAnswer: props?.html!,
      rightAnswer: (props.question as any).answer
        .map(it => it.answer || it.content)
        .join(' '),
      score: props.question.score
    })
  }

  function openDrawerByImage(el: JQuery) {
    $(el).siblings().find('button[data-src]').trigger('click')
  }

  // if (isRuntimeCXStudy) {
  //   return <RichText {...props} />
  // }

  return (
    <>
      <RichTextWithDrawerBtn
        html={props.html}
        onDrawerBtnClick={props.readonly ? undefined : onDrawerClick}
        onDrawerViewBtnClick={props.readonly ? onDrawerViewClick : undefined}
        onDrawerViewBtnAllClick={onDrawerViewAllClick}
        onCommentBtnClick={props.readonly ? undefined : onCommentBtnClick}
        onCommentViewBtnClick={
          props.readonly ? onCommentViewBtnClick : undefined
        }
        onSimilarityBtnClick={onSimilarityBtnClick}
        onImageClick={openDrawerOnClickImage ? openDrawerByImage : undefined}
      />

      <CurrentQuestionContext.Consumer>
        {({ question, parentQuestion }) => (
          <>
            {drawerParams && (
              <LocalImageDrawerModal
                question={question!}
                parentQuestion={parentQuestion}
                url={drawerParams.src}
                readonly={drawerParams.readonly}
                onConfirm={onLocalDrawerConfirm}
                onClose={() => setDrawerParams(undefined)}
                onReset={onLocalDrawerReset}
              />
            )}
          </>
        )}
      </CurrentQuestionContext.Consumer>

      {drawerViewAllModalVisible && (
        <DrawerViewAllModal
          questionId={props.questionId}
          onClose={() => setDrawerViewAllModalVisible(false)}
        />
      )}

      {commentDialogData && <CommentDialog {...commentDialogData} />}

      {similarityData && (
        <SimilarityModal
          data={similarityData}
          onClose={() => setSimilarityData(undefined)}
        />
      )}
    </>
  )
}
