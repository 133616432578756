import { Breadcrumb, Tabs } from 'antd'
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import './index.less'

export const PageWrapper: FC<{
  breadcrumb?: string[]
  headerExtra?: () => ReactNode
  headerRight?: () => ReactNode
  content?: ReactNode
  tabsItems?: ReactNode
  onTabsChange?: (key: string) => void
}> = props => {
  return (
    <div className='page-wrapper'>
      <div
        className={classNames('page-header', {
          'page-header__has-tabs': !!props.tabsItems
        })}
      >
        {props.breadcrumb && (
          <div className={'page-header__breadcrumb'}>
            当前位置：
            <Breadcrumb>
              {props.breadcrumb.map((b, i) => (
                <Breadcrumb.Item key={i}>{b}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            {props.headerExtra && (
              <div className={'page-header__extra'}>{props.headerExtra()}</div>
            )}
            {props.headerRight && (
              <div className={'page-header__right'}>{props.headerRight()}</div>
            )}
          </div>
        )}

        {props.content && (
          <div className='page-header__content'>{props.content}</div>
        )}

        {props.tabsItems && (
          <Tabs
            className={'page-header__tabs'}
            size={'large'}
            onChange={key => props.onTabsChange && props.onTabsChange(key)}
          >
            {props.tabsItems}
          </Tabs>
        )}
      </div>
      {props.children}
    </div>
  )
}
