import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IS_IN_FRAME } from 'config'
import { Modal } from 'antd'
import './style.less'

export default props => {
  useEffect(() => {
    if (IS_IN_FRAME) {
      const html = document.querySelector('html')
      html && html.classList.add('is-in-frame')
    }
  }, [])

  const location = useLocation()
  useEffect(() => {
    Modal.destroyAll()
  }, [location])

  return <div className={'basic-layout'}>{props.children}</div>
}
