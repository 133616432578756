import { PageWrapper } from 'components/PageWrapper'
import { createEdit } from 'core/service'
import { Button, Card, Col, Modal, Row, Space, Tabs, Typography } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ExamSummaryStatistic } from 'components/reports/ExamSummaryStatistic'
import { CurrentExamProvider, useCurrentExamContent } from 'hooks/useExamData'
import { dateRangeFormat } from 'utils'
import { POST } from 'core/request'
import { Edit } from './Edit'
import { ExamScoreLevelItem, ExamScoreLevelItemOrigin } from 'typing'
import {
  transToSubjectScoreLevel,
  transToSubjectScoreLevelOrigin
} from 'utils/score'
import _ from 'lodash'
import { ClockCircleOutlined } from '@ant-design/icons'
import {
  StatisticStateProvider,
  useStatisticStateContext
} from 'hooks/useStatisticState'
import { useMount, useUpdateEffect } from '@umijs/hooks'
import { ReportStateGuard } from 'components/reports/ReportStateGuard'
import { ReportExamPaperTable } from 'components/ReportExamPaperTables'
import { ReportPaperSelectWrapper } from 'components/reports/ReportPaperSelectWrapper'

const { Title, Text } = Typography

const { EditProvider, useEditContext } = createEdit<
  {
    configs: ExamScoreLevelItem[]
  },
  { id }
>({
  fetch: {
    url: '/city/exam/statistic/config',
    data: params => ({ exam_id: params.id }),
    parser: data => {
      const configs: ExamScoreLevelItemOrigin[] = data.configs
      return {
        configs: _.map(configs, origin => {
          return {
            ...transToSubjectScoreLevel(origin.config, origin.score),
            meta: origin
          }
        })
      }
    }
  },
  submit: {
    url: ({ params }) => `/city/exam/statistic/config?exam_id=${params.id}`,
    method: POST,
    parser: (data, params) => {
      return {
        configs: _.map(data.configs, item => {
          return {
            ...item.meta,
            config: transToSubjectScoreLevelOrigin(item)
          } as ExamScoreLevelItemOrigin
        })
      }
    }
  },
  remove: {
    url: '/item'
  }
})

export { EditProvider, useEditContext }

export default function () {
  return (
    <CurrentExamProvider>
      <StatisticStateProvider>
        <EditProvider>
          <Page />
        </EditProvider>
      </StatisticStateProvider>
    </CurrentExamProvider>
  )
}

const Page: FC = () => {
  const query = useParams<{ id }>()
  const edit = useEditContext()

  // useCurrentExamContent
  const { currentExam, initCurrentExam } = useCurrentExamContent()
  useEffect(() => {
    initCurrentExam(query.id)
  }, [])

  //#region useStatisticStateContext
  const {
    loading,
    statisticConfig,
    checkStatisticState,
    requestStatisticCompute
  } = useStatisticStateContext()

  async function _checkStatisticState() {
    await checkStatisticState('exam', query.id)
  }

  function openSetting() {
    edit.onEdit({ id: query.id })
  }

  useMount(() => {
    _checkStatisticState()
  })

  useUpdateEffect(() => {
    _checkStatisticState()
  }, [edit.lastModify])

  useEffect(() => {
    // 检查 statisticConfig
    if (!loading && !statisticConfig) {
      openSetting()
    }
  }, [loading])

  //#endregion

  // UI
  function onRequestStatisticComputeClick() {
    Modal.confirm({
      title: '确定重新汇总成绩',
      onOk: () => requestStatisticCompute()
    })
  }

  // tabs
  const [tabKey, setTabKey] = useState<'1' | '2'>('1')

  return (
    <PageWrapper
      breadcrumb={['统考管理', '成绩统计']}
      headerExtra={() => (
        <Link to={'/exams'} replace>
          返回上级
        </Link>
      )}
      content={
        currentExam && (
          <Row>
            <Col flex={'auto'}>
              <Title level={4} style={{ marginBottom: 4 }}>
                {currentExam.name} - 成绩统计
              </Title>
              <Space size={'large'}>
                <Text type={'secondary'}>
                  考试时间：
                  {dateRangeFormat({
                    value: [currentExam.begin_date, currentExam.end_date]
                  })}
                </Text>
                <Text type={'secondary'}>总分：{currentExam.score}</Text>
              </Space>
            </Col>
            <Col flex={'none'}>
              <Space>
                <Button
                  icon={<ClockCircleOutlined />}
                  onClick={() => onRequestStatisticComputeClick()}
                >
                  重新汇总成绩
                </Button>
                <Button
                  type={'primary'}
                  onClick={() => edit.onEdit({ id: query.id })}
                >
                  成绩统计设置
                </Button>
              </Space>
            </Col>
          </Row>
        )
      }
      tabsItems={[
        <Tabs.TabPane
          key={'1'}
          tab={<span style={{ padding: '0 8px' }}>试卷统计</span>}
        />,
        <Tabs.TabPane
          key={'2'}
          tab={<span style={{ padding: '0 8px' }}>统考总分</span>}
        />
      ]}
      onTabsChange={k => setTabKey(k as any)}
    >
      <ReportStateGuard openSetting={() => openSetting()}>
        {tabKey === '2' && (
          <>
            <ExamSummaryStatistic id={query.id} />

            <Card title={'统考总分'}>
              <ReportExamPaperTable
                reportEntryType={'exam'}
                reportEntryId={_.parseInt(query.id)}
              />
            </Card>
          </>
        )}
        {tabKey === '1' && (
          <Card>
            <ReportPaperSelectWrapper examId={query.id}>
              {({ id, name }) => (
                <ReportExamPaperTable
                  key={id}
                  reportEntryType={'paper'}
                  reportEntryId={id}
                  reportEntryName={name}
                />
              )}
            </ReportPaperSelectWrapper>
          </Card>
        )}
      </ReportStateGuard>

      <Edit />
    </PageWrapper>
  )
}
