import { useRef, useState } from 'react'
import { DELETE, GET, POST, PUT, defaultErrorHandler } from 'core/request'
import constate from 'constate'
import { ListContext } from 'core/service/useList'
import { message, Modal } from 'antd'

type ValueOrValueFunction<P, V> = V | ((params: P) => V)

export interface UseEditOptions<T, P> {
  getDefaultFormData?: (params) => any
  fetch?: {
    url: ValueOrValueFunction<P, string>
    data?: ValueOrValueFunction<P, any>
    parser?: (data: any, params: P) => T
  }
  submit?: {
    url: ValueOrValueFunction<{ isEdit: boolean; data: T; params: P }, string>
    parser?: (data: T, params: P) => any
    method?: any
  }
  remove?: {
    url: ValueOrValueFunction<P, string>
    data?: (params: P) => any
  }
}

export interface EditContext<T, P> {
  visible: boolean
  isEdit: boolean
  data: T
  params: P
  loading: boolean
  saving: boolean
  lastModify: Date | null

  onAdd: (params?: P) => void
  onEdit: (params: P) => void
  onSubmit: (data: T) => void
  onCancel: () => void
  onRemove: (data) => void

  connect: (list: ListContext) => void
  setLoading: (boolean) => void
}

export function createEdit<FormDataType = any, ParamsType = FormDataType>(
  options: Readonly<UseEditOptions<FormDataType, ParamsType>>
) {
  function hook(): EditContext<FormDataType, ParamsType> {
    const [visible, setVisible] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [data, setData] = useState<FormDataType>({} as any)
    const [params, setParams] = useState<ParamsType>({} as any)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [lastModify, setLastModify] = useState<Date | null>(null)

    function onAdd(params?) {
      setParams(params)
      setData(
        options.getDefaultFormData ? options.getDefaultFormData(params) : {}
      )
      setIsEdit(false)
      setVisible(true)
    }

    async function onEdit(params: ParamsType) {
      setParams(params)
      setIsEdit(true)

      if (options.fetch) {
        setVisible(true)
        setLoading(true)
        try {
          const { data } = await GET(
            getFunctionalValue(options.fetch.url, params),
            options.fetch.data && {
              data: options.fetch.data(params)
            }
          )
          setData(
            options.fetch.parser ? options.fetch.parser(data, params) : data
          )
          setLoading(false)
        } catch (e) {
          setLoading(false)
          console.error(e)
          throw e
        }
      } else {
        setData((params as any) as FormDataType)
        setVisible(true)
      }
    }

    async function onSubmit(formData) {
      if (!options.submit) {
        console.error('[useEdit] options.submit undefined')
        return
      }
      const _data = { ...data, ...formData }
      setSaving(true)

      try {
        const ajax = options.submit.method
          ? options.submit.method
          : isEdit
          ? PUT
          : POST
        const url = getFunctionalValue(options.submit.url, {
          isEdit,
          data: _data,
          params
        })
        const remoteData = options.submit.parser
          ? options.submit.parser(_data, params)
          : _data
        await ajax(url, {
          data: remoteData
        })

        setVisible(false)
        message.success('保存成功')
        requestListReload({ reset: true })
        setLastModify(new Date())
      } catch (e) {
        Modal.warn({
          title: '提示',
          content: (e as Error).message
        })
        throw e
      } finally {
        setSaving(false)
      }
    }

    function onCancel() {
      setLoading(false)
      setVisible(false)
    }

    async function onRemove(data) {
      if (!options.remove) {
        console.error('[useEdit] options.submit undefined')
        return
      }
      try {
        await DELETE(
          getFunctionalValue(options.remove.url, data),
          options.remove.data && options.remove.data(data)
        )
        requestListReload()
        message.success('删除成功')
      } catch (e) {
        console.log(e)
        message.warn((e as Error).message)
      }
    }

    function getFunctionalValue(val, params) {
      return typeof val === 'function' ? val(params) : val
    }

    // inject listContext
    const listRef = useRef<ListContext>()

    function connect(list: ListContext) {
      listRef.current = list
    }

    function requestListReload(options?: { reset: boolean }) {
      listRef.current && listRef.current.fetch(options)
    }

    return {
      visible,
      isEdit,
      data,
      params,
      loading,
      saving,
      lastModify,

      onAdd,
      onEdit,
      onSubmit,
      onCancel,
      onRemove,
      connect,
      setLoading
    }
  }

  const [EditProvider, useEditContext] = constate(hook)
  EditProvider.displayName = 'EditProvider'
  return {
    EditProvider,
    useEditContext
  }
}
