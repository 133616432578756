import { getPaginationProps } from 'components'
import { PageWrapper } from 'components/PageWrapper'
import { createList } from 'core/service'
import { useMount } from '@umijs/hooks'
import { Button, Card, Col, List, Modal, Row, Tag } from 'antd'
import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Marking } from 'typing'
import numeral from 'numeral'
import moment from 'moment'
import { FORMATTER_DATE, FORMATTER_TIME } from 'utils'
import { PreviewMarkingDialog } from 'components/Preview/PreviewMarkingDialog'

const { ListProvider, useListContext } = createList<Marking.MarkingPaper>({
  url: () => '/review/paper'
})

export default function () {
  return (
    <ListProvider>
      <Page />
    </ListProvider>
  )
}

const Page: FC = () => {
  const history = useHistory()
  const list = useListContext()

  function startMarking(item: Marking.MarkingPaper) {
    const begin = moment(item.review_time)
    if (moment().isBefore(begin)) {
      Modal.info({
        title: item.name,
        content: `${begin.format(
          `${FORMATTER_DATE} ${FORMATTER_TIME} 开启在线判卷`
        )}`
      })
      return
    }
    history.push(`/marking/${item.id}`)
  }

  // Preview
  const [previewVisible, setPreviewVisible] = useState(false)
  const [paperMarkingId, setPaperMarkingId] = useState<number>(0)
  async function onPreview(item: Marking.MarkingPaper) {
    setPaperMarkingId(item.id)
    setPreviewVisible(true)
  }

  useMount(() => {
    list.fetch()
  })

  return (
    <PageWrapper breadcrumb={['在线判卷']}>
      <Card>
        <List<Marking.MarkingPaper>
          dataSource={list.items}
          loading={list.loading}
          itemLayout={'horizontal'}
          renderItem={item => (
            <List.Item>
              <div className='list-item__content'>
                <div className='list-item__title'>
                  <a onClick={() => onPreview(item)}>{item.name}</a>
                  {!item.has_begin && (
                    <Tag style={{ marginLeft: 16 }}>未开始</Tag>
                  )}
                </div>
                <div className='list-item__desc'>
                  <Row gutter={[16, 4]}>
                    <Col span={8}>所属统考：{item.exam_name}</Col>
                    <Col span={5}>分配答卷：{item.review_count}份</Col>
                    {!!item.review_count && (
                      <Col span={5}>
                        未判答卷：
                        {item.review_count - item.complete_review_count}
                      </Col>
                    )}
                    <Col span={6}>
                      判卷完成：
                      {item.review_count &&
                      item.review_count === item.complete_review_count ? (
                        <Tag color={'success'}>100%</Tag>
                      ) : (
                        <Tag color={'error'}>
                          {numeral(
                            item.complete_review_count / item.review_count
                          ).format('0.00%')}
                        </Tag>
                      )}
                    </Col>
                    <Col span={16}>
                      判卷开始：
                      {moment(item.review_time).format(
                        `${FORMATTER_DATE} ${FORMATTER_TIME}`
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={'list-item__actions'}>
                {/*{!!item.review_count &&*/}
                {/*  item.review_count !== item.complete_review_count && (*/}
                {
                  <Button
                    type={'primary'}
                    style={{ marginRight: 8 }}
                    onClick={() => startMarking(item)}
                  >
                    在线判卷
                  </Button>
                }
                <Button
                  type={'primary'}
                  ghost
                  onClick={() =>
                    history.push(
                      `/marking/${item.id}/history?paper_id=${item.paper_id}`
                    )
                  }
                >
                  批阅历史
                </Button>
              </div>
            </List.Item>
          )}
          pagination={getPaginationProps(list)}
        />
      </Card>

      <PreviewMarkingDialog
        id={paperMarkingId}
        visible={previewVisible}
        title={'查看批阅试题'}
        onCancel={() => setPreviewVisible(false)}
        showContentForce
      />
    </PageWrapper>
  )
}
