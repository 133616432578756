import React, { FC, useEffect, useState } from 'react'
import { Select } from 'antd'
import { GET } from '../../../core/request'

export const BatchTypeSelect: FC<{
  value?: string
  onChange?: (value: string) => void
}> = props => {
  const [data, setData] = useState<{ id: number; flmc: string }[]>([])
  async function fetch() {
    const { data } = await GET('statistics/v2/kspc/list', {})
    setData(data.data)
  }
  useEffect(() => {
    fetch()
  }, [])

  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      placeholder={'考试批次类型'}
      allowClear
      style={{ width: 150 }}
    >
      {data.map(it => (
        <Select.Option key={it.id} value={it.id}>
          {it.flmc}
        </Select.Option>
      ))}
    </Select>
  )
}
