import {
  Button,
  Card,
  Cascader,
  Form,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tag
} from 'antd'
import { PageWrapper } from 'components/PageWrapper'
import { GET } from 'core/request'
import { useQuery } from 'hooks/useQuery'
import _ from 'lodash'
import qs from 'qs'
import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Link, useParams } from 'react-router-dom'
import { Paper } from 'typing'
import { QuestionIdentity } from 'typing.paper'
import { createPaperQuestion } from 'utils/question'

type QuestionOption = {
  label: string
  value: QuestionIdentity
  children?: QuestionOption[]
}

const MarkingQualityReport: FC = props => {
  const { pid } = useParams<{ pid: string }>()
  const { exam_name } = useQuery<{ paper_id; exam_name }>()

  // Question
  async function fetchQuestion() {
    const { data } = await GET(`city/paper/${pid}`, {})
    const po = _.get(data, 'data[0]') as Paper.PaperOriginal
    if (!po) {
      console.error('无法获取试卷数据')
      Modal.error({
        title: '无法获取试卷数据，请稍后再试',
        onOk: () => {
          onCancel()
        }
      })
      return
    }
    const box = createPaperQuestion(po)
    return box
  }

  const [questionOptions, setQuestionOptions] = useState<QuestionOption[]>([])

  async function init() {
    setLoading(true)
    const box = await fetchQuestion()
    console.log('box', box)

    if (!box) return

    const questionOptions: QuestionOption[] = box.group.map(g => {
      const children = g.children.map(q => ({
        label: `(${q.displayName})`,
        value: q.id
      }))
      return {
        label: `${g.displayName}、${g.typeNameAlias}`,
        value: g.id,
        children
      }
    })
    setQuestionOptions(questionOptions)

    if (!questionOptions.length || !questionOptions[0].children?.length) {
      Modal.error({
        title: '无法获取试卷数据，请稍后再试',
        onOk: () => {
          onCancel()
        }
      })
      return
    }
    const questionIds = [
      questionOptions[0].value,
      questionOptions[0].children?.[0].value
    ]
    form.setFieldsValue({ question: questionIds })

    await fetchTeacherAndSetDefaultSearch(questionIds)

    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  // Teacher
  const [teachers, setTeachers] = useState<{ label: string; value: number }[]>()

  // Search
  const [search, setSearch] = useState<{
    question?: number[]
    teacher?: number[]
  }>()

  const [form] = Form.useForm()

  const fetchTeacherAndSetDefaultSearch = async (
    questionIds: QuestionIdentity[]
  ) => {
    if (!questionIds?.[1]) return

    const { data } = await GET('fanya/monitor/teachers', {
      data: {
        exam_paper_id: pid,
        question_id: questionIds[1]
      }
    })
    const result = data?.map(it => ({
      label: it.display_name,
      value: it.uid
    }))
    setTeachers(result)

    const ids = result.map(it => it.value)
    setSearch((value: any) => ({
      ...value,
      question: questionIds,
      teacher: ids
    }))
    form.setFieldsValue({ teacher: ids })
  }

  // Data
  const [loading, setLoading] = useState(false)

  const [chartData, setChartData] = useState<{
    teacher_score_space: {
      [uid: string]: {
        score_space: number
        count: number
      }[]
    }
    average: {
      score_space: number
      count: number
    }[]
  }>()

  async function fetchLineChartData() {
    const { question, teacher } = search || {}
    if (!question?.[1] || !teacher?.length) return

    const { data } = await GET('fanya/monitor/line_chat', {
      data: {
        exam_paper_id: pid,
        question_id: question?.[1],
        teachers: teacher.join(',')
      }
    })
    setChartData(data)
  }

  const questionDisplayNames = useMemo(() => {
    if (!search?.question) return []
    const g = questionOptions.find(it => it.value === search.question?.[0])
    const q = g?.children?.find(it => it.value === search.question?.[1])
    return [g?.label, q?.label] as string[]
  }, [questionOptions, search])

  const showAvg = useMemo(() => teachers && teachers.length > 1, [teachers])

  // Chart
  const lineChartRef = useRef<HTMLDivElement>(null)
  const lineChartInstance = useRef<any>(null)

  function renderLineChart() {
    const teacherNames = _.keys(chartData?.teacher_score_space)
      .map(it => teachers?.find(t => String(t.value) === it)?.label)
      .filter(Boolean)

    const combinedScoreSpace = [
      ...new Set<number>([
        ...(chartData?.average.map(it => it.score_space) || []),
        ..._.values(chartData?.teacher_score_space).flatMap(it =>
          it.map(it => it.score_space)
        )
      ])
    ]

    // @ts-ignore
    const chart = echarts.init(lineChartRef.current!)
    const opt = {
      legend: {
        data: [showAvg && '全体平均', ...teacherNames].filter(Boolean),
        top: 'bottom'
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: value => value + '份'
      },
      xAxis: {
        name: '分数区间',
        type: 'category',
        data: combinedScoreSpace.map(val => `[${val}~${val + 1}]`),
        axisLabel: {
          // formatter: (value: string) => `[${value}~${parseInt(value) + 1}]`
        }
      },
      yAxis: {
        name: '份数',
        type: 'value'
      },
      series: [
        showAvg && {
          name: '全体平均',
          type: 'line',
          data: combinedScoreSpace.map(score => {
            const item = chartData?.average.find(it => it.score_space === score)
            return item?.count || 0
          })
        },
        ..._.keys(chartData?.teacher_score_space).map(uid => {
          return {
            name: teachers?.find(t => String(t.value) === uid)?.label,
            type: 'line',
            data: combinedScoreSpace.map(score => {
              const item = chartData?.teacher_score_space[uid].find(
                it => it.score_space === score
              )
              return item?.count || 0
            })
          }
        })
      ].filter(Boolean)
    }
    chart.setOption(opt)
    lineChartInstance.current = chart
  }

  useLayoutEffect(() => {
    lineChartInstance.current?.dispose()
    renderLineChart()
    return () => {
      lineChartInstance.current?.dispose()
    }
  }, [chartData])

  // Table
  const [tableData, setTableData] = useState<{
    teachers: any[]
    total: any
  }>()
  const lineChartSummary = useMemo(() => tableData?.total, [tableData])

  async function fetchTableData() {
    if (!search?.question?.[1] || !search?.teacher?.length) return
    const { data } = await GET('fanya/monitor/detail_table', {
      data: {
        exam_paper_id: pid,
        question_id: search.question[1],
        teachers: search.teacher.join(',')
      }
    })
    setTableData(data)
  }

  useEffect(() => {
    console.log('search', search)

    setChartData(undefined)
    setTableData(undefined)

    setLoading(true)
    fetchTableData()
    fetchLineChartData()
    setLoading(false)
  }, [search])

  // Misc
  function onCancel() {
    function postMessagesToKaoShi(obj: { type: string; payload? }) {
      console.log('postMessage', obj)
      window.parent.postMessage({ source: 'exam-paper-setting', ...obj }, '*')
    }
    postMessagesToKaoShi({ type: 'cancel' })
  }

  return (
    <PageWrapper
      breadcrumb={['阅卷质量监控']}
      headerRight={() => (
        <Button type='primary' onClick={onCancel}>
          返回
        </Button>
      )}
      content={
        <div>
          <span style={{ color: '#333', fontWeight: 'bold' }}>试卷名称：</span>
          <Tag>{exam_name}</Tag>
        </div>
      }
    >
      <Space size='large' direction='vertical' style={{ width: '100%' }}>
        <Card>
          <Form
            form={form}
            onValuesChange={changed => {
              if (changed.question) {
                fetchTeacherAndSetDefaultSearch(changed.question)
              } else {
                setSearch((value: any) => ({ ...value, ...changed }))
              }
            }}
            layout='inline'
          >
            <Form.Item label='试卷题目' name='question'>
              <Cascader
                options={questionOptions}
                allowClear={false}
                style={{ width: 240 }}
              />
            </Form.Item>
            <Form.Item label='阅卷教师' name='teacher'>
              <Select
                mode='multiple'
                options={teachers}
                allowClear={false}
                style={{ minWidth: 240 }}
              ></Select>
            </Form.Item>
          </Form>
        </Card>

        <Spin spinning={loading}>
          <Card title='给分区间'>
            {lineChartSummary && (
              <div style={{ textAlign: 'center' }}>
                <Space size={50}>
                  <span>
                    <strong>总份数:</strong> {lineChartSummary.total_count}份
                  </span>
                  <Space size={30}>
                    <span>
                      <strong>全体平均:</strong>
                    </span>
                    <span>
                      <strong>平均分:</strong>{' '}
                      <span className='danger'>{lineChartSummary.avg}</span>分
                    </span>
                    <span>
                      <strong>最高分:</strong>{' '}
                      <span className='danger'>{lineChartSummary.max}</span>分
                    </span>
                    <span>
                      <strong>最低分:</strong>{' '}
                      <span className='danger'>{lineChartSummary.min}</span>分
                    </span>
                  </Space>
                </Space>
              </div>
            )}

            <div
              ref={lineChartRef}
              style={{ height: 500, background: '#fff' }}
            ></div>
          </Card>
        </Spin>

        <Spin spinning={loading}>
          <Card title='各判卷人员给分明细'>
            <Table
              dataSource={tableData?.teachers}
              columns={[
                {
                  title: '序号',
                  render: (text, record, index) => index + 1
                },
                {
                  title: '教师',
                  dataIndex: 'display_name'
                },
                {
                  title: '工号',
                  dataIndex: 'name'
                },
                {
                  title: '判卷进度',
                  render: (text, record) =>
                    `${record.reviewed_count}/${record.total_count}`
                },
                {
                  title: '平均分',
                  dataIndex: 'avg',
                  align: 'right'
                },
                { title: '最高分', dataIndex: 'max', align: 'right' },
                { title: '最低分', dataIndex: 'min', align: 'right' },
                {
                  title: '操作',
                  key: 'action',
                  render: (text, record) => (
                    <Link
                      to={`monitor-students?${qs.stringify({
                        pid,
                        questionId: search?.question?.[1],
                        teacherId: record.teacher_uid,
                        teacherName: record.display_name,
                        questionNames: questionDisplayNames.join(' '),
                        source: 'kaoshi',
                        exam_name
                      })}`}
                    >
                      查看
                    </Link>
                  )
                }
              ]}
              pagination={false}
              bordered
            />
          </Card>
        </Spin>
      </Space>
    </PageWrapper>
  )
}

export default () => <MarkingQualityReport />
