import { defaultErrorHandler, GET } from 'core/request'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { StatisticState } from 'config'
import constate from 'constate'
import { message, Modal } from 'antd'

const debug = require('debug')('useStatisticState:')

const hook = () => {
  const [loading, setLoading] = useState(true)
  const [initialized, setInitialized] = useState(false)
  const [statisticConfig, setStatisticConfig] = useState()
  const [statisticState, setStatisticState] = useState<{
    not_complete_count: number
    statistic_status: StatisticState
  }>()

  const [checkParams, setCheckParams] = useState<{
    scope: 'exam' | 'paper'
    examId
    paperId?
  }>()

  async function checkStatisticState(
    scope: 'exam' | 'paper',
    examId,
    paperId?
  ) {
    setLoading(true)
    setCheckParams({ scope, examId, paperId })

    let configData

    if (scope === 'paper') {
      const { data } = await GET('/city/exam/paper/statistic/config', {
        data: { exam_paper_id: paperId }
      })
      configData = data
    } else if (scope === 'exam') {
      const { data } = await GET('/city/exam/statistic/config', {
        data: { exam_id: examId }
      })
      configData = data
    }

    debug('statisticConfig', configData)

    if (scope === 'paper' && _.keys(configData).length) {
      setStatisticConfig(configData)
    } else if (scope === 'exam' && configData.complete_config) {
      setStatisticConfig(configData)
    } else {
      setLoading(false)
      console.warn('statisticConfig no found')
      return
    }

    const { data: statisticData } = await GET('/city/exam/statistic/status', {
      data: { exam_id: examId }
    })
    setStatisticState(statisticData)
    debug('statisticState', statisticData)

    setLoading(false)
    setInitialized(true)
  }

  async function requestStatisticCompute(showMsg = true) {
    message.loading('正在处理')
    if (checkParams) {
      try {
        await GET('/city/exam/statistic/init', {
          data: { exam_id: checkParams.examId }
        })
        showMsg && message.success('已开始重新汇总')

        await checkStatisticState(
          checkParams.scope,
          checkParams.examId,
          checkParams.paperId
        )
      } catch (e) {
        defaultErrorHandler(e)
      }
    }
  }

  useEffect(() => {
    if (!statisticState) {
      return
    }
    if (
      !~[StatisticState.UN_COMPUTED, StatisticState.COMPUTING].indexOf(
        statisticState.statistic_status
      )
    ) {
      return
    }
    if (!statisticState.not_complete_count) {
      Modal.confirm({
        title: '统考试卷已全部判完，请点击「确定」开始汇总成绩',
        onOk: () => {
          requestStatisticCompute()
        }
      })
    } else {
      Modal.confirm({
        title: `还有 ${statisticState.not_complete_count} 份试卷未判完，确定现在汇总成绩吗`,
        content: '需要汇总未判完的试卷成绩，后续需重新汇总',
        onOk: () => {
          requestStatisticCompute()
        }
      })
    }
  }, [statisticState])

  return {
    loading,
    initialized,
    statisticConfig,
    statisticState,
    checkStatisticState,
    requestStatisticCompute
  }
}

const [StatisticStateProvider, useStatisticStateContext] = constate(hook)
StatisticStateProvider.displayName = 'StatisticStateProvider'

export { StatisticStateProvider, useStatisticStateContext }
