import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { browserHistory } from 'config'
import ExamPaperMarkingSetting from 'pages/exam-paper-marking-setting'
import ExamPapers from 'pages/exam-papers'
import ExamPaperReport from 'pages/exam-papers-report'
import ExamReport from 'pages/exam-report'
import Exams from 'pages/exams'
import Login from 'pages/login'
import Marking from 'pages/marking'
import MarkingForm from 'pages/marking-form'
import MarkingHistory from 'pages/marking-history'
import MarkingQualityReport from 'pages/marking-quality-report'
import MarkingQualityReportStudentList from 'pages/marking-quality-report/StudentList'
import MarkingReport from 'pages/marking-report'
import React, { FC } from 'react'
import { RouteProps } from 'react-router'
import { Route, Router, Switch } from 'react-router-dom'
import { LoginProvider } from './hooks/useLogin'
import BasicLayout from './layouts/BasicLayout'
import Error from './pages/error'
import MarkingGroupedPapersReport from './pages/marking-grouped-papers-report'
import MarkingGroupedPapersTeacherReport from './pages/marking-grouped-papers-report/teacher'
import TeacherReport from './pages/teacher-report'
import TeacherReportBatchDetail from './pages/teacher-report/batch-detail'
import TeacherReportBatchesExamsTeacher from './pages/teacher-report/batch-exams-teachers'
import TeacherReportTeacherBatchesExams from './pages/teacher-report/teacher-batch-exams'
import TeacherReportTeacherBatches from './pages/teacher-report/teacher-batches'

export const App: FC = props => {
  return (
    <ConfigProvider locale={zhCN}>
      <LoginProvider>
        <Router history={browserHistory}>
          <Switch>
            <Route path={'/login'}>
              <Login />
            </Route>
            <Route path='/error'>
              <Error />
            </Route>

            <RouteWithLayout path={'/exams'} exact>
              <Exams />
            </RouteWithLayout>
            <RouteWithLayout path={'/exams/:id/papers'} exact>
              <ExamPapers />
            </RouteWithLayout>
            <RouteWithLayout path={'/exams/:id/papers/:pid/report'}>
              <ExamPaperReport />
            </RouteWithLayout>
            <Route path={'/exam-papers/:pid/setting'}>
              <ExamPaperMarkingSetting />
            </Route>
            <RouteWithLayout path={'/exams/:id/marking-report'}>
              <MarkingReport />
            </RouteWithLayout>
            <RouteWithLayout path={'/exams/:id/report'}>
              <ExamReport />
            </RouteWithLayout>

            <RouteWithLayout path={'/marking'} exact>
              <Marking />
            </RouteWithLayout>
            <RouteWithLayout path={'/marking/:id'} exact>
              <MarkingForm />
            </RouteWithLayout>

            {/* 批阅历史 */}
            <RouteWithLayout path={'/marking/:id/history'}>
              <MarkingHistory />
            </RouteWithLayout>

            {/* 教师统计 */}
            <RouteWithLayout path={'/teacher-report'} exact>
              <TeacherReport />
            </RouteWithLayout>

            {/* 教师统计(汇总)/教师批次批阅统计 */}
            <RouteWithLayout
              path={'/teacher-report/teachers/:tid/batches'}
              exact
            >
              <TeacherReportTeacherBatches />
            </RouteWithLayout>

            {/* 教师统计(批次)/批次批阅统计 */}
            <RouteWithLayout path={'/teacher-report/batches/:bid/detail'}>
              <TeacherReportBatchDetail />
            </RouteWithLayout>

            {/* 教师统计(批次)/批次批阅统计/批次教师统计 */}
            <RouteWithLayout
              path={'/teacher-report/batches/:bid/exams/:examId/teachers'}
            >
              <TeacherReportBatchesExamsTeacher />
            </RouteWithLayout>

            {/* 教师考试批阅统计 (批阅历史) */}
            <RouteWithLayout
              path={'/teacher-report/teachers/:tid/batches/:bid'}
            >
              <TeacherReportTeacherBatchesExams />
            </RouteWithLayout>

            {/* 质量监控 */}
            <RouteWithLayout path={'/exam-papers/:pid/monitor'}>
              <MarkingQualityReport />
            </RouteWithLayout>
            <RouteWithLayout path={'/exam-papers/:pid/monitor-students'}>
              <MarkingQualityReportStudentList />
            </RouteWithLayout>

            {/* 子卷批阅统计 */}
            <RouteWithLayout path={'/exam-grouped-papers/:id/statistics'} exact>
              <MarkingGroupedPapersReport />
            </RouteWithLayout>
            <RouteWithLayout
              path={'/exam-grouped-papers/:id/statistics/teachers/:teacherId'}
            >
              <MarkingGroupedPapersTeacherReport />
            </RouteWithLayout>
          </Switch>
        </Router>
      </LoginProvider>
    </ConfigProvider>
  )
}

const RouteWithLayout: FC<RouteProps & { layout?: FC }> = props => {
  const { layout, children, ...rest } = props
  const Layout = layout || BasicLayout
  return (
    <Route {...rest}>
      <Layout>{children}</Layout>
    </Route>
  )
}
