import { Table as ATable } from 'antd'
import { TableProps as ATableProps, ColumnType } from 'antd/es/table'
import { PaginationProps } from 'antd/lib/pagination/Pagination'
import { ListContext } from 'core/service/useList'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

export interface TableProps<T> extends ATableProps<T> {
  list: ListContext
  items?: T[]
  pagination?: PaginationProps | false
  indexColumn?: ColumnType<T> | false
  isMulti?: boolean
  defaultSelectedRows?: T[]
  onSelectRow?: (row: T[]) => void
}

export const getPaginationProps = (list: ListContext): PaginationProps => {
  return {
    current: list.index.value,
    total: list.total,
    pageSize: list.size,
    showSizeChanger: true,
    showQuickJumper: true,
    hideOnSinglePage: false,
    showTotal: total => `共${total}条记录`,
    onChange: (page, size) => {
      window.scrollTo(0, 0)
      list.onIndexChange(page)
    },
    onShowSizeChange: (page, size) => {
      list.setPageSize(size)
      setTimeout(() => {
        list.onIndexChange(page)
      })
    }
  }
}
export function Table<T extends object = any>(props: TableProps<T>) {
  const {
    list,
    items,
    columns,
    indexColumn,
    pagination,
    defaultSelectedRows,
    onSelectRow,
    ...tableProps
  } = props
  const rowKey: any = props.rowKey || 'id'

  function getColumns(): ColumnType<T>[] {
    if (indexColumn === false) {
      return columns as ColumnType<T>[]
    }
    return [
      {
        title: '序号',
        key: 'index',
        render: (text, row, index) => list.indexMethod(index),
        width: '5em',
        ...indexColumn
      },
      ...(columns as ColumnType<T>[])
    ]
  }

  // Selection
  const showSelection = !!onSelectRow
  const [selectedItems, setSelectedItems] = useState<T[]>([])

  function appendSelectedItemsUniq(rows: T[]) {
    setSelectedItems(_.uniqBy(_.concat([], selectedItems, rows), rowKey))
  }
  function removeSelectedItemsUniq(rows: T[]) {
    setSelectedItems(
      _.filter(selectedItems, item => {
        const matched = _.find(rows, r => r[rowKey] === item[rowKey])
        return !matched
      })
    )
  }

  function onSelectChange(row: T, selected: boolean) {
    if (!props.isMulti) {
      setSelectedItems(selected ? [row] : [])
      return
    }

    if (selected) {
      appendSelectedItemsUniq([row])
    } else {
      removeSelectedItemsUniq([row])
    }
  }

  function onSelectAllChange(
    selected: boolean,
    selectedRows: T[],
    changes: T[]
  ) {
    if (selected) {
      appendSelectedItemsUniq(selectedRows)
    } else {
      removeSelectedItemsUniq(changes)
    }
  }

  // emit
  useEffect(() => {
    if (onSelectRow) {
      onSelectRow(selectedItems)
    }
  }, [selectedItems])

  // init selectedItems with props.defaultSelectedRows
  useEffect(() => {
    if (defaultSelectedRows) {
      setSelectedItems(defaultSelectedRows)
    }
  }, [])

  return (
    <div>
      <ATable<T>
        columns={getColumns()}
        rowKey={rowKey}
        dataSource={props.items || list.items}
        loading={list.loading}
        pagination={
          _.isUndefined(pagination) ? getPaginationProps(list) : pagination
        }
        rowSelection={
          showSelection
            ? {
                type: props.isMulti ? 'checkbox' : 'radio',
                selectedRowKeys: _.map(selectedItems, rowKey),
                onSelect: onSelectChange,
                onSelectAll: onSelectAllChange
              }
            : undefined
        }
        {...tableProps}
      />
    </div>
  )
}
