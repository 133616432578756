import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Table, Tag } from 'antd'
import { GET, defaultErrorHandler } from 'core/request'
import _ from 'lodash'
import { useMarkingSettingContent } from 'pages/exam-papers/MarkingSettingDialog/useMarkingSettingState'
import { useMarkingSettingEditContext } from 'pages/exam-papers/store'
import React, { FC, useEffect, useMemo, useState } from 'react'

export const SettingSyncDialogVisible: FC<{
  visible: boolean
  onClose: () => void
}> = props => {
  const edit = useMarkingSettingEditContext()
  const {
    papersGroupId,
    papersInGroup,
    checkIsGroupedPaper
  } = useMarkingSettingContent()

  const [selected, setSelected] = useState<number[]>([])
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<Array<{ name: string; msg: string }>>([])
  const [errorVisible, setErrorVisible] = useState(false)

  useEffect(() => {
    if (!props.visible) {
      setSelected([])
      setSaving(false)
      setError([])
    }
  }, [props.visible])

  async function onSubmit() {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: `确认同步当前判卷配置到其他 ${selected.length} 份试卷`,
      content: '将覆盖原试卷的配置',
      onOk: () => {
        submit()
      }
    })
  }

  async function submit() {
    setSaving(true)

    try {
      const { data } = await GET('fanya/group/paper/copy', {
        data: {
          exam_paper_id: edit.data.id,
          group_id: papersGroupId,
          target_exam_paper_ids: selected.join(',')
        }
      })

      const result: Record<string, { msg; status: 'success' | 'error' }> = data
      const resultArr = _.map(result, (v, k) => {
        return {
          id: parseInt(k),
          ...v
        }
      })

      const failed = _.filter(resultArr, r => r.status === 'error')
      if (!failed.length) {
        await checkIsGroupedPaper()
        setSaving(false)

        props.onClose()
        Modal.success({
          title: '同步完成'
        })
        return
      }

      const message: Array<{
        name: string
        msg: string
      }> = resultArr
        .map(it => {
          return {
            name: _.find(papersInGroup, { exam_paper_id: it.id })?.name!,
            msg: it.msg
          }
        })
        .filter(it => it.name)
      setError(message)
      setErrorVisible(true)
    } catch (e) {
      setSaving(false)
      defaultErrorHandler(e as any)
    }
  }

  // Preview
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewPaper, setPreviewPaper] = useState<number>()
  const previewUrl = useMemo(() => {
    if (!previewPaper) return null
    const currId = edit.data.id
    const currUrl = location.href
    return currUrl.replace(String(currId), String(previewPaper)) + '&preview=1'
  }, [edit.data.id, previewPaper])

  return (
    <>
      <Modal
        visible={props.visible}
        title='试卷列表'
        width={680}
        cancelButtonProps={{
          disabled: saving
        }}
        onCancel={props.onClose}
        okText='同步'
        okButtonProps={{
          loading: saving,
          disabled: selected.length === 0
        }}
        onOk={onSubmit}
      >
        <Table
          rowKey='exam_paper_id'
          rowSelection={{
            onChange: selectedRowKeys =>
              setSelected(selectedRowKeys as number[]),
            getCheckboxProps: record => ({
              disabled: edit.data.id === record.exam_paper_id
            })
          }}
          dataSource={papersInGroup}
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              render: (text, record, index) => index + 1,
              align: 'center'
            },
            {
              title: '试卷名称',
              dataIndex: 'name',
              render: (text, record) => {
                if (record.exam_paper_id === edit.data.id)
                  return (
                    <span>
                      {text}
                      <Tag style={{ marginLeft: 4 }}>当前试卷</Tag>
                    </span>
                  )
                return text
              }
            },
            {
              title: '配置状态',
              dataIndex: 'has_setting',
              render: text => {
                return text ? '已配置' : '未配置'
              },
              align: 'center'
            },
            {
              title: '操作',
              dataIndex: 'operation',
              render: (text, record) => {
                if (record.exam_paper_id === edit.data.id) return null
                return (
                  <a
                    onClick={() => {
                      setPreviewPaper(record.exam_paper_id)
                      setPreviewVisible(true)
                    }}
                  >
                    查看
                  </a>
                )
              }
            }
          ]}
          pagination={false}
        ></Table>
      </Modal>

      <Modal
        visible={errorVisible}
        title='同步结果'
        width={680}
        onCancel={() => setErrorVisible(false)}
        footer={null}
      >
        <Table
          dataSource={error}
          columns={[
            {
              title: '试卷名称',
              dataIndex: 'name'
            },
            {
              title: '错误信息',
              dataIndex: 'msg'
            }
          ]}
          pagination={false}
        ></Table>
      </Modal>

      <Modal
        visible={previewVisible}
        title='设置预览'
        width={'80%'}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
      >
        <iframe
          src={previewUrl || ''}
          style={{
            border: 0,
            width: '100%',
            height: '60vh'
          }}
        ></iframe>
      </Modal>
    </>
  )
}
