import {
  Button,
  Card,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Space,
  Table
} from 'antd'
import _ from 'lodash'
import { useMarkingSettingContent } from 'pages/exam-papers/MarkingSettingDialog/useMarkingSettingState'
import React, { FC, useMemo, useState } from 'react'
import { PaperMarkingSettingFormData, QuestionIdentity } from 'typing.paper'
import { getQuestionDisplayName, getQuestionPath } from 'utils/question'
import { QuestionSelect } from '../QuestionSelect'

type T = NonNullable<PaperMarkingSettingFormData['judge_filter_by_question']>

export const JudgeFilterFormItem: FC<{
  value?: T
  onChange?: (value: T) => void
}> = ({ value, onChange }) => {
  const {
    paperQuestions,
    formFieldReadonly: readonly
  } = useMarkingSettingContent()

  function getQuestionName(questionId: QuestionIdentity) {
    const path = getQuestionPath(paperQuestions, questionId)
    if (!path) return ''
    return getQuestionDisplayName(path)
  }

  const [editIndex, setEditIndex] = useState<number>(-1)
  const isEdit = useMemo(() => editIndex !== -1, [editIndex])

  const [formVisible, setFormVisible] = useState(false)
  const [form] = Form.useForm()

  const disabledIds = useMemo(() => {
    const items = _.map(value, 'question').filter((g, i) => {
      // Exclude the current on edit group
      if (isEdit) {
        return i !== editIndex
      }
      return true
    })
    return _.flatten(items)
  }, [editIndex, isEdit, value])

  function add() {
    form.resetFields()

    setEditIndex(-1)
    setFormVisible(true)
  }
  function edit(row: T[number], index: number) {
    form.setFieldsValue(row)

    setEditIndex(index)
    setFormVisible(true)
  }
  function remove(index: number) {
    onChange?.(_.filter(value, (v, i) => i !== index))
  }

  async function onSubmit() {
    const data = await form.validateFields()
    if (isEdit) {
      onChange?.(_.map(value, (v, i) => (i === editIndex ? data : v)) as T)
    } else {
      onChange?.(_.concat(value, data) as T)
    }
    setFormVisible(false)
  }

  return (
    <Card>
      {!readonly && (
        <div style={{ marginBottom: 16 }}>
          <Button type='primary' onClick={add}>
            添加
          </Button>
        </div>
      )}

      <Table
        rowKey={(r, i) => String(i)}
        dataSource={value}
        pagination={false}
        columns={[
          {
            title: '题号',
            dataIndex: 'question',
            render: (v, r, i) => (
              <Space
                style={{
                  flexWrap: 'wrap'
                }}
              >
                {_.map(v, q => (
                  <span key={q} className='normal-tag'>
                    {getQuestionName(q)}
                  </span>
                ))}
              </Space>
            )
          },
          {
            title: '分差',
            dataIndex: 'percent',
            align: 'right',
            render: (v, r, i) => <span>{v}%</span>
          },
          {
            width: 80
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 120,
            render: (v, r, i) => (
              <Space>
                <a onClick={() => edit(r, i)}>编辑</a>
                <Popconfirm title='确认删除' onConfirm={() => remove(i)}>
                  <a>删除</a>
                </Popconfirm>
              </Space>
            )
          }
        ]}
      ></Table>

      <Modal
        title='仲裁分差题组'
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        onOk={onSubmit}
        destroyOnClose
        width={600}
        style={{ top: '20vh' }}
      >
        <Form form={form} labelCol={{ span: 6 }}>
          <Form.Item
            label='题号'
            name='question'
            rules={[
              { required: true, type: 'array', min: 1, message: '请选择题号' }
            ]}
          >
            <QuestionSelect multi disabledIds={disabledIds} />
          </Form.Item>
          <Form.Item label='分差'>
            <Form.Item
              name='percent'
              rules={[
                { required: true, message: '请输入分差' },
                {
                  type: 'number',
                  min: 0,
                  max: 100,
                  message: '请输入0-100的数字'
                }
              ]}
              noStyle
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
            <span>%</span>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}
