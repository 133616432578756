import { EditModel, FormList } from 'components'
import { FormItemSubjectScoreLevel } from 'components/FormItemSubjectScoreLevel'
import { Space, Typography } from 'antd'
import React, { FC } from 'react'
import { useEditContext } from './index'
import { useCurrentExamContent } from 'hooks/useExamData'
import { dateRangeFormat } from 'utils'
import { ExamScoreLevelType, Subject } from 'config'

const { Title, Text } = Typography

export const Edit: FC = () => {
  const { currentExam } = useCurrentExamContent()

  const edit = useEditContext()

  return (
    <EditModel edit={edit} title={'成绩统计设置'}>
      {currentExam && (
        <div style={{ marginBottom: 24 }}>
          <Title level={4} style={{ marginBottom: 4 }}>
            {currentExam.name}
          </Title>
          <Space size={'large'}>
            <Text type={'secondary'}>
              考试时间：
              {dateRangeFormat({
                value: [currentExam.begin_date, currentExam.end_date],
                showTime: true
              })}
            </Text>
            <Text type={'secondary'}>
              考试科目：
              {currentExam.paper_subjects.map(s => Subject[s]).join('、')}
            </Text>
          </Space>
        </div>
      )}

      <FormList name={'configs'} showAdd={false}>
        {(field, { index }) => {
          const config = edit.data.configs[index]
          const subject =
            config.meta.scope === ExamScoreLevelType.PAPER_CONFIG
              ? Subject[config.meta.subject]
              : '统考'
          return (
            <div key={index} style={{ marginBottom: 16 }}>
              <FormItemSubjectScoreLevel
                title={`${subject}总分统计（满分${config.meta.score}）`}
                {...field}
              />
            </div>
          )
        }}
      </FormList>
    </EditModel>
  )
}
