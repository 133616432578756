import React, { FC } from 'react'
import { Report } from 'typing'
import { Modal } from 'antd'
import { ModalSizeXLarge } from 'config'
import { TableTabsWrapper } from 'components/ReportExamPaperTables/TableTabsWrapper'
import { TotalScoreTableStudent } from 'components/ReportExamPaperTables/table/TotalScoreTableStudent'
import { QuestionScoreTable } from 'components/ReportExamPaperTables/table/QuestionScoreTable'

export const DialogStudentDetail: FC<Report.BasicTableDialogProps<
  Report.TotalScoreTableItemOfClazz
>> = ({ scopeParams, stateParams, visible, onClose }) => {
  return (
    <>
      <Modal
        visible={visible}
        title={
          scopeParams.reportEntryName
            ? `${stateParams.params?.class_name} (${scopeParams.reportEntryName})`
            : stateParams.params?.class_name
        }
        width={ModalSizeXLarge}
        onCancel={onClose}
        footer={false}
        destroyOnClose
      >
        <TableTabsWrapper
          scopeParams={scopeParams}
          defaultTableIndex={
            stateParams.tabIndex === 1 ? 0 : stateParams.tabIndex
          }
        >
          {(tabIndex, summary) => {
            if (!stateParams.params) {
              return null
            }
            if (tabIndex === 0) {
              return (
                <TotalScoreTableStudent
                  scopeParams={scopeParams}
                  stateParams={stateParams}
                  totalScoreSummary={summary}
                  onDetailClick={() => {}}
                />
              )
            }
            if (tabIndex === 2) {
              return (
                <QuestionScoreTable
                  scopeParams={scopeParams}
                  stateParams={stateParams}
                  totalScoreSummary={summary}
                  onDetailClick={() => {}}
                />
              )
            }
          }}
        </TableTabsWrapper>
      </Modal>
    </>
  )
}
