import { Modal, Table } from 'antd'
import { GET } from 'core/request'
import React, { FC, useEffect, useState } from 'react'

type Items = {
  display_name: string
  teacher_uid: string
  count: number
  quest_complete_count: number
  complete_count: number // student
  uncomplete_count: number // student
  rate: number
}

export const MarkingStatusDialog: FC<{
  paperId: string
  visible: boolean
  onClose: () => void
}> = props => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  async function fetch() {
    setLoading(true)
    const { data } = await GET('/fanya/exam/paper/progress', {
      data: {
        exam_paper_id: props.paperId,
        order: 'desc'
      }
    })
    setItems(data)
    setLoading(false)
  }

  const columns = [
    {
      title: '教师uid',
      dataIndex: 'teacher_uid'
    },
    {
      title: '教师姓名',
      dataIndex: 'display_name'
    },
    {
      title: '学生总数',
      dataIndex: 'count',
      align: 'right'
    },
    {
      title: '题目总数',
      dataIndex: 'quest_complete_count',
      align: 'right'
    },
    {
      title: '已批改学生',
      dataIndex: 'complete_count',
      align: 'right'
    },
    {
      title: '未批改学生',
      dataIndex: 'uncomplete_count',
      align: 'right'
    },
    {
      title: '批改完成率',
      dataIndex: 'rate',
      render: (text: number) => `${text}%`,
      align: 'right',
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      sorter: (a: Items, b: Items) => a.rate - b.rate
    }
  ]

  useEffect(() => {
    if (props.visible) {
      fetch()
    }
  }, [props.visible])

  return (
    <Modal
      title='批阅情况'
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
      width={800}
    >
      <Table
        columns={columns as any}
        dataSource={items}
        loading={loading}
        size={'small'}
        pagination={false}
      ></Table>
    </Modal>
  )
}
