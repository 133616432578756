import { TagOutlined } from '@ant-design/icons'
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tag,
  message
} from 'antd'
import { MarkingTag, useMarkingTagsContent } from 'hooks/useMarkingTags'
import _ from 'lodash'
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useList } from 'react-use'
import { Paper } from 'typing'
import { QuestionIdentity } from 'typing.paper'

export const TagPopover: FC<{
  question: Paper.Question
}> = props => {
  const {
    findTagListByQuestion,
    findCurrentTagsByQuestion,
    updateQuestionTags,
    newTag
  } = useMarkingTagsContent()

  const [tags, tagsActions] = useList<MarkingTag>([])
  const [selectedTags, selectedTagsActions] = useList<MarkingTag>()

  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [tagUpdating, setTagUpdating] = useState(false)

  useEffect(() => {
    const _tags = findTagListByQuestion(props.question.id)
    tagsActions.set(_tags)

    const selectedIds = findCurrentTagsByQuestion(props.question.id).map(
      t => t.id
    )
    selectedTagsActions.set(_tags.filter(t => selectedIds.includes(t.id)))
  }, [
    props.question,
    tagsActions,
    selectedTagsActions,
    findTagListByQuestion,
    findCurrentTagsByQuestion
  ])

  const isChecked = useCallback(
    (tag: MarkingTag) => {
      return selectedTags.includes(tag)
    },
    [selectedTags]
  )

  const onClick = useCallback(
    (it: MarkingTag) => {
      if (tagUpdating) return

      if (isChecked(it)) {
        selectedTagsActions.removeAt(selectedTags.indexOf(it))
      } else {
        selectedTagsActions.push(it)
      }
      setShouldUpdate(true)
    },
    [isChecked, selectedTagsActions, selectedTags]
  )

  useEffect(() => {
    async function update() {
      if (shouldUpdate) {
        setTagUpdating(true)
        await updateQuestionTags(props.question.id, selectedTags)
        setTagUpdating(false)

        setShouldUpdate(false)
      }
    }
    update()
  }, [selectedTags, updateQuestionTags, props.question, shouldUpdate])

  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)

  const onAddClick = async ({ tag }) => {
    setSaving(true)
    try {
      await newTag(props.question.id, tag)
      setVisible(false)
      form.resetFields()
      message.success('标签新增成功')
    } finally {
      setSaving(false)
    }
  }

  const [visible, setVisible] = useState(false)

  // Editor
  const [editorVisible, setEditorVisible] = useState(false)

  return (
    <>
      <Popover
        visible={visible}
        onVisibleChange={setVisible}
        trigger={'click'}
        content={
          <div style={{ width: 260 }}>
            <div
              style={{
                marginBottom: '.8em',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: 13
              }}
            >
              <span>点击选择标签</span>
              <a
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setVisible(false)
                  setEditorVisible(true)
                }}
              >
                编辑
              </a>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {tags.map(t => {
                return (
                  <Tag
                    key={t.name}
                    color={isChecked(t) ? '#1890ff' : undefined}
                    onClick={() => onClick(t)}
                    style={{
                      marginBottom: '1em',
                      cursor: 'pointer',
                      opacity: tagUpdating ? 0.6 : 1
                    }}
                  >
                    {t.name}
                  </Tag>
                )
              })}
            </div>

            <Divider dashed style={{ margin: '.5em 0' }} />

            <div
              style={{
                marginBottom: '.8em',
                fontWeight: 'bold',
                fontSize: 13
              }}
            >
              新增标签
            </div>
            <Form
              layout='inline'
              size='small'
              form={form}
              onFinish={(value: any) => onAddClick(value)}
            >
              <Form.Item
                name='tag'
                rules={[{ required: true, message: '请输入标签名' }]}
                style={{ width: 160 }}
              >
                <Input />
              </Form.Item>
              <Button type='primary' htmlType='submit' loading={saving}>
                新增
              </Button>
            </Form>
          </div>
        }
        placement='bottom'
      >
        <Button
          size='small'
          icon={<TagOutlined />}
          type={selectedTags.length ? 'primary' : 'default'}
        >
          标签
        </Button>
      </Popover>

      <Modal
        title={'标签编辑'}
        visible={editorVisible}
        onCancel={() => setEditorVisible(false)}
        footer={null}
      >
        <EditableTable questionId={props.question.id} data={tags} />
      </Modal>
    </>
  )
}

type EditableCellProps = {
  editing: boolean
  dataIndex: keyof MarkingTag
  // record: MarkingTag
  children: ReactNode
}
const EditableCell: FC<EditableCellProps> = ({
  editing,
  dataIndex,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: '请输入标签名'
            }
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
const EditableTable: FC<{
  questionId: QuestionIdentity
  data: MarkingTag[]
}> = ({ questionId, data: dataOriginal }) => {
  const { editTag, removeTag } = useMarkingTagsContent()

  const [form] = Form.useForm()

  const data = useMemo(() => _.cloneDeep(dataOriginal), [dataOriginal])

  const [editingKey, setEditingKey] = useState<number>()

  const isEditing = (record: MarkingTag) => record.id === editingKey

  const onEditClick = (record: MarkingTag) => {
    setEditingKey(record.id)
    form.setFieldsValue({
      name: record.name
    })
  }

  const onSave = async () => {
    const { name: newName } = await form.validateFields()

    const hide = message.loading('正在处理', 0)
    try {
      await editTag(questionId, editingKey!, newName)
      setEditingKey(undefined)
      hide()
      message.success('标签编辑成功')
    } finally {
      hide()
    }
  }

  const onRemove = async (record: MarkingTag) => {
    const hide = message.loading('正在处理', 0)
    try {
      await removeTag(questionId, record.id)
      hide()
      message.success('标签删除成功')
    } finally {
      hide()
    }
  }

  const onCancel = () => {
    setEditingKey(undefined)
  }

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        columns={[
          {
            title: '序号',
            render: (text, record, index) => index + 1,
            align: 'center'
          },
          {
            title: '标签名',
            dataIndex: 'name',
            onCell: (record: MarkingTag) => {
              return {
                record,
                dataIndex: 'name',
                title: '标签名',
                editing: isEditing(record)
              }
            }
          },
          {
            title: '操作',
            render: (text, record) =>
              isEditing(record) ? (
                <Space>
                  <a onClick={onSave}>保存</a>
                  <a onClick={onCancel}>取消</a>
                </Space>
              ) : (
                <Space>
                  <a onClick={() => onEditClick(record)}>编辑</a>
                  <Popconfirm
                    title='确认删除'
                    onConfirm={() => onRemove?.(record)}
                  >
                    <a>删除</a>
                  </Popconfirm>
                </Space>
              )
          }
        ]}
        dataSource={data}
        pagination={false}
        size='small'
      />
    </Form>
  )
}
