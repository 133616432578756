import { Card, Col, Progress, Row, Statistic } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Report } from 'typing'
import { GET } from 'core/request'

export const ExamSummaryStatistic: FC<{ id }> = ({ id }) => {
  const [summary, setSummary] = useState<Report.ExamPaperSummaryData>()

  async function fetch() {
    const { data } = await GET('/city/exam/statistic/summary', {
      data: { exam_id: id }
    })
    setSummary(data)
  }

  useEffect(() => {
    fetch()
  }, [])

  if (!summary) {
    return null
  }

  return (
    <Card style={{ marginBottom: 16 }}>
      <Row align={'middle'}>
        <Col span={12}>
          <Row gutter={[0, 24]} style={{ paddingTop: 16, textAlign: 'center' }}>
            <Col span={12}>
              <Statistic title={'参与学校'} value={summary.org_count} />
            </Col>
            <Col span={12}>
              <Statistic title={'参与班级'} value={summary.class_count} />
            </Col>
            <Col span={12}>
              <Statistic title={'参与学生'} value={summary.student_count} />
            </Col>
            <Col span={12}>
              <Statistic
                title={'平均分'}
                precision={0}
                value={summary.avg_score}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={24} justify={'center'} style={{ textAlign: 'center' }}>
            <Col>
              <Progress
                type={'circle'}
                percent={Math.round(summary.excellent_rate * 100 * 10) / 10}
                strokeColor={'#52c41a'}
              />
              <div style={{ marginTop: 8, fontSize: 16 }}>优秀率</div>
            </Col>
            <Col>
              <Progress
                type={'circle'}
                percent={Math.round(summary.good_rate * 100 * 10) / 10}
                strokeColor={'#13c2c2'}
              />
              <div style={{ marginTop: 8, fontSize: 16 }}>良好率</div>
            </Col>
            <Col>
              <Progress
                type={'circle'}
                percent={Math.round(summary.pass_rate * 100 * 10) / 10}
                strokeColor={'#1890ff'}
              />
              <div style={{ marginTop: 8, fontSize: 16 }}>及格率</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
