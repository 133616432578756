import { useMount } from '@umijs/hooks'
import { Button, Form } from 'antd'
import { FormInstance } from 'antd/es/form'
import { ListContext } from 'core/service/useList'
import React, { FC } from 'react'
import './index.less'

interface SearchProps {
  list: ListContext
  showReset?: boolean
  showSubmit?: boolean
  form?: FormInstance
}

export const Search: FC<SearchProps> = props => {
  const [form] = Form.useForm(props.form)

  function onReset() {
    form.resetFields()
    const value = props.list.onSearchReset()
    form.setFieldsValue(value)
  }

  useMount(() => {
    form.setFieldsValue(props.list.search)
  })

  return (
    <Form
      form={form}
      onFinish={props.list.onSearchSubmit}
      className={'search-form'}
      layout={'inline'}
    >
      {props.children}

      <Form.Item>
        {props.showSubmit && (
          <Button type={'primary'} htmlType={'submit'}>
            搜索
          </Button>
        )}
        {props.showReset && (
          <Button style={{ marginLeft: '8px' }} onClick={onReset}>
            重置
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

Search.defaultProps = {
  showReset: true,
  showSubmit: true
}
