import { Button, Card, Col, Row, Tabs, Tag } from 'antd'
import { useQuery } from 'hooks/useQuery'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import BatchExams from './batch-exams'
import BatchTeachers from './batch-teachers'
import './style.less'

export default function () {
  const history = useHistory()
  const queryParams = useQuery<{ batch; relationId; classId }>()

  const [activeKey, setActiveKey] = useState('1')
  return (
    <Card>
      <Row align={'middle'} className='teacher-report-header'>
        <Col flex={1}>
          <div className={'title'}>
            批次批阅统计
            <Tag className={'tag'}>{queryParams.batch || '批次名称'}</Tag>
          </div>
        </Col>
        <Col>
          <Button onClick={() => history.goBack()}>返回</Button>
        </Col>
      </Row>

      <Tabs activeKey={activeKey} onChange={setActiveKey}>
        <Tabs.TabPane tab={'按教师统计'} key={'1'} />
        <Tabs.TabPane tab={'按考试统计'} key={'2'} />
      </Tabs>

      {activeKey === '1' && <BatchTeachers />}
      {activeKey === '2' && <BatchExams />}
    </Card>
  )
}
