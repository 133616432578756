import { useMount } from '@umijs/hooks'
import { Form, Input } from 'antd'
import qs from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'
import { Search, Table } from '../../components'
import { createList } from '../../core/service'
import { useQuery } from '../../hooks/useQuery'
import { RateInput } from './components/RateInput'
import './style.less'

const { ListProvider, useListContext } = createList({
  url: () => '/statistics/gather/list/exam_teacher',
  parseSearchData: data => {
    return {
      ...data,
      rate: undefined,
      lowerLimit: data.rate && data.rate[0],
      upperLimit: data.rate && data.rate[1]
    }
  }
})

export default function () {
  return (
    <ListProvider>
      <BatchExams />
    </ListProvider>
  )
}

const BatchExams = () => {
  const queryParams = useQuery<{ batch; relationId; classId }>()
  const list = useListContext()

  function onTableChange(pagination, filters, sorter) {
    if (list.index.value !== pagination.current) {
      window.scrollTo(0, 0)
      list.onIndexChange(pagination.current)
      return
    }

    list.setPageSize(pagination.pageSize)
    list.onSearchSubmit({
      order_type: !!sorter.order
        ? sorter.order === 'ascend'
          ? '0'
          : '1'
        : null
    })
  }

  useMount(() => {
    list.setDefaultSearch({
      relationIds: queryParams.relationId,
      clazzIds: queryParams.classId
    })
    list.onSearchSubmit({})
  })

  return (
    <div>
      <Search list={list}>
        <Form.Item name={'kcmc'}>
          <Input placeholder={'输入课程名称/编号'} />
        </Form.Item>
        <Form.Item name={'rate'}>
          <RateInput />
        </Form.Item>
      </Search>

      <Table
        list={list}
        onChange={onTableChange}
        columns={[
          { title: '考试名称', dataIndex: 'examname', fixed: 'left' },
          { title: '考试码', dataIndex: 'ksdm' },
          { title: '课程名称', dataIndex: 'kcmc' },
          { title: '课程编码', dataIndex: 'kcdm' },

          {
            title: '应批阅试卷份数',
            dataIndex: 'exam_paper_count',
            align: 'right'
          },
          {
            title: '已批阅阅试份数',
            dataIndex: 'completed_paper_count',
            align: 'right'
          },
          {
            title: '待批阅试卷份数',
            dataIndex: 'uncompleted_paper_count',
            align: 'right'
          },
          {
            title: '批阅试卷完成率',
            dataIndex: 'exam_paper_completed_count_rate',
            align: 'center',
            render: t => `${t}%`
          },

          { title: '应批阅题目份数', dataIndex: 'total', align: 'right' },
          { title: '已批阅题目份数', dataIndex: 'done', align: 'right' },
          {
            title: '待批阅题目份数',
            dataIndex: 'total',
            align: 'right',
            render: (t, r) => t - r.done
          },
          {
            title: '批阅完成率',
            dataIndex: 'rate',
            render: (_, record) =>
              `${Math.round((record.done / record.total) * 10000) / 100}%`,
            align: 'center',
            sorter: true
          },
          {
            title: '操作',
            key: 'actions',
            render: (t, r) => {
              const q = {
                batch: queryParams.batch,
                examName: r.examname,
                relationId: r.relationid,
                classId: r.classid,
                form: 'batch-exams'
              }
              return (
                <Link
                  to={`/teacher-report/batches/${0}/exams/${
                    r.examname
                  }/teachers?${qs.stringify(q)}`}
                >
                  查看详情
                </Link>
              )
            }
          }
        ]}
        pagination={{
          current: list.index.value,
          total: list.total,
          pageSize: list.size,
          showSizeChanger: true,
          showQuickJumper: true,
          hideOnSinglePage: !true,
          showTotal: total => `共${total}条记录`
        }}
        scroll={{
          x: 'max-content'
        }}
        indexColumn={{ fixed: 'left' }}
      />
    </div>
  )
}
