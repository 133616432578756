import { Search, Table } from 'components'
import { ModalSizeLarge } from 'config'
import { createList } from 'core/service'
import { Paper } from 'typing'
import { Form, Input, Modal } from 'antd'
import moment from 'moment'
import React, { FC } from 'react'

interface PaperSelectDialog {
  visible: boolean
  onClose: () => void
  onConfirm: (data: Partial<Paper.Paper>) => void
}

interface SearchFormData {
  paperId: string
}

const { ListProvider, useListContext } = createList<
  Paper.PaperOriginal,
  SearchFormData
>({
  url: search => (search.paperId ? `/city/paper/${search.paperId}` : null),
  parseFetchedData: data => {
    return { items: data.data, total: data.count }
  }
})

export const PaperSelectDialog: FC<PaperSelectDialog> = props => {
  return (
    <Modal
      visible={props.visible}
      title={'添加试卷'}
      width={ModalSizeLarge}
      footer={null}
      destroyOnClose
      onCancel={() => props.onClose()}
    >
      <ListProvider>
        <Content {...props} />
      </ListProvider>
    </Modal>
  )
}

const Content: FC<PaperSelectDialog> = props => {
  const list = useListContext()

  function onConfirm(data: Paper.PaperOriginal) {
    props.onConfirm({
      paper_id: data.id,
      name: data.title,
      quest_count: data.questionnum,
      score: data.score
    })
  }

  return (
    <div>
      <Search list={list} showReset={false}>
        <Form.Item label={'试卷id'} name={'paperId'}>
          <Input allowClear autoFocus />
        </Form.Item>
      </Search>

      <Table<Paper.PaperOriginal>
        list={list}
        size={'small'}
        columns={[
          { title: '试卷id', dataIndex: 'id' },
          { title: '试卷', dataIndex: 'title' },
          { title: '题量', dataIndex: 'questionnum' },
          { title: '总分', dataIndex: 'score' },
          {
            title: '难度',
            dataIndex: 'easy',
            render: t => (
              <span>
                {t === 0 ? '易' : t === 1 ? '中' : t === 2 ? '难' : ''}
              </span>
            )
          },
          {
            title: '创建时间',
            dataIndex: 'createtime',
            render: t => <span>{moment(t).format('YYYY-MM-DD HH:mm')}</span>
          },
          {
            title: '操作',
            key: 'actions',
            render: (t, r) => <a onClick={() => onConfirm(r)}>选择</a>
          }
        ]}
      />
    </div>
  )
}
