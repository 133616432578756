import { MarkingMode, MarkingRange, QuestionTypeKey, SubjectKey } from 'config'
import { Moment } from 'moment'
import { DateTimeInString, Teacher } from './typing.exam'

export enum Type2BlankType {
  Choice = 0,
  Input = 1
}

export type Paper = {
  // exam_paper_id
  id: number
  paper_id: number
  exam_id: number
  name: string
  begin_time: DateTimeInString
  end_time: DateTimeInString
  review_time: DateTimeInString
  quest_count: number
  review_mode: MarkingMode
  score: number
  subject: SubjectKey
  // Form
  _date: Moment
  _timeRange: Moment[]
  papers: Paper[]
  //TODO remove
  type: number
}

export type PaperOriginal = {
  id: number
  title: string
  easy: 0 | 1 | 2
  createtime: number
  questionnum: number
  score: number
  paperlibraryrelation: {
    // Group
    data: {
      id: number
      type: QuestionTypeKey
      questionnum: number
      score: number
      coursequestiontypename: string
      paperlibraryrelationquestion: {
        // Question
        data: {
          id: number
          type: QuestionTypeKey
          score: number
          content: string
          answer: string | PaperOriginalAnswer[] | PaperOriginalAnswerData[]
          __order: number
          // parent question
          q__id?: number
          q__coursequestiontypename?: string
          q__content?: string
          q__score?: number
          q__order?: number
        }[]
      }
      __order: number
    }[]
  }
}

// Question inner answer type
export type PaperOriginalAnswer =
  | { name: string; content: string; isanswer: boolean }
  | { name: string; content: string }
  | { answer: string }

export type PaperOriginalAnswerData = {
  id: string
  type: QuestionTypeKey
  name: string
  data: {
    content: string
    score: number
    answer: PaperOriginalAnswerOrNestingData
  }
}

export type Type25OriginalAnswerData = [
  { optionalNum: number },
  PaperOriginalAnswerData[]
]

// PaperOriginal Alias
export type PaperOriginalQuestionGroup = PaperOriginal['paperlibraryrelation']['data'][0]
export type PaperOriginalQuestion = PaperOriginalQuestionGroup['paperlibraryrelationquestion']['data'][0]
export type PaperOriginalAnswerOrNestingData = PaperOriginalQuestion['answer']

export type QuestionBox = {
  id: number
  title: string
  easy: 0 | 1 | 2
  questionCount: number
  score: number
  group: QuestionGroup[]
  isType2Choice?: boolean
}

export type QuestionGroup = {
  id: number
  type: QuestionTypeKey
  questionCount: number
  score: number
  children: Question[]
  displayName: string
  typeNameAlias: string

  // biz extensions
  __order: number
}

export type Question =
  | QuestionOption
  | QuestionContent
  | QuestionNesting
  | QuestionFlattenNesting

type QuestionBase = {
  id: QuestionIdentity
  type: QuestionTypeKey
  score: number
  content: string
  displayName: string
  coursequestiontypename?: string

  // biz extensions
  __order: number

  // local extensions
  _groupId?: number // for type2 group-marking
}

export type QuestionOption = QuestionBase & {
  cate: 'option'
  answer: AnswerOption[]
}

export type QuestionContent = QuestionBase & {
  cate: 'content'
  answer: AnswerContent[]
}

export type QuestionNestingChildQuestion = Question & { id: string }

export type QuestionNesting = QuestionBase & {
  cate: 'nesting'
  children: QuestionNestingChildQuestion[]
}

export type QuestionFlattenNesting = QuestionBase & {
  cate: 'flatten-nesting'
  id: string
  q_id: number
  q_coursequestiontypename: string
  q_content: string
  q_score: number
  q__order: number
}

export type AnswerOption = {
  name: string
  content: string
  isanswer: boolean
}

export type AnswerContent = {
  name: string
  content: string
  answer: string
}

export type QuestionIdentity = number | string

/**
 * 试卷设置
 */
export type PaperMarkingSettingFormData = Paper & {
  teachers: Teacher[]
  review_mode: MarkingMode
  review_range: MarkingRange
  hide_info: 1 | 0
  review_time: DateTimeInString | Moment
  review_end_time: DateTimeInString | Moment
  quest_group: {
    quests: QuestionIdentity[]
    teachers: number[] | number[][]
  }[]
  relation_id: number
  class_id: number
  review_distribute_status: PaperMarkingSettingDistributeStatus
  students: {
    empty: number
    loading: number
    loaded: number
  }
  has_setting: boolean

  // 多人阅卷
  judge?: Teacher
  judge_filter?: any[]
  judge_filter_in_percent?: number
  score_plus_mode: 0 | 1 | 2
  score_auto_count: 0 | 1

  // 督查
  inspector: Teacher[]
  change_score: 0 | 1

  // 填空题合并批改
  question_type2_group_marking: boolean

  // 按步打分
  step_score_setting?: string

  // 仲裁分差设置
  judge_filter_by_question?: {
    question: number[]
    percent: number
  }[]

  // 多人仲裁
  judge_teacher_by_question?: {
    question: number[]
    teacher: Teacher
  }[]
}

export enum PaperMarkingSettingDistributeStatus {
  PENDING = 0,
  RUNNING = 1,
  DONE = 2
}

export type PaperWithStatistic = Paper & {
  distribute_count: number
  student_count: number
  student_complete_count: number
  student_not_complete_count: number
  teacher_count: number
}

export type SteppedScoreSetting = {
  enable: false
  items: {
    enable: boolean
    question_id: QuestionIdentity
    steps: {
      title: string
      score: number
      description: string
    }[]
  }[]
}
