import { ExclamationCircleOutlined, SettingOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Divider,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message
} from 'antd'
import classnames from 'classnames'
import { Toolbar as ImageDrawerToolbar } from 'components/ImageDrawer/toolbar/Toolbar'
import { PageWrapper } from 'components/PageWrapper'
import { useQuestionAnswerDataContent } from 'hooks/useQuestionAnswerData'
import _ from 'lodash'
import {
  StudentMarkStatus,
  useMarkingForm
} from 'pages/marking-form/useMarkingForm'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { APP_SCOPE, redirectToFanYa } from '../../config'
import { AnswerSheetLoader } from './components/AnswerSheetLoader'
import { PartialMarkingSetting } from './components/PartialMarkingSetting'
import { QuestionIndex } from './components/QuestionIndex'
import { SettingPopover } from './components/SettingPopover'
import { StudentSearch } from './components/StudentSearch'
import { QuestionList } from './question-list/QuestionList'

const { Text } = Typography

export const MarkingForm: FC = props => {
  const query = useParams<{ id }>()
  const history = useHistory()

  const { isCurrentUserHasAuditRole } = useQuestionAnswerDataContent()
  const { userImageMarkingMode } = useMarkingForm()

  const {
    loading,
    saving,
    getSpecMarkingId,

    setMarkingPaperId,
    markingPaper,
    currentStudentIndex,
    studentList,
    isAllMarkedOnInitial,
    isCurrentStudentMarked,

    currentStudent,
    validateScoreForm,
    submitScoreForm,
    skipCurrentStudent,
    scrollToQuestion,

    hasNextStudent,
    gotoPrevStudent,
    gotoNextStudent,
    pageReload,

    markingSubmitConfirmIgnore,

    partialMarkingQuestions,
    unmarkedStudentCount,
    setPartialMarkingSettingVisible,

    teacherMarkingQuestionGroup,
    teacherCurrentMarkingQuestionGroupIndex
  } = useMarkingForm()

  useEffect(() => {
    setMarkingPaperId(_.parseInt(query.id))
  }, [])

  function onBackClick() {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: '确定退出',
      content: '此份试卷评分将不保存',
      onOk: () => {
        if (APP_SCOPE.isInFanYa) {
          redirectToFanYa()
        } else {
          getSpecMarkingId() ? history.goBack() : history.replace('/marking')
        }
      }
    })
  }

  function onSubmit() {
    const { error, errorList } = validateScoreForm()
    if (!error) {
      if (markingSubmitConfirmIgnore) {
        setTimeout(() => {
          submitScoreForm()
        })
      } else {
        Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          title: '确定评分无误，进行提交',
          content: <div>可按「回车」确认</div>,
          onOk: () => submitScoreForm()
        })
      }
    } else {
      Modal.confirm({
        title: '还有题目未评分，请检查',
        icon: <ExclamationCircleOutlined />,
        cancelText: '跳过当前答卷',
        onOk: () => {
          errorList[0] && scrollToQuestion(errorList[0].questionId)
        },
        onCancel: () => {
          onSkipClick()
        }
      })
    }
  }

  function onSkipClick() {
    Modal.confirm({
      title: '将跳过不批改当前答卷，请确认',
      icon: <ExclamationCircleOutlined />,
      okText: '确认跳过',
      onOk: () => {
        skipCurrentStudent()
      }
    })
  }

  // 非回评
  const isNotRemarking = useMemo(() => !getSpecMarkingId(), [])

  // Pager
  const isPagerPrevDisabled = useMemo(() => {
    return currentStudentIndex === 0
  }, [currentStudentIndex])

  const isPagerNextDisabled = useMemo(() => {
    if (!isCurrentStudentMarked) return true
    if (isLastIndexOfStudentList()) return true
    return !hasNextStudent()
  }, [currentStudentIndex, isCurrentStudentMarked])

  function isLastIndexOfStudentList() {
    return currentStudentIndex! >= studentList.length - 1
  }

  function onPagerPrevClick() {
    if (currentStudentIndex! <= 0) {
      message.info('已经是第一份答卷')
      return
    }
    gotoPrevStudent()
  }
  function onPagerNextClick() {
    // 1. 当前答卷是否批改
    // 2. 是否是最后一份答卷
    // 3. 向后查找已批改的答卷
    if (!isCurrentStudentMarked) {
      message.info('请批阅当前答卷')
      return
    }

    if (isLastIndexOfStudentList()) {
      message.info('已经是最后一份答卷')
      return
    }

    if (!hasNextStudent()) {
      message.info('没有更多已批阅的答卷')
      return
    }
    gotoNextStudent()
  }

  const [pagerGoValue, setPagerGoValue] = useState<
    number | string | undefined
  >()

  function onPagerGoClick() {
    const index = Number(pagerGoValue) - 1

    if (index > studentList.length - 1) {
      message.info('该数值超过当前已批阅份数，请重新输入')
      return
    }

    pagerGoValue &&
      pageReload(
        // limit index in [0, studentList.length - 1]
        Math.min(Math.max(index, 0), studentList.length - 1)
      )
  }

  const hasPartialMarkingSetting = useMemo(() => {
    return partialMarkingQuestions.length > 0
  }, [partialMarkingQuestions])

  //
  const [answerCardVisible, setAnswerCardVisible] = useState(false)

  return (
    <PageWrapper
      content={
        <div>
          <Row style={{ marginBottom: 16 }}>
            <Col flex={1}>
              <Space>
                <Text strong style={{ fontSize: 16 }}>
                  {markingPaper && markingPaper.name}
                </Text>
                {isCurrentUserHasAuditRole ? (
                  <>
                    <Tag color='red'>仲裁批阅模式</Tag>
                    {isAllMarkedOnInitial && (
                      <Tag color={'#52c41a'}>已全部仲裁</Tag>
                    )}
                  </>
                ) : (
                  <>
                    {isAllMarkedOnInitial && (
                      <Tag color={'#52c41a'}>已全部批阅</Tag>
                    )}
                  </>
                )}
                {hasPartialMarkingSetting && (
                  <Tag
                    color={'blue'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPartialMarkingSettingVisible(true)}
                  >
                    部分批阅模式 <SettingOutlined />
                  </Tag>
                )}

                {teacherMarkingQuestionGroup &&
                  teacherMarkingQuestionGroup.length > 1 && (
                    <Space style={{ marginLeft: 24 }}>
                      <span>当前题组:</span>
                      <Radio.Group
                        value={teacherCurrentMarkingQuestionGroupIndex}
                        onChange={e => {
                          message.loading('正在切换题组')
                          const groupId = e.target.value
                          setTimeout(() => {
                            pageReload(undefined, groupId)
                          }, 1000)
                        }}
                        size='small'
                      >
                        {teacherMarkingQuestionGroup.map((item, index) => {
                          return (
                            <Radio.Button key={index} value={index}>
                              题组{index + 1}
                            </Radio.Button>
                          )
                        })}
                      </Radio.Group>
                    </Space>
                  )}
              </Space>
            </Col>
            <Col>
              <Button
                type={'primary'}
                size={'small'}
                onClick={() => onBackClick()}
              >
                退出阅卷
              </Button>
            </Col>
          </Row>

          <Row justify='space-between'>
            <Col>
              <Space>
                <Text type={'secondary'} className='page-info'>
                  <>
                    共 <span className={'primary'}>{studentList.length}</span>{' '}
                    份试卷，
                  </>
                  {isNotRemarking && (
                    <>
                      待批阅{' '}
                      <span className={'primary'}>{unmarkedStudentCount}</span>{' '}
                      份，
                    </>
                  )}
                  <>
                    当前第{' '}
                    <span className={'primary'}>
                      {typeof currentStudentIndex === 'number' &&
                        currentStudentIndex + 1}
                    </span>{' '}
                    份试卷
                  </>
                </Text>
                {currentStudent?.is_complete === StudentMarkStatus.MARKED ? (
                  <Tag color={'success'}>已批阅</Tag>
                ) : currentStudent?.is_complete ===
                  StudentMarkStatus.PARTIAL_MARKED ? (
                  <Tag color={'warning'}>已部分批阅</Tag>
                ) : (
                  <Tag color={'warning'}>待批阅</Tag>
                )}
              </Space>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              {isNotRemarking && (
                <Space className='pager'>
                  <a
                    className={classnames({
                      'ant-btn': true,
                      'ant-btn-default': true,
                      'ant-btn-sm': true,
                      'pager-prev': true,
                      disabled: isPagerPrevDisabled
                    })}
                    onClick={onPagerPrevClick}
                  >
                    上一份
                  </a>
                  <a
                    className={classnames({
                      'ant-btn': true,
                      'ant-btn-default': true,
                      'ant-btn-sm': true,
                      'pager-next': true,
                      disabled: isPagerNextDisabled
                    })}
                    onClick={onPagerNextClick}
                  >
                    下一份
                  </a>
                  {currentStudentIndex !== undefined && (
                    <Space style={{ marginLeft: 10 }}>
                      <Typography.Text>跳转到</Typography.Text>
                      <InputNumber
                        defaultValue={currentStudentIndex + 1}
                        style={{ width: 60 }}
                        size='small'
                        min={1}
                        onChange={setPagerGoValue}
                      />
                      <Typography.Text>份</Typography.Text>
                      <Button size='small' onClick={onPagerGoClick}>
                        GO
                      </Button>
                    </Space>
                  )}
                </Space>
              )}
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Space>
                <StudentSearch />
                <SettingPopover />
              </Space>
            </Col>
          </Row>

          <Divider style={{ margin: '12px 0' }} />

          {markingPaper &&
            (!markingPaper.hide_info || !!markingPaper.show_answer_card) && (
              <Space
                className={'student-info'}
                size={'large'}
                style={{ marginBottom: 5 }}
              >
                {!markingPaper.hide_info && currentStudent && (
                  <>
                    <span>
                      学号: {currentStudent.student_login_name || '-'}
                    </span>
                    <span>姓名: {currentStudent.student_user_name || '-'}</span>
                  </>
                )}
                {!!markingPaper.show_answer_card && (
                  <>
                    <AnswerSheetLoader>
                      {({ open }) => <a onClick={open}>查看答题卡</a>}
                    </AnswerSheetLoader>
                  </>
                )}
              </Space>
            )}

          <QuestionIndex />
        </div>
      }
    >
      {loading ? (
        <Spin />
      ) : (
        <div>
          <QuestionList />
          <div className={'footer'}>
            <Button
              type={'primary'}
              size={'large'}
              shape={'round'}
              disabled={saving}
              onClick={onSubmit}
            >
              提交
            </Button>

            <PartialMarkingSetting />
          </div>

          {userImageMarkingMode && (
            <div className='image-drawer inline'>
              <ImageDrawerToolbar
                layout='page'
                onRotate={() => {}}
                onSave={() => {}}
                onUndo={() => {}}
                onRedo={() => {}}
              />
            </div>
          )}
        </div>
      )}
    </PageWrapper>
  )
}
